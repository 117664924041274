import React , { Component , Fragment}  from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { isNull } from 'lodash';


/*
| UI
*/
import { InputNumber, Space, Descriptions, Popconfirm, Tooltip, Badge, Divider, Button, Alert, Typography, Tag, AutoComplete, Input, Form       } from 'antd';
import { Col, Row,  Dropdown, ListGroup, Modal, CloseButton   }  from 'react-bootstrap';
// import  AsyncSelect            from 'react-select/async';
// https://www.npmjs.com/package/react-bootstrap-typeahead
import {  Menu, MenuItem, AsyncTypeahead, Highlighter  } from 'react-bootstrap-typeahead';

import { isIterableArray } from '../../../helpers/utils';

import { defaultTheme }         from 'react-select';

// import InvoiceService           from '../../../services/';
// import ClientModalForm          from './ClientModalForm';
// import ClientContactSection     from './ClientContactSection';
// import CreateClientModal        from './CreateClientModal';

import Flex from 'components/common/Flex'



/*
| ICONS
*/
import { PiUserPlusDuotone, PiArrowElbowDownLeftBold, PiArrowDownBold, PiArrowUpBold, PiMagnifyingGlassDuotone, PiPlusBold, PiEyeDuotone, PiNotePencilDuotone, PiUserFocusDuotone, PiArrowUUpLeftDuotone } from "react-icons/pi";

import {BiEdit} from 'react-icons/bi';
import {FiUserPlus} from 'react-icons/fi';



/*
| Pages
*/
import CustomerForm from './CustomerForm'
/*
| Customers
*/
import CustomerDisplayCard from './customerOverview/CustomerDisplayCard';


/*
| Services
*/
import CustomerService  from '../services/';


const { colors } = defaultTheme;


const WAIT_INTERVAL = 200;

// const mapStateToProps = ({ settings }) => ({
//   settings : settings
// })

class CustomerSearchBilling extends Component  {

    constructor(props) {
        super(props);
        let clients = [];

        let isClientSelected = false;
        let canRestore       = false;
        
        // this.props.data.map(function(object, i){
          
        //   if( i == 0 )
        //   {
        //     if( parseInt(object.idClient) > 0)
        //     {
        //       isClientSelected = true;
        //       canRestore       = true;
        //     } 
        //   }
        //   if( typeof  object['billing'] !== 'undefined')
        //   {
        //     clients.push(object['billing']);
        //   }          
        //   if( typeof  object['shipping'] !== 'undefined')
        //   {
        //     clients.push(object['shipping']);
        //   }           
        // })
        this.searchRef = React.createRef();

        this.state = { 
          clients : clients,
          clientsReserve : this.props.data,
          clientsSearchOptions  : [],
          clientSearchOnModal : false,
          clientSelected: isClientSelected,
          canRestore: canRestore,
          selectedIndex: -1,
          createCustomerModalStates: false,
          createCustomerFirstName:'',

          windowWidth :  window.innerWidth,
          isMobileView : false,

        };

        this.handleClientChangeInternal = this.handleClientChangeInternal.bind(this);  
        this.customerFormCallBackBilling = this.customerFormCallBackBilling.bind(this);  
        // this.handleToggleCreateClientModalParent = this.handleToggleCreateClientModalParent.bind(this); 

    }

  componentDidMount(){

      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
  }

    updateDimensions = () => {
      let state = {...this.state};

      state.windowWidth = window.innerWidth;
      if( state.windowWidth < 1024) { state.isMobileView = true; } else { state.isMobileView = false; }
      this.setState(state);
    }

    _handleSearch =  (targer) => {
    let query = targer;
    this.setState({
      createCustomerFirstName: query,
    });  
    if(query !== '')
    {
            
      clearTimeout(this.timer);

      this.timer = setTimeout(() =>
      {        
        CustomerService.getCustomerBillingBySearch(query).then((res) =>{
        try{

          if( res.data !== undefined ){
            let options = res.data;

            this.setState({
              clientsSearchOptions: options,
              selectedIndex : -1
            });    
          }
        } catch(error){  } 
      });
    }, WAIT_INTERVAL);         
    } else {

      this.setState({
        clientsSearchOptions: [],
        selectedIndex: -1,
      });         
    }
  }
  handleClientChangeInternal =  (r) => {
    if (isIterableArray(r)) {

      var result = r[0];
      let client = this.clientAddFields(result);

      let state = this.state;
      state.client = client;
      state.clientSelected = true;
      state.clientSearchOnModal = false;
      state.selectedIndex = -1;




        this.setState(state); 
        this.props.handleBillingClientChange(client);             
    }
  }

  

  clientAddFields = (client) => {
    // coordinates should not be required
    let newClientSet =
    {
        idClient     : client.idClient,
        type         : client.type,
        firstName    : client.firstName,
        lastName     : client.lastName,
        fullName     : client.fullName,
        email        : client.email,
        mobile       : client.mobile,
        note         : client.note,

    };
    return newClientSet;
  }
  chooseAnotherCustomerClick = () => {

    this.setState({
      clientSelected: false,
      clientSearchOnModal:true,
      clients:this.state.clientsReserve
    });
    this.props.handleBillingClientChange(this.state.clientsReserve);
 
  }

  retrieveCustomerClick = () => {
    this.setState({
      clients:this.state.clientsReserve,
      clientSelected: true
    });  
    this.props.handleBillingClientChange(this.state.clientsReserve);
 
  }



  setSelectedItemTo = (idKey) => {
    this.setState({
        selectedIndex : idKey
    }) 
    this.searchRef.current.focus({
        preventScroll: true,
    })
  }  

    handleKeyDown = (e) => {
      const { selectedIndex, clientsSearchOptions } = this.state;
  
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (selectedIndex < clientsSearchOptions.length -1) {

          this.setState({ 
              selectedIndex: selectedIndex + 1 
          });

        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (selectedIndex > -1) {
          this.setState({ 
              selectedIndex: selectedIndex - 1 
          });
        }
      } else if (e.key === 'Enter' && selectedIndex !== -1) {
        this.handleClientChangeInternal([clientsSearchOptions[selectedIndex]]);
      }
    };

  displaySearchModal = () => {
    this.setState({clientSearchOnModal:true});  
  }

  // handleToggleCreateClientModalParent = (val) =>{
  //   this.setState({createCustomerModalStates:val})
  // }

  customerFormCallBackBilling = (data) => {  

    this.props.customerFormCallBackBilling()

  } 



  selectedDisplay = () => {
    const { Text, Title, Paragraph } = Typography;
    if( this.state.selectedIndex == -1 )
    {
        return (
            <Fragment>
                <Col xs={12} className='my-5 text-center'>
                    <Space direction="vertical">
                        <div className='icon-box p-2 rounded-xl bg-soft-primary'>
                            <PiUserFocusDuotone className='fs-3 text-primary' />
                        </div>
                        <Text className='mb-0 text-600'>Search billing accounts<br/>and see the detail here</Text>
                    </Space>
                </Col>
            </Fragment>
        )
    } else {


        return this.state.clientsSearchOptions.map((options, catIndex)=>{

          if (catIndex == this.state.selectedIndex ){
              return (
                  <Fragment>
                      <Col>
                          <div>
                          <CustomerDisplayCard showStatistics={false} idClient={options.idClient} editable={false} cardBorder="success" boxShadow={true}  
                            
                            style={{boxShadow:'none', background: 'var(--varexe-bg-100)',  border:'none', padding:'0px', margin: '0px', marginBottom: '0px'}}
                          />
                          </div>
                      </Col>                                
                  </Fragment>
              )
          }
        });

    }
    
}


 render(){
 const {Title, Text } = Typography;

return (
<div className="">

{(this.state.createCustomerModalStates?
<div className='my-2'>

<Fragment>
      <h6 className="text-warning mt-2">
        <Link to="#" className='btn btn-sm btn-soft-primary' onClick={()=>{
          this.setState({createCustomerModalStates: false})
        }} >
          <PiArrowUUpLeftDuotone className="fs-1 me-1 text-warning" /> Search billing accounts
        </Link>
        </h6>
</Fragment>

  <CustomerForm 
    // {...this.props} 
    idMain={this.props.idClient}
    target = "billingAccount" 
    customerFormCallBack={this.customerFormCallBackBilling}
    />  

</div>
  :   
<div className='my-4' 
                // onEnter={()=>{
                //     this.searchRef.current.focus({
                //         preventScroll: true,
                //     })                       
                // }}
                >
                    <div className="">
                        <h6 className="mb-1">Select or Create a Billing account</h6>
                        <p className="fs--2 ">
                        You can create a new billing or select an existing billing account for your customer.
                        </p>
                    </div>  
                        <Row className='gx-0'>
                            <Col md={6}>
                                {/* Search */}
                                    <div>

                                    <Form
                                        className="m-2 mb-0"
                                        disabled={false}

                                        >
                                        <Form.Item className='mb-0'>
                                            <AutoComplete
                                            
                                            allowClear={true}
                                            onSearch={this._handleSearch}
                                            onKeyDown={this.handleKeyDown}
                                            >
                                            <Input 
                                                ref={this.searchRef}
                                                prefix={<PiMagnifyingGlassDuotone className='fs-1 text-primary' />}
                                                size='large' 
                                                placeholder="Search"  
                                            />
                                            </AutoComplete>
                                        </Form.Item>
                                        
                                        </Form>  
                                        
                                        <div className="text-end m-0 ">
                                            <Button 
                                                type="link"
                                                icon={<PiPlusBold className=' me-2 text-primary' />}
                                                size='sm'
                                                className="fs--1 m-0"
                                                onClick={()=>{
                                                this.setState({createCustomerModalStates:true});
                                                }} 
                                            >
                                                Create a new billing account
                                            </Button>  
                                        </div>
                                        <ListGroup variant="flush">

                                          {this.state.clientsSearchOptions.map((options, catIndex) => {
                                              
                                          return (
                                              <Fragment  key={catIndex}>
                                                  <ListGroup.Item 
                                                  action 
  
                                                  active={catIndex === this.state.selectedIndex ? true: false}
                                                  >
                                                      <Flex>
                                                          <div className='flex-grow-1'>
                                                          <Flex 
                                                              justifyContent="between" 
                                                              alignItems="center"
                                                              className='cursor-helper'
                                                                  onClick={()=>{
                                                                      this.handleClientChangeInternal([options])
                                                                  }}                                        
                                                              >
                                                              <div >
                                                                  <p className='fs--1 mb-0'>{options.fullName}</p>
                                                              </div>
                                                              {catIndex === this.state.selectedIndex ? <PiArrowElbowDownLeftBold />: null}
                                                          </Flex>                                              
                                                          </div>
                                                          <div>
                                                          {(!this.state.isMobileView ?
                                                          <Flex gap="small" wrap="wrap">
                                                              {/* <Button type='default' size='small' className='btn-btn-sm btn-falcon'   onClick={()=>{
                                                                  this.setSelectedItemTo(catIndex)
                                                              }}>
                                                                  <PiEyeDuotone />
                                                              </Button> */}
                                                              <Button type='default' size='small' className='btn-btn-sm btn-soft-primary'   onClick={()=>{
                                                                  this.setSelectedItemTo(catIndex)
                                                              }}>
                                                                  <PiEyeDuotone />
                                                              </Button>
                                                          </Flex>
                                                          :null)}
                                                          </div>
                                                      </Flex>
                                                  </ListGroup.Item>
                                              </Fragment>
                                          )})}
                                        </ListGroup>

                                </div>        
                                {/* end Search */}
                            </Col>
                            {(!this.state.isMobileView ? 
                                <Col md={6} className='border-start' style={{boxShadow: 'inset 0 0 0px'}}>
                                    <div>
                                    {this.selectedDisplay() }
                                    </div>
                                </Col>
                            : null)}
                        </Row>


                    <Row>
                      <Col xs={12} className='text-end py-2'>
                          <Flex inline >
                              <div><Text className='text-400' code><PiArrowElbowDownLeftBold /></Text> <Text>to select</Text></div>
                              <div><Text code><PiArrowDownBold /></Text> <Text code><PiArrowUpBold /></Text> <Text>to navigate</Text></div>
                              <div><Text code>esc</Text> <Text>to close</Text></div>
                          </Flex>                        
                      </Col>
                    </Row>

</div>
)}



</div>
);
}
};

export default CustomerSearchBilling;