import React from 'react';

import { Typography, Space, Tag     } from 'antd';

import { Col, Row, Container } from 'reactstrap';
import { version } from '../../config';
import Flex from '../common/Flex'
import VarexeLogo from 'components/common/VarexeLogo';


const { Text, Title, Paragraph } = Typography;
const Footer = () => (
  <footer className="footer">
    <div className='border-top'>
      <Flex direction="row" className={'pt-3 px-2'}>
        <div className='flex-grow-1'>
          <Space direction="vertical">
            <Text className="mb-0 text-600 fs--1">
            <VarexeLogo type={'svg'} style={{width:'20px'}}  className='fs-1'/> Developed by &copy; <a href="https://varexe.com/">varexe.com</a>   <span className="d-none d-sm-inline-block"> </span>
              <br className="d-sm-none" /> {new Date().getFullYear()} 
              <br />
              Please note that your security is important to us. Varexe has implemented a series of security measures designed to keep your information protected and confidential.
  If you have any quetions please contact us support@varexe.com.  
            </Text>
            <Text className='fs--1 text-600'>
          
            </Text>          
          </Space>
        </div>
        <div className='flex-shrink-1'>
          <h6 className="mb-0 text-600"><Tag color="blue">v{version}</Tag></h6>
        </div>
      </Flex>
    </div>
  </footer>
);

export default Footer;
