
import React, { Component, Fragment } from 'react';
import PropTypes                  from 'prop-types';
// import Select from 'react-select'
import Select from 'react-select';
import {Placeholder, Card } from 'react-bootstrap';

import CountriesService from '../services';

/*
| 2 npm installed for the flags
*/
// Direct for 1 import svg
// IF NOT USED DELETE THIS country-flag-icons
// import US from 'country-flag-icons/react/3x2/US'
// this is react component
import ReactCountryFlag from "react-country-flag"

class AdvancedCountrySelect extends Component
{
    constructor(props){
        super(props)
        this.state = {
            serviceType : this.props.serviceType,
            defaultValue: this.props.defaultValue,
            options:[],
            loading:true
        }
    }
    componentDidMount(){    
        this.makeOptions();
    }
    switchServiceType(key){

        switch (key) {
            case "countries":
                return CountriesService.getCountries();
            break;
            case "timezones":
                return CountriesService.getTimezones(this.props.idCountry);
            break; 
            case "states":
                return CountriesService.getStates(this.props.idCountry);
            break;
            case "cities":
                 return CountriesService.getCities(this.props.idState);
            break;            
        
            default:
                break;
        }
    }



    makeOptions(){

       const MakeOptionFlag = ({iso2, country}) => {
            return(
                <Fragment>
                    <ReactCountryFlag
                    className='me-2'
                    svg 
                    countryCode={iso2}
                    style={{
                    fontSize: '1em',
                    }}
                    />
                </Fragment>
            )
        }

        this.switchServiceType(this.state.serviceType).then((result)=>{
            try {
                let values = result.data.data;
                let options= [];

                options = values.map((res, index)=>{

                    let result   = res;
                    result.value = res.id;
                    result.label = res.name;

                    if( this.state.serviceType == 'countries')
                    {
                        result.image =  <MakeOptionFlag iso2={res.iso2} country={res.name} />;
                    }

                    if( this.state.defaultValue == res.id )
                    {
                        if( this.state.serviceType == 'countries')
                        {
                            this.setState({defaultValue : {value:res.id, label: res.name, image: <MakeOptionFlag iso2={res.iso2} country={res.name} />}});
                        } else {
                            this.setState({defaultValue : {value:res.id, label: res.name}}); 
                        }                        
                    }


                    if( this.props.defaultValue == null )
                    {
                        this.setState({defaultValue : {value:values[0].id, label: values[0].name}});
                        this.props.callBackResult({value:res.id, label: res.name});
                    }
                    // return {value:res.id, label: res.name} 
                    return result 
                })
                this.setState({options : options, loading : false});
            } catch(error){
                console.log(error);
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot){
          switch (this.state.serviceType) {
            case "countries":
                // return CountriesService.getCountries();
            break;
            case "timezones":
                if (prevProps.idCountry !== this.props.idCountry) {
                    this.makeOptions();
                }
            break;            
            case "states":
                if (prevProps.idCountry !== this.props.idCountry) {
                    this.makeOptions();
                }
            break;
            case "cities":
                if (prevProps.idState !== this.props.idState) {
                    if( this.props.idState != null )
                    {                    
                        this.makeOptions();
                    } else {
                        this.setState({options : [], defaultValue: null});
                    }
                }
            break;
            default:
                break;
        }          
    }   
    // componentWillUnmount(){

    // } 
    handleChange = (result) =>{
        this.setState({defaultValue : result});
        // this.props.callBackResult(result);
        this.state.options.map((res, index)=>{
            if( res.value == result.value )
            {
                this.props.callBackResult(res);
            }
        })
        
    }
    render(){


        return(
            <Fragment>
                {(this.state.loading?
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={12} /> 
                    </Placeholder>               
                :
                    <Select
                    value={this.state.defaultValue}
                    options={this.state.options}
                    formatOptionLabel={country => (
                        <div className="country-option">
                          {country.image}
                          <span className='text-nunito'>{country.label}</span>
                        </div>
                      )} 
                    onChange={this.handleChange}
                    // classNamePrefix="react-select"
                    />
                )}
            </Fragment>
        );
    }

}

AdvancedCountrySelect.protoTypes = {
    serviceType:  PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
    callBackResult: PropTypes.func.isRequired
};


export default AdvancedCountrySelect;
