import  React, { useEffect }    from "react";
// import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// import { Button, notification, Space } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Button, Dropdown } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import Flex from '../Flex';

import warningLight from 'assets/img/animated-icons/warning-light.json';
import Lottie from 'react-lottie';

/*
| <Toaster /> is added to the index page
*/

const warningLightOptions = {
  loop: true,
  autoplay: true,
  animationData: warningLight,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};  


const ToastVarexe = ({ 
                      type, 
                      title = 'Notification',
                      message, 
                      duration= 3,
                      placement = 'topRight' 
                    }) => {

                      let _class;
                      //  type = 'error';

  switch(type)
  {
    case 'success':
      _class  = 'success';
      toast[_class](
        message
        );
    break;
    case 'error':
      _class  = 'error';
      toast[_class](
        message,
        {
          duration: 6000,
        }         
        );
      
    break;
    case 'warning':
      _class  = 'warning';
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } notification max-w-md w-full bg-white  `}
        >
          <Flex direction='row' className="">  
          <div className="">
          <Lottie options={warningLightOptions} width="45px" height="45px"  />
          </div>
          <div className="">
            <h6>{title}</h6>
            {message}
          </div>
          <div className=" ms-2 border-l border-gray-200">
          <Button
                variant="primary"
                size="sm"
                className="fs--1 fw-normal btn btn-falcon-default btn-sm"
                onClick={() => toast.dismiss(t.id)}
                ><IoClose className=" fs-2 "/></Button>
          </div>
          </Flex>          

        </div>
      ))      
    break; 
    case 'info':
      _class  = 'info';
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } notification max-w-md w-full bg-white  `}
        >
          <Flex direction='row' className="">  
          <div className="">
          <Lottie options={warningLightOptions} width="45px" height="45px"  />
          </div>
          <div className="">
            <h6>{title}</h6>
            {message}
          </div>
          <div className=" ms-2 border-l border-gray-200">
          <Button
                variant="primary"
                size="sm"
                className="fs--1 fw-normal btn btn-falcon-default btn-sm"
                onClick={() => toast.dismiss(t.id)}
                ><IoClose className=" fs-2 "/></Button>
          </div>
          </Flex>          

        </div>
      ))      
    break;                     
  }
  
  return (
    <>
    </>
        )
  };
  
  ToastVarexe.propTypes = {
    type:     PropTypes.string.isRequired,
    message:  PropTypes.string.isRequired
  };
  
  export default ToastVarexe;