import React, {Component, Fragment} from 'react';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import dayjs from 'dayjs';

/*
| UI 
*/

import {  Col, Skeleton, Layout, Card, Divider, Typography, Form, Input , Switch, Steps, Tag, Space, Tooltip, Button, Checkbox, Radio, DatePicker,TimePicker, InputNumber, Alert, Select, Popconfirm, List, Popover    } from 'antd';
import {   Row } from 'react-bootstrap';
import Avatar from '../../common/Avatar'
import ToastVarexe              from '../../common/ToastVarexe';
import Flex                     from '../../common/Flex'
import { CirclePicker     } from 'react-color';
// import NumberFormat             from 'react-number-format';
// import FormGroupInput           from '../../../common/FormGroupInput';
// import useFakeFetch             from '../../../hooks/useFakeFetch';

/*
| Icons
*/



import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';
import { PiMagnifyingGlassDuotone, PiUserFocusDuotone, PiEnvelopeSimpleDuotone, PiBellRingingDuotone , PiPencilLineDuotone, PiPaperPlaneTiltDuotone, PiUserPlusDuotone, PiTextAlignLeftDuotone, PiMapPinLineDuotone, PiUserCircleDuotone, PiPaintBucketDuotone } from 'react-icons/pi';



/*
| Pages
*/

/*
| Utils
*/
import {fullName } from '../../../helpers/utils'



/*
| Serviecs
*/
import CalendarService        from '../services/';
import CustomerService        from '../../customers/services';

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;


const mapStateToProps = ({ settings }) => ({
    settings : settings
  })
  
  

class CalendarForm extends Component  { 


    static defaultProps = {
        shouldRedirect:  true,
        onSubmit : () => {}
      };



    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            idCalendar: this.props.idCalendar,
            title       :null,
            description :null,
            sendEmailConfirmation    :true,
            isAllDay    :false,
            startDate     :(this.props.scheduleStartDate ? moment(this.props.scheduleStartDate).format('YYYY-MM-DD') : null),
            startTime     :(this.props.scheduleStartDate ? dayjs(this.props.scheduleStartDate).format('HH:mm:ss') : null),
            endDate       :(this.props.scheduleEndDate   ? moment(this.props.scheduleEndDate).format('YYYY-MM-DD') : null),
            endTime       :(this.props.scheduleEndDate   ? dayjs(this.props.scheduleEndDate).format('HH:mm:ss') : null),   
            // backgroundColor: '#106EB6',
            labels: [],
            idCalendarLabel: 1,

            businessAddressReserve : null,
            
            clients : [],
            clientAssigned :  false,
            showClientSearch : false,
            location :  null,
            showLocationInput :  false,
            searchOptionClients: [],

            sendRemindersOn : [ 
                { key: 7  , value: false , isDisabled : false, scheduledDateFormated : '', isSent: false, tooltip : '7 day before'},
                { key: 3  , value: false , isDisabled : false, scheduledDateFormated : '', isSent: false, tooltip : '3 day before'},
                { key: 1  , value: false , isDisabled : false, scheduledDateFormated : '', isSent: false, tooltip : '1 day before'},
            ],
            
            user : null,
            displayUser: false

        }

    }


    componentDidMount = () => {
        this.getData(this.state.idCalendar);
        
    }

    getData = (idCalendar) => {

        CalendarService.getInitData(idCalendar).then((res) =>{

            if( res.data !== undefined ){
              let calendarService = res.data.data;

              let state = this.state;

              state.title       = calendarService.calendar.title;
              state.description = calendarService.calendar.description;
              state.isAllDay    = calendarService.calendar.isAllDay;
              state.businessAddress             = calendarService.calendar.business.fullAddress;
              state.businessAddressReserve      = calendarService.calendar.business.fullAddress;
              state.user                        = calendarService.calendar.user;
              state.idCalendarLabel             = calendarService.calendar.idCalendarLabel;

              

                if( idCalendar > 0 )
                {
                    state.startDate  = calendarService.calendar.startDate;
                    state.endDate    = calendarService.calendar.endDate;

                    if(calendarService.calendar.clients.length > 0  ){

                        this.getCustomerById(calendarService.calendar.clients[0].idClient);

                    }

                }

              this.setState({state}, () =>{
                this.getListCalendarLabel();
              }); 
              
              
            }
    
          });         
    }

    getListCalendarLabel = () => {
        CalendarService.getListCalendarLabel().then((res) =>{
          try{
    
    
            let result = res.data;
            let _label = [];

            result.data.map((param, index)=>{
                _label.push({label: param.name, value: param.idCalendarLabel , color: param.color})
            })
            
            this.setState({
              isLoading: false,
              labels: _label,
            })
    
          } catch(error){
            console.log(error);
          }
        });
    
      }    

    handleChange =  (target) =>  {

        const name      = target.name;
        const value     = target.value;
        const newState  = {};
    
        Object.keys(this.state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });
        this.setState(newState);
     }

     handleServiceUpdate = event => {
    //     var state = { ...this.state };
    //     if( this.state.serviceName == '' )
    //     {
    //       ToastVarexe({
    //         type :  'warning',
    //         title:   'Service name can not be empty',
    //         message: 'Your must at least add your Service name!',
    //         duration:5
    //     }); 
    //     } else {

    //     ServiceService.updateService(state.idService,state)
    //     .then((res) =>{

    //       let result = res.data;
    //       if( result.status == 200 )
    //       {
    //           ToastVarexe({
    //                   type :  'success',
    //                   message: result.message
    //           });      
    //       }

    //       if( this.props.shouldRedirect )
    //       {
    //           if( state.idService !== result.data.id ){
    //               this.setState({
    //                   redirect: `/services/service-details/${result.data.id}`,
    //               });
    //           }    
    //       } else {
    //         this.props.onSubmit()
    //       }
    //     });  
    //   }  
      }   
      


      onSubmit = (values) => {
        values.idCalendar          = this.state.idCalendar;
        values.startDate           = this.state.startDate;
        values.startTime           = this.state.startTime;
        values.endDate             = this.state.endDate;
        values.endTime             = this.state.endTime;
        values.isAllDay            = this.state.isAllDay;
        // values.backgroundColor     = this.state.backgroundColor;
        values.displayUser         = this.state.displayUser;
        values.user                = this.state.user;
        values.idCalendarLabel                = this.state.idCalendarLabel;
        
        values.clients = [];
        if( this.state.clients.length > 0 ){

            values.clients = this.state.clients.map((_client, clientIndex)=>{
                return {idClient: _client.idClient}
            })

            values.sendRemindersOn      = this.state.sendRemindersOn;
        }

        toast.promise(    

            CalendarService.updateCalendar(this.state.idCalendar, values)
                .then((res) =>{
                    let result = res.data;
                            this.props.refreshContent()
                }),
            {
            loading: 'Saving schedule information',
            success: <span>Schedule saved</span>,
            error: <span>Something went wrong!</span>,
            }
        );
      
    } 




    _handleSearchTax  = (query) => {
        // if(query != '')
        // {
        //   SalesTaxService.getSalesTaxBySearch(query).then((res) =>{
        //     try{
        //         let options = res.data;
        //         this.setState({taxesSearchOptions :options});  
        //     } catch(error){  } 
        //   });
        // } else { 
        // }
    } 



  

    // idCalendarLabel
    // handlePrimaryColorChange = (e) => { 
    //     this.setState({
    //         backgroundColor: e.hex
    //     })
    // }
    handleChangeCalendarLabel = (value) => { 
        this.setState({
            idCalendarLabel: value
        })
    }

    handleSearchCustomers = (value) => {
  
        if( value == '' ) return false;
  
        clearTimeout(this.timer);
  
        this.timer = setTimeout(() =>
        {
            CustomerService.getCustomerBySearch(value).then((res) =>{
            try{
  
              if( res.data !== undefined ){
                let options = res.data;
                var result = [];
                options.map((param, index)=>{
                  result.push(this.renderItem(param.idClient,param.fullName, param.email) )
                });
                this.setState({
                  searchOptionClients : result
                })
              }
            } catch(error){  } 
          });         
        }, WAIT_INTERVAL); 
  
  
      }; 

      renderItem = (idClient, title, email) => ({
        value: idClient,
        label: (
        <Flex direction='column'>
            <div><PiUserFocusDuotone className='fs-1 text-400'/> <b>{title}</b></div>
            <span>
             <PiEnvelopeSimpleDuotone className='fs-1 text-400' /> {email}
            </span>
        </Flex> 
        ),
      });

      onSelectCustomer = (data) => {
          
        let _data = {
            idClient : data.value,
        };

        this.getCustomerById(_data.idClient);
  
      }; 
      
      getCustomerById = (idClient) => {
          

        CustomerService.getCustomerById(idClient).then((res) =>{
            try{
  
              if( res.data !== undefined ){
                let result = res.data;

                this.setState({
                    clients: [...this.state.clients, result],
                    clientAssigned : true
                })

              }
            } catch(error){  } 
          });      
  
      };  


      scheduleSender = (e, number) => {
        let state = {...this.state};

        state.sendRemindersOn = state.sendRemindersOn.map((param, index) => {
            if(param.key == number)
            {
                param.value = e.target.checked;
            }
            return param;
        });

        this.setState(state);
    }      

      getScheduleSender = () => {
        let state = {...this.state};
        // InvoiceService.getScheduleSender(this.state.idInvoice).then((res) => {
        //     try {
        //         let result = res.data.data;
                
        //         state.otherExchangesLink = result.otherExchangesLink;
                
        //         if( result.schedules.length > 0 )
        //         {
                    
        //             state.sendOnDue          = result.schedules;

        //             state.sendOnDue.map((param, index) => {
        //                 if(param.isSent)
        //                 {
        //                     state.lastSendDue = 'with Varexe on ' + param.scheduledDateFormated;
        //                 }
        //             });
        //         }
        //         state.ToBeSendLoading = false;
        //         this.setState(state);
        //     } catch(error){
        //         console.log(error);
        //     }                
        // });
    }      



    handleRemoveCustomer = () => {
        this.setState({
            client: null,
            clientAssigned : false,
            searchOptionClients : []
        })        
    }

    onStartDateChange = (e) => {
        this.setState({
            startDate: moment(e).format('YYYY-MM-DD')
        })
    } 
    onStartTimeChange = (e) => {
        this.setState({
            startTime: dayjs(e).format('HH:mm:ss')
        })
    } 

    onEndDateChange = (e) => {
        this.setState({
            endDate: moment(e).format('YYYY-MM-DD')
        })
    } 
    onEndTimeChange = (e) => {
        this.setState({
            endTime: dayjs(e).format('HH:mm:ss')
        })
    }   


    render(){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        } 
        const { Text, Title, Paragraph } = Typography;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };    
        const colors = ['#FF6900', '#FFEB3B', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#106EB6',  '#EB144C', '#F78DA7', '#9900EF'];    
        return (
            
            <>
            {(this.state.isLoading ? 
                <Skeleton  className="mt-3 p-4" active />
            : 
            <Fragment>

             
                <Form
                    {...layout}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 18 }}
                    // layout="vertical"
                    // style={{ maxWidth: 600, margin:'auto' }}
                    className="mt-0 p-3"
                    disabled={false}
                    onFinish={this.onSubmit}
                    size='large'

                    initialValues={{
                        title:              this.state.title,
                        description:        this.state.description,
                        isAllDay:           this.state.isAllDay,
                        price:              this.state.price,
                        startDate:          this.state.startDate,
                        end:                this.state.end,
                        className:          this.state.className,
                        displayUser:        this.state.displayUser
                        }}

                >
                    <Row>
                        {/* <Col md={14} > */}
                            {/* <Form.Item
                            label="Title"
                            name="title"
                            className='pt-0'
                            onChange={({ target }) => this.handleChange(target)}  
                            
                            rules={[{ required: true, message: 'Title name is required' }]}             
                            >
                                <Input name="title"  defaultValue={this.state.title} Placeholder="Add title"   />            
                            </Form.Item>  */}

    <Form.Item className='mb-2' label={ (this.props.settings.isMobileView  ? 'Title' : <PiPencilLineDuotone className='fs-1' />)}>
        <Form.Item
          name={'title'}
          noStyle
          rules={[{ required: true, message: 'Title name is required' }]} 
        >
          <Input placeholder="Add title" />
        </Form.Item>
    </Form.Item>


    <Form.Item className='mb-2' label={(this.props.settings.isMobileView  ?  'Customer': <PiUserPlusDuotone className='fs-1' />)}>
        <Form.Item
          name={'customer'}
          noStyle
          className='mb-2'
        >
            {(!this.state.clientAssigned ? 
                <Fragment>

                        {(!this.state.showClientSearch ? 
                        <Fragment>
                            <Button  size='small' className='btn btn-soft-primary' onClick={()=>{
                                this.setState({showClientSearch:true})
                            }}>Add Customer</Button>
                        </Fragment>
                        :
                        <Fragment>
                                <Form.Item
                                className="mb-0"                                                       
                                >
                                <Select
                                suffixIcon={<PiMagnifyingGlassDuotone className='fs-1' />}
                                showSearch
                                labelInValue
                                filterOption={false}
                                onSearch={this.handleSearchCustomers}
                                options={this.state.searchOptionClients}
                                onChange={this.onSelectCustomer}
                                style={{minWidth:250}}
                                size='large'
                                placeholder="Add a customer"
                                /> 
                                </Form.Item>
                                
                                <Form.Item
                                className="mb-2 text-end"                                                       
                                >
                                    <Button  size='small' type='link' danger onClick={()=>{
                                        this.setState({
                                            clientAssigned: false,
                                            showClientSearch:false,
                                            client:null,
                                        })
                                    }}>Remove</Button>  

                                </Form.Item>
          
            
                        </Fragment>
                        )}
            
                </Fragment>
            :
                <Fragment>


                    <Flex>
                        <div className='flex-grow-1'>
                            <Flex direction='row' wrap='wrap' className=" rounded-2 mb-3 mt-2" > 

                                <div>
                                    <p className='mb-0'><Text className='fw-semi-bold'>{fullName(this.state.clients[0].firstName, this.state.clients[0].lastName)}</Text></p>
                                    <p className='mb-0'><Text>{this.state.clients[0].email}</Text></p>
                                    <p className='mb-0'><Checkbox onChange={(e)=>{
                                        this.setState({
                                            sendEmailConfirmation : e.target.checked 
                                        })
                                    }} checked={this.state.sendEmailConfirmation}>Send schedule confirmation</Checkbox></p>
                                </div>
                            </Flex>
                        </div>
                        <div>
                        <Popconfirm
                            placement="topRight"
                            title="Remove Customer"
                            description="Are you sure to remove customer?"
                            okText="remove"
                            okType="danger"
                            onConfirm={this.handleRemoveCustomer}
                        >
                            <Button size='small' className='' type="text" danger>
                                Remove
                            </Button>                            
                        </Popconfirm>                 
                        </div>
                    </Flex>

                    <Row className="gx-0 kanban-header rounded-2  p-2  mb-0 border">
                        <div>
                        <Flex direction='row' wrap='wrap' className=" rounded-2 mb-0" > 
                        <div
                            className="bg-soft-primary px-1 py-1 rounded-2 text-center"
                            style={{ width: '30px', height: '30px' }}
                        >
                            <PiPaperPlaneTiltDuotone  className="fs-2 text-primary"/>
                        </div> 
                        <div className='ms-1'>
                            <p className="mb-0 fs--2 text-500">Send notifications to your customer</p>
                            <h5 className="text-primary fs-0">Reminders via email</h5>                            
                        </div>
                        </Flex>
                        <hr  className='mt-1' />
                        {/* <p><Text><Avatar size={'small'}  />} /> </Text></p> */}

                        <Flex direction='row' wrap='wrap' className=" rounded-2 mb-0" > 
                        <div
                            className=" px-1  rounded-2 text-center"
                        >
                            <PiBellRingingDuotone  className="fs-2 text-primary"/>
                        </div> 
                        <div className='ms-1'>
                            <p><Text>Choose the date to send notifications</Text></p>                           
                        </div>
                        </Flex>
                        <Flex direction='row' wrap='wrap' className=" rounded-2 mb-0" >  
                            {(this.state.sendRemindersOn.map((param, index)=>{
                                return (
                                <Fragment key={index}>
                                    <Tooltip placement="top" title={param.tooltip} arrow={true}>
                                        <div className="bg-200 p-2 me-2 rounded-2 border border-300 mb-2"  >
                                        <Checkbox 
                                            name={`sendRemindersOn${param.key}`}
                                            onChange={e => this.scheduleSender(e, param.key) }
                                            checked={param.value}
                                            disabled={param.isDisabled}
                                        >
                                        {param.key} before
                                        </Checkbox> 
                                        </div>
                                    </Tooltip>                                
                                </Fragment>
                                )
                            }))}
                        </Flex>
                        </div>
                    </Row>            

                </Fragment>
            )}
        </Form.Item>
    </Form.Item> 




                    <Divider orientation="left" plain>Time Range</Divider>
                    <Form.Item
                        label="All day"
                        name="isAllDay"   
                        className='mb-2'    
                    >
                        <Switch 
                            defaultValue={this.state.isAllDay} 
                            onChange={(value)=>{
                            const state     = this.state;
                            state.isAllDay = value;
                            this.setState(state)
                            }} 
                        />          
                    </Form.Item> 
                    <Form.Item
                        label="Start"
                        name="startDate"  
                        className='mb-2'         
                    >
                        <Space direction="horizontal" size={12}>
                            <DatePicker name='startDate' onChange={this.onStartDateChange}           
                                defaultValue={dayjs(this.state.startDate, 'YYYY-MM-DD')}  
                            />
                            {(!this.state.isAllDay ? 
                                <TimePicker name='startTime' use12Hours format="h:mm a" minuteStep={30} onChange={this.onStartTimeChange} 
                                    defaultValue={dayjs(this.state.startTime, 'HH:mm:ss')} 
                                />
                            : null)}
                        </Space>          
                    </Form.Item>   
                    <Form.Item
                        label="End"
                        name="end"           
                    >
                        <Space direction="horizontal" size={12}>
                            <DatePicker name='startTime' onChange={this.onEndDateChange}  
                                defaultValue={dayjs(this.state.endDate, 'YYYY-MM-DD')}  
                            />
                            {(!this.state.isAllDay ? 
                                <TimePicker name='endTime' use12Hours format="h:mm a" minuteStep={30} onChange={this.onEndTimeChange} 
                                    defaultValue={dayjs(this.state.endTime, 'HH:mm:ss')} 
                                />
                            : null)}
                        </Space>          
                    </Form.Item> 



                    <Form.Item
                        className="mt-3"
                        label={(this.props.settings.isMobileView  ?  'Description': <PiTextAlignLeftDuotone className='fs-1' />)}
                        name="description"
                        onChange={({ target }) => this.handleChange(target)}                                                        
                    >
                        <Input.TextArea name="description"  
                            // defaultValue={this.state.description}  
                        />            
                    </Form.Item>                                           





 <Form.Item className="mb-2" label={(this.props.settings.isMobileView  ?  'User': <PiUserCircleDuotone className='fs-1' />)}>
        <Space>
            <Form.Item
                className="mb-2"                                                 
            >
                        <div className='flex-grow-1'>
                            <Flex direction='row' wrap='wrap' className=" rounded-2 mb-3 mt-2" > 

                                <div>
                                    <p className='mb-0'><Text className='fw-semi-bold'>{fullName(this.state.user.firstName, this.state.user.lastName)}</Text></p>
                                    <p className='mb-0'><Text>{this.state.user.email}</Text></p>



                                </div>
                            </Flex>
                        </div>
                        <Checkbox 
                        disabled={(!this.state.clientAssigned ? true: false)}
                        onChange={(e)=>{
                            this.setState({
                                displayUser : e.target.checked 
                            })
                        }} >Let your customer know that the schedule is with {this.state.user.firstName}</Checkbox>                        
            </Form.Item>             
        </Space>
</Form.Item>




    <Form.Item className="mb-2" label={(this.props.settings.isMobileView  ?  'Location': <PiMapPinLineDuotone className='fs-1' />)}>

                <Form.Item
                    className="mb-0"
                    name="businessAddress"
                    onChange={({ target }) => this.handleChange(target)}                                                        
                >
                    {(this.state.showLocationInput ? 
                    <Input name="businessAddress" placeholder="Add location"     />            
                    :
                    <Fragment>
                        <p className='mb-0'>{this.state.businessAddress}</p>
                        <Button  className='ms-0 ps-0' size='small' type='link' onClick={()=>{
                            this.setState({showLocationInput:true})
                        }}>Change location</Button>        
                    </Fragment>
                    )}
                </Form.Item> 

                <Form.Item
                    className="mb-2 text-end"                                                       
                >
                {(this.state.showLocationInput ? 
                    <Button  size='small' type='link' danger onClick={()=>{
                        this.setState({
                            location: null,
                            showLocationInput:false,
                            businessAddress: this.state.businessAddressReserve
                        })
                    }}>Remove</Button>           
                    :
                    null        
                )}            
                    </Form.Item>                
    </Form.Item>





    <Form.Item name="idCalendarLabel" className="mb-2" label={(this.props.settings.isMobileView  ?  'Type': <PiPaintBucketDuotone className='fs-1' />)}>

    <Select
        
        style={{ width: '100%' }}
        placeholder="select a label"
        defaultValue={this.state.idCalendarLabel}
        onChange={this.handleChangeCalendarLabel}
        optionLabelProp="label"
        options={this.state.labels}
        optionRender={(option) => (
        <Space>
            <span>
            
            </span>
            test x{option.data.color} {option.data.label}
        </Space>
        )}
    />


        <Space>
        {/* <Popover placement="topLeft" title={'Schedule color'} content={ <CirclePicker  
                                colors={colors}
                                color={ this.state.backgroundColor }
                                onChangeComplete={ this.handlePrimaryColorChange }
                                circleSize={18}

                            />   } arrow={true}>
            <Text style={{ cursor:'pointer'}}>
            <div style={ {          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'}} 
          onClick={ this.handleClick }>
                <div style={ {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: this.state.backgroundColor,                    
                }} />
            </div>
            </Text>
        </Popover> */}
       
        </Space>
    </Form.Item>









    <Flex className="my-3" direction='row-reverse'>
        <div className="">
            <Button type="primary" htmlType="submit" size='sm'>Add Schedule</Button>
        </div>
    </Flex>    




                        {/* </Col> */}
                        {/* <Col md={10} className='bg-200'>
                           
                        </Col> */}
                    </Row>   

 
                </Form>

            </Fragment>
            )}
            
            </>
            )
    }

}

export default connect(mapStateToProps)(CalendarForm);
