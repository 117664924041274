import React , { Component, Fragment }  from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';

/* 
| UI
*/
import { Button, Skeleton, Collapse   } from 'antd';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from '../../common/Flex';

/*
| ICONS
*/
import {PiWarningDuotone, PiTimerDuotone} from "react-icons/pi"

/*
| Pages
*/

import InvoiceList   from '../components/partials/list/InvoiceList'
/* 
| UTILS
*/

import {formatCurrency} from 'helpers/utils';
import InvoiceService        from '../services';

export default class InvoicesOverdueList extends Component {

    static defaultProps = {
        idClient : null
    }  

    constructor(props) {
      super(props);  
  
        this.state = {
          isLoading: true,
          idClient : this.props.idClient,
          displaylist : this.props.displaylist,
          currencySymbol : '$',
          currencyCode : 'CAD',
          listTotalBill : 0,
          data : [],
          total : 0,

          isPageLoading : true,
          isButtonMoreLoading: false,
          tableSpinLoading: false,
          quickViewOpen : false,
    
          tableFilled: false,
          listPageIndex : 1,
          listPageSize : 3,
          listOrder : 'down',
          listSearch : '',
          listFilters:[       
            {id : 'statusTag'   ,operator: '=', value : 'overdue'  , type : 'OR' },     
          ],          
        
        }
    }
    componentDidMount(){ 
    let data = {
      listPageIndex :  this.state.listPageIndex,
      listPageSize : this.state.listPageSize,
      listSearch : ''
    }
    this.getData(data);
    } 
    getData = (data, dataReset = false) => {

        var state = this.state ;
        state.listPageIndex     = data.listPageIndex;
        state.listPageSize      = data.listPageSize;
        state.listSearch        = data.listSearch;
        state.listOrder                   = state.listOrder;
        state.listReactTableSearch       = state.listReactTableSearch;
        state.listFilters                = state.listFilters;
        state.generateType               = 'invoice';
    
        // this.setState(state);
    
        InvoiceService.getListFiltersInvoice(state).then( (response) => {
          try {
            if( response.data !==  undefined && response.data.data !==  undefined )
            {
              let result = response.data;
    
              state.isPageLoading           = false;
              state.isButtonMoreLoading     = false;
              state.tableSpinLoading        = false;
    
              if(!dataReset)
              {
                result.data.map((invoice, index) => {
                  state.data.push(invoice);
                })
              } else {
                state.data = result.data;
              }
    
              
    
              state.currencySymbol = result.currencySymbol;
              state.currencyCode = result.currencyCode;
              state.listTotalBill = result.listTotalBill;
              state.listPageIndex = result.listPageIndex;
              state.listPageSize  = result.listPageSize;
              state.listTotalSize = result.listTotalSize;
              state.tableFilled   = result.tableFilled;
    
              this.setState(state);
    
            }        
          } catch(error){
            console.log(error);
          }
         });
      }
    
    
      loadMore = () => {
        this.setState({
          isButtonMoreLoading : true
        })    
    
        let data = {
          listPageIndex :  parseInt(this.state.listPageIndex) + 1,
          listPageSize : this.state.listPageSize,
          listSearch : this.state.listSearch
        }
        this.getData(data);
    
      }   


    render()  {

        // const SingleItem = (data) => {
        //     return (
        //       <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">

        //       </div>
        //     );
        //   };

        return (
            <Fragment>
                
                <div className="pt-0">
                    {(this.state.isPageLoading ? 
                    <Fragment>
                        <Card className='p-3'>
                            <Skeleton active/>
                        </Card>
                    </Fragment>
                    :
                    <Fragment>
                        {(this.state.data.length > 0 ? 
                        <Fragment>
                          <Collapse className="mt-0 " bordered={false} defaultActiveKey={(this.props.displaylist? [0] : [])} size="small"  ghost>
                          <Collapse.Panel className="p-0" header={
                            <Fragment>
                                  <Flex className=""> 
                                  <div className="pt-0 pb-3 me-2">
                                      <div className='bg-soft-danger rounded-2 p-2'>
                                          <PiWarningDuotone className='fs-5 text-danger' />
                                      </div>
                                  </div>
                                  <div className="">
                                      <h6 className="mb-0 fs--1">Total unpaid</h6>
                                      <h4 className=" mb-0">{formatCurrency(this.state.listTotalBill, this.state.currencySymbol)} <span className='fs-0 text-400'>{this.state.currencyCode}</span></h4>                                
                                      <h6 className="mb-0 fs--1 text-danger">{this.state.listTotalSize} invoice{(this.state.data.length >1 ? 's are ': ' is ')} unpaid</h6>
                                      <h4 className=" mb-0 "></h4>
                                  </div> 
                                  </Flex>
                            </Fragment>
                          }>
                              <SimpleBarReact className="ask-analytics ">
                              {(this.state.data.map((invoice, index)=>(  <InvoiceList key={invoice.id} data={invoice} generateType={'invoice'} />)))}
                              {(this.state.listTotalSize != this.state.data.length ? 
                                          
                                          <Row className="gx-0  rounded-2  mt-2 mb-3">
                                          <Col xs="auto" >
                                              <Button type='default' onClick={this.loadMore} loading={this.state.isButtonMoreLoading}  className=''>Load more</Button>
                                          </Col>
                                          </Row>
                                      
                                      : null)}    
                              </SimpleBarReact>   
                          </Collapse.Panel>
                          </Collapse>
                        </Fragment>
                        :
                        <Fragment>
                            <Row className="gx-0  rounded-2  p-2  mb-0">
                                <Flex direction='row' className="p-0 flex-wrap">  
                                <div
                                    className="bg-200 px-3 py-3 rounded-2 text-center"
                                    style={{ width: '60px', height: '60px' }}
                                >
                                    <PiTimerDuotone  className="fs-2 text-default"/>
                                </div> 
                                <div className='flex-grow-1 p-2'>
                                    <p className="mb-1 fs--1 text-500">Overdue Payments</p>
                                    <h5 className="text-400 fs-1">No overdue payment</h5>
                                </div>                                         
                                </Flex>
                            </Row>
                        </Fragment>
                        )}
                    </Fragment>
                    )}
                    </div>
               
            </Fragment>
        );
      };


}