import React, {Fragment, Component} from 'react';
import Flex                   from '../../Flex';
import { List, Tag, Popover } from 'antd';
import { Dropdown, Button, Form, Placeholder, Card, OverlayTrigger, Tooltip }  from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BiMessageSquareAdd } from 'react-icons/bi';
import TagService        from '../services/';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import TagsVarexe from './TagsVarexe';

class TagsSelect extends Component
{
    constructor(props){
        super(props);
        this.state = {
            idTable :   props.idTable,
            tableName : props.tableName,            
            isLoading: true,
            isLoaded: false,
            isShowing:false,
            
            assignedTagsLoading: true,
            assignedTags:[],
            tags:[],


            isTagsVarexeShowing:false
            
        }
    }

    componentDidMount(){
        this.getRelatedTags();

    }  
    
    
    getRelatedTags = () => {
        let data = {
            idTable: this.props.idTable,
            tableName: this.props.tableName
         }

         let state = this.state;
          TagService.getAssignedTags(data).then( (response) => {
              try {
                if( response.data !==  undefined && response.data.data !==  undefined )
                {
                  let result = response.data;
                  state.assignedTagsLoading = false;
                  state.assignedTags = result.data;
        
                  this.setState(state);        
                }        
              } catch(error){
                console.log(error);
              }
          });        
      }
      getFullTags = () => {
          let state = this.state;
         TagService.getTags(state).then( (response) => {
            try {
              if( response.data !==  undefined && response.data.data !==  undefined )
              {
                let result = response.data;
                state.tags = result.data;
                state.isShowing = true;
                state.isLoading  = false;
                state.isLoaded  = true;
      
                this.setState(state);        
              }        
            } catch(error){
              console.log(error);
            }
        });
      }

      handleDropDownShowing = () => {
        if(!this.state.isShowing && !this.state.isLoaded){
            this.getFullTags();
        }          


      }
      
  handleSelectTag = (tag) => {
    let state = this.state;

    let data = {
        idTable: this.props.idTable,
        tableName: this.props.tableName,
        idTag : tag.id
     }

     TagService.addAssignedTag(data).then( (response) => {
      try {
          if( response.data !==  undefined && response.data.data !==  undefined )
          {
            let result = response.data;
            let tagResult = result.data;
            if(tagResult)
            {
                state.assignedTags .push(tagResult);
                state.isShowing = false;
          
              this.setState(state);  
            }

          }

        } catch(error){
          console.log(error);
        }
    });





  }


  handleDeleteAssignedTag = (tag) => {
    let state = this.state;

    let data = {
        idTable: this.props.idTable,
        tableName: this.props.tableName,
        idTag : tag.id,
        idTagAssigned : tag.idTagAssigned,
     }

     TagService.deleteAssignedTags(data).then( (response) => {
        try {
          if( response.data !==  undefined && response.data.data !==  undefined )
          {
            let result = response.data;
            let tagResult = result.data;
                state.assignedTags.map((tagItem, index) => {
                    if(tagItem.id == tag.id){

                        state.assignedTags.splice(index , 1);
                    }
                });
            this.setState(state); 
          }        
        } catch(error){
          console.log(error);
        }
    });    
  }


      
    render(){

        const content = (
            <List
            size="small"
            header={<h6 className='m-0 p-0'>Select Label</h6>}
            footer={<div>
                        <Button
                        variant="primary"
                        size="sm"
                        className="d-block w-100 "
                        onClick={()=> { this.setState({isTagsVarexeShowing:true})}}
                        >
                        Manage label
                        </Button>                
            </div>}
            bordered={true}
            dataSource={this.state.tags}
            renderItem={(item) => 
            <List.Item key={item.id} className=''> 
                  <Button
                      as="button"
                      size="sm"
                      className={`rounded-1 m-0  text-start w-100 text-nunito text-nowrap fs--1`}
                      // style={{background:`rgba(${label.rgb.r}, ${label.rgb.g}, ${label.rgb.b}, .8)` , color: `#${label.color}`}}
                      style={{ background:`rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, .08)`, color: `rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, 1)`, borderColor:  `rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, 1)` }}
                      key={item.id}
                      onClick={()=>{this.handleSelectTag(item)}}
                  >
                      {item.name}
                  </Button>            
                {/* <Tag 
                color={`rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, .8)`}
                key={item.id}
                style={{cursor:'pointer'}}
                onClick={()=>{this.handleSelectTag(item)}}

                >{item.name}</Tag> */}
            </List.Item>}
        />
        )

        return(
            <Fragment>
            {(!this.state.assignedTagsLoading ? 
                this.state.assignedTags.map( (item, indexTag) => {
                    return  ( <li className=' mb-1' style={{display: 'inline-block'}}>
                                <Tag 
                                closable

                                style={{ background:`rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, .08)`, color: `rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, 1)`, borderColor:  `rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, 1)` }}
                                key={item.id}
                                onClose={(e) => {
                                    e.preventDefault();
                                    this.handleDeleteAssignedTag(item);
                                  }}                                

                                >{item.name}</Tag>                        
                        {/* <h6
                            className={`rounded-1 border-0 text-start  fs--2 py-1 px-2 list-inline-item`}
                            style={{background:`rgba(${label.rgb.r}, ${label.rgb.g}, ${label.rgb.b}, .8)` , color: `#${label.color}`}}
                            key={label.id}
                        >
                        
                            {label.name}
                        </h6> */}
                        </li>
                )
                }) 
                : null)}


                <Popover placement="bottomLeft"  
                        content={content} trigger="click">
                <Button 
                    variant=""
                    size="sm"
                    className="fs--1 p-0 text-400"
                    onClick={this.handleDropDownShowing}
                >
                        <BiMessageSquareAdd className='fs-1' />
                    </Button>
                </Popover>

                {(this.state.isTagsVarexeShowing?
                    <TagsVarexe 
                    module={null}
                    idModule={null}
                    isCanvasShowing={this.state.isTagsVarexeShowing}
                    />                
                :
                null)}                              
            </Fragment>
        );
    }
}
export default TagsSelect;