import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
/*
| UI
*/
import {  Button } from 'antd';
import {Row, Col, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Flex from 'components/common/Flex'

// import NotificationDropdown from './NotificationDropdown';
// import SettingsAnimatedIcon from './SettingsAnimatedIcon';
// import CartNotification from './CartNotification';



/*
| ICONS
*/
import { PiListBulletsDuotone } from "react-icons/pi";
import { BsGear } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
| Pages
*/
import AppContext from '../../context/Context';
import { navbarBreakPoint } from '../../config';
import ProfileDropdown from './ProfileDropdown';
import SettingModal from '../Settings/components/SettingModal'
import ToggleThemDark from './partials/ToggleThemDark'


export default class TopNavRightSideNavItem extends Component {

  static defaultProps = {
  }  
  static contextType = AppContext;


  constructor(props)
  {
      super(props);
      this.state = {
        displayModalSetting:false
      }
      this.handleModalSetting = this.handleModalSetting.bind(this);
  }

  componentDidMount(){   
  }

  handleModalSetting = () => {
    this.setState({
      displayModalSetting:!this.state.displayModalSetting
    })
  }

  render(){
    let { isDark } = this.context;

    

      return(
          <Fragment>

              <Flex className="">
                {/* <div>
                  <Button size='sm' className="btn btn-sm btn-falcon-default me-1" style={{height: '29px'}} onClick={this.handleModalSetting}>
                    <BsGear className="fs-0"  />
                  </Button> 
                </div> */}
                <div>
                <ProfileDropdown /> 
                </div>

                  {/* <Row className="gx-0 kanban-header rounded-2 px-card py-2 mt-2 mb-3">

                    <Col className="d-flex align-items-center">
                      <h5 className="mb-0">Falcon</h5>

                      asc
                      <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>

                      asc
                      <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
                      asc
                    </Col>
                    <Col xs="auto" as={Flex} alignItems="center">
                      ascasc
                    </Col>
                  </Row> */}
              </Flex>

            {/* <SettingModal display={this.state.displayModalSetting} handleModalSetting={this.handleModalSetting} /> */}
          </Fragment>
      )
  }
}

