import React, { Fragment, Component } from 'react';
import { withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';


/*
| UI
*/
import { Anchor,  Card, Divider, Typography, Space, Radio} from 'antd'
import {  Row,Col,  Image, Container }                from 'react-bootstrap';
import Flex                                      from '../common/Flex';
/*
| Icons
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PiPlusMinusDuotone, PiReceiptDuotone, PiUserPlusDuotone, PiPackageDuotone, PiShoppingCartDuotone  } from "react-icons/pi";

/*
| Invoices
*/
import GreetingCard from './GreetingCard';
// Change this to invoice cashflow -- to show expenses
import PaymentsLineChart from './PaymentsLineChart';

import TodayAnalytics   from '../invoices/analytics/TodayAnalytics';
import InvoicesActivity from '../invoices/analytics/InvoicesActivity';
import InvoicesOverdueList from '../invoices/analytics/InvoicesOverdueList';
import InvoicesDraftList from '../invoices/analytics/InvoicesDraftList';
/*
| Invoices Quick Analytics
*/


/*
| Wizard
*/

/*
| Calendar
*/
// import CalendarMini from '../calendars/components/CalendarMini';
import CalendarsUpcoming          from '../calendars/components/CalendarsUpcoming';



/*
| Images
*/

{/* <div id="part-1" style={{ height: '100vh', background: 'rgba(255,0,0,0.02)' }} >
<Row>
  <Col sm={24} md={8} className='mb-2'>
    
  </Col>
  <Col sm={24} md={16}>
    
  </Col>                
</Row>
<Title level={5} className='mb-0'>Cash Flow</Title>
<Text>Cash coming in and going out of your business.</Text>
</div> */}


class Dashboard extends Component 
{
  render()
  {
    const quickLinksERP = [
      {title : 'Estimate', text :'Create a new estimate', icon : 'plus', color : 'success', link: "/estimates/new/estimate"   },
      {title : 'Invoice', text :'Create a new invoice', icon : 'plus', color : 'success', link: "invoices/new/invoice"   },
      {title : 'Customers', text :'Create a new customer', icon : 'address-book', color : 'warning', link: "customers/new"   },
      {title : 'Products', text :'Create a new product', icon : 'plus', color : 'primary', link: "services/new"   },
      // {title : 'Tax', text :'Create tax and tax number', icon : 'plus', color : 'primary', link: "admin/sales-tax"   },
      
    ]

    const quickLinksSettings = [
      {title : 'Your Info', text :'Update information and settings', icon : 'user', color : 'info', link: "profile"   },
      {title : 'Invoice Customization', text :'Customization, Visibilities and others', icon : 'cogs', color : 'primary', link: "admin/invoice-customization"   },
      {title : 'Invoice Template', text :'Desing and adjust what to display on the invoices', icon : 'eye', color : 'primary', link: "admin/invoice-size-design"   },
      {title : 'Business Info', text :'Company general information', icon : 'info-circle', color : 'primary', link: "admin-settings/business-details"   },
      
    ]

    const { Text, Title, Paragraph } = Typography;
    return (
    <Fragment>

      <Container className='mt-2'>
      <Row>
          <Col md={12} lg={10}>
            
            <div id="greeting" className='mb-5'  >
              <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Greetings</Title>
                  <Text>Always make sure to check notifications in the box front. </Text>                  
                </Col>
                <Col sm={12} md={8}>
                  <Card>
                    <GreetingCard  />            
                  </Card>
                </Col>                
              </Row>
            </div>

            <div id="quicklinks" className='mb-5'  >
              <Row>
                <Col sm={12} md={4} className='mb-2'>
                  {/* <Title level={5} className='mb-0'>Quick links</Title> */}
                  {/* <Text>Stay organized and on top of your commitments. Enjoy a stress-free journey through your upcoming events.</Text>                   */}
                </Col>
                <Col sm={12} md={8}>
                  <Card className='p-0' body>

                  <Row>
                  <Title level={5} className='mb-0'>Quick links</Title>
                      <Col xs={12}  className="my-3">
                          <Radio.Group className="radio-image"> 
                            <Link to={'/estimates/new/estimate'} className="" >
                                <Radio value="A" className="rounded mb-1">
                                    <div className="text-center" style={{minWidth: '70px'}}>
                                      <div className="cursor-pointer  rounded-xl p-2 m-1  bg-soft-info icon-box" >
                                        <PiPlusMinusDuotone className="text-info" style={{fontSize: '30px'}}  />
                                      </div>
                                    <p className="mb-0 fs--1 fw-medium">Estimate</p>
                                    </div>
                                  </Radio>
                            </Link>

                              <Link to={'/invoices/new/invoice'} className="">
                              <Radio value="B" className="rounded-2 mb-1">
                                  <div className="text-center" style={{minWidth: '70px'}}>
                                  <div className="cursor-pointer rounded-xl p-2 m-1  bg-soft-info icon-box" >

                                    <PiReceiptDuotone className="text-info" style={{fontSize: '30px'}}  />
                                  </div>
                                  <p className="mb-0 fs--1 fw-medium">Invoice</p>
                                  </div>
                                  </Radio>
                              </Link>
                              <Link to={'/customers/new'} className="">
                              <Radio value="C" className="rounded mb-1">
                                  <div className="text-center" style={{minWidth: '70px'}}>
                                  <div className="cursor-pointer rounded-xl p-2 m-1  bg-soft-info icon-box" >
                                    <PiUserPlusDuotone className="text-info" style={{fontSize: '30px'}}  />
                                  </div>
                                  <p className="mb-0 fs--1 fw-medium">Customer</p>
                                  </div>
                                  </Radio>
                              </Link>                              
                              <Link to={'/services/new'} className="">
                              <Radio value="D" className="rounded mb-1">
                                  <div className="text-center" style={{minWidth: '70px'}}>
                                  <div className="cursor-pointer rounded-xl p-2 m-1  bg-soft-info icon-box" >
                                    <PiPackageDuotone className="text-info" style={{fontSize: '30px'}}  />
                                  </div>
                                  <p className="mb-0 fs--1 fw-medium">Product</p>
                                  </div>
                                  </Radio>
                              </Link>          
                              <Link to={'/purchases/bills/new'} className="">
                              <Radio value="D" className="rounded mb-1">
                                  <div className="text-center" style={{minWidth: '70px'}}>
                                  <div className="cursor-pointer rounded-xl p-2 m-1  bg-soft-info icon-box" >
                                    <PiShoppingCartDuotone  className="text-info" style={{fontSize: '30px'}}  />
                                  </div>
                                  <p className="mb-0 fs--1 fw-medium">Bill</p>
                                  </div>
                                  </Radio>
                              </Link>                                                     

                          </Radio.Group>
                      </Col>
                    

                      </Row>



                  </Card>
                </Col>                
              </Row>
            </div>


            <div id="calendar" className='mb-5'  >
              <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Your schedule & events</Title>
                  <Text>Stay organized and on top of your commitments. Enjoy a stress-free journey through your upcoming events.</Text>                  
                </Col>
                <Col sm={12} md={8}>
                  <Card className='p-0' body>
                    <Flex>
                      <div className='flex-grow-1'>
                        <p><Text className='text-600'>Upcoming Events</Text></p>
                      </div>
                      <div>
                        <Link to={'calendar'}>
                          <h6 className="text-800 mb-0">View all</h6>
                        </Link>                        
                      </div>
                    </Flex>
                    <CalendarsUpcoming />
                  </Card>
                </Col>                
              </Row>
            </div>



            <div id="today" className='mb-5'  >
              <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Today's cash Flow</Title>
                  <Text>In the chart of today's activity, All drafts and invoices are calculated.</Text>                  
                </Col>
                <Col sm={12} md={8}>
                  <TodayAnalytics />
                  <Card>
                    <PaymentsLineChart  />                 
                  </Card>
                </Col>                
              </Row>
            </div>



            <div id="overdueList" className='mb-5'  >
              <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Overdue Invoices</Title>
                  <Text>Always make sure to check notifications in the box front. </Text>                  
                </Col>
                <Col sm={12} md={8}>
                  <Card bodyStyle={{padding: '0px', paddingTop: '10px'}}>
                    <InvoicesOverdueList  />            
                  </Card>
                </Col>                
              </Row>
            </div>     
            <div id="overdueDraft" className='mb-5'  >
              <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Draft Invoices</Title>
                  <Text>Always make sure to check notifications in the box front. </Text>                  
                </Col>
                <Col sm={12} md={8}>
                  <Card bodyStyle={{padding: '0px', paddingTop: '10px'}}>
                    <InvoicesDraftList  />            
                  </Card>
                </Col>                
              </Row>
            </div>                     

            
            <div id="InvoicesPayments" className='mb-5'  >
            <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Invoices & Payments</Title>
                  <Text>In the chart of Invoices and activities, you see the invoices and payments activities in the past 12 months.</Text>                  
                </Col>
                <Col sm={12} md={8}>
                  <Card>
                    <InvoicesActivity />               
                  </Card>
                </Col>                
              </Row>
            </div>
            <div id="links" className='mb-5' >
            <Row>
                <Col sm={12} md={4} className='mb-2'>
                  <Title level={5} className='mb-0'>Shorcut links</Title>
                  <Text>Find the useful links</Text>                  
                </Col>
                <Col sm={12} md={8}>
                {/* <Divider orientation="left">Create pages</Divider> 
                  <Row className=" mt-0">
                    {quickLinksERP.map(({ icon, color, title, text, link }) => {
                      return (
                        <Col sm={12} md={6} key={title} className='mb-2'>
                          <Flex className="position-relative align-top">
                            <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                              <FontAwesomeIcon icon={icon} className={`text-${color}`} />
                            </div>
                            <div className="flex-1">
                              <Link to={link} className="stretched-link">
                                <h6 className="text-800 mb-0">{title}</h6>
                              </Link>
                              <p className="mb-0 fs--2 text-500 ">{text}</p>
                            </div>
                          </Flex>
                        </Col>
                      );
                    })}
                  </Row>  */}
                <Divider orientation="left">Settings</Divider> 
                

                  <Row className="">
                      {quickLinksSettings.map(({ icon, color, title, text, link }) => {
                        return (
                          <Col sm={12} md={6} key={title} className='mb-2'>
                            <Flex className="position-relative">
                              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                                <FontAwesomeIcon icon={icon} className={`text-${color}`} />
                              </div>
                              <div className="flex-1">
                                <Link to={link} className="stretched-link">
                                  <h6 className="text-800 mb-0">{title}</h6>
                                </Link>
                                <p className="mb-0 fs--2 text-500 ">{text}</p>
                              </div>
                            </Flex>
                          </Col>
                        );
                      })}
                    </Row>                   


                </Col>                
              </Row>


            
            </div>
          </Col>
          <Col className='d-none d-lg-block ps-0' md={12} lg={2}>
            <Anchor
              items={[
                {
                  key: 'greeting',
                  href: '#greeting',
                  title: 'Greeting',
                },
                {
                  key: 'calendar',
                  href: '#calendar',
                  title: 'Calendar',
                },

                {
                  key: 'today',
                  href: '#today',
                  title: 'Today',
                },
                {
                  key: 'overdueList',
                  href: '#overdueList',
                  title: 'Overdue List',
                },                
                {
                  key: 'InvoicesPayments',
                  href: '#InvoicesPayments',
                  title: 'Invoices & Payments',
                },
                {
                  key: 'links',
                  href: '#links',
                  title: 'Links',
                },
              ]}
            />
          </Col>
        </Row>
      </Container>

    </Fragment>
    );    
  }
}


export default withRouter (Dashboard);
