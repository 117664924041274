import React, {Fragment, Component} from 'react';


import {  Skeleton     } from 'antd';
import { Card, Row, Col, Placeholder, Modal, CloseButton, Button, Nav, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiActivity, FiEdit, FiPhone, FiMail } from 'react-icons/fi';
import { IoSaveOutline, IoCloseSharp } from 'react-icons/io5';
import { FcPhone } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {capitalize, isEmpty, phoneNumFormatter}    from '../../../../helpers/utils';
import ToastVarexe     from '../../../common/ToastVarexe';

import IconButton      from '../../../common/IconButton';
import Flex            from '../../../common/Flex';

import CustomerService from '../../services/';
import Statistics      from './Statistics';
import CustomerForm    from '../CustomerForm';
import CustomerEdit    from '../CustomerEdit';


export default class CustomerDisplayCard extends Component
{
    static defaultProps = {
        target: 'mainAccount',
        editable: false,
        cardBorder: 'primary',
        showStatistics:true,
        showAllInfo:true,
        boxShadow:  true,
        style:  {index:1},
        modalShow : false
      }; 

    constructor(props){
        super(props)
        this.state = {
            idClient :      this.props.idClient,
            loading  :      true,
            modalShow:      this.props.modalShow,
        }

        this.customerFormCallBack      = this.customerFormCallBack.bind(this);
            
    }


    componentDidMount(){

        this.getPageData();

    }

    getPageData = () => {
      this.setState({loading : true});
      CustomerService.getCustomerById(this.state.idClient).then((res) =>{
        try{
          if( res.data !== undefined ){
            if( res.data ){
              let customer = res.data;
    
              let _state           = customer;
              _state.loading       = false;
            //   _state.modalShow     = false;
              
              this.setState(_state);          
            }
          }
          } catch(error){
            console.log(error);
          } 
    });
    }


  customerFormCallBack = () => {
    ToastVarexe({
        type :  'success',
        message: 'Customer Updated'
    });       
    // this.getPageData();
    
  }

  handleModalStatus = () => {
    this.getPageData();
      this.setState({modalShow:!this.state.modalShow});
}


    render(){

        //   const { coordinates } = this.state;
          const coordinates = this.state.coordinates;
        return(
            <Fragment>
                {/* <Card className={`border-start border-start-2 border-${this.props.cardBorder} ${(!this.props.boxShadow ? 'shadow-none' : null )}`}> */}
                <Card style={this.props.style}>
                <Card.Body className='pt-1'>
                {(this.state.loading?
                  <Skeleton active />
                :
                 
                <Fragment>

                    {(this.props.showAllInfo ? 
                    <Fragment>
                    <Col xs={12}>
                    <p className="mt-1 mb-0 fs--1"><span className='text-primary me-1'>{capitalize(this.state.type)} Account</span>customer overview</p>
                    </Col>
                    <Row className="mb-2">
                        <Link to={`/customers/customer-details/${this.state.idClient}`}><h5 className="mb-0 text-black mt-2">{this.state.firstName} {this.state.lastName}</h5></Link> 

                    </Row>
                    <Row>
                    <Table size='sm' className='p-0 m-0'>
                        <tbody>
                        <tr>
                            <td style={{width:50}}>
                            <Flex direction={'row'}>
                                <h6 className="text-700 mb-0  fs--2">Cell:</h6>      
                            </Flex>
                            </td>
                            <td>
                            <h6 className="text-700 mb-0"><FontAwesomeIcon icon="phone" className="fs--1 text-300 me-2" />{(isEmpty(this.state.mobile)? <span className='text-danger'>Empty Mobile number</span>: 
                                <a href={`tel:${coordinates[0].phoneCode}${this.state.mobile}`} className="fs--1 text-dark"> 
                                {coordinates[0].phoneCode} {this.state.mobile}
                                </a>                             
                            )}</h6>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width:50}}>
                            <Flex direction={'row'}>
                                <h6 className="text-700 mb-0  fs--2">Email:</h6>      
                            </Flex>
                            </td>
                            <td>
                            <h6 className="text-700 mb-0"><FontAwesomeIcon icon="envelope" className="fs--1 text-300 me-2" />{(isEmpty(this.state.email)? <span className='text-danger'>Empty Email Address</span>: 
                                <a href={`mailto:${this.state.email}`} className="fs--1 text-dark"> 
                                {this.state.email}
                                </a>                              
                            )}</h6>
                            </td>
                        </tr>  
                        <tr >
                            <td style={{width:50}}>
                            <Flex direction={'row'}>
                                <h6 className="text-700 mb-0  fs--2">Address:</h6>      
                            </Flex>
                            </td>
                            <td>
                            <h6 className="text-700 mb-0"><FontAwesomeIcon icon="address-book" className="fs--1 text-300 me-2" />
                            {/* {(!isEmpty(coordinates[0].unit) ?  coordinates[0].unit + ", " : null )} 
                            {(!isEmpty(coordinates[0].apartment) ?  coordinates[0].apartment + ", " : null )} 
                            {(!isEmpty(coordinates[0].address) ?  coordinates[0].address + "," : null )} 
                            {(!isEmpty(coordinates[0].cityName) ?  coordinates[0].cityName + "," : null )} 
                            {(!isEmpty(coordinates[0].statesName) ?  coordinates[0].statesName + "" : null )}                                                            */}
                            {(!isEmpty(coordinates[0].addressLine1) ?  coordinates[0].addressLine1 + "" : null )}
                            {(!isEmpty(coordinates[0].addressLine2) ?  coordinates[0].addressLine2 + "" : null )}
                            </h6>
                            </td>
                        </tr> 
                        <tr >
                            <td style={{width:50}}>
                            <Flex direction={'row'}>
                                <h6 className="text-700 mb-0  fs--2">Zipcode:</h6>      
                            </Flex>
                            </td>
                            <td>
                            <h6 className="text-700 mb-0"><FontAwesomeIcon icon="location-arrow" className="fs--1 text-300 me-2" />
                                {coordinates[0].postalCode}                                                         
                            </h6>
                            </td>
                        </tr>                           
                        </tbody>
                        </Table>                        
                    </Row>


                        </Fragment>
                        : null)}
                        {(this.props.showStatistics? 
                            <Row>
                                <Statistics idClient={this.state.idClient} flexDirection="column" />
                            </Row>
                        : null)}
                        <Row className='text-center p-0 m-0'>
                            {(this.props.editable ? 
                            <Button
                                variant="soft-secondary"
                                size="sm"
                                className="mt-2 fs--1 fw-normal "
                                onClick={() => this.setState({modalShow:!this.state.modalShow})}
                                ><FiEdit className=" fs-1 me-2"/>Edit this customer</Button>



                            //     <Nav className="flex-lg-column fs--1">
                            //     <Nav.Item className="">
                            //       <Nav.Link  onClick={() => this.setState({modalShow:!this.state.modalShow})} className="nav-link-card-details fw-semi-bold text-center">
                            //       <FiEdit className="fs-1 me-2"/>
                            //         Edit this customer
                            //       </Nav.Link>
                            //     </Nav.Item>
                            //   </Nav>


                            : null)}

                        </Row> 
                            <Modal size="xl" show={this.state.modalShow} fullscreen={'lg-down'} onHide={this.handleModalStatus} backdrop="static" keyboard={false}>
                            <Modal.Header className='bg-light'>
                                <Modal.Title className='fs-1'>Manage your customer</Modal.Title>

                                <CloseButton
                                onClick={this.handleModalStatus}
                                className="btn btn-rounded btn-sm transition-base p-0"
                                />     
                            </Modal.Header>
                            <Modal.Body className='p-0'>
                                <CustomerEdit 
                                idClient={this.state.idClient} 
                                target={this.props.target} 
                                customerFormCallBack={this.customerFormCallBack}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                variant="soft-secondary"
                                size="sm"
                                className="mt-2 fs--1 fw-normal "
                                onClick={this.handleModalStatus}
                                ><IoCloseSharp className="text-default fs-2 me-2"/> Close</Button>  

                            </Modal.Footer>
                            </Modal>                         
                    </Fragment>
                    )}                  
                </Card.Body>
                </Card>
                 
            </Fragment>
        );
    }


}

CustomerDisplayCard.propTypes = {
    target: PropTypes.oneOf(['mainAccount', 'billingAccount', 'shippingAccount']),
    cardBorder: PropTypes.oneOf(['primary', 'info', 'warning', 'success', 'danger']),
    // style: PropTypes.string,
    boxShadow : PropTypes.bool,
    editable :  PropTypes.bool,
    // showStatistics : PropTypes.bool,
  };

 
