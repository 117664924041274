import React, {Component, Fragment} from 'react';
import PropTypes   from 'prop-types';
import { Redirect } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';
import dayjs from 'dayjs';
/*
| UI 
*/
import { Skeleton, Layout, Card, Divider, Typography, Form, Input , Switch, Steps, Tag, Space, Tooltip, Button, Checkbox, Radio, DatePicker,TimePicker, InputNumber, Alert, Select, Avatar, Drawer   } from 'antd';
import {  Col, Row, Modal,   Placeholder, CloseButton   } from 'react-bootstrap';
import ToastVarexe              from '../../../common/ToastVarexe';
import Flex                     from '../../../common/Flex'
// import NumberFormat             from 'react-number-format';
// import FormGroupInput           from '../../../common/FormGroupInput';
// import useFakeFetch             from '../../../hooks/useFakeFetch';

/*
| Icons
*/



import { BsFillCircleFill} from 'react-icons/bs';
import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';
import { PiBookmarkSimpleDuotone, PiBriefcaseDuotone, PiCalendarBlankDuotone, PiNotePencilDuotone, PiArticleDuotone, PiBellSimpleSlashDuotone, PiBellSimpleDuotone, PiCheckDuotone,PiCalendarPlusDuotone  } from 'react-icons/pi';



/*
| Pages
*/
import CalendarForm               from '../CalendarForm';
/*
| Utils
*/

import {isEmpty} from '../../../../helpers/utils'


/*
| Serviecs
*/
import CalendarService        from '../../services/';

class ViewDetailsCalendar extends Component  { 

    constructor(props) {

        // let { id } = this.state.content.event;

        super(props);
        
        this.state = {
            data: {},
            // idCalendar: this.props.viewDetailsContent.event.id,  
            // content:    this.props.viewDetailsContent,


            idCalendar: this.props.idCalendar,  
            // content:    this.props.viewDetailsContent,

            isLoading :   true,  
            calendarModalShow: false,
        }
        this.refreshContent = this.refreshContent.bind(this);
    }


    componentDidMount = () => {
        this.getData(this.state.idCalendar);
    }

    componentDidUpdate = () =>{
        if( this.state.idCalendar != this.props.idCalendar) 
        {         
            this.setState({
                idCalendar: this.props.idCalendar,
            });
            if( this.props.idCalendar > 0 )
            {
                this.getData(this.props.idCalendar);
            }         
        }        
    }

    getData = (idCalendar) => {
        this.setState({
            isLoading:true,
          });         
        CalendarService.getCalendarById(idCalendar).then((res) =>{

            if( res.data !== undefined ){
              let calendar = res.data;
    
            // className:          this.state.className
              this.setState({
                isLoading:false,
                idCalendar: idCalendar,
                data: calendar.data  
              }); 
              
              
            }
    
          });         
    }

    handleChange =  (target) =>  {

        const name      = target.name;
        const value     = target.value;
        const newState  = {};
    
        Object.keys(this.state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });
        this.setState(newState);
     }


      
     scheduleSender = (e, number) => {
        
        let state = {...this.state};

        state.data.sendRemindersOn.schedules = state.data.sendRemindersOn.schedules.map((param, index) => {
            if(param.key == number)
            {
                param.value = e.target.checked;
            }
            return param;
        });

        this.setState(state);

        let data = {
            sendRemindersOn : state.data.sendRemindersOn.schedules,
            idCalendar: this.state.idCalendar
        }

        CalendarService.setScheduleSender(data).then((res) => {
            try {
            } catch(error){
                console.log(error);
            }                
        });
    }

    handleCalendarModalShow = () => {
        this.setState({calendarModalShow:!this.state.calendarModalShow});
    }  
    refreshContent = () =>{
        
        this.setState({calendarModalShow:false});
        this.getData(this.state.idCalendar);
    }


    render(){
        const { Text, Title, Paragraph } = Typography;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };   
          
          
          const DescriptionItem = ({ title, content }) => (
            <div>
              <Text className='me-2'>{title}:</Text>
              <Text>{content}</Text>
            </div>
          );


        return (
            
            <>
            {(this.state.isLoading ? 
                <Skeleton  className="mt-3" active/>
            : 
            <Fragment>

                <Flex>
                    <div className='p-0'></div>

                </Flex>
                <Flex>
                    <div className='ms-2'><BsFillCircleFill className='fs-1 me-1' style={{color: this.state.data.backgroundColor}} /></div>
                    <div className='ms-2 flex-grow-1'> 
                        <h5>{this.state.data.title}</h5>
                    </div>
                    <div className='p-0'> 
                        <Button className='btn-soft-primary' onClick={this.handleCalendarModalShow}><PiNotePencilDuotone className='fs-1' /></Button>
                    </div>                    
                </Flex>

                 

                
                

                <Flex>
                    <div className='p-2'><PiCalendarBlankDuotone className='fs-1 ' /></div>
                    <div className='p-2'> 
                        <table>
                            <tr>
                                <td>Start</td>
                                <td><b>{this.state.data.startHourFormated}</b>  {this.state.data.startDateFormated}</td>
                            </tr>
                            <tr>
                                <td>End</td>
                                <td><b>{this.state.data.endHourFormated}</b> {( this.state.data.startDateFormated != this.state.data.endDateFormated ? this.state.data.endDateFormated : null )}</td>
                            </tr>
                        </table>
                    </div>
                </Flex>



                {(this.state.data.clients.length > 0 ? 
                <Fragment>
                    <Divider orientation='left' orientationMargin={0} plain>Customer</Divider>
                    <Row>
                        <Col xs={6}>
                            <DescriptionItem title="Full Name" content={this.state.data.clients[0].fullName}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            {(this.state.data.clients[0].clientEmailAddresses.map((param)=>{
                                if( param.isDefault )
                                {
                                    return (
                                        <DescriptionItem title={`Email ${ (!isEmpty(param.emailName) ? '(' + param.emailName + ')' : null) }`} content={param.emailAddress}/>
                                    )
                                }
                            }))}
                            
                        </Col>
                        <Col xs={6}>
                        {(this.state.data.clients[0].clientContactNumbers.map((param)=>{
                                if( param.isDefault )
                                {
                                    return (
                                        <DescriptionItem title={`Contact ${ (!isEmpty(param.contactName) ? '(' + param.contactName + ')' : null) }`} content={param.contactNumber}/>
                                    )
                                }
                            }))}                            
                        </Col>
                    </Row>

                    <div>

                    <Divider orientation='left' orientationMargin={0} plain>Scheduled reminders</Divider>
                    <p><Text>Number of days before to send notifications</Text></p>
                        <Flex direction='row' wrap='wrap' className=" rounded-2 mb-3" >  
                            {(this.state.data.sendRemindersOn.schedules.map((param, index)=>{
                                return (
                                    <Fragment key={index}>
                                        <Tooltip placement="top" title={param.tooltip} arrow={true}>
                                        <div className="bg-200 p-2 me-2 rounded-2 border border-300 mb-2"  >
                                            <Flex>
                                                <div>
                                                    {(param.isDisabled && !param.isSent?                    <PiBellSimpleSlashDuotone className='fs-1 me-1' /> : null)}
                                                    {(param.isDisabled && param.value && param.isSent?      <PiCheckDuotone           className='fs-1 me-1 text-success' /> : null)}                                                    
                                                    {(!param.isDisabled && !param.isSent?   
                                        
                                                        <Checkbox className="mt-0 me-1" 
                                                        checked={param.value} 
                                                        name={`sendOnBefore${param.key}`}
                                                        onChange={e => this.scheduleSender(e, param.key) }>
                                                        </Checkbox>                                                         
                                                    
                                                    : null)}
                                                </div>
                                                <div>
                                                {param.key} before
                                                <br /><span className='fs--2'>{param.scheduledDateFormated}</span>                                                    
                                                </div>
                                            </Flex>


                                        </div>
                                        </Tooltip>                              
                                    </Fragment>
                                )
                            }))}
                        </Flex>
                        </div>


                    {(this.state.data.user != null ? 
                    <Fragment>
                        <Divider orientation='left' orientationMargin={0} plain>Assigned to</Divider>
                    
                        <Row>
                            <Col xs={12}>
                                <Flex>
                                    <div><Avatar className='me-2' size="xl" rounded="circle" src={this.state.data.user.imageDisplay} /></div>
                                    <div>
                                        
                                        <h6 className="mb-0">{this.state.data.user.firstName} {this.state.data.user.lastName}</h6>
                                        <p className='mb-0'>{this.state.data.user.primaryEmail}</p>                                 
                                    </div>
                                </Flex>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mt-2' xs={12}>
                                {(this.state.data.displayUser ? 
                                
                                <Text ><PiCheckDuotone  className='fs-1 me-1 text-success' /> Customer will see that the schedual is with {this.state.data.user.fullName}</Text>                            
                                :
                                null                           
                                )}
                            </Col>
                        </Row>                        
                    </Fragment>
                    : null)}


                </Fragment>
                :null)}




                <Fragment>
                    <Divider orientation='left' orientationMargin={0} plain>Description</Divider>
                    <Flex>
                        <div className='p-2'><PiArticleDuotone className='fs-1 ' /></div>
                {(!isEmpty(this.state.data.description) ? 
                        <div className='p-2'>{this.state.data.description}</div>
                : 
                <div className='p-2 text-400'>No Description</div>
                )}
                    </Flex>
                </Fragment>

                <Divider orientation='left' orientationMargin={0} plain>Created By</Divider>

                <Flex >
                    <div className=" mt-3">
                        <Flex>
                            <div><Avatar className='me-2' size="xl" rounded="circle" src={this.state.data.createdByObj.imageDisplay} /></div>
                            <div>
                                
                            <h6 className="mb-0">{this.state.data.createdByObj.firstName} {this.state.data.createdByObj.lastName}</h6>
                            <p className='m-0 fs--1 text-nunito'><b>{this.state.data.createdDateFormated}</b></p>                                    
                            </div>
                        </Flex>
                    </div>
                </Flex>                




{/* 
                <Modal size="lg" show={this.state.calendarModalShow} fullscreen={'lg-down'} onHide={this.handleCalendarModalShow} backdrop="static" keyboard={false}>
                    <Modal.Header className='bg-light'>
                        <Modal.Title className='fs-0 '><PiCalendarPlusDuotone className='fs-2 me-1' />Edit Schedule</Modal.Title>
                        <CloseButton
                        onClick={this.handleCalendarModalShow}
                        className="btn btn-rounded btn-sm transition-base p-0"
                        />     
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <CalendarForm 
                        idCalendar={this.state.idCalendar} 
                        scheduleStartDate={this.state.data.startDate}
                        scheduleEndDate={this.state.data.endDate}   
                        refreshContent={this.refreshContent}     
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                        variant="soft-secondary"
                        size="sm"
                        className="mt-2 fs--1 fw-normal "
                        onClick={this.handleCalendarModalShow}
                        ><IoCloseSharp className="text-default fs-2 me-2"/> Close</Button>  

                    </Modal.Footer>
                </Modal>  */}



<Drawer 
    width={(window.innerWidth > 1000 ? 640 : window.innerWidth)} placement="right" 
    // closable={false} 
    onClose={this.handleCalendarModalShow} open={this.state.calendarModalShow}>
    <CalendarForm 
    idCalendar={this.state.idCalendar} 
    scheduleStartDate={this.state.data.startDate}
    scheduleEndDate={this.state.data.endDate}   
    refreshContent={this.refreshContent}     
    />       
</Drawer>




            </Fragment>
            )}
            
            </>
            )
    }

}
export default ViewDetailsCalendar;
