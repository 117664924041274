import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';



import { Layout, Drawer } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'



/*
| PAGES
*/


import Menu from '../components/navbar/varexe/Menu';
import NavbarTop from '../components/navbar/varexe/navbarTop/NavbarTop';

import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
// import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
// import NavbarTop from '../components/navbar/NavbarTop';
// import NavbarVertical from '../components/navbar/varexe/menuLeft/NavbarVertical';
// import NavbarSecondaryVertical from '../components/navbar/secondary/NavbarSecondaryVertical';
import Footer from '../components/footer/Footer';
// import loadable from '@loadable/component';
import AppContext from '../context/Context';
// import ProductProvider from '../components/e-commerce/ProductProvider';
// import SidePanelModal from '../components/side-panel/SidePanelModal';
// import { toast, ToastContainer } from 'react-toastify';
// import { CloseButton, Fade } from '../components/common/Toast';
import { getPageName } from '../helpers/utils';
import { color } from 'echarts';
// import { Children } from 'react';

// const DashboardRoutes = loadable(() => import('./DashboardRoutes'));


const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const DashboardLayout = props => {
  // const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const { hash, pathname } = useLocation();

  const {location, children } = props; 


  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
        <div className='content'>
          <Layout
            style={{
              background: 'none',
            }}
          >
            {/* <NavbarVertical /> */}
            <Menu />

            <Layout style={{background: 'none'}}>
              <Layout.Header
              className='p-0'
                style={{
                  background: 'none',
                }}              
              >
                <NavbarTop />
              </Layout.Header>
                <Layout.Content style={{ height: '100%', position: 'relative', background: 'none' }}>
                  <div className="varexe_components">{children}</div>
                </Layout.Content> 
                <Layout.Footer
                  className='p-0'
                    style={{
                      background: 'none',
                    }}              
                  >
                <Footer />
              </Layout.Footer>                           
              </Layout>
          </Layout>
        </div>
  );
};

export default withRouter(connect(mapStateToProps)(DashboardLayout))

