import React, {Fragment, Component} from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
/*
| UI
*/

import {  Skeleton,Divider, Badge, Typography, Tag , Popover    } from 'antd';
import { Card, Row, Col, Placeholder, Modal, CloseButton, Button, Nav, Table} from 'react-bootstrap';


/*
| icons
*/
import { InfoCircleOutlined} from '@ant-design/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FiActivity, FiEdit, FiPhone, FiMail } from 'react-icons/fi';
import { IoSaveOutline, IoCloseSharp } from 'react-icons/io5';
import { FcPhone } from 'react-icons/fc';


import {capitalize, isEmpty, phoneNumFormatter}    from '../../../../helpers/utils';
import ToastVarexe     from '../../../common/ToastVarexe';

import IconButton      from '../../../common/IconButton';
import Flex            from '../../../common/Flex';

import CustomerService from '../../services/';
import Statistics      from './Statistics';
import CustomerForm    from '../CustomerForm';
import CustomerEdit    from '../CustomerEdit';

/*
| PAGES
*/

import CustomerDisplayCard from './CustomerDisplayCard'

/*
| Utils
*/



export default class CustomerDisplayCardCustomerDetails extends Component
{
    static defaultProps = {
        target: 'mainAccount',
        editable: false,
        cardBorder: 'primary',
        showStatistics:true,
        showAllInfo:true,
        boxShadow:  true,
        style:  {index:1},
        modalShow : false
      }; 

    constructor(props){
        super(props)
        this.state = {
            idClient :      this.props.idClient,
            loading  :      true,
            modalShow:      this.props.modalShow,
        }

        this.customerFormCallBack      = this.customerFormCallBack.bind(this);
            
    }


    componentDidMount(){

        this.getPageData();

    }

    getPageData = () => {
      this.setState({loading : true});
      CustomerService.getCustomerById(this.state.idClient).then((res) =>{
        try{
          if( res.data !== undefined ){
            if( res.data ){
              let customer = res.data;
    
              let _state           = customer;
              _state.loading       = false;
            //   _state.modalShow     = false;
              
              this.setState(_state);          
            }
          }
          } catch(error){
            console.log(error);
          } 
    });
    }


  customerFormCallBack = () => {
    ToastVarexe({
        type :  'success',
        message: 'Customer Updated'
    });       
    // this.getPageData();
    
  }

  handleModalStatus = () => {
    this.getPageData();
      this.setState({modalShow:!this.state.modalShow});
}


    render(){

          const { Text } = Typography;
          const coordinates = this.state.coordinates;
        return(
            <Fragment>

                   
                {/* <Card className={`border-start border-start-2 border-${this.props.cardBorder} ${(!this.props.boxShadow ? 'shadow-none' : null )}`}> */}
                <Badge.Ribbon text={ {'main':'Main', 'billing': 'Billing', 'shipping': 'Shipping'}[this.state.type] } color={ {'main':'', 'billing': 'green', 'shipping': 'red'}[this.state.type] }>
                    <Card style={this.props.style}>
                    <Card.Body className='pt-1'>
                    {(this.state.loading?
                    <Skeleton active />
                    :
                    
                    <Fragment>

                        {(this.props.showAllInfo ? 
                        <Fragment>
                        <Col xs={12}>
                            <Flex>
                                <div><p className="mt-1 mb-0 fs--1">Account customer overview</p></div>
                                <div></div>
                            </Flex>
                        
                        </Col>
                        <Row className="mb-2">
                            <h5 className="mb-0 text-black mt-2">{this.state.firstName} {this.state.lastName}</h5> 

                        </Row>
                        <Row>
                        <Table size='sm' className='p-0 m-0'>
                            <tbody>
                            <tr>
                                <td style={{width:50}}>
                                <Flex direction={'row'}>
                                    <h6 className="text-700 mb-0  fs--2">Gender:</h6>      
                                </Flex>
                                </td>
                                <td>
                                <h6 className="text-700 mb-0">{ (!isEmpty(this.state.mobile)? capitalize(this.state.gender) : null)}</h6>
                                </td>
                            </tr>


                            <tr>
                                <td style={{width:50}}>
                                <Flex direction={'row'}>
                                    <h6 className="text-700 mb-0  fs--2">Cell:</h6>      
                                </Flex>
                                </td>
                                <td>
                                <h6 className="text-700 mb-0"><FontAwesomeIcon icon="phone" className="fs--1 text-300 me-2" />{(isEmpty(this.state.mobile)? <span className='text-danger'>Empty Mobile number</span>: 
                                    <a href={`tel:${coordinates[0].phoneCode}${this.state.mobile}`} className="fs--1 text-dark"> 
                                    {coordinates[0].phoneCode} {this.state.mobile}
                                    </a>                             
                                )}</h6>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:50}}>
                                <Flex direction={'row'}>
                                    <h6 className="text-700 mb-0  fs--2">Email:</h6>      
                                </Flex>
                                </td>
                                <td>
                                <h6 className="text-700 mb-0"><FontAwesomeIcon icon="envelope" className="fs--1 text-300 me-2" />{(isEmpty(this.state.email)? <span className='text-danger'>Empty Email Address</span>: 
                                    <a href={`mailto:${this.state.email}`} className="fs--1 text-dark"> 
                                    {this.state.email}
                                    </a>                              
                                )}</h6>
                                </td>
                            </tr>  
                            <tr >
                                <td style={{width:50}}>
                                <Flex direction={'row'}>
                                    <h6 className="text-700 mb-0  fs--2">Address:</h6>      
                                </Flex>
                                </td>
                                <td>
                                <h6 className="text-700 mb-0"><FontAwesomeIcon icon="address-book" className="fs--1 text-300 me-2" />
                                {(!isEmpty(coordinates[0].addressLine1) ?  coordinates[0].addressLine1 + "" : null )}
                                {(!isEmpty(coordinates[0].addressLine2) ?  coordinates[0].addressLine2 + "" : null )}
                                </h6>
                                </td>
                            </tr> 
                            <tr >
                                <td style={{width:50}}>
                                <Flex direction={'row'}>
                                    <h6 className="text-700 mb-0  fs--2">Zipcode:</h6>      
                                </Flex>
                                </td>
                                <td>
                                <h6 className="text-700 mb-0"><FontAwesomeIcon icon="location-arrow" className="fs--1 text-300 me-2" />
                                    {coordinates[0].postalCode}                                                         
                                </h6>
                                </td>
                            </tr>                           
                            </tbody>
                            </Table>                        
                        </Row>

                        <Fragment>
                        {(this.state.type == 'main' ?
                        <>
                            <Divider orientation='left' orientationMargin={0} plain>Billing & Shipping</Divider>
                            <Row>
                            <Table size='sm' className='p-0 m-0'>
                                <tbody>
                                    <tr>
                                        <td style={{width:50}}>
                                        <Flex direction={'row'}>
                                            <h6 className="text-700 mb-0  fs--2">Billing:</h6>      
                                        </Flex>
                                        </td>
                                        <td>
                                        <h6 className="text-700 mb-0">
                                        {(!this.state.isBilling ? <span>Same as primary account</span>: 
                                        <Fragment>
                                            <Popover placement="bottomLeft" title={''} content={<CustomerDisplayCard showStatistics={false} idClient={this.state.billingAccount.idClient} editable={false} cardBorder="success" boxShadow={true}  style={{boxShadow : 'none'}}  />} arrow={true}>
                                                <Text style={{ cursor:'help'}}>
                                                    <InfoCircleOutlined  className='me-1 text-400 fs--1' /> {this.state.billingAccount.fullName}
                                                </Text>
                                            </Popover>                                        
                                        </Fragment> )}                                                         
                                        </h6>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td style={{width:50}}>
                                        <Flex direction={'row'}>
                                            <h6 className="text-700 mb-0  fs--2">Shipping:</h6>      
                                        </Flex>
                                        </td>
                                        <td>
                                        <h6 className="text-700 mb-0">
                                        {(!this.state.isShipping ? <span>Same as primary account</span>:
                                        <Fragment>
                                            <Popover placement="bottomLeft" title={''} content={<CustomerDisplayCard showStatistics={false} idClient={this.state.shippingAccount.idClient} editable={false} cardBorder="success" boxShadow={true}  style={{boxShadow : 'none'}}  />} arrow={true}>
                                                <Text style={{ cursor:'help'}}>
                                                    <InfoCircleOutlined  className='me-1 text-400 fs--1' /> {this.state.shippingAccount.fullName}
                                                </Text>
                                            </Popover>                                        
                                        </Fragment>                                        
                                        )}                                                         
                                        </h6>
                                        </td>
                                    </tr>                                                        
                                </tbody>
                            </Table>
                            </Row>
                        </>
                        : null )}
                        </Fragment>


                            </Fragment>
                            : null)}
                            {(this.props.showStatistics? 
                                <Row>
                                    <Statistics idClient={this.state.idClient} flexDirection="column" />
                                </Row>
                            : null)}
                            <Row className='text-center p-0 m-0'>
                                {(this.props.editable ? 
                                <Button
                                    variant="soft-secondary"
                                    size="sm"
                                    className="mt-2 fs--1 fw-normal "
                                    onClick={() => this.setState({modalShow:!this.state.modalShow})}
                                    ><FiEdit className=" fs-1 me-2"/>Edit this customer</Button>
                                : null)}

                            </Row> 
                                <Modal size="xl" show={this.state.modalShow} fullscreen={'lg-down'} onHide={this.handleModalStatus} backdrop="static" keyboard={false}>
                                <Modal.Header className='bg-light'>
                                    <Modal.Title className='fs-1'>
                                        {(this.props.idClient == -1 ? 
                                            'Create account'
                                        : 
                                        <>
                                            <p className='m-0'><Text><span className='text-primary'>{capitalize(this.state.type)}</span> |  {this.state.fullName}</Text></p>                                        
                                        </>
                                        )}
                                    </Modal.Title>

                                    <CloseButton
                                    onClick={this.handleModalStatus}
                                    className="btn btn-rounded btn-sm transition-base p-0"
                                    />     
                                </Modal.Header>
                                <Modal.Body className='p-0'>
                                    <CustomerEdit 
                                    idClient={this.state.idClient} 
                                    target={this.props.target} 
                                    customerFormCallBack={this.customerFormCallBack}
                                    />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                    variant="soft-secondary"
                                    size="sm"
                                    className="mt-2 fs--1 fw-normal "
                                    onClick={this.handleModalStatus}
                                    ><IoCloseSharp className="text-default fs-2 me-2"/> Close</Button>  

                                </Modal.Footer>
                                </Modal>                         
                        </Fragment>
                        )}                  
                    </Card.Body>
                    </Card>
                </Badge.Ribbon>
                 
            </Fragment>
        );
    }


}

CustomerDisplayCardCustomerDetails.propTypes = {
    target: PropTypes.oneOf(['mainAccount', 'billingAccount', 'shippingAccount']),
    cardBorder: PropTypes.oneOf(['primary', 'info', 'warning', 'success', 'danger']),
    // style: PropTypes.string,
    boxShadow : PropTypes.bool,
    editable :  PropTypes.bool,
    // showStatistics : PropTypes.bool,
  };

 
