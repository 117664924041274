import React, { Fragment ,useContext,useState, useEffect, useRef } from 'react';
import { Link, useHistory   } from 'react-router-dom';
import store from 'store'
/*
|UI
*/
import { Divider } from 'antd';

/*
|ICONS
*/

import { PiGearFineDuotone, PiShoppingBagDuotone, PiCalendarCheckDuotone, PiPackageDuotone, PiBuildingsDuotone, PiBookBookmarkDuotone, PiIdentificationCardDuotone, PiGearDuotone, PiCreditCardDuotone, PiBankDuotone, PiHeadsetDuotone, PiScalesDuotone, PiNotebookDuotone, PiCalendarBlankDuotone  } from "react-icons/pi";
import { FcCalendar } from "react-icons/fc";

/*
|PAGES
*/
import IconImage from 'components/common/icon/IconImage';
import VarexeLogo from 'components/common/VarexeLogo';

export const renderItems = (label ='', key = '', url= '', icon = '') => {
  return {
      label: <Link className='text-decoration-none' to={url}>{label}</Link>,
      key: key,
      icon: icon,
    }
}

export const renderGroupLabel = (label ='') => {
  return <Divider className='m-0' orientation="left" orientationMargin="0" plain>{label}</Divider>
}

export const dashboardRoutes = renderItems(
    'Dashboard',
    'dashboard',
    '/dashboard',
    <IconImage icon="dashboard"  className=''/>
)

export const calendarRoutes = renderItems(
    'Calendar',
    'calendar',
    '/calendar',
    <PiCalendarBlankDuotone    className='fs-2 text-success'/>
)
// export const calendarRoutes = {
//     label: 'Calendar',
//     key:   'calendar',
//     url: '/calendar',

//     icon:  <PiCalendarBlankDuotone className='fs-2'/>,
// }


export const salesRoutes = {
  label: 'Sales',
  key:   'sales',
  icon:  <PiCreditCardDuotone className='fs-2'/>,
  children: [
    renderItems(
      'Estimates',
      'estimates',
      '/estimates/list'
    ),
    renderItems(
      'Invoices',
      'invoices',
      '/invoices/list',
    ),
    renderItems(
      'Customers',
      'customers',
      '/customers/list',
    ),
  ],
}


export const servicesRoutes = {
  label: 'Services & Products',
  key:   'servicesproducts',
  icon:  <PiPackageDuotone className='fs-2'/>,
  children: [
    renderItems(
      'Services',
      'services',
      '/services/list',
    ),
    renderItems(
      'Services Categories',
      'servicescategories',
      '/services/services-category/list',
    )
  ],
}



export const accountingRoutes = {
  label: 'Accounting',
  key:   'accounting',
  icon:  <PiScalesDuotone className='fs-2'/>,
  children: [
    renderItems(
      'Sales Taxes',
      'salestaxes',
      '/accounting/sales-tax',
    ),
    renderItems(
      'Payments Report',
      'paymentsreport',
      '/reports/payments',
    ),
    renderItems(
      'Customers Statements',
      'customersstatements',
      '/customers-statements',
    )    
  ],
}

export const purchasesRoutes = {
  label: 'Purchases',
  key:   'purchases',
  icon:  <PiShoppingBagDuotone className='fs-2'/>,
  children: [
    renderItems(
      'Vendors',
      'vendors',
      '/purchases/vendors/list',
    ),   
    renderItems(
      'Bills',
      'bills',
      '/purchases/bills/list',
    ),   
  ],
}


  export const myProfileRoutes =  renderItems(
      'My Profile',
      'myprofile',
      '/profile',
      <PiIdentificationCardDuotone className='fs-2'/>
    )  

  export const notesRoutes =  renderItems(
      'My Notes',
      'notes',
      '/notes',
      <PiNotebookDuotone className='fs-2'/>
    )  




    export const myOrganizationRoutes = {
      label: 'My Organization',
      key:   'myOrganization',
      icon:  <PiBuildingsDuotone className='fs-2'/>,
      children: [
        renderItems(
          'Users',
          'users',
          '/users/list',
        ),   
        renderItems(
          'Business Details',
          'businessdetails',
          '/admin-settings/business-details',
        ),   
      ],
    }








    export const bankAccountRoutes =  renderItems(
      'Bank Account',
      'bankaccount',
      '/admin-settings/bank-accounts',
      <PiBankDuotone className='fs-2'/>
    )  

    export const settingsRoutes =  renderItems(
      'Settings',
      'settings',
      '/settings',
      <PiGearFineDuotone className='fs-2'/>
    )  

    export const licensesRoutes =  renderItems(
        'Licenses',
        'licenses',
        '/admin-settings/licenses',
        <VarexeLogo type={'svgIcon'} style={{width:'20px'}}  className='fs-1'/>
      ) 
 
 
      export const supportRoutes =  renderItems(
        'Support',
        'support',
        '/support',
        <PiHeadsetDuotone className='fs-1'/>
      ) 



export default  function getMenuData() {
    
  let userRole = store.get('userRole');

  let menuItems = [];
  if( userRole != undefined )
  {

 


  menuItems.push(dashboardRoutes);
  menuItems.push(calendarRoutes);
  menuItems.push({
    type: 'group', 
    label: renderGroupLabel('Sales'),
    key: 'SalesGroup',
    children: [
      salesRoutes,    
      servicesRoutes,
      accountingRoutes    
    ],
  });
  menuItems.push({
    type: 'group', 
    label: renderGroupLabel('Purchases'),
    key: 'purchasesGroup',
    children: [
      purchasesRoutes,  
    ],
  });
  menuItems.push({
    type: 'group', 
    label: renderGroupLabel('My stuff'),
    key: 'mystuffGroup',
  });
  menuItems.push(myProfileRoutes);
  menuItems.push(notesRoutes);
  menuItems.push({
    type: 'group', 
    label: renderGroupLabel('Organization'),
    key: 'OrganizationGroup',
    children: [
      myOrganizationRoutes,  
    ],
  });

  /*
  | Admin Menu 
  */

  if( userRole.tag == 'admin' )
  {
    menuItems.push({
      type: 'group', 
      label: renderGroupLabel('Admin'),
      key: 'adminGroup',
    });
    menuItems.push(bankAccountRoutes);
    menuItems.push(settingsRoutes);
    menuItems.push(licensesRoutes);
  }



  menuItems.push({
    type: 'group', 
    label: renderGroupLabel('Support'),
    key: 'supportGroup',
  });
  menuItems.push(supportRoutes);



}



    return menuItems
  }