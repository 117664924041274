
import api from '../../../services/api/';
const URL = '/countries/';

class CountriesService {



    getCountries(){
        return api.get(URL + 'getCountries/');
    }
    getStates(idCountry){     
        return api.get(URL + 'getStates/?idCountry=' + idCountry );
    }
    getCities(idState){
        return api.get(URL + 'getCities/?idState=' + idState);
    }    
    getLabels(data){
        const  params = [];
        if( data.idCountry != undefined  )
         {
            params.push( "idCountry=" + data.idCountry );
         }
         if( data.idState != undefined )
         {
            params.push( "idState=" + data.idState );
         }         
         if( data.idCity != undefined )
         {
            params.push( "idCity=" + data.idCity );
         } 
         return api.get(URL + 'getLabels/' + (params.length ? '?' + params.join('&') : '' ));
    }    
    getTimezones(idCountry){
        return api.get(URL + 'getTimezones/' + idCountry);
    }    

}

export default new CountriesService()