import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import React, {Component, Fragment}      from 'react';

import { Form, Button, Card, Col, Row, Placeholder } from 'react-bootstrap';

import Flex                     from '../../../common/Flex';

export default class SvgDesigner extends Component
{
    constructor(props) {

        super(props)
        this.state = {           
        };
        
    }

    componentDidMount(){         
    }  

    // {
    //     "template": "temp1",
    //     "backgroundColor": "#0069D9",
    //     "watermark": {
    //         "text": "varexe",
    //         "opacity": 0.1,
    //         "bold": false,
    //         "italics": false
    //     },
    //     "pageSize": "A4",
    //     "pageOrientation": "portrait"
    // }

    render(){

        const { docDefinition } = this.props;



        const MakeSVGRemote = (param) => {
            /*
            | Primary color must be #3669b3
            */

            const {designBackgroundColor} = docDefinition

           var svg = param.filecontents;
           var newStr = svg.replaceAll('#3669b3', designBackgroundColor);
        //    const buff = new Buffer(newStr);
        //    const base64data = buff.toString('base64');     
        const base64data = btoa(unescape(encodeURIComponent(newStr)));   
            return ( <img src={`data:image/svg+xml;base64,${base64data }`}  className="position-absolute top-0 start-0" /> );
        }


        const Portrait = () => {

            
            const {designBackgroundColor} = docDefinition;
            let fontStyle = '';

            if( docDefinition.designWatermarkItalics )
            {
                fontStyle = 'italic';
            }

            let fontWeight = '';

            if( docDefinition.designWatermarkBold )
            {
                fontWeight = 'bold';
            }            

            return (

                <Fragment>
                    <div className='position-relative' >
                        <svg  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595 740.3662">
                        {/* <rect y="786.493" width="595" height="10.8732" style={{fill:backgroundColor}} /> */}
                        <text font-style={fontStyle} font-weight={fontWeight}  text-anchor="middle" transform="translate(50,100) rotate(-45)"  x="-5%" y="55%"  
                        style={{fontSize:'117px',fill:designBackgroundColor,margin:'auto', textAlign:'center',  opacity : docDefinition.designWatermarkOpacity}} >{docDefinition.designWatermarkText}</text>
                        {/* <rect  width="595" height="10.8732" style={{fill:designBackgroundColor}} /> */}
                        </svg>  
                        <MakeSVGRemote {...this.props.selected} />
                    </div>
                                  
                </Fragment>
            );
    
    
        }

        const Landscape = () => {

            const {designBackgroundColor} = docDefinition;
            let fontStyle = '';

            if( docDefinition.designWatermarkItalics )
            {
                fontStyle = 'italic';
            }

            let fontWeight = '';

            if( docDefinition.designWatermarkBold )
            {
                fontWeight = 'bold';
            }             
            return (

                <Fragment>
                    <svg  data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 841.8898 551.5556">
                    <text font-style={fontStyle} font-weight={fontWeight}  text-anchor="middle" transform="translate(70,-100) rotate(-32)"  x="13%" y="100%"  
                    style={{fontSize:'117px',fill:designBackgroundColor,margin:'auto', textAlign:'center', fontFamily:'BebasNeueRegular, Bebas Neue', opacity : docDefinition.designWatermarkOpacity}} >{docDefinition.designWatermarkText}</text>                    
                        <rect width="841.8898" height="3.1111" style={{fill:designBackgroundColor}}/>
                        <rect y="548.4444" width="841.8898" height="3.1111" style={{fill:designBackgroundColor}}/>
                    </svg>                   
                </Fragment>
            );
    
    
        }        


        return (
            <Fragment>
                {(docDefinition.designPageOrientation == "portrait" ? 
                <Portrait />
                :
                <Landscape />
                )}                
            </Fragment>
        );
    }

}