import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import classNames   from 'classnames';


/*
| UI
*/
import { Drawer } from 'antd';
import { Row, Col, Navbar, Nav, Button, Offcanvas } from 'react-bootstrap';
import Flex from '../../common/Flex';

/*
| ICONS
*/
import { PiListBulletsDuotone, PiListBold } from "react-icons/pi";



// import NavbarVertical from 'components/navbar/NavbarVertical';


// import AppContext from '../../context/Context';
// import Logo from './Logo';
// import SearchBox from './SearchBox';
// import TopNavRightSideNavItem from './TopNavRightSideNavItem';
// import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';




// import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
// import autoCompleteInitialItem from '../../data/autocomplete/autocomplete';
import { useLocation } from 'react-router';

import NavbarTop    from './navbarTop/NavbarTop';
import NavbarLeft   from './navbarLeft/NavbarLeft';




const mapStateToProps = ({ settings }) => ({
  menuLayoutType: settings.menuLayoutType,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMobileView: settings.isMobileView,
  leftMenuWidth: settings.leftMenuWidth,
})

let touchStartPrev = 0
let touchStartLocked = false

const Menu = ({ dispatch, isMobileMenuOpen, isMobileView, menuLayoutType, leftMenuWidth }) => {


  const { pathname } = useLocation();


  useEffect(() => {
    // mobile menu touch slide opener
    const unify = e => {
      return e.changedTouches ? e.changedTouches[0] : e
    }
    document.addEventListener(
      'touchstart',
      e => {
        const x = unify(e).clientX
        touchStartPrev = x
        touchStartLocked = x > 70
      },
      { passive: false },
    )
    document.addEventListener(
      'touchmove',
      e => {
        const x = unify(e).clientX
        const prev = touchStartPrev
        if (x - prev > 50 && !touchStartLocked) {
          toggleMobileMenu()
          touchStartLocked = true
        }
      },
      { passive: false },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const toggleMobileMenu = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const GetMenu = () => {
    if (isMobileView) {
      return (
        <div>
          <div
            className={'handler'}
            onClick={toggleMobileMenu}
            onFocus={e => {
              e.preventDefault()
            }}
            onKeyPress={toggleMobileMenu}
            role="button"
            tabIndex="0"
          >
            <div className={'handlerIcon'} />
          </div>
          <Drawer
            closable={false}
            open={isMobileMenuOpen}
            placement="left"
            className={'mobileMenu'}
            onClose={toggleMobileMenu}
            maskClosable
            getContainer={null}
            width={leftMenuWidth}
            bodyStyle={{padding:0}}
          >
            <NavbarLeft />
          </Drawer>
        </div>
      )
    }
    if (menuLayoutType === 'top') {
      return <NavbarTop />
    }
    if (menuLayoutType === 'nomenu') {
      return null
    }
    return <NavbarLeft />
  }

  return GetMenu()

};
export default connect(mapStateToProps)(Menu)
