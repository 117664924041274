import React, { Component, useContext } from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';
// import { Line } from 'react-chartjs-2';
// import { hours, paymentByStatus } from '../../data/dashboard/payments';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import { getColor, rgbaColor, themeColors, numberFormatter, formatCurrency } from '../../helpers/utils';

import AppContext from '../../context/Context';

import InvoiceService        from '../invoices/services/';


echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

class  PaymentsLineChart extends Component {

  static context = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      paymentStatus : 'all',
      currencySymbol : '$',
      totalSumToday : 0.00,
      payments: [],
      hours: [],
      paymentByStatus : {
        all:        [],
        // successful: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(d => (d * 3.14).toFixed(2)),
        // failed:     [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(d => (d * 3.14).toFixed(2))
      }   ,  
      isLoading : true 
    }
  }

  componentDidMount(){ 
    this.getData();
  } 

  getData(){
    var state = this.state ;
    InvoiceService.getChartDayStatus(state).then( (response) => {
      try {
        if( response.data !==  undefined && response.data.data !==  undefined )
        {        


          // state.paymentByStatus['all'] = response.data.data.dailySum.map(d => (d * 3.14).toFixed(2));
          state.payments = response.data.data.dailySum;
          state.hours    = response.data.data.hours;
          state.currencySymbol    = response.data.data.currencySymbol;
          state.isLoading    = false;
          this.setState({ state });

        }        
      } catch(error){
        console.log(error);
      }
     });
  }

  render()
  {
    const changeTime = (time) => {

      if( time == 0 )
      {
        return 'Midnight';
      } else if(time<=12){
        return time+' AM';
      } else if(time>12) {
        return (time-12)+' PM';
      }
    }

    const tooltipFormatter = params => {


      let tooltipItem = ``;
      if (Array.isArray(params)) {
        params?.forEach(el => {

          let time = parseInt(el.name);

          tooltipItem =
            tooltipItem +
            `<div className='ms-1'> 
            <h6 className="text-700">Time: ${(changeTime(time))}</h6>
            <h6 className="text-700">
            Total bill : ${
                typeof el.value === 'object' ? formatCurrency(el.value[1], this.state.currencySymbol)  : formatCurrency(el.value, this.state.currencySymbol) 
              }
            </h6>
            </div>`;
        });
      }
      return `<div>
                <p className='mb-2 text-600'>
                </p>
                ${tooltipItem}
              </div>`;
    }; 
   

  // const [paymentStatus, setPaymentStatus] = useState('successful');
  // const { isDark } = this.context;
  const  isDark  = false;
  const { paymentStatus, paymentByStatus } = this.state;

  const getOptions = () => ({
    color: getColor('gray-100'),
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-100'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0
    },
    xAxis: {
      type: 'category',
      data: this.state.hours,
      boundaryGap: true,
      axisPointer: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      axisTick: { show: true },
      axisLabel: {
        color: getColor('gray-400'),
        // formatter: value => `${this.state.currencySymbol} ${value}`,
        // interval: 0,
        // rotate: 30,
        margin: 15
      }
    },
    yAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        formatter: (value) => {
          return `${this.state.currencySymbol} ${numberFormatter(value, 0)}`
        },
        margin: 15
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: [
      {
        type: 'line',
        data: this.state.payments,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          borderColor: getColor('primary'),
          borderWidth: 1
        },
        symbol: 'circle',
        symbolSize: 4,
        smooth: false,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.2)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: { right: 10, left: 0, bottom: 0, top: 10, containLabel: true }
  });




  return (
    (this.state.isLoading ? null : 
      <>
      <h6>Today's Activity</h6>
        <ReactEChartsCore
          echarts={echarts}
          option={getOptions()}
          style={{ height: '10.4375rem' }}
        />
      </>
    )
  );
}
};

export default PaymentsLineChart;
