import {capitalize, isEmpty, phoneNumFormatter} from '../../../../../helpers/utils';
import htmlToPdfmake from "html-to-pdfmake"

export const TermsData= (obj) => {

    let data = [];
    if(obj.displaySetting.displayTerms)  {
  
      data.push( ['Terms'] );
      data.push( [htmlToPdfmake( obj.note )] );
      
  
    } 


    if( obj.configuration.incFooterNote )  {
  
      data.push( [htmlToPdfmake(  obj.configuration.incFooterNote )] );
      
  
    }     




    if( data.length == 0 )
    {
      data = [''];
    }  
    return data;

}

export const chooseTerms = ($type, obj) => {
 
    switch($type){  

      case 1 :
        return Terms1(obj);
      break;
     
      default:
        return Terms1(obj);
      break;
    }
  }

  export const Terms1 = (obj) => {
    
    return TermsData(obj);    
  }