
import React, { useState,createRef, Fragment, useContext } from 'react';
import SimpleBarReact           from 'simplebar-react';
import { connect }              from 'react-redux'
import classNames               from 'classnames';
import { Link  }                from 'react-router-dom';
import toast, { Toaster }       from 'react-hot-toast';

import moment from 'moment';
import dayjs from 'dayjs';
/*
| Images
*/

/*
| Icons
*/
import {PiArrowRightDuotone, PiArrowLeftDuotone, PiCalendarPlusDuotone, PiCaretLeftBold, PiCaretRightBold} from 'react-icons/pi';
import { IoSaveOutline, IoCloseSharp } from 'react-icons/io5';
/*
| UI
*/
import { Badge, Skeleton, Space, Spin, Button, Divider, Switch, Checkbox, Radio,  DatePicker, Typography, Select, Drawer   } from 'antd';
import { Card,  Row, Col, Container, Modal, CloseButton  } from 'react-bootstrap';


import Flex                   from '../../../common/Flex';
import Avatar                 from '../../../common/Avatar';


/*
| pages
*/

import ViewDetailsCalendar from './ViewDetailsCalendar'

/*
| UTILS
*/
import { isIterableArray, formatCurrency, capitalize, isEmpty }     from 'helpers/utils';

/*
| Invoices
*/



/*
| For Search
*/
const WAIT_INTERVAL = 200;
const ENTER_KEY = 13;

const mapStateToProps = ({ settings }) => ({
  settings : settings
})


class EventComponent extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      isLoading: true,
      data: this.props,
      viewDetailsShow: false
    }

  }


  componentDidMount(){
    this.setState({
      isLoading: false
    })
  }  
  

render(){
  const { Text, Title, Paragraph } = Typography;
    return (
    <Fragment>


          {(this.state.isLoading ? 
            <Container fluid >
              <Row>
                  <Card className='p-3 mb-3'><Skeleton active /></Card>
              </Row>
                
            </Container>
            :   
            <Fragment>

              <Card className="hover-actions-trigger shadow-none  border mb-1 mt-1 p-2" >
                <Flex>

                    <div>
                      <div className='mx-0' style={{width: '5px', height:'100%', background:`${this.state.data.backgroundColor}` , borderRadius: '5px'}}></div>
                    </div>
                    <div className='ms-1 flex-grow-1'>
                      <Flex className="">

                        <div className='flex-grow-1'>
                          <p className='mb-0'><Title level={5}>{this.state.data.title}</Title></p>
                          {(this.state.data.isAppointment ? 
                          <p className='mb-0'><Text>Scheduled with {this.state.data.clients[0].fullName}</Text></p>
                          : null)}
                        </div>
                        <div className=''>
                          <Button size='small' className='btn btn-sm btn-soft-primary'
                            onClick={()=>{
                              this.setState({
                                viewDetailsShow:!this.state.viewDetailsShow,
                              });                          
                            }}                                  
                          >View</Button>
                        </div>
                      </Flex>
                      <p className='mb-0'><Text><b>{this.state.data.startHourFormated}</b> - <b>{this.state.data.endHourFormated} </b></Text></p>
                      <p className='mb-0'><Text>{this.state.data.startDateFormatedShort}</Text></p>
                    </div>                      
                  </Flex>                                  


              </Card>

            <Drawer 
              width={(window.innerWidth > 1000 ? 640 : window.innerWidth)} placement="right" 
              onClose={()=>{ this.setState({viewDetailsShow: false})}} open={this.state.viewDetailsShow}>
                <ViewDetailsCalendar 
                    idCalendar={this.state.data.idCalendar} 
                    // viewDetailsContent={{
                    //   event: {
                    //     id: this.state.data.idCalendar
                    //   }
                    // }}   
                    refreshContent={this.refreshContent}     
                  />        
            </Drawer>


            </Fragment> 
          )}



    </Fragment>
    )
  }
}
export default connect(mapStateToProps)(EventComponent);