

import React, {Component, Fragment}      from 'react';
/*
| UI
*/
import {  Skeleton, Layout, Card, Divider, Typography, Form, Input , Switch, Steps, Tag, Space, Tooltip, Button, Checkbox, Radio, DatePicker, InputNumber, Alert, Select    } from 'antd';
import {  Col, Row } from 'react-bootstrap';
import {  UncontrolledTooltip }         from 'reactstrap';



/*
| Pages
*/
import Flex                     from '../../../common/Flex';
import FormGroupInput           from '../../../common/FormGroupInput';
import FalconCardHeader         from '../../../common/FalconCardHeader';
import ButtonIcon               from '../../../common/ButtonIcon';
import ToastVarexe              from '../../../common/ToastVarexe';
/*
| ICONS
*/
import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';
import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';

/*
| draft-js
*/

import TextEditorVarexe      from '../../../common/TextEditorVarexe/TextEditorVarexe';
/*
| End draft-js
*/


import InvoiceService       from '../../services/';

class CustomizationSetting extends Component
{
    constructor(props) {

        super(props)
        this.state = {
            idInvoiceConfiguration: -1,
            
            draftTitle           : "Draft",
            title         : "Invoice",
            taxformat:      "%",
            discountFormat: "%",
            currency:       "$",
            currency:       "CAD",
            currencyOptionList: [],
            calculationSetting:true,
            defaultTaxes:[],
            topNote         : "",
            incFooterNote         : "",

            isLoading: true,
            isEditingVisible: true,
            redirect:      null,
            // defaultdefaultTaxes: { 
            //     title:  "GST",
            //     value:  "0.00"
            //   }             
        };
        this.editorStateChanged = this.editorStateChanged.bind(this);
        this.editorStateFooterNoteChanged = this.editorStateFooterNoteChanged.bind(this);
    }
    componentDidMount(){

        InvoiceService.getSetting().then((res) =>{
            try{
                if( res.data !== undefined ){   
                if( res.data ){
                let InvoiceSetting = res.data;
        
                this.setState({
                    isLoading:false,
                    idInvoiceConfiguration:  InvoiceSetting.idInvoiceConfiguration,
                    
                    customFieldTitle_1:                 InvoiceSetting.customFieldTitle_1,
                    customFieldTitle_2:                 InvoiceSetting.customFieldTitle_2,
                    customFieldTitle_3:                 InvoiceSetting.customFieldTitle_3,
                    customFieldTitle_4:                 InvoiceSetting.customFieldTitle_4,

                    displayInternalCustomField_1:                 InvoiceSetting.displayInternalCustomField_1,
                    displayInternalCustomField_2:                 InvoiceSetting.displayInternalCustomField_2,
                    displayInternalCustomField_3:                 InvoiceSetting.displayInternalCustomField_3,   
                    displayInternalCustomField_4:                 InvoiceSetting.displayInternalCustomField_4,   
                    
                    displayPublicCustomField_1:                 InvoiceSetting.displayPublicCustomField_1,
                    displayPublicCustomField_2:                 InvoiceSetting.displayPublicCustomField_2,
                    displayPublicCustomField_3:                 InvoiceSetting.displayPublicCustomField_3,                    
                    displayPublicCustomField_4:                 InvoiceSetting.displayPublicCustomField_4,                    
                });          
                }
            }

            } catch(error){
                console.log(error);
            }            
    
          }); 

          
    }


    handleSettingUpdate  = (values) => {

        values.customFieldTitle_1 = this.state.customFieldTitle_1;
        values.customFieldTitle_2 = this.state.customFieldTitle_2;
        values.customFieldTitle_3 = this.state.customFieldTitle_3;
        values.customFieldTitle_4 = this.state.customFieldTitle_4;

        values.displayInternalCustomField_1 = this.state.displayInternalCustomField_1;
        values.displayInternalCustomField_2 = this.state.displayInternalCustomField_2;
        values.displayInternalCustomField_3 = this.state.displayInternalCustomField_3;
        values.displayInternalCustomField_4 = this.state.displayInternalCustomField_4;

        values.displayPublicCustomField_1 = this.state.displayPublicCustomField_1;
        values.displayPublicCustomField_2 = this.state.displayPublicCustomField_2;
        values.displayPublicCustomField_3 = this.state.displayPublicCustomField_3;
        values.displayPublicCustomField_4 = this.state.displayPublicCustomField_4;


        InvoiceService.updateSetting(values)
        .then((res) =>{
            let result = res.data;
          
            if( result.status == 400 )
            {
 
            ToastVarexe({
                    type :  'error',
                    message: result.message
                });                                     
            }
            if( result.status == 200 )
            {
                ToastVarexe({
                        type :  'success',
                        message: result.message
                    });      
            }            
        });    
      }


      
    //   handleChange =  (target) =>  {

    //     const name      = target.name;
    //     const value     = target.value;
    //     const newState  = {};
    
    //     Object.keys(this.state).forEach(key => {
    //         if (key.endsWith(name)) {
    //           newState[key] = value;
    //         } else {
    
    //         }
    //     });
    //     this.setState(newState);
    //  }
     
    handleChange = (target) => {

        const name      = target.name;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        let state = this.state;
    
        state[name] = value;
        this.setState(state);
    }   

     editorStateChanged = (value) => {
        this.setState(() => (
          {
              ...this.state,
              ['topNote']: value
          }
      ));
       
    } 
    editorStateFooterNoteChanged = (value) => {
        this.setState(() => (
          {
              ...this.state,
              ['incFooterNote']: value
          }
      ));
       
    } 


    handleServiceItemChange = (i) => (event) => {
       var state = { ...this.state };
       this.setState({ state });
   }

    render(){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const { Text, Title, Paragraph } = Typography;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };        
        
        return (
            <Fragment>


                <Title level={4}>Custom Fields</Title>
                <Text>Tailor your experience by adding custom fields to your invoices. Capture specific details that matter most to your business, such as project numbers, purchase order references, or additional client information. These customizable fields enhance the flexibility of your invoicing process, allowing you to adapt to unique client requirements and internal tracking needs.</Text>  

                { this.state.isLoading ? 

                    <Skeleton  className="mt-3" />

                    :
                    <> 

                <Form
                    {...layout}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    size='large'
                    layout="horizontal"
                    style={{ maxWidth: 600, margin:'auto' }}
                    className="mt-5"
                    disabled={false}
                    onFinish={this.handleSettingUpdate}

                    initialValues={{

                        
                        customFieldTitle_1       : this.state.customFieldTitle_1,  
                        displayInternalCustomField_1       : this.state.displayInternalCustomField_1,  
                        displayPublicCustomField_1       : this.state.displayPublicCustomField_1,  
                        customFieldTitle_2       : this.state.customFieldTitle_2,  
                        displayInternalCustomField_2       : this.state.displayInternalCustomField_2,  
                        displayPublicCustomField_2       : this.state.displayPublicCustomField_2,  
                        customFieldTitle_3       : this.state.customFieldTitle_3,  
                        displayInternalCustomField_3       : this.state.displayInternalCustomField_3,  
                        displayPublicCustomField_3       : this.state.displayPublicCustomField_3,   
                        
                        customFieldTitle_4       : this.state.customFieldTitle_4,  
                        displayInternalCustomField_4       : this.state.displayInternalCustomField_4,  
                        displayPublicCustomField_4       : this.state.displayPublicCustomField_4,                          
                        }}

                >



                                    <Col lg={{ span: 18, offset: 0 }} className="mt-0 mb-2" >
                                        <Divider orientation='left' orientationMargin={0} plain>Custom organization fields</Divider>
                                    </Col> 



                                    <div className=' rounded p-3' style={{border: 'dashed 3px var(--varexe-border-color)'}}>

                                        <Form.Item
                                            className='mb-0'
                                            label="Visible to"  
                                        >
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayInternalCustomField_1"
                                            checked={this.state.displayInternalCustomField_1}
                                            onChange={({ target }) => 
                                            {
                                                if( !target.checked )
                                                {
                                                    this.setState({
                                                        displayInternalCustomField_1: false,
                                                        displayPublicCustomField_1: false,
                                                    })
                                                } else {
                                                    this.setState({
                                                        displayInternalCustomField_1: true,
                                                    })     
                                                }
                                            }
                                        
                                        }
                                            type="checkbox"
                                            >Internal</Checkbox> 
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayPublicCustomField_1"
                                            onChange={({ target }) => this.handleChange(target)}
                                            checked={this.state.displayPublicCustomField_1}
                                            type="checkbox"
                                            disabled={(this.state.displayInternalCustomField_1 ? false : true)}
                                            >Public</Checkbox>                                        
                                        </Form.Item>

                                        <Form.Item
                                            className='mb-0'
                                            label="Field 1 title"
                                            name="customFieldTitle_1"   
                                        >
                                                <Input name="customFieldTitle_1"  placeholder="" 
                                                onChange={({ target }) => this.handleChange(target)} disabled={(this.state.displayInternalCustomField_1 || this.state.displayPublicCustomField_1 ? false : true)}
                                                />
                                        </Form.Item> 
                                    </div>


                                    <div className=' rounded p-3 mt-3' style={{border: 'dashed 3px var(--varexe-border-color)'}}>

                                        <Form.Item
                                            className='mb-0'
                                            label="Visible to"  
                                        >
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayInternalCustomField_2"
                                            checked={this.state.displayInternalCustomField_2}
                                            onChange={({ target }) => 
                                            {
                                                if( !target.checked )
                                                {
                                                    this.setState({
                                                        displayInternalCustomField_2: false,
                                                        displayPublicCustomField_2: false,
                                                    })
                                                } else {
                                                    this.setState({
                                                        displayInternalCustomField_2: true,
                                                    })     
                                                }
                                            }
                                        
                                        }
                                            type="checkbox"
                                            // disabled={(this.state.displayInternalCustomField_1 ? false : true)}
                                            >Internal</Checkbox> 
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayPublicCustomField_2"
                                            onChange={({ target }) => this.handleChange(target)}
                                            checked={this.state.displayPublicCustomField_2}
                                            type="checkbox"
                                            disabled={(this.state.displayInternalCustomField_2 ? false : true)}
                                            >Public</Checkbox>                                        
                                        </Form.Item>

                                        <Form.Item
                                            className='mb-0'
                                            label="Field 2 title"
                                            name="customFieldTitle_2"   
                                        >
                                                <Input name="customFieldTitle_2"  placeholder="" 
                                                onChange={({ target }) => this.handleChange(target)} disabled={(this.state.displayInternalCustomField_2 || this.state.displayPublicCustomField_2 ? false : true)}
                                                />
                                        </Form.Item> 
                                    </div>   

                                    <div className=' rounded p-3 mt-3' style={{border: 'dashed 3px var(--varexe-border-color)'}}>

                                        <Form.Item
                                            className='mb-0'
                                            label="Visible to"  
                                        >
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayInternalCustomField_3"
                                            checked={this.state.displayInternalCustomField_3}
                                            onChange={({ target }) => 
                                            {
                                                if( !target.checked )
                                                {
                                                    this.setState({
                                                        displayInternalCustomField_3: false,
                                                        displayPublicCustomField_3: false,
                                                    })
                                                } else {
                                                    this.setState({
                                                        displayInternalCustomField_3: true,
                                                    })     
                                                }
                                            }
                                        
                                        }
                                            type="checkbox"
                                            >Internal</Checkbox> 
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayPublicCustomField_3"
                                            onChange={({ target }) => this.handleChange(target)}
                                            checked={this.state.displayPublicCustomField_3}
                                            type="checkbox"
                                            disabled={(this.state.displayInternalCustomField_3 ? false : true)}
                                            >Public</Checkbox>                                        
                                        </Form.Item>

                                        <Form.Item
                                            className='mb-0'
                                            label="Field 3 title"
                                            name="customFieldTitle_3"   
                                        >
                                                <Input name="customFieldTitle_3"  placeholder="" 
                                                onChange={({ target }) => this.handleChange(target)} disabled={(this.state.displayInternalCustomField_3 || this.state.displayPublicCustomField_3 ? false : true)}
                                                />
                                        </Form.Item> 
                                    </div>     

                                    <div className=' rounded p-3 mt-3' style={{border: 'dashed 3px var(--varexe-border-color)'}}>

                                        <Form.Item
                                            className='mb-0'
                                            label="Visible to"  
                                        >
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayInternalCustomField_4"
                                            checked={this.state.displayInternalCustomField_4}
                                            onChange={({ target }) => 
                                            {
                                                if( !target.checked )
                                                {
                                                    this.setState({
                                                        displayInternalCustomField_4: false,
                                                        displayPublicCustomField_4: false,
                                                    })
                                                } else {
                                                    this.setState({
                                                        displayInternalCustomField_4: true,
                                                    })     
                                                }
                                            }
                                        
                                        }
                                            type="checkbox"
                                            >Internal</Checkbox> 
                                        <Checkbox
                                            className="form-label-nogutter "
                                            name="displayPublicCustomField_4"
                                            onChange={({ target }) => this.handleChange(target)}
                                            checked={this.state.displayPublicCustomField_4}
                                            type="checkbox"
                                            disabled={(this.state.displayInternalCustomField_4 ? false : true)}
                                            >Public</Checkbox>                                        
                                        </Form.Item>

                                        <Form.Item
                                            className='mb-0'
                                            label="Field 4 title"
                                            name="customFieldTitle_4"   
                                        >
                                                <Input name="customFieldTitle_4"  placeholder="" 
                                                onChange={({ target }) => this.handleChange(target)} disabled={(this.state.displayInternalCustomField_4 || this.state.displayPublicCustomField_4 ? false : true)}
                                                />
                                        </Form.Item> 
                                    </div>                                                                                                      

                                    




                                    <Flex className="">
                                        <div className="p-2 flex-grow-1 ">
                                            {/* <Button type="default" onClick={this.handleStripeAccountUpdate}>Finish later</Button> */}
                                        </div>
                                        <div className="p-2 ">
                                            <Button type="primary" htmlType="submit">Save and continue</Button>
                                        </div>
                                </Flex>     

                </Form>


        </>
        }






            </Fragment>
        );
    }
}
export default CustomizationSetting;