
import React, {Fragment, Component} from "react";
import PropTypes from 'prop-types';
/*
| UI
*/
import {  Modal, CloseButton, ListGroup } from 'react-bootstrap';
  
import Flex from '../../common/Flex'
/*
| ANTD
*/
import { AppstoreOutlined, MailOutlined, SettingOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
// import type { MenuProps } from 'antd';
import {Layout,Drawer,  Menu, Button } from 'antd';

/*
| Pages
*/

// import BusinessDetailsDashboardForm from '../../invoices/admin-components/BusinessDetailsDashboardForm'; should be removed ------ make sure to remove first redirect user
// import CompanyDetails           from '../../admin-settings/company/components/CompanyDetails';
// import CompanyHeaderProfile     from '../../admin-settings/company/components/partials/CompanyHeaderProfile';
import OtherFormats             from '../../admin-settings/company/components/OtherFormats';


import InvoiceSizeDesign        from '../../invoices/admin-components/InvoiceSizeDesign';
import CustomizationSetting     from '../../invoices/admin-components/partials/CustomizationSetting';
import VisibilitiesSetting      from '../../invoices/admin-components/partials/VisibilitiesSetting';
import ColumnSetting            from '../../invoices/admin-components/partials/ColumnSetting';
import OutgoingEmailsSetting    from '../../invoices/admin-components/OutgoingEmailsSetting';
import CurrencySetting          from '../../invoices/admin-components/partials/CurrencySetting';
import NumberSetting            from '../../invoices/admin-components/partials/NumberSetting';

function getItem(
    label,
    key,
    icon,
    children,
    type,
  ) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  
  const items = [



    getItem('Sales & Invoices', '#', <AppstoreOutlined />, 
    [
        getItem('Color & Design',   'InvoiceSizeDesign'),
        getItem('Customization',    'CustomizationSetting'),
        getItem('Visibilities',     'VisibilitiesSetting'),
        getItem('Column features',  'ColumnSetting'),
        getItem('Outgoing Emails',  'OutgoingEmailsSetting'),
        getItem('Currency',         'CurrencySetting'),
        getItem('Sequence Number',  'NumberSetting'),
        
    ], 'group'),

    { type: 'divider' },

        getItem('Formats', '#', <AppstoreOutlined />, 
        [
            getItem('Date & Phone format', 'OtherFormats')
        ], 'group'),      
        
        // { type: 'divider' },

        // getItem('Business', '#', <AppstoreOutlined />, 
        //     [
        //         getItem('Business information',  'CompanyDetails'), 
        //         getItem('Business Cover Images', 'CompanyHeaderProfile'),
        //     ], 'group'),
    
  

  
    


  ];



export default class SettingModal extends Component {

    static defaultProps = {
        display:  false,
    }  

    constructor(props)
    {
        super(props);
        this.state = {

            page: 'InvoiceSizeDesign',

            display : this.props.display,
            displayDrawer : false
        }
    }

    componentDidMount(){     
    }

    componentDidUpdate(prevProps) {
        if (prevProps.display !== this.props.display) {
          this.setState({
            display: this.props.display
          })
        }
      } 

    handleMuneClick = ({ item, key, keyPath, domEvent }) => {
        // console.log(key);
        this.setState({
            page : key,
            displayDrawer:false
        });
    }      
      
    closeDisplay = () => {
        this.setState({
            display: false
          });
          
        this.props.handleModalSetting();  
    }  

    handleDisplayDrawer = () => {
        this.setState({
            displayDrawer : !this.state.displayDrawer
        })
    }

    render(){
        const { Header, Content, Footer, Sider } = Layout;
        return(
            <Fragment>
                    <Modal size="xl"  show={this.state.display} fullscreen="lg-down" onHide={this.closeDisplay}>
                        <Modal.Body className='p-0'>
                            {/* <div className="bg-light rounded-top-lg px-4 py-3">
                            <h6 className="mb-1">Select or Create a </h6>
                            <p className="fs--2 mb-0">
                            You can create a new 
                            </p>
                            </div> */} 
                            <div className="position-absolute top-0 end-0 mt-1 me-1 z-index-1">
                            <Button
                            size="default"
                                className="btn-falcon"
                                // type="primary"
                                icon={<CloseOutlined className="m-0 p-0" />}
                                onClick={this.closeDisplay}

                                ></Button>
                                                                
                            {/* <CloseButton
                                onClick={this.closeDisplay}
                                className="btn btn-sm btn-rounded d-flex flex-center transition-base"
                            />   */}
                            </div>  



<Layout className="bg-light">

    {/* Sibar Large screen */}
    <Sider
    className="bg-light"
    // style={{ width: 300 }}
    // collapsible 
    breakpoint="lg"
    collapsedWidth="0"
    onBreakpoint={(broken) => {
        console.log(broken);
    }}
    onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
    }}
    trigger={null}
    >
        <Menu
            // className="bg-light"
            // theme="dark"
            mode="inline"     
            onClick={this.handleMuneClick}
            style={{  height: '100%' }}
            defaultSelectedKeys={[this.state.page]}
            // defaultOpenKeys={['sub1']}
            items={items}
        />

    </Sider>
    {/* Sibar Large screen */}



    <Drawer
        size={'default'}
        title="Settings Menu"
        placement="left"
        onClick={this.handleDisplayDrawer}
        onClose={this.handleDisplayDrawer}
        visible={this.state.displayDrawer}
        bodyStyle={{padding: '0px', background : 'var(--varexe-bg-light)'}}
        className="p-0 bg-light"
      > 
        <Menu
        // className="bg-light"
            // theme="dark"
            mode="inline"     
            onClick={this.handleMuneClick}
        // style={{ width: 256, height: '100%' }}
        defaultSelectedKeys={[this.state.page]}
        // defaultOpenKeys={['sub1']}
        items={items}
        />
     </Drawer>




    <Layout className="bg-light"  >
        <Header style={{ padding: 0, height:'30px', lineHeight:'0px' }} className="bg-light" >
            <>
            <Flex direction='row' className=" bg-light ">  
                <div className="pt-2">
                    <Button
                    className="settingMenuButton m-0 ms-2"
                    // type="primary"
                    icon={<MenuOutlined />}
                    onClick={this.handleDisplayDrawer}

                    >
                        Setting Menu
                    </Button>
                </div>
                {/* <div className="">
                    <h5 className="">Varexe Setting</h5>
                </div> */}
            </Flex>
            </>

            
        </Header>


            <Content  className=" m-3 bg-light">
                <div style={{ minHeight: 360 }} className="p-md-4 p-2 ">

                    {
                    {
                        'OtherFormats':
                        <Fragment>
                            <OtherFormats />
                        </Fragment>,

                        // 'CompanyDetails':
                        // <Fragment>
                        //     <CompanyDetails />
                        // </Fragment>,
                        // 'CompanyHeaderProfile':
                        // <Fragment>
                        //     <CompanyHeaderProfile />
                        // </Fragment>,

                        'InvoiceSizeDesign':
                        <Fragment>
                            <InvoiceSizeDesign />
                        </Fragment>,
                        'CustomizationSetting':
                        <Fragment>
                            <CustomizationSetting />
                        </Fragment>,
                        'VisibilitiesSetting':
                        <Fragment>
                            <VisibilitiesSetting />
                        </Fragment>,
                        'ColumnSetting':
                        <Fragment>
                            <ColumnSetting />
                        </Fragment>,
                        'OutgoingEmailsSetting':
                        <Fragment>
                            <OutgoingEmailsSetting />
                        </Fragment>,
                        'CurrencySetting':
                        <Fragment>
                            <CurrencySetting />
                        </Fragment>,
                        'NumberSetting':
                        <Fragment>
                            <NumberSetting />
                        </Fragment>,

                    } [this.state.page]
                    }

                </div>
            </Content>
        <Footer className="text-muted bg-light" style={{ textAlign: 'left', padding: '10px 0px 0px 10px' }}>
            <p >Please note that your security is important to us. Varexe has implemented a series of security measures designed to keep your information protected and confidential. If you have any quetions please contact us support@varexe.com.</p>
        </Footer>
    </Layout>


</Layout>


                        </Modal.Body>
                    </Modal>
            </Fragment>
        )
    }
}