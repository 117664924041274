import React from "react";
import {  Form  } from 'react-bootstrap';

class HeaderStyleDropdown extends React.Component {
	onToggle = event => {
		let value = event.target.value;
		this.props.onToggle(value);
	};

	render() {
		return (
			<Form.Select className="form-control" size="sm" value={this.props.active} onChange={this.onToggle}>
				<option value="">Header Levels</option>
				{this.props.headerOptions.map((heading,index) => {
					return <option value={heading.style} key={index}>{heading.label}</option>;
				})}
			</Form.Select>
		);
	}
}

export default HeaderStyleDropdown;