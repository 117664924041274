import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect  } from 'react-router-dom';


/*
| UI
*/
import { Skeleton, Tooltip, Button, Dropdown, Space, Typography, Popover     } from 'antd';
import { Card , Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
/*
| ICONS
*/


import {EllipsisOutlined, DownOutlined, InfoCircleOutlined} from '@ant-design/icons'

import { BsFillInfoSquareFill } from 'react-icons/bs';
import { PiEyeDuotone, PiLayoutDuotone, PiDotsThreeOutlineVerticalDuotone, PiDotsThreeOutlineDuotone } from 'react-icons/pi';
/*
| PAGES
*/

import DrawerInvoice from 'components/common/DrawerVarexe/DrawerInvoice';
import TagsSelect    from 'components/common/TagsVarexe/components/TagsSelect';


/*
| Customers
*/
import CustomerDisplayCard from '../../../../customers/components/customerOverview/CustomerDisplayCard';

/*
| UTILS
*/
import {statusComponent,statusEstimateComponent, dueDateComponent} from '../utilsComponents';
import { isIterableArray, formatCurrency, capitalize, isEmpty }     from 'helpers/utils';


class InvoiceListDesktop extends Component {

    static defaultProps = {
        data : [],
        generateType : 'invoice'
    }

    constructor(props) {
        super(props);
        this.state = {
            data : this.props.data,
            viewLink : (this.props.generateType == 'invoice' ? '/invoices/invoice-details/': '/estimates/estimate-details/' ),
            editLink : (this.props.generateType == 'invoice' ? '/invoices/invoice-edit/'   : '/estimates/estimate-edit/' ),
            showQuickView : false,
            dropDownForceVisible : false,
            redirect : null
        }
    }

    componentDidMount(){
    }

    onMenuClick = (e) => {
    };

    handleShowQuickView = (showQuickView) => {
        this.setState({
            showQuickView : showQuickView
        })
    }
    render(){

        let invoice = this.state.data;

        const items = [
            {
              key: '1',
              label: 'View Details',
              onClick: () => {
                this.setState({
                    redirect : `${this.state.viewLink}${this.state.data.id}`
                })
              }
            },
            {
              key: '2',
              label: `Edit ${this.props.generateType == 'invoice' ? 'invoice' : 'estimate'}`,
              onClick: () => {
                this.setState({
                    redirect : `${this.state.editLink}${this.state.data.id}`
                })
              }             
            },                       
          ];

          const { Text, Title, Paragraph } = Typography;

          if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }


        return(
            <Fragment>
                <Card className="hover-actions-trigger shadow-none border mb-1 mt-1" >
                <div className={`${(this.state.dropDownForceVisible ? null : 'hover-actions')}  p-1  position-absolute top-0 end-0`}>
                    <div className='d-flex'>

                        <div className='me-1'>
                            <DrawerInvoice idInvoice={invoice.id}    /> 
                        </div>                     
                        <div className="   ">
                            <Dropdown className='' menu={{ items }} placement="bottomLeft" arrow={{ pointAtCenter: true }} trigger={['click']}
                            onOpenChange={(status)=>{
                                this.setState({
                                    dropDownForceVisible : status
                                })

                            }}
                            >
                                <Button size="small"><PiDotsThreeOutlineDuotone className="fs-0" /></Button>
                            </Dropdown>                            
                        </div>
                    </div>
                </div>

                    <Row className="p-0 m-0">
                        <Col xs={2} className="p-2 flex-fill">
                        <Flex>

                            <div className=''>
                                <Tooltip placement="top" title={`View ${this.props.generateType} details`} >
                                    <Link to={`${this.state.viewLink}${invoice.id}`} className={'link-dark fw-semi-bold text-nowrap'}  >
                                        {invoice.number}
                                    </Link> 
                                </Tooltip>  

                           
            
                            </div>
                        </Flex>  
                                                   
                        </Col>

                        <Col xs={2} className="p-2 flex-fill">
                            <Flex>
                                {/* <div>
                                    <Avatar className='mt-1' size="m" rounded="circle" name={invoice.client} />
                                </div> */}
                                <div className='ms-1'>
   
                                <Popover placement="rightTop" title={''} content={<CustomerDisplayCard idClient={invoice.idClient} editable={false} cardBorder="success" boxShadow={true}  style={{boxShadow : 'none'}}  />} arrow={true}>
                                    <Text style={{ cursor:'help'}}>
                                        <InfoCircleOutlined  className='me-1 text-400 fs--1' /> {invoice.client}
                                    </Text>
                                </Popover>

                                {/* {(this.props.isMobileView ? null: 
                                    <PopoverStickOnHover
                                            component={<CustomerDisplayCard idClient={invoice.idClient} editable={false} cardBorder="success" boxShadow={true} style={{  zIndex:'9999', minWidth:'400px'}}  />}
                                            placement="top"
                                            onMouseEnter={() => { }}
                                            delay={200}
                                        > 
                                        <Text style={{ cursor:'help'}}>
                                        <InfoCircleOutlined  className='me-1 text-400 fs--1' /> {invoice.client}
                                        </Text>
                                </PopoverStickOnHover>   
                                )}                                                                 */}
                                </div>
                            </Flex>                             
                        </Col>
                        <Col xs={2} className="p-2 flex-fill">
                            {dueDateComponent(invoice)}
                        </Col>
                        <Col xs={2} className="p-2 flex-fill">
                            <Flex alignItems='top' className={'mt-0'}>
                                <div><Avatar size="m" rounded="circle" src={invoice.createdBy.imageDisplay} /></div>
                                <div className=' ps-2'>
                                    <h6 className="mb-0">{invoice.createdBy.firstName} {(this.props.isMobileView ? null: invoice.createdBy.lastName )} </h6>
                                    <p className='mb-0 fs--1'>{invoice.createdDateFormat} 
                                    {(this.props.isMobileView ? null: 
                                        <Tooltip placement="top" 
                                        title={`This ${this.props.generateType} create on ${invoice.createdDate}`} 
                                        arrow={false}><InfoCircleOutlined  className='ms-2 text-400 fs--1' /></Tooltip>
                                    )}
                                    </p>
                                </div>
                            </Flex>                            
                        </Col>
                        <Col xs={2} className="p-2 flex-fill">
                            <p className='fw-semi-bold text-800 fs-0 m-0' style={{lineHeight: '1'}}>{formatCurrency(invoice.total, invoice.currencySymbol)}</p>
                            <p className="fs--1 m-0 text-400">Total ({invoice.currencyCode})</p>
                                                        
                        </Col>
                        {/* <div className="p-2 flex-fill bg-300 border border-400">Flex item</div> */}
                        {/* <div className="p-2 flex-fill bg-300 border border-400">Flex item</div> */}
                    </Row> 
                    <Row className='m-0 p-0 '>
                    <Col xs={12} className="ps-2 m-0 flex-fill">
                    <ul className="list-inline mb-0 mt-0">
                        {(this.props.generateType == 'invoice' ? statusComponent(invoice): statusEstimateComponent(invoice))}
                        <TagsSelect  idTable={invoice.id} tableName={'inc_invoices'} />  
                    </ul>                   
                    </Col>
                    </Row>                   
                </Card>
            </Fragment>            
        )
        
    }


}
export default InvoiceListDesktop