import { isIterableArray, capitalize } from '../../../helpers/utils';

import UploaderService from '../../../services/uploader/';
import pdfExport         from './pdfExport';

/*
| Invoices utils and calculations
*/

export const GetExtraFeeMessage = ({name, amount, operator, perQTY, currencySymbol}) => {
    return(
      <>
        <span className='ms-2 text-primary text-nunito'>
          {capitalize(name)}
          {(operator=='+'?<span className='ms-1'>increases</span>:null)}
          {(operator=='-'?<span className='ms-1'>decreases</span>:null)}
          <span className='ms-1'>{currencySymbol}{amount}</span>
          {(perQTY?<span className='ms-1'>per quantity</span>:null)}
          </span>
      </>
    )
  }

export const _getLineExtraFeeTotal = (data) => {
    let lineItemExtraFeeTotal = parseFloat((0).toFixed(2));

    const { quantity } = data.item;

    if( data.item.itemSpecifications.length > 0 )
    {
      if( isIterableArray(data.item.itemSpecifications) )
      {
        data.item.itemSpecifications.map((itemSpecification, specificationIndex)=>{
          if( itemSpecification.hasOwnProperty('options') )
          {
            if( isIterableArray(itemSpecification.options) )
            {
              itemSpecification.options.map((extraFee, index)=>{

                if( extraFee.isChecked )
                {
                  let _amount = parseFloat((extraFee.amount*1).toFixed(2));
                  if( extraFee.perQTY )
                  {
                    _amount = _amount*quantity;
                  } else {
                    _amount = _amount*extraFee.defaultQTY;
                  }
                 
                  
                  
                  if( extraFee.operator == '+' )
                  {
                    lineItemExtraFeeTotal += _amount; 
                  }
                  if( extraFee.operator == '-' )
                  {
                    lineItemExtraFeeTotal -= _amount; 
                  }
                }
              });
            }                
          }
        });
      }
    }
    return  (lineItemExtraFeeTotal).toFixed(2);
}

export const _getRawAmount = (data) => {
  let lineItemTotal = (0).toFixed(2);

  const { quantity, price } = data.item;

   let totalAmount   = quantity * price;  

   let prepareData = {
       item:data.item
   }
   return totalAmount;
   
}

export const _getDiscountAmount = (data) => {
  let lineItemTotal = (0).toFixed(2);

  const { quantity, price, taxes, discount } = data.item;

   let totalAmount   = quantity * price;  

   let prepareData = {
       item:data.item
   }
   totalAmount += parseFloat(_getLineExtraFeeTotal(prepareData));

   let _discount = 0;
   if(data.discountFormat == "%")
   {
     _discount = totalAmount * (discount / 100);
   }
   if(data.discountFormat == "$")
   {
     _discount = discount;
   } 

   return _discount;
   
}
export const _getLineItemTotal = (data) => {
       let totalAmount = (0).toFixed(2);
       let { quantity, price, taxes, discount } = data.item;

       price    = parseFloat(price);
       discount = parseFloat(discount);

        totalAmount   = quantity * price;  

        if( data.includeExtraFees )
        {
          let prepareData = {
              item:data.item
          }
          totalAmount += parseFloat(_getLineExtraFeeTotal(prepareData));
        }

        if( data.includeDiscount )
        {

          let _discount = 0;
          if(data.discountFormat == "%")
          {
            _discount = totalAmount * (discount / 100);
          }
          if(data.discountFormat == "$")
          {
            _discount = discount;
          } 
          totalAmount = (totalAmount - _discount);
        }     

        if( data.includeTax )
        {
          let _tax = 0;
  
          if( taxes.length > 0 )
          {
            taxes.map((tax, taxIndex)=>{
              _tax +=  (totalAmount) * (parseFloat(tax.value) / 100); 
            })
            totalAmount   =  (totalAmount  + _tax  ).toFixed(2);
          }
        }

    return totalAmount;

}

export const _getMergedTaxes = (items) => {

  let _taxes = [];
    items.map((item, itemIndex)=>{
      item.taxes.map((tax, taxIndex)=>{
        let _t = tax;
        _t.amount = parseFloat(item.total) * (parseFloat(_t.value) / 100).toFixed(2);

      if(typeof _taxes[tax.idSalesTax] === 'undefined') {
        _taxes[tax.idSalesTax] = _t;
      }
      else {
        _taxes[tax.idSalesTax].amount += _t.amount; 
      }
    })
    })
    
    var taxesArray = _taxes.filter(elm => elm);
    return taxesArray;

}

export const _getSubtotalInvoice = (items) => {
  let subTotal = 0;
    
  items.map((item, index) => {
      let prepareData = {
        item:    item,
        includeExtraFees: true,
        includeDiscount: false,
        includeTax:      false
      }      
      subTotal += Number(_getLineItemTotal(prepareData));
    });
    return subTotal;

}


export const _getTotalInvoice = (data) => {

        let totalBill = 0;
        let subTotal  = 0;

        data.items.map((item, index) => {
          let prepareData = {
            item:    item,
            discountFormat:    data.discountFormat,
            includeExtraFees: true,
            includeDiscount:  true,
            includeTax:       true
          }
          subTotal += Number(_getLineItemTotal(prepareData))
        });
      
    totalBill = ( Number(subTotal)  + Number(data.shipping || 0) ).toFixed(2);    
    return totalBill;

}

export const uuid = () => {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

export const makeAttachments = (invoice, callback = null) => {
  // const i = this.state.invoice;
  const i = invoice;
  let pdfDocGenerator = pdfExport.getCallBack(i);

    pdfDocGenerator.getBase64((data) => {
      let attache = {
        name : `${i.prefix+i.number}.pdf`,
        type: 'application/pdf',
        uploadType:'base64',
        // id   : this.state.attachments.length + 1 + Date.now(),
        id   : uuid,
        tableName: 'inc_invoices',
        idTable: i.idInvoice,
        size : 3333,
        data : data,
        invoice : i,
        url : '',
        response:
        {
          idAttachment : 0
        }
      }
      
      UploaderService.invoiceUploadBase64(attache, (event) => {
      }).then((res) =>{
        try {

          attache.response.idAttachment = res.data.idAttachment;
          if( typeof callback === "function" )
          {
            callback(attache);
          } 

          } catch(error){
            console.log(error);
          }
        }); 
    });
}


