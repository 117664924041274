import React , { Fragment } from 'react';
import { connect } from 'react-redux'
/*
| UI
*/
import { Divider, Space,  Radio, Button, Typography, Select, Tooltip   } from 'antd';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IoAddOutline } from 'react-icons/io5';
import { BsPlusSquareDotted } from "react-icons/bs";
import { PiTrashDuotone } from "react-icons/pi";
import ButtonIcon       from '../../../common/ButtonIcon';
import {

    Card,
    Form,
    FormControl,
    InputGroup,
    Table
  } from 'react-bootstrap';
  
  import { RiDeleteBinLine } from 'react-icons/ri';
  import SimpleBarReact from 'simplebar-react';
  import store from 'store';
  import InputMask from "react-input-mask";

  const mapStateToProps = ({ settings }) => ({
    settings : settings
  })

class CustomerWebPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // client: this.props,
            // loading: true,
            target: this.props.target,
            targerType: this.props.targerType,
            items: this.props.clientWebPages,

            pageDefaultTags : [
              {value : 'others',    name: 'Others'},
              {value : 'facebook',  name: 'Facebook'},
              {value : 'instagram', name: 'Instagram'},
              {value : 'linkedin',  name: 'LinkedIn'},
              {value : 'twitter',   name: 'Twitter'},
              {value : 'youtube',   name: 'YouTube'},
              {value : 'pinterest', name: 'Pinterest'},
              {value : 'skype',     name: 'Skype'},
              {value : 'whatsapp',  name: 'WhatsApp'},
              {value : 'telegram',  name: 'telegram'}
                          
            ]
        }


        if(this.state.items.length == 0)
        {
          this.handleAddItem();
        }

    }




    
    
    itemDefault = () => {
        const itemDefault = {
          createdBy: store.get('idUser'),
          pageTag: '',
          pageName: '',
          pageURL: ''
      }   
      return itemDefault;
    }
    
    handleAddItem  = () => {

        let item = this.itemDefault();

          var state = { ...this.state };
          state.items.push(item);
          this.setState({ state });   
    }



    changeItem  = (id, target, value) => {

        var state = { ...this.state };
        this.state.items = this.state.items.map((item, index) => {
  
            if (index !== id) return item;
          
            if( target == 'pageTag' )
            {   
                item.pageTag = value;

            }
            if( target == 'pageName' )
            {   
                item.pageName = value;

            }
            if( target == 'pageURL' )
            {   
                item.pageURL = value;

            }            
            
            return item;
        })
        this.setState(state); 
        this.props.subHandleCustomerWebPageChange(this.state.target,this.state.items);       
    }
    removeItem = (index) => {

        var state = { ...this.state };

        let array = [];
        array = state.items.filter((_, i) => i !== index)


        state.items = array;

        if(state.items.length == 0)
        {
          let item = this.itemDefault();
          state.items.push(item);
        }
        this.setState(state);
        this.props.subHandleCustomerWebPageChange(this.state.target, array);   
        


    }

    ItemRow = ({
        pageTag,
        pageName,
        pageURL,
        id,
        handleChange,
        handleRemove
      }) => {
        const {Text} = Typography;
        return (
          <Space direction={(this.props.settings.isMobileView ? 'vertical' : 'horizontal' )} size={'small'} key={id} className='rounded-3 p-3 ' style={{ display: 'flex', marginBottom: 8, border: `dashed 1px ${(this.props.settings.isMobileView ? this.props.settings.primaryColor : null )}` }} align="top" block>  
          <>  

          <div>
            <Tooltip title="Platform" placement='top' >
              <Form.Select value={pageTag}  className='mb-0'
              onChange={({ target }) => handleChange(id, 'pageTag', target.value)} 
              >
                {(this.state.pageDefaultTags.map((tag, index)=>
                  <option value={tag.value}   >{tag.name}</option>
                ))}
              </Form.Select>
            </Tooltip>
          </div>
          <div>
          <Tooltip title="Page Name" placement='top' >
            <Form.Control
              placeholder="(Portfolio, Work, etc)" 
              type="text"
              value={pageName}
              onChange={({ target }) => handleChange(id, 'pageName', target.value)}              
            />
          </Tooltip>
          </div>
          <div>
          <Tooltip title="Website URL" placement='top' >
            <Form.Control
              placeholder="(Website URL)" 
              type="text"
              value={pageURL}
              onChange={({ target }) => handleChange(id, 'pageURL', target.value)}              
            />        
          </Tooltip>
          </div>
          <div className=''>
            <Text className='mb-1'> </Text>
            <Button  size='small' onClick={() => handleRemove(id)}>
              <PiTrashDuotone className='fs-1'  /> {(this.props.settings.isMobileView ? 'Remove': null )} 
            </Button>            
          </div>
          </>
          </Space>

        );
      };    

    render(){
        return (
            <Fragment>
                {/* <Form.Item wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 20, offset: 4 } }}> */}
                        {this.state.items.length > 0 ?  
                        <>
                            {this.state.items.map((item, index) => (
                                  <this.ItemRow 
                                  {...item}
                                  id={index}
                                  key={index}
                                  handleChange={this.changeItem}
                                  handleRemove={this.removeItem}
                                    />
                                  
                              ))}
                        </>
                          : 
                          <tr className="is-empty">
                            <td colspan="4">
                                <div className="text-center">
                                  <p></p>
                                  <p className='fs--1 text-400 nunito'>No Web page has been added</p>
                                </div>
                            </td>
                          </tr>
                          } 
                                      
                  <Button  type="link" className='mt-3 text-primary'  onClick={this.handleAddItem}  icon={<BsPlusSquareDotted className='fs-1 me-2 pb-1 text-primary' />}>
                  Add Web page
                  </Button>

  
            </Fragment>
        )
    }


}
export default connect(mapStateToProps)(CustomerWebPage);