

import {capitalize, isEmpty, phoneNumFormatter, isIterableArray, formatCurrency} from '../../../../../helpers/utils';
import { GetExtraFeeMessage } from '../../utils';
export const specificationsData= (obj) => {
    let body   = [];

    let header = ['Services And Specifications']
    body.push(header);
    for (let index = 0; index < obj.items.length; index++) {
        let element  = obj.items[index];
        if( element.itemSpecifications.length > 0 )
        {

            let specs = [];
            /*
            | Header
            */
            specs.push({text: `${element.serviceName} - original ${obj.itemsFormat} ${obj.priceFormat} was ${formatCurrency(element.price,obj.currencySymbol )}\n` , italics: true , fontSize: 10 })

            /*
            | UL specs
            */
            
          /*
          | Find if one of the specs has opetion
          */
          let isOption = false;
          element.itemSpecifications.map((s, i)=>{
            if(isIterableArray(s.options) && s.options.length > 0 ){
              isOption = true;
            }
          })


            let specsVal = [];
             element.itemSpecifications.map((s, i)=>{

                let optionsValue = [];

                optionsValue.push(s.name);
                
                if(
                  s.type == 'text'
               || s.type == 'number'
               || s.type == 'email'
               || s.type == 'textarea'
               || s.type == 'url'
                ){
                  optionsValue.push(s.value)
                  if(isOption)
                  {
                    optionsValue.push('')
                    optionsValue.push('')

                  }
                } else {
                  optionsValue.push([isIterableArray(s.options) &&
                    s.options.map((val, i) => {
                          if( val.isChecked )
                          {
                              return `${val.name}`
                          }
                    })
                  ])
                  optionsValue.push([isIterableArray(s.options) &&
                    s.options.map((val, i) => {
                          if( val.isChecked )
                          {
                            return `x ${val.defaultQTY}`
                          }
                    })
                  ])
                  optionsValue.push([isIterableArray(s.options) &&
                      s.options.map((val, i) => {
                            if( val.isChecked )
                            {
                              return `${formatCurrency((val.amount*val.defaultQTY),obj.currencySymbol )}`
                            }
                      })
                    ])
                }





              
              
              specsVal.push( optionsValue );

            })
            specs.push(
                {
                    table: {
                        widths: '*',
                        margin:0,
                        body:specsVal
                    },
                    // layout: 'lightHorizontalLines' 
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex % 2 === 0) ? '#f2f2f2' : null;
                        }
                        
                    }                    
                }
                )


            body.push(
                [ 
                    specs
                ]
            );
        }
    }

    if( body.length > 0 )
    {
      return body;
    } else {
      return [''];
    }
}
// [
//     [ 'Services And Specifications'],
//     [ 
//       {
//           table: {
//               body : [['test']],
//           }
//       }
//     ],
//     [ 
//       {
//           table: {
//               body : [['test']],
//           }
//       }
//     ] ,
//   ]

export const chooseSpecifications = ($type, obj) => {
    let layout;
        // layout = 'noBorders';
        // layout = 'lightHorizontalLines';
        // layout = {
        //       fillColor: function (rowIndex, node, columnIndex) {
        //         return rowIndex === 0 ? "#f2f2f2" : null;
        //       }}; 
    if(!obj.displaySetting.displayItemSpecs)
    {
      return ''
    }

    for (let index = 0; index < obj.items.length; index++) {
        let element  = obj.items[index];
        if( element.itemSpecifications.length > 0 )
        {
            switch($type){  
        
              case 1 :
                   layout = {
                      fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex === 0 ? "#f2f2f2" : null;
                      }};          
                return Item1(obj , layout);
              break;
        
              case 2 :
                layout = 'lightHorizontalLines';         
                return Item1(obj , layout);
              break;      
            }
        }

    }

  }

  export const Item1 = (obj, layout) => {
    
    let data =  [{
        //   style: "tableExample",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 0,
            widths: ['auto'],
    
            body: specificationsData(obj)
            // body: [['test']]
          },
        //   layout: layout          
          layout: 'noBorders'          
        }];
        return data;
    
  }