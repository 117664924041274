//  import 'antd/lib/style/index.less' // antd core styles
// import "antd/dist/antd.css";
// import "antd/dist/antd.dark.less";

//  import './assets/scss/antd/themes/default.less' // default theme antd components
//  import './assets/scss/antd/themes/dark.less' // dark theme antd components



import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';

import './helpers/initFA';




// import ReactDOM from 'react-dom'
// import { createHashHistory } from 'history'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'

import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import reducers from './redux/reducers'
import sagas    from './redux/sagas'
import Localization from './localization'
import Router       from './router'



import 'react-toastify/dist/ReactToastify.min.css';
// import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';
// import 'react-image-lightbox/style.css';

// import 'antd/dist/antd.dark.less';



import toast, { Toaster } from 'react-hot-toast';

// middlewared
const history 			    = createBrowserHistory()
const sagaMiddleware 	  = createSagaMiddleware()
const routeMiddleware 	= routerMiddleware(history)
const middlewares 		  = [sagaMiddleware, routeMiddleware]

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

{/* <Provider store={store}>
<Localization>
  <Router basename={process.env.PUBLIC_URL} history={history} />
</Localization>
</Provider>  */}



ReactDOM.render(
    <Provider store={store}>
    <Localization>
      <Router basename={process.env.PUBLIC_URL}  history={history} />
    </Localization>
    <Toaster />
    </Provider>,
  document.getElementById('main')
);

export { store, history }
