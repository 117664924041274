import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux'

/*
| UI
*/
import { Skeleton, Tooltip, Button, Dropdown, Space, Typography    } from 'antd';
import { Card , Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
/*
| ICONS
*/


import {EllipsisOutlined, DownOutlined, InfoCircleOutlined} from '@ant-design/icons'

import { BsFillInfoSquareFill } from 'react-icons/bs';
import { PiEyeDuotone, PiLayoutDuotone } from 'react-icons/pi';
/*
| PAGES
*/

import DrawerInvoice from 'components/common/DrawerVarexe/DrawerInvoice';
import TagsSelect    from 'components/common/TagsVarexe/components/TagsSelect';
import InvoiceListMobile    from './InvoiceListMobile';
import InvoiceListDesktop    from './InvoiceListDesktop';


/*
| Customers
*/
import CustomerDisplayCard from '../../../../customers/components/customerOverview/CustomerDisplayCard';

/*
| UTILS
*/
import {statusComponent,statusEstimateComponent, dueDateComponent} from '../utilsComponents';
import { isIterableArray, formatCurrency, capitalize, isEmpty }     from 'helpers/utils';

const mapStateToProps = ({ settings }) => ({
    isMobileView: settings.isMobileView,
  })

class InvoiceList extends Component {

    static defaultProps = {
        data : [],
        generateType : 'invoice'
    }

    constructor(props) {
        super(props);
        this.state = {
            data : this.props.data,
            viewLink : (this.props.generateType == 'invoice' ? '/invoices/invoice-details/': '/estimates/estimate-details/' ),
            editLink : (this.props.generateType == 'invoice' ? '/invoices/invoice-edit/'   : '/estimates/estimate-edit/' ),
            showQuickView : false,
            redirect : null
        }
    }

    componentDidMount(){
    }

    onMenuClick = (e) => {
    };

    handleShowQuickView = (showQuickView) => {
        this.setState({
            showQuickView : showQuickView
        })
    }
    render(){

        let invoice = this.state.data;

        const items = [
            {
              key: '1',
              label: 'View Details',
              onClick: () => {
                this.setState({
                    redirect : `${this.state.viewLink}${this.state.data.id}`
                })
              }
            },
            {
              key: '2',
              label: `Edit ${this.props.generateType == 'invoice' ? 'invoice' : 'estimate'}`,
              onClick: () => {
                this.setState({
                    redirect : `${this.state.editLink}${this.state.data.id}`
                })
              }             
            },                       
          ];

          const { Text, Title, Paragraph } = Typography;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return(
            <Fragment>
                 {(this.props.isMobileView ? 
                    <InvoiceListMobile data={invoice} />
                 :
                <Fragment>
                     <InvoiceListDesktop data={invoice} />
                </Fragment> 
                )}
            </Fragment>            
        )
        
    }


}
export default connect(mapStateToProps)(InvoiceList)