import {capitalize, isEmpty, phoneNumFormatter, formatCurrency, decimalRemove} from '../../../../../helpers/utils';
// import htmlToPdfmake from "html-to-pdfmake"

export const specifications = (specs , obj) => {
    let s = '';
    if(obj.displaySetting.displayItemSpecs)
    {
    if (specs.length > 0) {
      s += " \n ";
      specs.map((spec, index) => {
        s += spec.name  + ': ' + spec.value + (specs.length-1 > index ? ', ' : '' );
      }) 
    }
  }
    return (s)
  }
  export const serviceNameAdjuster = (element, obj) => {
    
    return { 
      
      text: element.serviceName         + "\n" 
           + element.serviceDescription 
           , alignment: "left" 
          };
  
          // (specifications(element.itemSpecifications , obj)
        }  

export const ItemsData= (obj) => {
    let itemsHeader = [' ' , capitalize(obj.itemsFormat), capitalize(obj.unitsFormat), capitalize(obj.priceFormat)];

    /* Make header */
    let itemsBody   = [];
    if(obj.displaySetting.displayItemTax)
      {
        itemsHeader.push( capitalize("tax"));
      }
      if(obj.displaySetting.displayItemDiscount)
      {
        itemsHeader.push( capitalize('discount'));
      }   
      itemsHeader.push(capitalize(obj.amountFormat));
  
    itemsBody.push(itemsHeader);
  
    /* Make Items */
    for (let index = 0; index < obj.items.length; index++) {
      
      let element  = obj.items[index];
      let row      = [];
  
      row.push(index + 1);
      row.push(serviceNameAdjuster(element , obj));
      row.push({ text:  (obj.unitsFormat == 'hours'?element.quantity:decimalRemove(element.quantity)), alignment: "left" });

      /*
      | Make Price
      */
    //  let _price = formatCurrency( element.price,obj.currencySymbol);
     let _price = parseFloat(element.price);

      if( element.extraFee != 0 )
      {
        _price += element.extraFee;
        // _price += ' ('+ formatCurrency(element.extraFee,obj.currencySymbol ) +')';
      }
      _price = formatCurrency( _price ,obj.currencySymbol);

      row.push({ text: _price, alignment: "right" });
  
      if(obj.displaySetting.displayItemTax)
      {
        row.push({ text: obj.taxformat + element.tax, alignment: "right" });
      }
      if(obj.displaySetting.displayItemDiscount)
      {
        row.push({ text: obj.discountFormat + element.discount, alignment: "right" });
      }
      row.push({ text: formatCurrency( element.total, obj.currencySymbol), alignment: "right" });
      itemsBody.push( row );
      
    }
  
    if( itemsBody.length > 0 )
    {
      return itemsBody;
    } else {
      return [''];
    }

}

export const chooseItems = ($type, obj) => {
    let layout;

        // layout = 'noBorders';
        // layout = 'lightHorizontalLines';
        // layout = {
        //       fillColor: function (rowIndex, node, columnIndex) {
        //         return rowIndex === 0 ? "#f2f2f2" : null;
        //       }}; 

    switch($type){  

      case 1 :
           layout = {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex === 0 ? "#f2f2f2" : null;
              }};          
        return Item1(obj , layout);
      break;

      case 2 :
        layout = 'lightHorizontalLines';         
        return Item1(obj , layout);
      break;      
     
      default:
        return Item1(obj, layout);
      break;
    }
  }

  export const Item1 = (obj, layout) => {
    
    let data =  [{
          style: "tableExample",
          table: {
            widths: 
              (ItemsData(obj)[0].length > 0 ? (
                ItemsData(obj)[0].map((itm, index) => {                 
                 switch(index){
                   case 0:
                    return 'auto';
                   break;
                   case 1:
                    return 180;
                   break;
                   case 2:
                    return 'auto';
                   
                   break;
                   default:
                    return '*';
                   break;                                                         
                 }
                })
              ) : ["*"] )
            ,
            headerRows: 1,
            // widths: ["auto", 180, 30, 50, 50, 50, 50],
            keepWithHeaderRows: 1,
            body: ItemsData(obj),
          },
          layout: layout          
        }];
        return data;
    
  }