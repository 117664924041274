import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import store from 'store';
import { connect } from 'react-redux';

/*
|UI
*/
import { Typography, Divider, Drawer, Tag} from 'antd'
import {   Media } from 'reactstrap';
import { Dropdown, Button, Row } from 'react-bootstrap';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import ButtonIcon     from '../common/ButtonIcon';
/*
|ICONS
*/
import { FaFileInvoiceDollar, FaRegUserCircle, FaRegAddressCard } from 'react-icons/fa';
import { IoLogOutOutline } from 'react-icons/io5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
|PAGES
*/

/*
|IMAGES
*/


import userAvatar from '../../assets/img/team/avatar.png';








const mapStateToProps = ({ user }) => ({ user })

const ProfileDropdown = ({dispatch}) => {

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const {Text} = Typography;

  let userRole        = store.get('userRole');
  let userSession     = store.get('userSession');

// console.log(userSession);
  return (
    <Fragment>

    <Button
      bsPrefix="toggle"
      className="btn btn-sm btn-falcon-default"
      style={{height: '29px'}}
      onClick={() => setDrawerOpen(true)}
    >
      <Flex>
        <Avatar size="s"  className=" align-items-start" src={(userSession.imageDisplay!=''?userSession.imageDisplay:userAvatar)}  />
        {/* <div className="flex-1 chat-contact-body ms-2 d-none d-lg-block text-capitalize">
        {userSession.fullName}
        </div> */}
      </Flex>
    </Button>
    <Drawer title="Account" placement="right" onClose={()=> setDrawerOpen(false)} open={drawerOpen}>

        <Row>
          <Flex>
            <div className='flex-grow-1'>
              Would you like to log out?
            </div>
            <div className=''>
            <Button
              as='Link'
              className="btn btn-sm btn-falcon-default" 
              style={{lineHeight:'normal'}}
              onClick={logout}
              >
                
              Sign Out
            </Button>              
            </div>
          </Flex>          
        </Row>
        <Divider orientation='left' orientationMargin={'0'} plain>Your Profile</Divider>
        <Row>
          <Flex>
            <Avatar size="xl"  className=" align-items-start" src={(userSession.imageDisplay!=''?userSession.imageDisplay:userAvatar)}  />
            <div className="flex-1  ms-2 text-capitalize">
              <p className='mb-0 fw-semi-bold'><Text>{userSession.fullName}</Text></p>
              <p className='mb-0'><Text>{userSession.email}</Text></p>
            </div>
          </Flex>         
        </Row>
        <Divider orientation='left' orientationMargin={'0'} plain>Account Type</Divider>
        <p className='mb-0'><Text>You are logged in as <b>{userRole.tag}</b> user.</Text></p>

    </Drawer>
    </Fragment>
  );
};

export default connect(mapStateToProps)(ProfileDropdown)
