
import React, { Fragment, Component } from 'react';
import classNames from 'classnames';
/*
| UI 
*/
import { Skeleton } from 'antd';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
/*
| Icons
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
| Echart 
*/
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
// import { tooltipFormatter } from 'helpers/echart-utils';



/*
| Utils
*/

import { getColor, getDates, rgbaColor, numberFormatter, formatCurrency } from 'helpers/utils';
import InvoiceService        from 'components/invoices/services';

const monthNames = ["january", "february", "march", "april", "may", "june","july", "august", "september", "october", "november", "december"];
// const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

class InvoicesActivity extends Component
{
    constructor(props){

        

        super(props);
        this.chartRef = React.createRef();

        this.state = {

            currencySymbol : '$',
            totalInvoicesAmount : 0.00,
            totalPaymentsAmount : 0.00,

            
            invoicesPastYear : [],
            paymentsPastYear : [],
            monthNamesShort : [],

            displayInvoices: true,
            displayIncome: true,
            isLoading : true
        }

    }

    componentDidMount(){
        this.getData();
    }

    getData(){
        InvoiceService.getInvoiceAnalyticsByMonths().then( (response) => {
         try {
           if( response.data !==  undefined && response.data.data !==  undefined )
           {        
             var result = response.data.data;
             var state = this.state ;
             state.invoicesPastYear = result.invoicesPastYear;
             state.paymentsPastYear = result.paymentsPastYear;
             state.currencySymbol    = result.currencySymbol;


             

             result.invoicesPastYear.map((invoicePastYear, index) => {
                state.totalInvoicesAmount += parseFloat(invoicePastYear.total);
                state.monthNamesShort.push(invoicePastYear.monthName);
             })
             result.paymentsPastYear.map((invoicePastYear, index) => {
                state.totalPaymentsAmount += parseFloat(invoicePastYear.total);
             })

             state.isLoading = false;
             this.setState({ state });    
           }        
         } catch(error){
           console.log(error);
         }
        });
   
     }

     handleLegend = seriesName => {
        seriesName === 'New' && this.setState({displayInvoices :!this.state.displayInvoices});
        seriesName === 'Returning' && this.setState({displayIncome :!this.state.displayIncome});
        this.chartRef.current.getEchartsInstance().dispatchAction({
          type: 'legendToggleSelect',
          name: seriesName
        });
      };     


    render()
    {

        const tooltipFormatter = params => {
            let tooltipItem = ``;
            if (Array.isArray(params)) {
              params?.forEach(el => {
                tooltipItem =
                  tooltipItem +
                  `<div className='ms-1'> 
                <h6 className="text-700">
                <div className="dot me-1 fs--2 d-inline-block" style="background-color:${
                  el.borderColor ? el.borderColor : el.color
                }"></div>
                ${el.seriesName} : ${
                    typeof el.value === 'object' ? formatCurrency(el.value[1], this.state.currencySymbol)  : formatCurrency(el.value, this.state.currencySymbol) 
                  }
                </h6>
                </div>`;
              });
            }
            return `<div>
                      <p className='mb-2 text-600'>
                        ${
                          dayjs(params[0].axisValue).isValid()
                            ? dayjs(params[0].axisValue).format('MMMM DD')
                            : params[0].axisValue
                        }
                      </p>
                      ${tooltipItem}
                    </div>`;
          };        

          
          const getOptions = (invoicesPastYear, paymentsPastYear, month) => ({
            title: {
            //   text: '',
            //   textStyle: {
            //     fontWeight: 500,
            //     fontSize: 13,
            //     fontFamily: 'poppins',
            //     color: getColor('gray-900')
            //   }
            },
            legend: {
              show: false,
              data: ['New', 'Returning']
            },
          
            tooltip: {
              trigger: 'axis',
              padding: [7, 10],
              backgroundColor: getColor('gray-100'),
              borderColor: getColor('gray-100'),
              color: getColor('dark'),
              // textStyle: { color: getColor('dark') },
              borderWidth: 1,
              formatter: tooltipFormatter
            },
            xAxis: {
              type: 'category',
            //   data: dates(month),
              data: this.state.monthNamesShort,
              boundaryGap: false,
              axisPointer: {
                lineStyle: {
                  color: getColor('gray-300')
                }
              },
              axisLine: {
                lineStyle: {
                  color: getColor('gray-300'),
                  type: 'solid'
                }
              },
              axisTick: { show: false },
              axisLabel: {
                color: getColor('gray-400'),
                // formatter(value, index) {
                //   if (index === 0) {
                //     return `${dayjs(value).format('MMM DD')}`;
                //   }
                //   return `${dayjs(value).format('DD')}`;
                // },
                margin: 15
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: getColor('gray-300'),
                  type: 'dashed'
                }
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                lineStyle: {
                  color: getColor('gray-300')
                }
              },
              axisLabel: {
                color: getColor('gray-400'),
                formatter: (value) => {
                    return `${this.state.currencySymbol} ${numberFormatter(value, 0)}`
                  },
                margin: 15
              }
            },
            series: [
              {
                name: 'Invoices',
                type: 'line',
                data: invoicesPastYear.map((param, index)=> param.total),
                lineStyle: { color: getColor('primary') },
                itemStyle: {
                  borderColor: getColor('primary'),
                  borderWidth: 2
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: rgbaColor(getColor('primary'), 0.2)
                      },
                      {
                        offset: 1,
                        color: rgbaColor(getColor('primary'), 0.01)
                      }
                    ]
                  }
                },
                symbol: 'none',
                emphasis: {
                  scale: true
                }
              },
              {
                name: 'Payments',
                type: 'line',
                data: paymentsPastYear.map((param, index)=> param.total), 
                lineStyle: { color: getColor('success') },
                itemStyle: {
                  borderColor: getColor('success'),
                  borderWidth: 2
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: rgbaColor(getColor('success'), 0.2)
                      },
                      {
                        offset: 1,
                        color: rgbaColor(getColor('success'), 0.01)
                      }
                    ]
                  }
                },
                symbol: 'none',
                emphasis: {
                  scale: true
                }
              }
            ],
          
            grid: { right: 7, left: 0, bottom: 5, top: 50, containLabel: true }
          });

        return (
            <Fragment>

            {(this.state.isLoading ?
                <Skeleton active />
                
                :
                

<>
<h6>Invoices Activity</h6>
<Row className="g-0 align-items-center">
    <Col xs="auto me-2">
        <Form.Check
              type="checkbox"
              id="ecommerceLastMonth"
              className="mb-0 d-flex"
            >
              <Form.Check.Input
                type="checkbox"
                className="form-check-input-primary"
                onClick={() => this.handleLegend('Invoices')}
                defaultChecked
              />
              <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Invoices:
                <span className="text-dark d-none d-md-inline">
                  {' '}
                  {formatCurrency(this.state.totalInvoicesAmount, this.state.currencySymbol)}
                </span>
              </Form.Check.Label>
        </Form.Check>        
    </Col>
    <Col xs="auto">
        <Form.Check
                type="checkbox"
                id="ecommercePrevYear"
                className="mb-0 d-flex ps-0 ps-md-3"
            >
                <Form.Check.Input
                type="checkbox"
                className="ms-2 form-check-input-success opacity-75"
                onClick={() => this.handleLegend('Payments')}
                defaultChecked
                />
                <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                Payments recieved:
                <span className="text-dark d-none d-md-inline">
                    {' '}
                    {formatCurrency(this.state.totalPaymentsAmount, this.state.currencySymbol)}
                </span>
            </Form.Check.Label>
        </Form.Check>        
    </Col>
</Row>







        <ReactEChartsCore
          ref={this.chartRef}
          echarts={echarts}
          option={getOptions(this.state.invoicesPastYear, this.state.paymentsPastYear, 2)}
          style={{ height: '20rem' }}
        />




      </>
      )}


            </Fragment>
        )
    }
}

export default InvoicesActivity;