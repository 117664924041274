import {capitalize, isEmpty, phoneNumFormatter} from '../../../../../helpers/utils';


export const recipientBody  = (clients) => {
  let _body = [];

  _body.push( 
    {
      fontSize: 11,
      text: clients.firstName + ' ' + clients.lastName,
      alignment: "left",              
    }
  )

  _body.push( 
    {
      fontSize: 10,
      text: 'Address: ' + ( clients.unit != ''? clients.unit+', ' : '') + ( clients.apartment != ''? clients.apartment+', ' : '') + ( clients.street != ''? clients.street+', ' : '') + ( clients.address != ''? clients.address : ''),
      alignment: "left",              
    }
  ) 
  
  if( !isEmpty(clients.mobile) ){
      _body.push( 
        {
          fontSize: 10,
          text: 'Contact Number: ' +  clients.mobile  ,
          alignment: "left",              
        }
      )   
    } 

    if( clients.email != null ){
    
      _body.push( 
        {
          fontSize: 10,
          text: 'Contact Email: ' +  clients.email,
          alignment: "left",              
        }
      )   
    }
       
return _body;
}  
  export const recipientHeader  = (recipient) => {

    let _header = [];
    _header.push({ text: capitalize(recipient.type) , style: "tableHeader" });
    
  return _header;
  }
  export const chooseRecipient = ($type, obj) => {

    let recipients = [];
    if(obj.displaySetting.displayCustomerBilling)
    {
      recipients.push(obj.clients[1])
    }
    if(obj.displaySetting.displayCustomerShipping)
    {
      recipients.push(obj.clients[2])
    }    
    switch($type){  

      case 1 :
        return Recipient1(obj, recipients);
      break;

      case 2 :
        return Recipient2(obj, recipients);
      break;

      default:
        return header1(obj); 
      break;
    }
  }


  export const Recipient1 = (obj, recipients) => {
    let data =  [        {
      style: "tableExample",
      table: {
        headerRows: 1,
        widths: (obj.displaySetting.displayCompanyInfo ? ["*" , "*"] : ["*"] )  ,
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        body: [
          recipients.map((recipient, index) => { return recipientHeader(recipient)}),
          recipients.map((recipient, index) => { return recipientBody(recipient)})
        ],
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return rowIndex === 0 ? "#f2f2f2" : null;
        },
      },
    } ]
    return data;
  }

  export const Recipient2 = (obj, recipients) => {

    let data =  [        {
      style: "tableExample",
      table: {
        headerRows: 1,
        widths: (obj.displaySetting.displayCompanyInfo ? ["*" , "*"] : ["*"] )  ,
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        body: [
          recipients.map((recipient, index) => { return recipientHeader(recipient)}),
          recipients.map((recipient, index) => { return recipientBody(recipient)})
        ],
      },
      layout: "noBorders",
    } ]
    
    return data;
  }  
