import React , { Fragment } from 'react';

/*
| UI
*/

import {  Skeleton, Divider, Badge, Alert, Checkbox, Col, Row, Popconfirm, Button      } from 'antd';

/*
| ICONS
*/

import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined, SaveOutlined, QuestionCircleOutlined, CheckCircleTwoTone  } from '@ant-design/icons';

/*
| Page
*/
import CustomerSearchBilling from './CustomerSearchBilling';
import CustomerDisplayCardCustomerDetails from './customerOverview/CustomerDisplayCardCustomerDetails';

/*
| Service
*/
import CustomerService  from '../services/';

export default class CustomerBillingForm extends React.Component {

    static defaultProps = {
        idClient : -1,
    }


    constructor(props) {
        super(props)
        this.state = {
            idClient : this.props.idClient,
            isLoading: true,
            // displayBillingAccount  : false,

            billingAccount : null,

            hasBillingAccount: false,
            displayNewFormBillingAccount: false,
        }

        this.handleBillingClientChange = this.handleBillingClientChange.bind(this);
        this.customerFormCallBackBilling = this.customerFormCallBackBilling.bind(this);
    }

    componentDidMount(){
        this.getPageData();
    }    

    getPageData = (CallBack = false) => {
        this.setState({
            isLoading : true
        });     
        CustomerService.getCustomerBillingByIdClient(this.props.idClient).then((res) =>{
        try{
            if( res.data !== undefined ){
            if( res.data ){

                let customer = res.data;
                let _state   = this.state;


                _state.hasBillingAccount    = customer.data != null  ? true : false ;
                _state.billingAccount       = customer.data;
                _state.isLoading            = false;
                
                this.setState(_state); 
                // if( CallBack )
                // {
                //     this.props.customerFormCallBack(_state.client);
                // }
            }
            }
            } catch(error){
                console.log(error);
            } 
    
            });         
    }


    handleBillingClientChange = (data) => {
        this.setState({
            isLoading : true
        });   

        let _data = {
            idClient : this.props.idClient,
            idRelatedClient: data.idClient
        }

        CustomerService.addExistingBilling(_data).then((res) =>{
            try{
                if( res.data !== undefined ){
                if( res.data ){
    
                    let customer = res.data;
                    let _state   = this.state;
    
    
                    _state.hasBillingAccount    = customer.data != null  ? true : false ;
                    _state.billingAccount       = customer.data;
                    _state.isLoading            = false;
                    
                    this.setState(_state); 
                    // if( CallBack )
                    // {
                    //     this.props.customerFormCallBack(_state.client);
                    // }
                }
                }
        } catch(error){
            console.log(error);
        } 

        });   
    }

    handleRemoveBilling = () =>{

        CustomerService.removeBilling(this.props.idClient).then((res) =>{
              
          let result = res.data;
          if( result.status == 200 )
          {
            this.getPageData();
          }     
      });
      
      }

      customerFormCallBackBilling = () => {
        this.getPageData();
      }


    render(){
        return(
            <Fragment>
                {(this.state.isLoading ? <Skeleton active /> :
                <Fragment>

                    {(!this.state.hasBillingAccount ? 
                    <Fragment>
                        <Checkbox
                        className='mt-3 mb-3'
                        checked={(this.state.displayNewFormBillingAccount ? false : true )}
                        onChange={(e) => {
                            let value = !e.target.checked;
                            this.setState({displayNewFormBillingAccount: value});
                            }}
                        >
                        Billing Acount is the same as Primary Acount
                        </Checkbox> 
                    
                        {(!this.state.displayNewFormBillingAccount ? 
                        <Fragment>
                            <Alert message="Billing account is the same as primary account. Uncheck if you would like to add a billing account." type="success" />    
                        </Fragment>
                        : 
                        <Fragment>
                            <Alert message="If your customer's billing information is different from its primary account, create or select an existing billing account from below." type="info" />

                            {/*  */}
                                <CustomerSearchBilling {...this.props} idMain={this.props.idClient} handleBillingClientChange={this.handleBillingClientChange} customerFormCallBackBilling={this.customerFormCallBackBilling}  />
                            {/*  */}

                        </Fragment>
                        )} 
                    </Fragment>    
                    : 
                    <Fragment>
                        {(this.state.billingAccount.idRelatedClient > 0 ?
                        <div className={`bg-200 p-3 rounded`} >


                            <Col className='mb-2' style={{ maxWidth: 600, margin:'auto' }} >
                                <Row justify="end">
                                    {/* <Alert message="" type="warning" /> */}

                                    <Popconfirm
                                            title="Delete the Billing account"
                                            description="Are you sure to delete this billing account?"
                                            onConfirm={this.handleRemoveBilling}
                                            okText="Yes"
                                            cancelText="No"
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            >
                                        <Button type="text" danger>Delete billing account</Button>
                                    </Popconfirm>    
                                </Row>
                            </Col>       
                            
                            <CustomerDisplayCardCustomerDetails 
                                idClient={this.state.billingAccount.idRelatedClient}  
                                showStatistics={false} 
                                showAllInfo={true}
                                editable={true} 
                                cardBorder="primary" 
                                style={{boxShadow:'true', padding:'0px', margin: '0px', marginBottom: '0px'}} 
                                modalShow={false}
                                />

                            {/* <CustomerForm 
                            {...this.state.client.billingAccount} 
                            idMain={this.state.client.idClient}
                            target = "billingAccount" 
                            customerFormCallBack={this.customerFormCallBackBilling}
                            />   */}
                        </div>
                        : 
                        <Fragment></Fragment>              
                        )}                    
                    </Fragment>
                    )}





                   


                </Fragment>
                )}
            </Fragment> 
        )
    }   

}