import React, {useState }from 'react'
import { withRouter } from 'react-router-dom'
import { settings } from '../../config';
import { getItemFromStore, setItemToStore, themeColors } from '../../helpers/utils';
import Main from '../../Main';

const ParkLayout = ({ children }) => {

    const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', settings.isFluid));
    const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', settings.isRTL));
    const [isDark, setIsDark] = useState(getItemFromStore('isDark', settings.isDark));
    const [isTopNav, setIsTopNav] = useState(getItemFromStore('isTopNav', settings.isTopNav));
    const [isCombo, setIsCombo] = useState(getItemFromStore('isCombo', settings.isCombo));
    const [isVertical, setIsVertical] = useState(getItemFromStore('isVertical', settings.isVertical));
    
    
    return (
        <Main>
    <div
        style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: isDark ? themeColors.dark : themeColors.light
        }} >
        <div className={isFluid? 'container-fluid' : 'container'}>
            <div className="content">
                {children}
            </div>
        </div>
   </div>
   </Main>
    )
}

export default ParkLayout