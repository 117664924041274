import  React , {  Fragment, Component } from "react";

import PropTypes from 'prop-types';

/*
| UI
*/
import { Skeleton, Layout, Card, Form, Divider  } from 'antd';
import {  CardBody, CardFooter, CustomInput, FormGroup, Input, Table,  ButtonGroup, Col , UncontrolledTooltip } from 'reactstrap';

import { Nav, Tab, Row, Button, InputGroup, FormControl, OverlayTrigger, Tooltip, Badge, Image, Container} from 'react-bootstrap';

import Flex             from '../../common/Flex';

/*
| fonts
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MdOutlineDesignServices } from "react-icons/md";
import { BiBold, BiItalic, BiUnderline, BiListOl, BiListUl } from 'react-icons/bi';
import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';


/*
| Pages
*/

// import InvoiceVisibilities       from './partials/VisibilitiesSetting';
// import InvoiceLogoSetting       from './InvoiceLogoSetting';


// import pdfExport         from '../services/pdfExport';
// import Loader               from '../../common/Loader';
// import LoadingPDF               from './LoadingPDF';
// import Background           from "../../common/Background";


// import invoiceDesignPreviewData           from "./invoiceDesignPreviewData";
import SvgDesigner              from './partials/SvgDesigner';

// import {  } from 'react-icons/io5';
import FalconCardHeader           from '../../common/FalconCardHeader';
import IconButton from '../../common/IconButton';

import Label            from "reactstrap/lib/Label";
import ToastVarexe              from '../../common/ToastVarexe';
/*
| Services
*/
import InvoiceService    from '../services/';

/*
| Color picker https://casesandberg.github.io/react-color/
*/
import { CirclePicker     } from 'react-color';
/*
| Templates
*/
import {InvoiceTemplateCard}  from './partials/invoice-design-svg/InvoiceTemplateCard'; 


/*
| Images 
*/
import background from 'assets/img/illustrations/blue-circle.svg';

class InvoiceSizeDesign extends Component {

    static defaultProps = {
        hasTemplate: true
    }

    constructor(props) {
  
        super(props)
        this.state = {
            loading:  true,        
            // pdfDocGenerator : pdfExport,
            // invoice:{},
            // pdf: {},

            docDefinition: {
                designTemplate: "temp1",
                designBackgroundColor: "#FFF",
                designPrimaryColor: "#0069D9",
                designWatermarkText: "",
                designWatermarkOpacity: 0.1,
                designWatermarkBold: false,
                designWatermarkItalics: false,
                designPageSize: "A4",
                designPageOrientation: "portrait",
                // pageOrientation: "landscape",
            },
            invoiceTemplates : []
        }
     
        this.handleVisibilityChanges = this.handleVisibilityChanges.bind(this);
        this.handleChangeTemplate = this.handleChangeTemplate.bind(this);
    }


componentDidMount(){
    
    this.getSetting();
    this.getInvoiceTemplates();
    
} 
  
  

  getSetting = () => {
 


        /* Fake invoice */
        // let _invoice = invoiceDesignPreviewData ;
        
        // state = {
        //     invoice: _invoice[0]
        // } 


        InvoiceService.getSetting().then((res) => {
            try{
                if( res.data !== undefined ){                
                if( res.data ){

                    /* Load saved settings */
                    let state = {...this.state};
                    let InvoiceSetting = res.data;
                    state.docDefinition = InvoiceSetting;
                    state.loading = false;
                    // state.invoice.configuration.displaySetting = InvoiceSetting.displaySetting;
                    //   this.state.pdfDocGenerator.setSettings(InvoiceSetting.designSetting);
                //    let getBase64 = this.state.pdfDocGenerator.openBase64(state.invoice);
    
                //    getBase64.then((successMsg) => {
    
                //     state.loading = false;
                //     state.docDefinition = InvoiceSetting.designSetting;
                //     state.pdf = successMsg;
                //     this.setState(state);

                //     }) 

                this.setState(state);
     
                }

            }
            } catch(error){
                console.log(error);
            }            
    
          }); 
     
  }
  getInvoiceTemplates = () => {
    InvoiceService.getInvoiceTemplates().then((res) => {
        try{
            if( res.data !== undefined ){                
            if( res.data ){
                let InvoiceTemplates = res.data;
                this.setState({invoiceTemplates : InvoiceTemplates});
            }
        }
        } catch(error){
            console.log(error);
        }
      }); 
  }




watermarkStyle = () => {

    let _style = { 
        'opacity': this.state.docDefinition.designWatermarkOpacity,
        'fontWeight' : (this.state.docDefinition.designWatermarkBold? 'bold' : 'normal'), 
        'fontStyle' : (this.state.docDefinition.designWatermarkItalics? 'italic' : 'normal'),
        'color' : (this.state.docDefinition.designBackgroundColor? this.state.docDefinition.designBackgroundColor : '#0069D9'),
    };
    return _style;
}


  updatePDF = () => {
    let pdfDocGenerator = this.state.pdfDocGenerator.openBase64(this.state.invoice);
            
    pdfDocGenerator.then((successMsg) => {
        
        this.setState({
            loading:false, 
            pdf : successMsg});
    }) 
  }

handleWatermarkOpacityChange = (e) => {
    var state = this.state;
    state.docDefinition.designWatermarkOpacity = e.target.value;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();     
}
handleWatermarkBoldChange = () => {
    var state = this.state;
    state.docDefinition.designWatermarkBold = !this.state.docDefinition.designWatermarkBold;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();    
}

handleWatermarkItalicChange = () => {
    var state = this.state;
    state.docDefinition.designWatermarkItalics = !this.state.docDefinition.designWatermarkItalics;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();    
}

handleWatermarkTextChange = (target) =>
{
    var state = this.state;
    state.docDefinition.designWatermarkText = target.value;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();
}

onSubmit = () => {

}


handleBackgroundColorChange = (color, event) =>
{
    // color.hex 
    var state = this.state;
    state.docDefinition.designBackgroundColor = color.hex;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();
}
handlePrimaryColorChange = (color, event) =>
{
    // color.hex 
    var state = this.state;
    state.docDefinition.designPrimaryColor = color.hex;
    this.setState(state);
}

handlePaperSizeChange = (e) =>
{

    var state = this.state;
    state.docDefinition.designPageSize = e.target.value;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();
}

handlePageOrientationChange = (e) =>
{

    var state = this.state;
    state.docDefinition.designPageOrientation = e.target.value;
    // state.pdfDocGenerator.setDynamicSettings("docDefinition", state.docDefinition);
    this.setState(state);
    // this.updatePDF();
}



handleDesignUpdate  = () => {
    var docDefinition = { ...this.state.docDefinition };
    InvoiceService.updateDesign(docDefinition)
    .then((res) =>{
        let result = res.data;
      
        if( result.status == 400 )
        {
            ToastVarexe({
                title : 'Something went wrong!',
                type :  'error',
                message: result.message
            });            
        }
        if( result.status == 200 )
        {
            ToastVarexe({
                type :  'success',
                title : 'Design updated',
                message: result.message
            });
        }            
    });
  }

handleVisibilityChanges = (displaySetting) =>
{
    var state = this.state;
    state.invoice.configuration = displaySetting;
    this.setState(state);
    this.updatePDF();
}


handleChangeTemplate = (param) => {
    var state = this.state;
    state.docDefinition.designTemplate = param.temp;
    this.setState(state);
}













    render(){
    const {invoiceTemplates} = this.state;


 const colors = ['#FF6900', '#FFEB3B', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#106EB6', '#ABB8C3', '#EB144C', '#E91E63', '#F78DA7', '#9900EF', '#000000'];

return(
    <Fragment>

    
    


       

            { this.state.loading ? 
            <Container fluid className='mb-3'>
                <Skeleton  className="mt-3" />
            </Container>
            :
            <>



<Container
 style={{
    // textAlign: 'center',
    // minHeight: 120,
    // lineHeight: '120px',
    color: '#fff',
    // backgroundColor: '#108ee9',
    background: `no-repeat url(${background}) left`
    }}
    className="p-0 m-0"
>

<Container  className='mt-3 mb-3 p-0 m-0'>
  <Card
    style={{
        background: 'rgba(240, 242, 245, 0.25)',
        backdropFilter: 'blur(50px)',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 10px 2px'
    }}
    className="mt-5 mb-3"
    bordered={false}

    title="Invoice Design"
  >


    <h4>Customize Theme</h4>
    <Card title="My Theme" bordered={false}
    extra={<Button size='sm' variant="primary" className="mt-2 fs--1 fw-normal " onClick={this.handleDesignUpdate}><IoSaveOutline className="fs-2 me-2"/>Apply</Button> }
    >
        <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        disabled={false}
        style={{ maxWidth: 600, margin:'auto' }}
        // requiredMark={'optional'}
        size='large'
        >
            <Form.Item
                label="Primary Color"
                name="firstName"
                // onChange={({ target }) => this.handleClientOnChange(this.props.target, target)}               
            >
            {/* <Input name="firstName" defaultValue={this.state.docDefinition.designBackgroundColor}  />              */}
            <p className="mt-2">pick your custom primary theme color</p>
            
            {/* <Card style={{
                width: '40px',
                height: '40px',
                border: `solid 5px ${this.state.docDefinition.designPrimaryColor}` 
            }}
            className="rounded"
            >

            </Card> */}

            <CirclePicker  
                    colors={colors}
                    color={ this.state.docDefinition.designPrimaryColor }
                    onChangeComplete={ this.handlePrimaryColorChange }
                    width={'100%'}
                />            
            </Form.Item> 



        </Form>



        {/* <Col style={{ maxWidth: 600, margin:'auto' }}>

        <Divider orientation="left">Save all your data</Divider>
        
        </Col> */}

    </Card>


  </Card>

</Container>


</Container>






            </>
            
            }



    
</Fragment>
        )




        }
}



InvoiceSizeDesign.propTypes = {
    hasTemplate: PropTypes.bool
  };

export default InvoiceSizeDesign;