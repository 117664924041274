import React, { Component, Fragment } from 'react';
import { defaultProps } from 'prism-react-renderer';

import logoPNG from '../../assets/img/varexe/varexe-logo-2.png';
import logoSVGICON from '../../assets/img/varexe/varexe-farsi-logo-icon.svg';
import logoSVG from '../../assets/img/varexe/varexe-farsi-logo.svg';

export default class VarexeLogo extends Component {

    static defaultProps = {
        type : 'svg'
    }

    constructor(props){

        super(props);
        this.state = {
            type : this.props.type

        }

    }


    componentDidMount(){

    }

    getImage = () => {

        switch(this.props.type) {
            case 'svg':
                return <img src={logoSVG} {...this.props} />;
            break;
            case 'svgIcon':
                return <img src={logoSVGICON} {...this.props} />;
            break;
            case 'png':
                return <img src={logoPNG} {...this.props} />;
            break;            
        }

    }

    render(){
        return(
            <Fragment>
                {this.getImage()}
            </Fragment>
        )
    }

}