import React, {Component, Fragment } from 'react';
import classNames from 'classnames';

import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge'

const TrendComponent = ({trend, percent, initials, style = ''}) => {

    if( style == 'pills' )
    {
        switch (trend) {
            case 'increased':
              return (
                <Fragment>
                  <SoftBadge bg={(initials == 'DUE' ? 'danger': 'success')} pill className="ms-2"><FontAwesomeIcon icon="long-arrow-alt-up" className="me-1" /> {percent}%</SoftBadge>
                </Fragment>
              );
            break;
            case 'levelled':
              return (
                <Fragment>
                    <SoftBadge bg={'primary'} pill className="ms-2">{percent}%</SoftBadge>
                </Fragment>
              );
            break;
            case 'descending':
              return (
                <Fragment>
                    <SoftBadge bg={(initials == 'DUE' ? 'success': 'danger')} pill className="ms-2"><FontAwesomeIcon icon="caret-down" className="me-1" /> {percent}%</SoftBadge>
                </Fragment>
              );
            break;
            case 'dropped':
              return (
                <Fragment>
                    <SoftBadge bg={'danger'} pill className="ms-2"><FontAwesomeIcon icon="long-arrow-alt-down" className="me-1" /> {percent}%</SoftBadge>
                </Fragment>
              );
            break;
            default:
              return (
                <Fragment>
                    <SoftBadge bg={'primary'} pill className="ms-2">{percent}%</SoftBadge>
                </Fragment>
              );        
              break;
            }
    } else {

        switch (trend) {
          case 'increased':
            return (
              <Fragment>
                <h6 className={classNames('fs--1 mb-0', {
                   'text-success ': initials != 'DUE',
                   'text-danger': initials == 'DUE'
                 })}>
                <FontAwesomeIcon icon="long-arrow-alt-up" className="me-1" /> {percent}%
                </h6>
              </Fragment>
            );
          break;
          case 'levelled':
            return (
              <Fragment>
                <h6 className='fs--1 mb-0 text-primary'>
                {/* <FontAwesomeIcon icon="caret-up" className="me-1" /> */}
                {percent}%
                </h6>
              </Fragment>
            );
          break;
          case 'descending':
            return (
              <Fragment>
              <h6 className={classNames('fs--1 mb-0', {
                        'text-success ': initials == 'DUE',
                        'text-danger': initials != 'DUE'
                      })}>
                <FontAwesomeIcon icon="caret-down" className="me-1" />
                {percent}%
                </h6>
              </Fragment>
            );
          break;
          case 'dropped':
            return (
              <Fragment>
                <h6 className='fs--1 mb-0 text-danger'>
                <FontAwesomeIcon icon="long-arrow-alt-down" className="me-1" />
                {percent}%
                </h6>
              </Fragment>
            );
          break;
          default:
            return (
              <Fragment>
                <h6 className='fs--1 mb-0 text-primary'>
                {/* <FontAwesomeIcon icon="long-arrow-alt-down" className="me-1" /> */}
                {percent}%
                </h6>
              </Fragment>
            );        
            break;
    }


    }

  }

  export default TrendComponent;