import React, {Component, Fragment}       from 'react';

/*
| UI
*/
import {  Skeleton, Layout, Card, Divider, Typography, Form, Input , Switch, Steps, Tag, Space, Tooltip, Button, Checkbox, Radio, DatePicker, InputNumber, Alert, Select    } from 'antd';
import {  Col, Row } from 'react-bootstrap';
import {  UncontrolledTooltip }         from 'reactstrap';



import Flex                     from '../../../common/Flex';
import ToastVarexe              from '../../../common/ToastVarexe';
import FalconCardHeader         from '../../../common/FalconCardHeader';
import ButtonIcon               from '../../../common/ButtonIcon';
import IconAlert                from '../../../common/IconAlert'
import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';
import InvoiceService       from '../../services';
class VisibilitiesSetting extends Component {


    
    constructor(props) {

        super(props)
        this.state = {
            idInvoiceConfiguration: -1,
            
                displayLogo             : false,
                displayCustomerBilling  : true,
                displayCustomerShipping : false,
                displayTerms            : false,
                displayFooterNote       : true,
                displayDueDate          : true,
                displayCompanyInfo      : false,
                displayItemTax          : false,  
                displayItemDiscount     : false,
                displaySummaryDiscount  : false,
                displaySummaryShipping  : false,

                isLoading: true            
        };
    } 

    componentDidMount(){

        InvoiceService.getSetting().then((res) =>{
            try{
                if( res.data !== undefined ){                
                if( res.data ){
                let InvoiceSetting = res.data;
                this.setState({
                  isLoading:false,
                      idInvoiceConfiguration:  InvoiceSetting.idInvoiceConfiguration,
                    
                        displayLogo             : InvoiceSetting.displayLogo,
                        displayCustomerBilling  : InvoiceSetting.displayCustomerBilling,
                        displayCustomerShipping : InvoiceSetting.displayCustomerShipping,
                        displayTerms            : InvoiceSetting.displayTerms,
                        displayFooterNote       : InvoiceSetting.displayFooterNote,
                        displayCompanyInfo      : InvoiceSetting.displayCompanyInfo,
                        displayDueDate          : InvoiceSetting.displayDueDate,
                        displayItemSpecs        : InvoiceSetting.displayItemSpecs,
                        displayItemTax          : InvoiceSetting.displayItemTax,  
                        displayItemDiscount     : InvoiceSetting.displayItemDiscount,
                        displaySummaryDiscount  : InvoiceSetting.displaySummaryDiscount,
                        displaySummaryShipping  : InvoiceSetting.displaySummaryShipping,
                });          
                }
                // this.props.handleVisibilityChanges(this.state);
            }
            } catch(error){
                console.log(error);
            }            
    
          }); 
          
          
    } 

    handleChange =  (target) =>  {

        const name      = target.name;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        
        var state = this.state;
        const newState  = state;
        Object.keys(state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });

        state = newState;
        this.setState(state);
        // this.props.handleVisibilityChanges(state);
     }
     handleSettingUpdate = event => {
        var state = { ...this.state };
        InvoiceService.updateSetting(state)
        .then((res) =>{
            let result = res.data;
          
            if( result.status == 400 )
            {
                ToastVarexe({
                    type :  'error',
                    message: result.message
                });            
            }
            if( result.status == 200 )
            {
                ToastVarexe({
                    type :  'success',
                    message: result.message
                });
            }            
        }); 
  
                                             
        

      }     

    render(){
      const { Text, Title, Paragraph } = Typography;
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      }; 

        return (
            <Fragment>


                <Title level={4}>Customer-Facing Information</Title>
                <Text>Take charge of the information presented to your customers by adjusting the display settings. Tailor the visibility of details such as product descriptions, pricing breakdowns, and additional notes to create a clear and professional presentation. These controls empower you to customize the level of transparency in your invoices, ensuring that your clients receive a concise and easily understandable overview of their transactions. Enhance communication and build trust by showcasing the information that matters most to your clients.</Text>  

                { this.state.isLoading ? 

                    <Skeleton  className="mt-3" />

                    :
                    <> 

                <Form
                    {...layout}
                    size='large'
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    layout="horizontal"
                    style={{ maxWidth: 800, margin:'auto' }}
                    className="mt-5"
                    disabled={false}
                    onFinish={this.handleSettingUpdate}

                    initialValues={{
                      displayLogo             : this.state.displayLogo,
                      displayCustomerBilling  : this.state.displayCustomerBilling,
                      displayCustomerShipping : this.state.displayCustomerShipping,
                      displayTerms            : this.state.displayTerms,
                      displayFooterNote       : this.state.displayFooterNote,
                      displayCompanyInfo      : this.state.displayCompanyInfo,
                      displayDueDate          : this.state.displayDueDate,
                      displayItemSpecs        : this.state.displayItemSpecs,
                      displayItemTax          : this.state.displayItemTax,  
                      displayItemDiscount     : this.state.displayItemDiscount,
                      displaySummaryDiscount  : this.state.displaySummaryDiscount,
                      displaySummaryShipping  : this.state.displaySummaryShipping,                   
                        }}

                >

                                    <Col lg={{ span: 16, offset: 2 }} className="mt-0 mb-2" >
                                        <Divider orientation='left' orientationMargin={0} plain>Business Information</Divider>
                                    </Col> 

                                     
                                     <Form.Item
                                          label="Display Company logo"
                                          name="displayLogo"
             
                                      >
                                          <Switch name="displayLogo" defaultChecked={this.state.displayLogo} className="mt-2"  onChange={(value)=>{
                                              let target = {
                                                  name : 'displayLogo',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item> 

                                      <Form.Item
                                          label="Display Company Information"
                                          name="displayCompanyInfo"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayCompanyInfo} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayCompanyInfo',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        

                                    <Col lg={{ span: 16, offset: 2 }} className="mt-0 mb-2" >
                                        <Divider orientation='left' orientationMargin={0} plain>Business Terms</Divider>
                                    </Col> 

                                     <Form.Item
                                          label="Display Terms and conditions"
                                          name="displayTerms"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayTerms} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayTerms',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                         
                                     <Form.Item
                                          label="Display Footer note"
                                          name="displayFooterNote"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayFooterNote} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayFooterNote',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                         
                                       
                                    <Col lg={{ span: 16, offset: 2 }} className="mt-0 mb-2" >
                                        <Divider orientation='left' orientationMargin={0} plain>Customer addresses</Divider>
                                    </Col> 

                                    {/* <Form.Item
                                          label="Display Billing address"
                                          name="displayCustomerBilling"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayCustomerBilling} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayCustomerBilling',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        */}
                                    <Form.Item
                                          label="Display Shipping address"
                                          name="displayCustomerShipping"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayCustomerShipping} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayCustomerShipping',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                       


                                      <Col lg={{ span: 16, offset: 2 }} className="mt-0 mb-2" >
                                        <Divider orientation='left' orientationMargin={0} plain>Invoice</Divider>
                                      </Col> 

                                      <Form.Item
                                          label="Display Due date"
                                          name="displayDueDate"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayDueDate} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayDueDate',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        
                                      <Form.Item
                                          label="Display services specifications detail"
                                          name="displayItemSpecs"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayItemSpecs} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayItemSpecs',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        
                                      {/* <Form.Item
                                          label="Display services Tax"
                                          name="displayItemTax"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayItemTax} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayItemTax',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                         */}
                                      <Form.Item
                                          label="Display Discount Per Line Item"
                                          name="displayItemDiscount"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displayItemDiscount} onChange={(value)=>{
                                              let target = {
                                                  name : 'displayItemDiscount',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        
                                      <Form.Item
                                          label="Display global discount to all"
                                          name="displaySummaryDiscount"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displaySummaryDiscount} onChange={(value)=>{
                                              let target = {
                                                  name : 'displaySummaryDiscount',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        
                                      <Form.Item
                                          label="Display shipping charge to all"
                                          name="displaySummaryShipping"
             
                                      >
                                          <Switch className="mt-2" defaultChecked={this.state.displaySummaryShipping} onChange={(value)=>{
                                              let target = {
                                                  name : 'displaySummaryShipping',
                                                  value : value,
                                              }
                                              this.handleChange(target);
                                          }} />        
                                      </Form.Item>                                        






                                    <Flex className="">
                                        <div className="p-2 flex-grow-1 ">
                                            {/* <Button type="default" onClick={this.handleStripeAccountUpdate}>Finish later</Button> */}
                                        </div>
                                        <div className="p-2 ">
                                            <Button type="primary" htmlType="submit">Save and continue</Button>
                                        </div>
                                </Flex>     

                </Form>


        </>
        }






            </Fragment>
          );        
    }
}
export default VisibilitiesSetting;