import React, { Fragment } from 'react'


import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
// import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout   from './Auth'
import MainLayout   from './Main'
import ParkLayout   from './Park'
import AppatchLayout   from './Appatch'

import { history } from '../index'




const Layouts = {
  public:  PublicLayout,
  policy: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  park: ParkLayout,
  appatch: AppatchLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // NProgress & ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    // if (pathname === '/') {
    //   return 'public'
    // }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    if (/^\/park(?=\/|$)/i.test(pathname)) {
      return 'park'
    } 
    if (/^\/public(?=\/|$)/i.test(pathname)) {
      return 'public'
    }       
    if (/^\/policy(?=\/|$)/i.test(pathname)) {
      return 'policy'
    }  

    if (/^\/appatch(?=\/|$)/i.test(pathname)) {
      return 'appatch'
    }       
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized  = user.authorized
  const isUserLoading     = user.loading
  const isAuthLayout      = getLayout() === 'auth'
 
  
  const BootstrappedLayout = () => {
    // console.log('BootstrappedLayout');
    // console.log(isAuthLayout);
    // console.log(isUserAuthorized);
    
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    // if(isAuthLayout && isUserAuthorized){
    //   return <Redirect to="/dashboard" />
    // }  
    if(isAuthLayout && isUserAuthorized){
      // console.log('Redirect to="/dashboard');
      return <Redirect to="/dashboard" />
    }      
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      
      if(getLayout() === 'public' || getLayout() === 'park' || getLayout() === 'policy' || getLayout() === 'appatch')
      {
        // return null
      } else {
        return <Redirect to="/auth/login" />
      }
    } 

    // in other case render previously set layout
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      {BootstrappedLayout()} 
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
