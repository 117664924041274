import React, {Component, Fragment}      from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
/*
| UI
*/
import {  Skeleton, Layout, Card, Divider, Typography, Form, Input , Switch, Steps, Tag, Space, Tooltip, Button, Checkbox, Radio, DatePicker, InputNumber, Alert, Select    } from 'antd';

import {   Col, Row } from 'react-bootstrap';

import Flex                     from '../../../common/Flex';
import FormGroupInput           from '../../../common/FormGroupInput';
import FalconCardHeader         from '../../../common/FalconCardHeader';
import ButtonIcon               from '../../../common/ButtonIcon';
import ToastVarexe              from '../../../common/ToastVarexe';

import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';

/*
| Pages
*/
import InvoiceService       from '../../services/';

class ColumnSetting extends Component
{

    static defaultProps = {
        hasTitle:  true,
        hasBorder: true,
    }    
    constructor(props) {

        super(props)
        this.state = {
            idInvoiceConfiguration: -1,
            itemsFormat : "items",
            unitsFormat : "quantity",
            priceFormat : "price",
            amountFormat :"amount",
            loading: true,            
        };
        
    }

    componentDidMount(){

        InvoiceService.getSetting().then((res) =>{
            try{
                if( res.data !== undefined ){
                if( res.data ){
                let InvoiceSetting = res.data;
        
                this.setState({
                    loading:false,
                    idInvoiceConfiguration:  InvoiceSetting.idInvoiceConfiguration,
                    itemsFormat : InvoiceSetting.itemsFormat,
                    unitsFormat : InvoiceSetting.unitsFormat,
                    priceFormat : InvoiceSetting.priceFormat,
                    amountFormat :InvoiceSetting.amountFormat,
                                         
                }); 
                }
                }

            } catch(error){
                console.log(error);
            }            
    
          }); 

          
    }  
    
    handleChange =  (target) =>  {

        const name      = target.name;
        const value     = target.value;
        const newState  = {};

        Object.keys(this.state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });
        this.setState(newState);
     } 

    handleSettingUpdate = event => {
        var state = { ...this.state };
        InvoiceService.updateSetting(state)
        .then((res) =>{
            let result = res.data;
          
            if( result.status == 400 )
            { 
                ToastVarexe({
                    type :  'error',
                    message: result.message
                });                                       
            }
            if( result.status == 200 )
            {
                ToastVarexe({
                    type :  'success',
                    message: result.message
                });                   
            }            
        });    
      }    
    
    render(){      
        
        const { Text, Title, Paragraph } = Typography;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          }; 

        return (
            
           <Fragment>


        {(this.props.hasTitle?            
            <Col md={12} lg={12} >
            <Title level={4}>Invoice column settings</Title>
            <Text>This feature allows you to prioritize the information most relevant to your clients, presenting a clean and organized document. Whether you want to emphasize item descriptions, quantities, prices, or other details, column settings provide the flexibility to create professional and customized invoices that align with your business needs.</Text>  
            </Col>
        : null)}

        {this.state.loading ?
        <Fragment>
            <Skeleton  className="mt-3" />
        </Fragment>
        : 
        <Fragment>             

            <Form
            {...layout}
            size='large'
            // labelCol={{ span: 12 }}
            // wrapperCol={{ span: 12 }}
            layout="vertical"
            style={{ maxWidth: 800, margin:'auto' }}
            className="mt-5"
            disabled={false}
            onFinish={this.handleSettingUpdate}

            initialValues={{            
                itemsFormat : this.state.itemsFormat,
                unitsFormat : this.state.unitsFormat,
                priceFormat : this.state.priceFormat,
                amountFormat : this.state.amountFormat
            }}

            >

            <Form.Item
                className='mb-5'
                label="How do you name your items?"
                name="itemsFormat"             
            >
                <Radio.Group name="itemsFormat" defaultValue={'items'} onChange={({ target }) => this.handleChange(target)}>
                    <Radio value={'items'}>Items</Radio>
                    <Radio value={'services'}>Services</Radio>
                    <Radio value={'products'}>Products</Radio>
                </Radio.Group>

            </Form.Item> 

            <Form.Item
                className='mb-5'
                label="How do you name your units?"
                name="unitsFormat"             
            >
                <Radio.Group name="unitsFormat" defaultValue={'quantity'} onChange={({ target }) => this.handleChange(target)}>
                    <Radio value={'quantity'}>Quantity</Radio>
                    <Radio value={'hours'}>Hours</Radio>
                </Radio.Group>

            </Form.Item>         




            <Form.Item
                className='mb-5'
                label="How do you name your price?"
                name="priceFormat"             
            >
                <Radio.Group name="priceFormat" defaultValue={'price'} onChange={({ target }) => this.handleChange(target)}>
                    <Radio value={'price'}>Price</Radio>
                    <Radio value={'rate'}>Rate</Radio>
                </Radio.Group>

            </Form.Item> 


            <Form.Item
                className='mb-5'
                label="How do you name your amount?"
                name="amountFormat"             
            >
                <Radio.Group name="amountFormat" defaultValue={'amount'} onChange={({ target }) => this.handleChange(target)}>
                    <Radio value={'amount'}>Amount</Radio>
                    <Radio value={'total'}>Total</Radio>
                </Radio.Group>

            </Form.Item>             


            <Flex className="">
                <div className="p-2 flex-grow-1 ">
                </div>
                <div className="p-2 ">
                    <Button  type="primary" htmlType="submit">Save Column setting</Button>
                </div>
            </Flex>  

            </Form>
        </Fragment>  
        }
           </Fragment> 
            
        )
    }

} 


ColumnSetting.propTypes = {
    hasTitle: PropTypes.bool,
    hasBorder: PropTypes.bool,
  };

export default ColumnSetting;