

import React, {Component, Fragment}      from 'react';

import { Form, Button, Card, Col, Row } from 'react-bootstrap';
import {  UncontrolledTooltip }         from 'reactstrap';

import NumberFormat from 'react-number-format';

import { FontAwesomeIcon }              from '@fortawesome/react-fontawesome';

import Flex                     from '../../../common/Flex';
import FormGroupInput           from '../../../common/FormGroupInput';
import FalconCardHeader         from '../../../common/FalconCardHeader';
import ButtonIcon               from '../../../common/ButtonIcon';
import ToastVarexe              from '../../../common/ToastVarexe';


import IconAlert from '../../../common/IconAlert'
/*
| Pages
*/

import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';

/*
| draft-js
*/

import InvoiceService       from '../../services/';

class CurrencySetting extends Component
{
    constructor(props) {

        super(props)
        this.state = {
            idInvoiceConfiguration: -1,
            
            // USDExchangeRate : 0,
            currencyName:   "Canadian dollar",
            currencySymbol: "$",
            currencyCode:   "CAD",
            currencyOptionList: [],
            loading: true,
            redirect:      null,           
        };
        this.editorStateChanged = this.editorStateChanged.bind(this);
    }
    componentDidMount(){

        InvoiceService.getSetting().then((res) =>{
            try{
                if( res.data !== undefined ){   
                if( res.data ){
                let InvoiceSetting = res.data;
        
                this.setState({
                    loading:false,
                    idInvoiceConfiguration:  InvoiceSetting.idInvoiceConfiguration,
 
                    // USDExchangeRate:         InvoiceSetting.USDExchangeRate, 
                    currencyName:            InvoiceSetting.currencyName,    
                    currencySymbol:          InvoiceSetting.currencySymbol,    
                    currencyCode:            InvoiceSetting.currencyCode,    
                    currencyOptionList:      InvoiceSetting.currencyOptionList,
                });          
                }
            }

            } catch(error){
                console.log(error);
            }            
    
          }); 

          
    }


    handleSettingUpdate = event => {
        var state = { ...this.state };
        InvoiceService.updateSetting(state)
        .then((res) =>{
            let result = res.data;
          
            if( result.status == 400 )
            {
 
            ToastVarexe({
                    type :  'error',
                    message: result.message
                });                                     
            }
            if( result.status == 200 )
            {
                ToastVarexe({
                        type :  'success',
                        message: result.message
                    });      
            }            
        });    
      }


      
      handleChange =  (target) =>  {

        const name      = target.name;
        const value     = target.value;
        const newState  = {};
    
        Object.keys(this.state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });
        this.setState(newState);
     }
     
     editorStateChanged = (value) => {
        this.setState(() => (
          {
              ...this.state,
              ['topNote']: value
          }
      ));
       
    } 


    handleServiceItemChange = (i) => (event) => {
       var state = { ...this.state };
       this.setState({ state });
   }

    render(){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

            const {                
                currencyName, 
                currencySymbol, 
                currencyCode, 
                currencyOptionList, 
                      
        } = this.state;         
        return (
            <Fragment>


            
        <Form.Group as={Row} className="mb-0 justify-content-md-center " controlId="title">
        <Col md={12} lg={6} >
            <h1 className="fs-3 text-nunito">Business Currency</h1> 
            <p className='fs--1 text-600 mb-0'>The currency you choose will be your organization's default currency.</p>           
        </Col>
        </Form.Group>
        {this.state.loading ?
        ""
        : 
<Fragment>

<Form.Group as={Row} className="mb-3 mt-4 justify-content-md-center " controlId="title">
    <Col md={12} lg={6} >            
    <IconAlert variant={'warning'}  onClose={() => setShow(false)}>
        <h5 className="mb-2 text-warning">Currency can not be change!</h5>
        <p>Your current business currency used to track your business finances and create reports. You can not change this after your business account has been created in Varexe. You can still use other currencies on the invoices and have an exchange rate applied for reporting.</p>
    </IconAlert>
    </Col>
</Form.Group>

<Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="currency">
    <Col md={12} lg={4}>
        <Flex direction='lg-row-reverse'>  
            <p className='text-nunito'>Business Currency</p>
        </Flex>        
    </Col>
    <Col md={12} lg={6}>
        <Col  lg={12} xl={6}>
            <h5>{currencyCode} ({currencySymbol}) {currencyName}
                {/* <FontAwesomeIcon icon="question-circle" className="fs-0 ms-1 text-primary" id="view-invoicetitle-tooltip" />
                <UncontrolledTooltip placement="top" target="view-invoicetitle-tooltip">
                used to track your business finances and create reports. You can not change this after your business account has been created in Varexe. You can still use other currencies on the invoices and have an exchange rate applied for reporting.
                </UncontrolledTooltip>    */}
            </h5>          
         {/*    <Form.Select
            name="currencyCode"
            // id="currency"
            value={currencyCode}
            onChange={({ target }) => this.handleChange(target)}       
            >
            {currencyOptionList.map((param)=>{
                return (<option value={param.id}>{param.name}</option>)
            })}
            </Form.Select> */}
        </Col>
    </Col>
</Form.Group>





{/* <Form.Group as={Row} className="mb-3 justify-content-md-center " controlId="title">
    <Col md={12} lg={6} >
        <p>If ever needed to create an invoice or a payment record with a different currency will be calculated based on the present US dollar rate which you specify below.</p>         
    </Col>
</Form.Group>


<Form.Group as={Row} className="mb-3 justify-content-md-center " controlId="draftTitle">
    <Col md={12} lg={4}>
        <Flex direction='lg-row-reverse'>  
            <p className='text-nunito'>
                USD exchange rate             
            </p>
        </Flex>        
    </Col>
    <Col md={12} lg={6}>
        <Col  lg={12} xl={6}>
            <NumberFormat
            className="form-control"
            thousandsGroupStyle="thousand"
            value={USDExchangeRate}
            onValueChange={(values) => {
                const { formattedValue, value } = values;
                this.setState({
                    USDExchangeRate : value
                })
            }}        
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            // allowEmptyFormatting={false}
            />
        </Col>
    </Col>
</Form.Group>  */}







</Fragment>
        }


{/* <Form.Group as={Row} className="mb-3 justify-content-md-center  pb-5" controlId="save">
    <Col md={12} lg={4}>
        <Flex direction='lg-row-reverse'> 
        <p></p> 
        </Flex>        
    </Col>
    <Col md={12} lg={6}>
        <Col  lg={12} xl={12}>
        {this.state.loading ?
        ""
        : 
            <Button

            size="sm"
            className="mt-2 fs--1 fw-normal"
            type="submit" 
            onClick={this.handleSettingUpdate}
            >
            <IoSaveOutline className=" fs-2 me-2"/> Save Changes
            </Button> 
        } 
        </Col>
    </Col>
</Form.Group> */}

            </Fragment>
        );
    }
}
export default CurrencySetting;