import React, { Fragment ,useContext,useState, useEffect, useRef } from 'react';
import classNames                 from 'classnames';
import { Link, useHistory   } from 'react-router-dom';
import is                         from 'is_js';
import PropTypes                  from 'prop-types';
import { connect } from 'react-redux'
import store from 'store'
import { useLocation } from 'react-router';
import { map } from 'lodash';

/*
| UI
*/
import { Menu, Layout, Skeleton, Tooltip, Button, Dropdown, Space, Typography    } from 'antd';
import { Nav, Navbar, Row, Col, ButtonGroup, OverlayTrigger } from 'react-bootstrap';
import { capitalize } from 'helpers/utils';

/*
| Icons
*/
import { UsergroupAddOutlined, PlusOutlined } from '@ant-design/icons'
import { FcCalculator, FcContacts, FcAddressBook, FcCurrencyExchange, FcFeedIn } from "react-icons/fc";
import { PiMathOperationsDuotone, PiPlusMinusDuotone, PiUserPlusDuotone, PiReceiptDuotone } from "react-icons/pi"; 
/*
| IMAGES
*/
import Logo from '../../Logo';
import VarexeLogo from 'components/common/VarexeLogo';

/*
| PAGES
*/
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../../config';
import AppContext                 from '../../../../context/Context';
// import routes                     from '../../../../routes';
import IconButton                 from '../../../common/IconButton';
import Flex                       from '../../../common/Flex'
import ToggleThemDark             from '../../partials/ToggleThemDark'


import getMenuData from '../../../../services/menu'

// import UserRoleService from '../../../user-roles/services/index'
import { all, put, call } from 'redux-saga/effects'
const mapStateToProps = ({ menu, settings, user }) => ({
  // menuData: menu.menuData,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
  isMenuUnfixed: settings.isMenuUnfixed,
  isMenuShadow: settings.isMenuShadow,
  leftMenuWidth: settings.leftMenuWidth,
  menuColor: settings.menuColor,
  logo: settings.logo,
  role: user.role,
})

const NavbarLeft = ({
  dispatch,
  // menuData = [],
  // location: { pathname },

  isMenuCollapsed,
  isMobileView,
  isMenuUnfixed,
  isMenuShadow,
  leftMenuWidth,
  menuColor,
  logo,
  role,
}) => {

  let menuData = getMenuData();

  const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
  const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

  const [isUserRoleLoading, setIsUserRole] = useState(true);


  const { pathname } = useLocation();
  useEffect(() => {    
  }, [pathname, menuData])



  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const history = useHistory();

  let time = null;


  const onCollapse = (value, type) => {
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    })
    setOpenedKeys([])
  }

  const onOpenChange = keys => {
    store.set('app.menu.openedKeys', keys)
    setOpenedKeys(keys)
  }

  const handleClick = e => {
    store.set('app.menu.selectedKeys', [e.key])
    setSelectedKeys([e.key])
  }
  const menuSettings = isMobileView
    ? {
        width:leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width:leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: 'lg',
      } 
  return (
<Fragment>

<Layout.Sider
      {...menuSettings}
      className={classNames(`menu`, {
        // 'white': menuColor === 'white',
        // 'gray': menuColor === 'gray',
        // 'dark': menuColor === 'dark',
        'unfixed': isMenuUnfixed,
        'shadow': isMenuShadow,
      })}  
    >








    <div
    className={'menuOuter '}
    style={{
      width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
      height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 50px)',
      borderRight: isMobileView || isMenuUnfixed ? '' : 'solid 1px var(--varexe-border-color)',
    }}
    >



        <div className={'logoContainer'}>
    
          <div className={'logo p-3'}>
            {(isMenuCollapsed && !isMobileView ? 
            <VarexeLogo type={'svg'} style={{width:'35px'}}  className='fs-1 ms-2'/>
              :
              <Flex justifyContent='end' className={''}>
                <div className='flex-grow-1'>
                  <Logo at="navbar-top" width={25} id="topLogo" />
                </div>
                <div className='text-end '>
                  <ToggleThemDark />
                </div>
              </Flex>
            )}
          </div>
        </div>



{/* {(isUserRoleLoading ? 
  <>
  <Skeleton className='p-3' active/>
  <Skeleton className='p-3' active/>
  <Skeleton className='p-3' active/>
  <Skeleton className='p-3' active/>
  <Skeleton className='p-3' active/>
  </>
:
<> */}
        <div className='p-3'>
          <Fragment>
            {/* <NavbarLabel label={capitalize('Creates New')} /> */}
            <Space size={'small'} wrap={(isMenuCollapsed && !isMobileView ? true : false)}>
              <Button className='m-0' type="dashed" default onClick={() => history.push('/invoices/new/invoice')}>
              {(isMenuCollapsed && !isMobileView ? 
                <Tooltip placement="right" title={'New Invoice'} arrow={false}>
                  <PiReceiptDuotone className='fs-1' />
                </Tooltip>
                  :
                  'Invoice'
                )}                
                
              </Button>
              <Button className='m-0' type="dashed"  default onClick={() => history.push('/estimates/new/estimate')}>
              {(isMenuCollapsed && !isMobileView ? 
                  <Tooltip placement="right" title={'New Estimate'} arrow={false}>
                    <PiPlusMinusDuotone className='fs-1' />
                  </Tooltip>
                  :
                  'Estimate'
                )}
              </Button>
              <Tooltip placement="right" title={'New Customer'} arrow={false}>
                <Button  className='m-0' type="dashed" default onClick={() => history.push('/customers/new')}>
                  <PiUserPlusDuotone className='fs-1' />
                </Button>
              </Tooltip>
            </Space>
          </Fragment>
        </div>
        <div className="scrollbar" style={{ height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)'  }}>
          <Menu
              onClick={handleClick}
              selectedKeys={selectedKeys}
              openKeys={openedKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              className={'navigation'}
              inlineIndent="15"
              items={menuData}
              style={{background: 'none', border: 'none'}}
            >
          </Menu>  
        </div>
    {/* </>
)} */}

</div>






</Layout.Sider>
</Fragment>
  );
};

NavbarLeft.propTypes = {
  label: PropTypes.string
};

export default connect(mapStateToProps)(NavbarLeft)

