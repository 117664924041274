import React , { Fragment, Component }  from 'react';
import ReactDOM from 'react-dom';
import {Editor, EditorState, ContentState, RichUtils, convertFromHTML,convertToRaw, getDefaultKeyBinding} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
// import 'draft-js/dist/Draft.css';
import {isEmpty}             from '../../../helpers/utils';


//https://github.com/facebook/draft-js/tree/main/examples/draft-0-10-0
//https://www.npmjs.com/package/draft-js-export-html

import StyleButton from './StyleButton'
import HeaderStyleDropdown from './HeaderStyleDropdown'

import Flex from '../Flex';
import { BiBold, BiItalic, BiUnderline, BiListOl, BiListUl } from 'react-icons/bi';
import { VscSymbolParameter } from 'react-icons/vsc';
import { ConsoleSqlOutlined } from '@ant-design/icons';


class TextEditorVarexe extends React.Component {
	constructor(props) {
		super(props);
		
    
      const blocksFromHTML = convertFromHTML(props.content);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
    
    
    this.state = {
			// editorState: EditorState.createEmpty()
			editorState: EditorState.createWithContent(state)
		};
        this.focus = () => this.refs.editor.focus();

        this.toggleBlockType   = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);


        this.setEditor = (editor) => {
            this.editor = editor;
          };
        this.focusEditor = () => {
            if (this.editor) {
              this.editor.focus();
            }
          };        
	}

	onChange = editorState => {
		this.setState({
			editorState
		});


    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    
    let html = '';

    if( editorState.getCurrentContent().hasText() )
    {

      var contentState = editorState.getCurrentContent();
      html = stateToHTML(contentState);

    } else {
      html = '';
    };
    this.props.editorStateChanged(html);
	};

  clearCurrentContent = () =>{
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState });
  }



    _toggleBlockType(blockType) {
        this.onChange(
          RichUtils.toggleBlockType(
            this.state.editorState,
            blockType
          )
        );
      }

      _toggleInlineStyle(inlineStyle) {
        this.onChange(
          RichUtils.toggleInlineStyle(
            this.state.editorState,
            inlineStyle
          )
        );
      }      



	handleKeyCommand = command => {
		const newState = RichUtils.handleKeyCommand(
			this.state.editorState,
			command
		);
		if (newState) {
			this.onChange(newState);
			return "handled";
		}
		return "not-handled";
	};


	render() {

    const HEADER_TYPES = [
      { label: "(None)", style: "unstyled" },
      { label: "H1", style: "header-one" },
      { label: "H2", style: "header-two" },
      { label: "H3", style: "header-three" },
      { label: "H4", style: "header-four" },
      { label: "H5", style: "header-five" },
      { label: "H6", style: "header-six" }
    ];

    const BlockHeadingType = (props) => {

        const {editorState} = props;
        const selection = editorState.getSelection();
        const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();        
        return (
          <div className="RichEditor-controls">
              <HeaderStyleDropdown
              headerOptions={HEADER_TYPES}
              active={blockType}
              onToggle={props.onToggle}
            />
          </div>
        );
    };



        const BLOCK_TYPES = [
            {label: <BiListUl className='fs-2' />, style: 'unordered-list-item'},
            {label: <BiListOl className='fs-2' />, style: 'ordered-list-item'},
            // {label: <span className='fs--1 text-uppercase fw-semi-bold'>Code Block</span> , style: 'code-block'},
          ];        
        const BlockStyleControls = (props) => {
            const {editorState} = props;
            const selection = editorState.getSelection();
            const blockType = editorState
              .getCurrentContent()
              .getBlockForKey(selection.getStartKey())
              .getType();
            return (
              <div className="RichEditor-controls">
                <Flex direction='row' className="">
                  {BLOCK_TYPES.map((type, index) =>
                    <StyleButton
                      key={index}
                      active={type.style === blockType}
                      label={type.label}
                      onToggle={props.onToggle}
                      style={type.style}
                    />
                  )}
                </Flex>
              </div>
            );
          };


          var INLINE_STYLES = [
            {label: <BiBold   className='fs-1' />, style: 'BOLD'},
            {label: <BiItalic className='fs-1' />, style: 'ITALIC'},
            {label: <BiUnderline className='fs-2' />, style: 'UNDERLINE'},
            // {label: <VscSymbolParameter className='fs-3' /> , style: 'CODE'},
          ];
    
          const InlineStyleControls = (props) => {
            const currentStyle = props.editorState.getCurrentInlineStyle();
            
            return (
              <div className="RichEditor-controls">
                <Flex direction='row' className="">
                  {INLINE_STYLES.map((type, index) =>
                    <StyleButton
                      key={index}
                      active={currentStyle.has(type.style)}
                      label={type.label}
                      onToggle={props.onToggle}
                      style={type.style}
                    />
                  )}
                </Flex>
              </div>
            );
          };












        const styles = {
            editor: {
                // border: '1px solid gray',
                minHeight: '6em',
                cursor:'text'
            },
            buttonContainer : {
                // minHeight: 50,
                paddingTop : 0,
                paddingBotton : 20
            }
        };
        const {editorState} = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
          if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
          }
        }


        
        
		return (



			<div className="">
          <div style={styles.buttonContainer}>

            <Flex direction='row' className=" mb-2">  
            {(this.props.hasHeaderTypes? 
              <div className="p-2 ps-0">
              <BlockHeadingType   editorState={this.state.editorState}   onToggle={this.toggleBlockType}/>
              </div>
            : null)}
            {(this.props.hasInlineType? 
              <div className="p-2">
                  <InlineStyleControls editorState={this.state.editorState}  onToggle={this.toggleInlineStyle} />                
              </div>
            : null)}
            {(this.props.hasBlockType?
              <div className="p-2 pe-0  border-left border-800">
                <BlockStyleControls   editorState={this.state.editorState} onToggle={this.toggleBlockType}/>
              </div>
            : null)}
            </Flex>


              
              
              
          </div>

          <div className='border border-1 border-300 rounded-2' style={styles.editor} onClick={this.focus} >
          <Editor
            editorState={this.state.editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            ref="editor"
            spellCheck={true}
          />
        </div>
			</div>
		);
	}
}

TextEditorVarexe.defaultProps = {
  hasHeaderTypes: true,
  hasBlockType:   true,
  hasInlineType:   true,
};

export default TextEditorVarexe;