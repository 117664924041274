import React, {Component, Fragment} from 'react';
import { Card, CardBody, CardFooter, Col, Row, Table } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

class PreviewSkeleton extends Component {
    render() {
      return (
        <Fragment>
            <Card className="mb-3">
            <CardBody>         
                <Row className="align-items-center text-center mb-3">
                <Col sm={6} className="text-sm-left">
                    <Skeleton />
                </Col>
                </Row> 
            </CardBody>
            </Card>            
            <Card className="mb-3">
            <CardBody>         
                <Row className="align-items-center text-center mb-3">
                <Col sm={6} className="text-sm-left">
                    <Skeleton />
                </Col>
                </Row> 
            </CardBody>
            </Card>
            <Card>
                <CardBody>    
                    <Row className="align-items-center text-center mb-3">
                    <Col sm={6} className="text-sm-left">
                        <Skeleton />
                    </Col>
                    <Col className="text-sm-right mt-3 mt-sm-0">
                        <Skeleton count={5} />

                    </Col>
                    <Col xs={12}>
                        <hr />
                    </Col>
                    </Row> 
                    </CardBody> 
                    <CardFooter className="bg-light">
                    <p className="fs--1 mb-0">
                    <Skeleton />
                    </p>
                    </CardFooter>           
                </Card>         
        </Fragment>
      );
    }
  } export default PreviewSkeleton;