import React , { Component, Fragment } from 'react';

import { Divider, Space, Form, Input, Select  } from 'antd';
import { Card, Col,  Nav, Row, Tab, Placeholder, Container, Button } from 'react-bootstrap';

import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';
import { Link, Redirect } from 'react-router-dom';

import Flex             from '../../../common/Flex';
import ToastVarexe              from '../../../common/ToastVarexe';


/*
| Pages
*/
import CompanyService        from '../services';
import AdvancedCountrySelect  from '../../../countries/components/AdvancedCountrySelect';

class OtherFormats extends Component
{

    constructor(props) {

        super(props)
        this.state = {
            idInvoiceConfiguration: -1,

            phoneFormatList:[
                '(999) 999-9999',
                '999-999-9999',
                '999 999 9999',
                '(99) 9999-9999',
                '99-9999-9999',
                '99 9999 9999',
                '9999 999 999',
                '(999) 9999 9999',
                '999-9999-9999',
                '999 9999 9999',
            ],

            loading : true

        }
    }

    componentDidMount(){

        CompanyService.getSetting().then((res) =>{
            try{
                if( res.data !== undefined ){                
                if( res.data ){
                let InvoiceSetting = res.data;
                
                this.setState({
                    loading:false,
                    idInvoiceConfiguration:  InvoiceSetting.idInvoiceConfiguration,
                    
                    // companyName :            InvoiceSetting.companyName,
                    // tagline :                InvoiceSetting.tagline,
                    // companyDescription :     InvoiceSetting.companyDescription,
                    // companyAddress :         InvoiceSetting.companyAddress,
                    idCountry :              InvoiceSetting.idCountry,
                    // idState :                InvoiceSetting.idState,
                    // idCity :                 InvoiceSetting.idCity,
                    // postalCode :             InvoiceSetting.postalCode,
                    // phoneCode :              InvoiceSetting.phoneCode,
                    // companyEmail   :         InvoiceSetting.companyEmail,
                    // companyContactNumber:    InvoiceSetting.companyContactNumber,
                    // topNote        :         InvoiceSetting.topNote
                    dateTimeZone : InvoiceSetting.dateTimeZone,
                    phoneFormat : InvoiceSetting.phoneFormat
                });
                        
                }
            }
            } catch(error){
                console.log(error);
            }            
    
          });

    }
    handleChange = (target) => {
        const name      = target.name;
        const value     = target.value;
        const newState  = {};
    
        Object.keys(this.state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });
        this.setState(newState);
    }

    handleSelect = (data) => {

    }
    handleSettingUpdate = event => {
        var state = { ...this.state };
        CompanyService.updateOtherFormatSetting(state)
        .then((res) =>{
            let result = res.data;
          
            if( result.status == 400 )
            { 
                ToastVarexe({
                    type :  'error',
                    message: result.message
                });                                     
            }
            if( result.status == 200 )
            {
                ToastVarexe({
                    type :  'success',
                    message: result.message
                });                 
            }            
        });    
      }     

    render()
    {
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };

          const { Option } = Select;

        return (
        <Fragment>

{(!this.state.loading?

<Fragment>


    <Form
    {...layout}
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 18 }}
    layout="horizontal"
    disabled={false}
    style={{ maxWidth: 600, margin:'auto' }}
    // requiredMark={'optional'}
    size='large'
    >
    <h4 className='mb-5'>Date and other formats</h4>


    <Divider orientation="left">Convert all phone Format</Divider>
    <Form.Item
        label="Phone format"
        name="phoneFormat"
        onChange={({ target }) => this.handleChange(target)}               
    >
      <Select 
      defaultValue={this.state.phoneFormat}
      onChange={(value) =>{
        let  target = {
                    name :'phoneFormat',
                    value :value,
                } 

            this.handleChange(target);
    }}
      placeholder="Please select">
        {/* <Option value="china">China</Option>
        <Option value="usa">U.S.A</Option> */}
        {this.state.phoneFormatList.map((param)=>{
            return (<Option value={param}>{param}</Option>)
        })}        
      </Select>      
    </Form.Item> 

    <Divider orientation="left">Convert all dates to this timezone</Divider>
    <Form.Item
        label="Business time zone"
        name="timezones"
        onChange={({ target }) => this.handleChange(target)}               
    >
                <AdvancedCountrySelect 
                    serviceType="timezones"
                    idCountry={this.state.idCountry}
                    defaultValue={this.state.dateTimeZone}
                    callBackResult={(result)=>{
                        let state = {...this.state};  
                        this.setState({
                            dateTimeZone: result.value
                        })                     
                    }}
                />      
    </Form.Item> 

    




    <Divider orientation="left">Save</Divider> 

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button
                size="sm"
                className="mt-2 fs--1 fw-normal"
                type="submit" 
                onClick={this.handleSettingUpdate}
            >
            <IoSaveOutline className=" fs-2 me-2"/> Save Business Information
            </Button> 
        </Form.Item>  
    </Form>



{/* <Row>
    <Col lg={12}>
   
    <div className="varexe-table ">
        <div className="table-wrapper has-mobile-cards">
            <table className="table is-striped is-hoverable is-fullwidth text-black">
            <tbody>
            <tr className='p-0 '>
                <th className='sm-none m-0 p-0 w-25 pe-2 white-space-nowrap'><p className='text-end'>What is your business time zone?</p></th>
                <td data-label="What is your business time zone?" className="align-left p-0 pb-2">
                <AdvancedCountrySelect 
                    serviceType="timezones"
                    idCountry={this.state.idCountry}
                    defaultValue={this.state.dateTimeZone}
                    callBackResult={(result)=>{
                        let state = {...this.state};  
                        this.setState({
                            dateTimeZone: result.value
                        })                     
                    }}
                />

                </td>
            </tr>                 
            <tr className='p-0 '>
                <th className='sm-none m-0 p-0 w-25 pe-2 white-space-nowrap'><p className='text-end'>phone format</p></th>
                <td data-label="Company name" className="align-left p-0 pb-2">
                <Form.Select
                    name="phoneFormat"
                    // id="currency"
                    value={this.state.phoneFormat}
                    onChange={({ target }) => this.handleChange(target)}       
                    >
                    {this.state.phoneFormatList.map((param)=>{
                        return (<option value={param}>{param}</option>)
                    })}
                </Form.Select>

                </td>
            </tr> 

            <tr className='p-0'>
                <th className='sm-none m-0 p-0 w-25 pe-2 white-space-nowrap'><p className='text-end'></p></th>
                <td data-label="Contact Number" className="align-left p-0 pb-2">
                    <Button
                    size="sm"
                    className="mt-2 fs--1 fw-normal"
                    type="submit" 
                    onClick={this.handleSettingUpdate}
                    >
                    <IoSaveOutline className=" fs-2 me-2"/> Save Business Information
                    </Button> 
                </td>
            </tr>              

            </tbody>
            </table>
        </div>
    </div>
</Col>
</Row> */}


</Fragment>

: null)}


        </Fragment>);
    }
}

export default OtherFormats;