import axios from "axios";
import store from 'store';
// import logout from '../auth/logout';
/* @internal */
// import config from "../config";
// import TokenService from "./token_service";


export const getHeader = () =>  {
  let _header = { 
    'Client-Service' : 'frontend-client',
    'Auth-Key':        'varexe-api',
    'Content-Type':    'application/x-www-form-urlencoded' 
  };
  _header.Authorization = '';
  _header.AccessToken   = '';  
  _header.idUser        = 0;

  const AccessToken = store.get('AccessToken');

  if (AccessToken) {
    _header.Authorization = `Bearer ${AccessToken}`
    _header.AccessToken   = AccessToken
  }
  const idUser = store.get('idUser');
  if( idUser ) {
    _header.idUser = idUser;
  } 
  return _header;
}


class Request {
   constructor() {
       this.baseURL = process.env.REACT_APP_API_URL;
       this.isRefreshing = true;
       this.failedRequests = [];
      //  this.tokenService = new TokenService();
       this.api = axios.create({
           baseURL: process.env.REACT_APP_API_URL,
           headers: getHeader(),
       });
       this.beforeRequest = this.beforeRequest.bind(this);
       this.onRequestFailure = this.onRequestFailure.bind(this);
       this.processQueue = this.processQueue.bind(this);
       this.api.interceptors.request.use(this.beforeRequest);
       this.api.interceptors.response.use(this.onRequestSuccess, this.onRequestFailure);
}

beforeRequest(request) {
  //  const token = TokenService.getAccessToken();
   request.headers.AccessToken      = `${store.get('AccessToken')}`;
   request.headers.Authorization    = `Bearer ${store.get('AccessToken')}`;
   request.headers.idUser           = `${store.get('idUser')}`;
   return request;
}

static onRequestSuccess(response) {
   return response;
}

async onRequestFailure(err) {
   const { response } = err;
   
   if (response.status === 401 && err && err.config && !err.config.__isRetryRequest) {
    
      //  if (this.isRefreshing) {
        // console.log(response);
           try {
              // logout();
              return  response;           

              //  this.processQueue(err, null);
              //  reject(err.response);

              //  const token = await new Promise((resolve, reject) => {
              //      this.failedRequests.push({ resolve, reject });
              //  });
              //  err.config.headers.Authorization = `Bearer ${token}`;
              //  return this.api(err.config);
           }
           catch (e) {
               return e;
           }
      //  }
      //  this.isRefreshing = true;
      //  err.config.__isRetryRequest = true;
      //  return new Promise((resolve, reject) => {
      //      this.tokenService.refreshAccessToken().then((token) => {
      //          this.tokenService.setAccessToken(token);
      //          err.config.headers.Authorization = `Bearer ${token}`;
      //          this.isRefreshing = false;
      //          this.processQueue(null, token);
      //          resolve(this.api(err.config));
      //      }).catch((e) => {
      //          this.processQueue(e, null);
      //          reject(err.response);
      //      });
      //  });
   }
   throw response;
}

processQueue(error, token = null) {
   this.failedRequests.forEach((prom) => {
       if (error) {
         
           prom.reject(error);
       } else {
           prom.resolve(token);
       }
      });
       this.failedRequests = [];
   }

}

const request = new Request();

export default request.api;