import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Typography} from 'antd'
import {  Card,  Row, Col, Placeholder, Form } from 'react-bootstrap';

import Flex         from 'components/common/Flex';
import Notification from 'components/invoices/analytics/partials/Notification';
import Background   from 'components/common/Background';

/*
| Images
*/
// import ecomBg           from '../../../assets/img/illustrations/ecommerce-bg.png';
// import safebox          from '../../../assets/img/illustrations/3d/mobile-payment.png';
// import greetingsBg      from '../../../assets/img/illustrations/ticket-greetings-bg.png';
// import Payid            from '../../../assets/img/illustrations/badges/star-success.png';
// import Hold             from '../../../assets/img/illustrations/badges/hold-warning.png';


import InvoiceService        from 'components/invoices/services';

import {capitalize, formatCurrency} from 'helpers/utils'

class  TodayAnalytics extends Component {

  constructor(props) {
    super(props);  

      this.state = {
        isLoading: true,
        totalPaid: 0.00,
        totalCreatedBill: 0.00,
        notifications: [],
        currencySymbol : '$',
        currencyCode   : 'CAD',
        dateTimeZone : '',
        currentDateTime : '',
        calRange : 1,
        calRangeName : 'Today',
      }



  }

  componentDidMount(){ 
    this.getData();
  } 


 getData = () => {
    InvoiceService.getTodayAnalytics().then( (response) => {
      try {
        if( response.data !==  undefined && response.data.data !==  undefined )
        {        
          // totalCreatedBill
          let result = response.data.data;
          var state = this.state ;
          state.totalPaid = result.todayAnalytics.totalPaid;
          state.totalCreatedBill = result.todayAnalytics.totalCreatedBill;
          state.currencySymbol = result.currencySymbol;
          state.currencyCode    = result.currencyCode;
          state.dateTimeZone = result.dateTimeZone;
          state.currentDateTime = result.currentDateTime;
          state.isLoading = false;
          this.setState(state);
        }      


        var now = new Date(new Date().toLocaleString(undefined, {timeZone: state.dateTimeZone})  );
        var seconds = now.getSeconds();
        var minutes = now.getMinutes();
        var hours = now.getHours() % 12;

    } catch(error){
      console.log(error);
    }
   });
  }

render() {

  return (
    <Card className="  overflow-hidden mb-1  p-0"> 
    {(this.state.isLoading ? 
      <Fragment>
        <Card.Body className="">
          <Placeholder as={Card.Title} animation="glow" className='mb-2'>
          <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          </Placeholder> 
        </Card.Body>
      </Fragment>
      : 
      <Fragment>

        <Row className='mt-0 mb-0 gx-0 '>
        
            <Flex className="">
            <div className="pt-3 pb-3 flex-fill  border-end">
                <div className="ms-2">
                    <h6 className="mb-0 fs--1">{capitalize(this.state.calRangeName)}'s Total Invoices</h6>
                    <h4 className=" mb-0">{formatCurrency(this.state.totalCreatedBill.amount, this.state.currencySymbol)} <span className='fs-0 text-400'>{this.state.currencyCode}</span></h4>
                </div>            
            </div>
            <div className="pt-3 pb-3 flex-fill  ">
                <div className="ms-2">
                    <h6 className="mb-0 fs--1">{capitalize(this.state.calRangeName)}'s received payments</h6>
                    <h4 className=" mb-0">{formatCurrency(this.state.totalPaid.amount, this.state.currencySymbol)} <span className='fs-0 text-400'>{this.state.currencyCode}</span></h4>
                </div>            
            </div>
            </Flex>

        </Row>

      </Fragment>
      )}
    </Card>
    )
};
}


export default TodayAnalytics;
