import React, { Fragment, useContext } from 'react';
import classNames from 'classnames';

import { Switch, Space } from 'antd';
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*
| Pages
*/

import { navbarBreakPoint } from '../../../config';
import AppContext from '../../../context/Context';

/*
| ICONS
*/

import { BsSun } from "react-icons/bs";

const ToggleThemDark = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);


  const onChange = (checked) => {
    setConfig('isDark', checked)
  };

  return (
        // <Nav.Link
        // className="px-2 theme-control-toggle"
        // onClick={() => setConfig('isDark', !isDark)}
        // >
        // <OverlayTrigger
        // key="left"
        // placement={isRTL ? 'bottom' : 'left'}
        // overlay={
        //     <Tooltip id="ThemeColor">
        //     {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
        //     </Tooltip>
        // }
        // >
        // <div className="theme-control-toggle-label">
        //     <FontAwesomeIcon
        //     icon={isDark ? 'sun' : 'moon'}
        //     className="fs-0"
        //     />
        // </div>
        // </OverlayTrigger>
        // </Nav.Link>
        <Fragment>
            <Space className=''>
                <Switch size="large" checkedChildren="Dark" unCheckedChildren={'Light'} onChange={onChange} defaultChecked={isDark}  />
            </Space>
        </Fragment>
  );
};

export default ToggleThemDark;