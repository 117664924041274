import React from 'react';
import PropTypes from 'prop-types';

/*
| UI
*/

import { Alert } from 'antd';
import Marquee from 'react-fast-marquee';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Flex from '../../../common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createMarkup from 'helpers/createMarkup';
import { Link } from 'react-router-dom';

import Lottie from 'react-lottie';
import dotBeatWarning from '../../../../assets/img/animated-icons/dot-beat-warning.json';
import dotBeatDanger from '../../../../assets/img/animated-icons/dot-beat-danger.json';

const dotBeatWarningOptions = {
  loop: true,
  autoplay: true,
  animationData: dotBeatWarning,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}; 

const dotBeatDangerOptions = {
  loop: true,
  autoplay: true,
  animationData: dotBeatDanger,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Notification = ({ notification: { title, linkTitle, linkTo, type }, isLast }) => {
 
  return (
    <li className='mb-2'>

<Alert
    
    type={(type == 'danger' ? 'error' : type)}
    showIcon 
    // message={
    //   <Marquee pauseOnHover gradient={false}>
    //     {title}
    //   </Marquee>
    // }
    message={title}
  />      

    </li>
  );
};

Notification.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string.isRequired,
    // linkFor: PropTypes.string.isRequired,
    type: PropTypes.string
  }),
  isLast: PropTypes.bool.isRequired
};

export default Notification;
