
  import React , { Fragment } from 'react';
//   import {  Select } from 'reactstrap';
import PropTypes from 'prop-types'
//   import { Card, InputGroup, Button,  Col, Row, Form, Tab, Nav } from 'react-bootstrap';
//// NEW

    import { Divider, Space, Form, Checkbox,  Input, Radio, Switch, Button, Typography, Segmented, Modal , Select, Col , Card, Row, Skeleton, notification, Alert   } from 'antd';
    import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
//   import { } from 'react-bootstrap';
//// NEW


  import classnames         from 'classnames';
  import { Link, Redirect } from 'react-router-dom';
  import FormGroupInput   from '../../common/FormGroupInput';
  import ButtonIcon       from '../../common/ButtonIcon';
  import FalconCardHeader from '../../common/FalconCardHeader';
  import Loader           from '../../common/Loader';
  import ToastVarexe      from '../../common/ToastVarexe';

  import moment from 'moment';
/*
| Header
*/
import Flex              from '../../common/Flex';
import ButtonIconTooltip from '../../common/ButtonIconTooltip';
import AppContext        from '../../../context/Context';

/*
| Multiple contacts
*/
import CustomerContactNumber        from './partials/CustomerContactNumber';
import CustomerEmailAddress         from './partials/CustomerEmailAddress';
import CustomerWebPage         from './partials/CustomerWebPage';

import AdvancedCountrySelect        from '../../countries/components/AdvancedCountrySelect';
import CountryChangeOnModal         from '../../countries/components/CountryChangeOnModal';
  



import DatePicker from 'react-datepicker';
import SimpleBarReact from 'simplebar-react';

/*
| Utils
*/

import {isIterableArray} from '../../../helpers/utils'

/*
| Service
*/
import CustomerService  from '../services/';

class CustomerForm extends React.Component {

    static defaultProps = {
        idClient : -1,
        idMain   : -1,
        segment  : 'general',
    }


    constructor(props) {
        super(props)
        this.state = {
            client: {},
            idMain: this.props.idMain,
            loading: true,
            segment :  this.props.segment,
            displayBillingAccount  : false,
            displayShippingAccount : false,
        }
    }


    componentDidMount(){  

        this.getPageData()
    }

    getPageData = () => {
        CustomerService.getCustomerById(this.props.idClient).then((res) =>{
        try{
            if( res.data !== undefined ){
            if( res.data ){
                let customer = res.data;
                let _state   = this.state;

                _state.client = customer;
                _state.loading   = false;
                
                this.setState(_state);          
            }
            }
            } catch(error){
            console.log(error);
            } 
    
            });         
    }


/*
| Change handles
*/

handleClientOnChange  = (_targerType , target) => {
    let _state   = this.state;
    _state.client[target.name] = target.value;
    this.setState(_state); 
  }
  


  handleCoordinateUpdating = (_targerType, name, value) => {
    let i = 0;

    let _state   = this.state.client;
  
    _state.coordinates = _state.coordinates.map((item, index) => { 
        if (i !== index) return item; 
        return { ...item, [name]: value };
      });

    this.setState(_state);    
  }

  handleCoordinateChange = ( _targerType ) => (event) => {
    const name  = event.target.name;
    const value = event.target.value;
    this.handleCoordinateUpdating(_targerType, name, value)
  }
  
  
  handleCustomerCountry = (name, value) => {

    this.handleCoordinateUpdating(this.props.target, name, value)
  }

  
  
  handleCompanyUpdating = (_targerType, name, value) => {
    let i = 0;
    let _state   = this.state.client;
    _state.clientCompanies = _state.clientCompanies.map((item, index) => { 
        if (i !== index) return item; 
        return { ...item, [name]: value };
      });
     this.setState(_state);
  
  }
  
  handleCompanyChangeDropDown = ( _targerType, data ) => {
    this.handleCompanyUpdating(_targerType, data.name, data.value)
  } 
  
  handleCompanyChange = ( _targerType ) => (event) => {
    
    const name  = event.target.name;
    const value = event.target.value;
    
    this.handleCompanyUpdating(_targerType, name, value)
  }
  
  
  
  
  
  handleCustomerContactNumberChange = ( _targerType , value) => {
  
    let _state   = this.state.client;
    _state.clientContactNumbers = value;
  
    this.setState(_state);

  }
  
  
  handleCustomerEmailAddressChange = ( _targerType , value) => {
    
    let _state   = this.state.client;
    _state.clientEmailAddresses = value;  
    this.setState(_state);

  }
  handleCustomerWebPageChange = ( _targerType , value) => {
    
    let _state   = this.state.client;
    _state.clientWebPages = value;   
    this.setState(_state);

  }


  handleClientIsCompany = (checked) => {
    
    let _state   = this.state.client;
    _state.isCompany = checked;   
    this.setState(_state);
    
  }

  handleCustomerPrimaryUpdate = event => {
    event.preventDefault();
    // idState
    // idCity
    // console.log();
    if(!this.state.client.firstName)
    {
            notification.open({
              placement: 'top',
              type: 'error',
              message: 'Can not submit the form',
              description: 'Please enter the customer name. This field cannot be left empty.',
                   
              onClick: () => {
                console.log('Notification Clicked!');
              },
     });
 
    } else if(
            this.state.client.coordinates[0].idCountry  == 0
        ||  this.state.client.coordinates[0].idState    == 0
        ||  this.state.client.coordinates[0].idCity     == 0
    ){

        notification.open({
            placement: 'top',
            type: 'error',
            message: 'Can not submit the form',
            description: 'Please choose (Country, State, City) location',
                 
            onClick: () => {
              console.log('Notification Clicked!');
            },
   });

    } else {
    CustomerService.updateCustomer(this.state.client.idClient,this.state.client).then((res) =>{
        
        let result = res.data;
        if( result.status == 200 )
        {
            // ToastVarexe({
            //         type :  'success',
            //         message: result.message
            //     });      
            this.props.customerFormCallBack(result.data);        
        } else {
            notification.open({
                placement: 'top',
                type: 'error',
                message: 'Something went wrong!',
                description: 'Please trye again',
       });            
        }
    });
  }
}  

handleCustomerBillingUpdate = event => {
    event.preventDefault();
    if(!this.state.client.firstName)
    {
            notification.open({
              placement: 'top',
              type: 'error',
              message: 'Can not submit the form',
              description: 'Please enter the billing name. This field cannot be left empty.',
                   
              onClick: () => {
                console.log('Notification Clicked!');
              },
     });
 
    } else {    
    CustomerService.updateBilling(this.state.idMain,this.state.client).then((res) =>{
        
        let result = res.data;
        if( result.status == 200 )
        {
            ToastVarexe({
                    type :  'success',
                    message: result.message
                });      
        } 
        this.props.customerFormCallBack(result.data); 
                
    });
    }
} 

handleCustomerShippingUpdate = event => {
    event.preventDefault();
    if(!this.state.client.firstName)
    {
            notification.open({
              placement: 'top',
              type: 'error',
              message: 'Can not submit the form',
              description: 'Please enter the shipping name. This field cannot be left empty.',
                   
              onClick: () => {
                console.log('Notification Clicked!');
              },
     });
 
    } else {    
    CustomerService.updateShipping(this.state.idMain,this.state.client).then((res) =>{
        
        let result = res.data;
        if( result.status == 200 )
        {
            ToastVarexe({
                    type :  'success',
                    message: result.message
                });      
        } 
        this.props.customerFormCallBack(result.data); 
                
    });
    }
} 




    render(){
        
        const { isRTL } = this.context;        
        const {
            firstName,
            lastName,
            email, 
            mobile,   
            note,
            type,                 
            isCompany,                 
            clientContactNumbers,
            clientEmailAddresses,
            clientWebPages,
            coordinates,
            clientCompanies,
            gender,
            dateOfBirth
          } = this.state.client;
        return (
            <Fragment>
    {(this.state.loading ?

<Col style={{ maxWidth: 600, margin:'auto' }}>
<Skeleton active paragraph={{ rows: 7 }} />
<Divider orientation="left"></Divider>
<Skeleton active paragraph={{ rows: 7 }} />
</Col>  
    
:                 


                <Fragment>
                    <Form
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    layout="horizontal"
                    disabled={false}
                    style={{ maxWidth: 600, margin:'auto' }}
                    // requiredMark={'optional'}
                    size='large'
                    >
                    <Divider plain orientation="left">
                        {( this.props.target == 'main' || this.props.target == 'mainAccount' ? 'Primary Contact' : null )}
                        {( this.props.target == 'billingAccount' ? 'Billing Information' : null )}
                        {( this.props.target == 'shippingAccount' ? 'Shipping Information' : null )}
                    </Divider>

                    <Form.Item
                        label="First Name"
                        name="firstName"
                        onChange={({ target }) => this.handleClientOnChange(this.props.target, target)}               
                    >
                    <Input name="firstName" defaultValue={firstName}  />             
                    </Form.Item>    
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        onChange={({ target }) => this.handleClientOnChange(this.props.target, target)}               
                    >
                    <Input name="lastName" defaultValue={lastName}  />             
                    </Form.Item>                 


                    <Form.Item name="gender" label="Gender" 
                    
                    >

                        <Radio.Group 
                        name="gender"
                        defaultValue={gender}
                        onChange={( { target } ) => this.handleClientOnChange(this.props.target, target)}
                        >
                            <Radio value={"male"}>male</Radio>
                            <Radio value={"female"}>female</Radio>
                            <Radio value={"other"}>other</Radio>
                        </Radio.Group>
                    </Form.Item>                    

                    <Divider plain orientation="left">Contact Numbers</Divider>

                     <CustomerContactNumber 
                        subHandleCustomerContactNumberChange = {this.handleCustomerContactNumberChange} 
                        clientContactNumbers = {clientContactNumbers} 
                        target = {this.props.target}
                        /> 




                    <Divider plain orientation="left">Email Addresses</Divider>

                    <CustomerEmailAddress 
                        subHandleCustomerEmailAddressChange = {this.handleCustomerEmailAddressChange} 
                        clientEmailAddresses = {clientEmailAddresses} 
                        target = {this.props.target}
                        />              


                    <Divider plain orientation="left">Address</Divider>      

                    {(isIterableArray(coordinates) && coordinates.map((coordinate, index)=>
                    <Fragment>
                        <Form.Item
                            label="Country"           
                        >
                            <CountryChangeOnModal  idCountry={coordinate.idCountry} idState={coordinate.idState} idCity={coordinate.idCity} handleCountryChange={this.handleCustomerCountry} />
                        </Form.Item>  

                        <Form.Item
                            label="Address Line 1"
                            name="addressLine1"
                            onChange={this.handleCoordinateChange(this.props.target)}              
                        >
                        <Input name="addressLine1" defaultValue={coordinate.addressLine1}  />             
                        </Form.Item>

                        <Form.Item
                            label="Address Line 2"
                            name="addressLine2"
                            onChange={this.handleCoordinateChange(this.props.target)}              
                        >
                        <Input name="addressLine2" defaultValue={coordinate.addressLine2}  />             
                        </Form.Item>

                        {/* <Form.Item
                            label="Unit"
                            name="unit"
                            onChange={this.handleCoordinateChange(this.props.target)}              
                        >
                        <Input name="unit" defaultValue={coordinate.unit}  />             
                        </Form.Item> 

                        <Form.Item
                            label="Street Number"
                            name="apartment"
                            onChange={this.handleCoordinateChange(this.props.target)}               
                        >
                        <Input name="apartment" defaultValue={coordinate.apartment}  />             
                        </Form.Item>    
                        <Form.Item
                            label="Street Name"
                            name="street"
                            onChange={this.handleCoordinateChange(this.props.target)}               
                        >
                        <Input name="street" defaultValue={coordinate.street}  />
                        </Form.Item>                                                                                                           
                        <Form.Item
                            label="Address/Other"
                            name="address"
                            onChange={this.handleCoordinateChange(this.props.target)}               
                        >
                        <Input name="address" defaultValue={coordinate.address}  />
                        </Form.Item>                                                                                                            */}
                        <Form.Item
                            label="Postal Code"
                            name="postalCode"
                            onChange={this.handleCoordinateChange(this.props.target)}               
                        >
                        <Input name="postalCode" defaultValue={coordinate.postalCode}  />
                        </Form.Item> 


                        <Divider plain orientation="left">Company Information</Divider> 

                        <Form.Item label="Company">
                            <Switch
                                defaultChecked={(isCompany? true : false)}
                                name="isCompany"
                                checkedChildren="YES" unCheckedChildren="NO"
                                onChange={this.handleClientIsCompany}
                            />
                            <Alert className='mt-2' message="If your customer has a company name, or contacting you from a company, you can add the company information" type='info' />
                        </Form.Item>


                        {(isCompany ? 
                            
                            <Fragment>
                    <Fragment>

                        {(isIterableArray(clientCompanies) && clientCompanies.map((clientCompany, index)=>


                            
                            <Form
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            layout="horizontal"
                            disabled={false}
                            style={{ maxWidth: 600, margin:'auto' }}
                            // requiredMark={'optional'}
                            key={index}
                            size='large'
                        >

                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            onChange={this.handleCompanyChange(this.props.target)}               
                        >
                            <Input name="companyName" defaultValue={clientCompany.companyName}  />             
                        </Form.Item>                            
                        <Form.Item
                            label="Company size"
                            name="companySize" 
                        >                      
                            <Select
                                defaultValue={clientCompany.companySize}
                                options={[
                                    { value: '0', label: '0-1 employees' },
                                    { value: '2', label: '2-10 employees' },
                                    { value: '11', label: '11-50 employees' },
                                    { value: '51', label: '51-200 employees' },
                                    { value: '201', label: '201-500 employees' },
                                    { value: '501', label: '501-1,000 employees' },
                                    { value: '1001', label: '1,001-5,000 employees' },
                                    { value: '5001', label: '5,001-10,000 employees' },
                                    { value: '10001', label: '10,001+ employees' },
                                ]}
                                onChange={(value) =>{
                                    let  target = {
                                                name :'companySize',
                                                value :value,
                                            } 

                                        this.handleCompanyChangeDropDown(this.props.target, target)
                                }}
                                
                                />
                            </Form.Item>  

                        <Form.Item
                            label="Company Type"
                            name="companyType"
                                        
                        >                      
                            <Select
                                defaultValue={clientCompany.companyType}
                                allowClear
                                options={[
                                    { value: 'SELF_EMPLOYED', label: 'Self Employed' },
                                    { value: 'SELF_OWNED', label: 'Self Owned' },
                                    { value: 'PRIVATELY_HELD', label: 'Privately Held' },
                                    { value: 'PARTNERSHIP', label: 'Partnership' },
                                    { value: 'PUBLIC_COMPANY', label: 'Public Company' },
                                    { value: 'GOVERNMENT_AGENCY', label: 'Government Agency' },
                                    { value: 'EDUCATIONAL', label: 'Educational' },
                                    { value: 'NON_PROFIT', label: 'Non Profit' },
                                ]}
                                onChange={(value) =>{
                                    let  target = {
                                                name :'companyType',
                                                value :value,
                                            } 

                                        this.handleCompanyChangeDropDown(this.props.target, target)
                                }}
                                />
                        </Form.Item>  


                   

                        </Form>  
                        )

                        )}   




                            

                                                                

                        </Fragment>

                            </Fragment>
                        
                        
                        : null)}


                        <Form
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            layout="horizontal"
                            disabled={false}
                            style={{ maxWidth: 600, margin:'auto' }}
                            // requiredMark={'optional'}
                            key={index}
                            size='large'
                            >

                            <Divider plain orientation="left">Websites & Social Medias</Divider>

                                <CustomerWebPage 
                                subHandleCustomerWebPageChange = {this.handleCustomerWebPageChange} 
                                clientWebPages = {clientWebPages} 
                                target = {this.props.target}
                                /> 
                        </Form>                         

                    </Fragment>
                    ))} 

                    </Form>

                

                




  

                    <Col style={{ maxWidth: 600, margin:'auto' }}>

                        <Divider plain orientation="left">Save all your data</Divider>
                        <Form.Item>
                            <p>
                            Make sure to save the update before closing this page.
                            </p>      
                            {( this.props.target == 'main' || this.props.target == 'mainAccount' ?
                            <Button type="primary" size='default' htmlType="submit" icon={<SaveOutlined className="fs-1" />} onClick={this.handleCustomerPrimaryUpdate}>Save primary contact</Button> 
                            : null )}
                            {( this.props.target == 'billingAccount'?
                            <Button type="primary" size='default' htmlType="submit" icon={<SaveOutlined className="fs-1" />} onClick={this.handleCustomerBillingUpdate}>Save billing Information</Button> 
                            : null )}
                            {( this.props.target == 'shippingAccount'?
                            <Button type="primary" size='default' htmlType="submit" icon={<SaveOutlined className="fs-1" />} onClick={this.handleCustomerShippingUpdate}>Save shipping Information</Button> 
                            : null )}
                        </Form.Item>
                    </Col>


                    </Fragment>



)}
            </Fragment>
        )
    }

}

export default CustomerForm;