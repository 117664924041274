
import React, { Fragment } from 'react';
import TimeAgo from 'react-timeago'


import { BsCreditCard2Back } from 'react-icons/bs';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  FileDoneOutlined,
  SearchOutlined , 
  InfoCircleOutlined
} from '@ant-design/icons';
import { FcBookmark, FcHighPriority, FcClock, FcExpired, FcLeave } from "react-icons/fc";



// export const statusComponent = data => {
    
//     var d1 = new Date();
//     var d2 = new Date(data.dateDue);
  
  
//     let color = '';
//     let icon  = '';
//     let text  = '';
  
//     switch(data.status.name)
//     {
//       case 'Invoiced':
//         color=  'primary';
//         // icon = <FileDoneOutlined />;      
//         icon = 'is';      
//         text = data.status.name; 
//       break;
//       case 'Paid':
//         color =  'success';
//         icon  = <BsCreditCard2Back className='fs-0' />;
//         text  = data.status.name;
//       break; 
//       case 'Processing':
//         color =  'secondary';
//         icon  = <SyncOutlined spin />;
//         text  = data.status.name;
//       break;  
//       case 'Draft':
//         color =  'secondary';
//         // icon  = <FaRegClock className='fs-0' />;
//         icon  = 'pending';
//         // icon  = <FcClock className='fs-0' />;
  
//         if( d2.setHours(0,0,0,0) < d1.setHours(0,0,0,0) ){
//           color =  'warning';
//           icon  = <FcExpired className='fs-0'  />;
//         }
                
//         text  = data.status.name;
//       break;            
//       default:
//         color = 'default';
//         icon  = <ClockCircleOutlined />;
//         text  = data.status.name;
//       break;      
//     }
  
//     return (
//       <Fragment>
//           <li className='list-inline-item'>
//                 <div className="d-none d-md-block">
//                 {(data.isDeleted === "1" ?       
//                 <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-danger `} style={{width:'100px'}}>
//                   <span className="me-2"><ExclamationCircleOutlined  /></span> Deleted
//                 </div>                
//                 :null)}
//                 <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-${color} `} style={{width:'100px'}}>
//                   {icon} {text}
//                 </div>
//               </div> 
//                 <div className="d-block d-md-none">
//                   {(data.isDeleted === "1" ?
//                   <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-danger `} style={{width:'100px'}}>
//                     <span className="me-2"><ExclamationCircleOutlined  /></span> Deleted
//                   </div>          
//                   :null)}
//                   <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-${color}`} style={{width:'100px'}}>
//                     {icon} {text}
//                   </div>
//               </div>           
//           </li>
//       </Fragment>
//     );
//   }



export const statusEstimateComponent = data => {

  let color = '';
  let icon  = '';
  let text  = '';

  switch(data.status.name)
  {
    case 'Draft':
      color =  'secondary';
      icon  = 'pending';              
      text  = 'Estimate';
    break; 
  }
  return (
    <Fragment>
        <li className='list-inline-item'>
              <div className="d-none d-md-block">
              {(data.isDeleted ?       
              <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-danger `} style={{width:'100px'}}>
                <span className="me-2"><ExclamationCircleOutlined  /></span> Deleted
              </div>                
              :null)}
              <span className={`badge rounded-md   badge-varexe-${data.status.class}  text-${data.status.class}`} style={{width:'120px'}}>
              {text} 
              </span>              
            </div>          
        </li>
    </Fragment>
  );
}


export const statusComponent = data => {
    
  var d1 = new Date();
  var d2 = new Date(data.dateDue);


  let color = '';
  let icon  = '';
  let text  = '';

  switch(data.status.name)
  {
    case 'Invoiced':
      color=  'primary';
      // icon = <FileDoneOutlined />;      
      icon = 'is';      
      text = data.status.name; 
    break;
    case 'Paid':
      color =  'success';
      icon  = <BsCreditCard2Back className='fs-0' />;
      text  = data.status.name;
    break; 
    case 'Processing':
      color =  'secondary';
      icon  = <SyncOutlined spin />;
      text  = data.status.name;
    break;  
    case 'Draft':
      color =  'secondary';
      // icon  = <FaRegClock className='fs-0' />;
      icon  = 'pending';
      // icon  = <FcClock className='fs-0' />;

      if( d2.setHours(0,0,0,0) < d1.setHours(0,0,0,0) ){
        color =  'warning';
        icon  = <FcExpired className='fs-0'  />;
      }
              
      text  = data.status.name;
    break;            
    default:
      color = 'default';
      icon  = <ClockCircleOutlined />;
      text  = data.status.name;
    break;      
  }

  return (
    <Fragment>
        <li className='list-inline-item mb-1'>
              <div className="d-none d-md-block">
              {(data.isDeleted ?       
              <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-danger `} style={{width:'100px'}}>
                <span className="me-2"><ExclamationCircleOutlined  /></span> Deleted
              </div>                
              :null)}
              {/* <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-${color} `} style={{width:'100px'}}>
                {icon} {text} 
              </div> */}
              <span className={`badge rounded-md   badge-varexe-${data.status.class}  text-${data.status.class}`} style={{width:'120px'}}>
              {text} 
              </span>              
            </div> 
              <div className="d-block d-md-none">
                {(data.isDeleted ?
                <div className={`rounded-1 mb-2 border-0 text-center text-nunito-700 fs--1  list-inline-item  text-danger `} style={{width:'100px'}}>
                  <span className="me-2"><ExclamationCircleOutlined  /></span> Deleted
                </div>          
                :null)}
                {/* <div className={`rounded-1 mb-2  border-0 text-center text-nunito-700 fs--1  list-inline-item  text-${color}`} style={{width:'100px'}}>
                  {icon} {text}
                </div> */}
              <p className={`badge rounded-md badge-varexe-${data.status.class} text-${data.status.class} `} style={{width:'120px'}}>
              {text} 
              </p>                 
            </div>           
        </li>
    </Fragment>
  );
}

  export const dueDateComponent = (data) => {

    var d1 = new Date();
    var d2 = new Date(data.dateDue);


    let stateInvoice = '';
    let runDue  = false;
    let color     = '';
    let dateName     = '';
    let dateDisplay  = '';

    switch(data.status.name)
    {
      case 'Invoiced':
        color       =  'primary';        
        dateName    = 'Approved on'; 
        dateDisplay = data.approveDateFormat; 
        runDue = true;
      break;
      case 'Paid':
        color       =  'success';
        dateName    = 'Paid on'; 
        dateDisplay = data.paidDateFormat;
      break; 
      case 'Processing':
        color       =  'default';
        dateName    = 'Due'; 
        dateDisplay = data.dueFormat.dueDateFormat;

        runDue = true;
      break;  
      case 'Draft':

        color       =  'warning';
        dateName    = 'Due'; 
        dateDisplay = data.dueFormat.dueDateFormat;

        runDue = true;
        
        if( data.dueFormat.isDue ){
          color =  'warning';
        }
      break;            
      default:
        color       =  'default';
        dateName    = 'Due'; 
        dateDisplay = data.dueFormat.dueDateFormat;
      break;      
    }

    return (
      <Fragment>
        <div className="d-none d-md-block">
          <p className="mb-0 mt-0 fs-0">

          {( data.dueFormat.isDue && runDue  ?

                  <Fragment>
                    {/* <h6></h6> */}
                    <h6><span className='text-danger me-1'>{(data.generateType== 'invoice' ? 'Due:' : 'Expired')}</span><TimeAgo date={data.dueFormat.dueDateTime} className="text-danger" /></h6>
                  </Fragment> : 
                    
                  <Fragment>
                    {/* <h6></h6> */}
                    <h6><span className='me-1'>{dateName}</span>{dateDisplay}</h6>
                  </Fragment>
            )}     
            
            
          </p>
        </div>    
        <div className="d-block d-md-none">
          <p className="mb-0 mt-0 fs--1">
          {( data.dueFormat.isDue && runDue  ?
                  <Fragment>
                  {/* <h6><span className='text-danger me-1'>{(data.generateType== 'invoice' ? 'Due:' : 'Expired')}</span></h6> */}
                  <h6><span className='text-danger me-1'>{(data.generateType== 'invoice' ? 'Due:' : 'Expired')}</span><TimeAgo date={data.dueFormat.dueDateTime} className="text-danger" /></h6>
                </Fragment>            
            
            : 
            
            <Fragment>
            {/* <h6><span className='me-1'>{dateName}</span></h6> */}
            <h6><span className='me-1'>{dateName}</span>{dateDisplay}</h6>
          </Fragment>            
          )}
        </p>
            {(data.isDeleted === "1" ?
            <></>
            // <SoftBadge className="me-2" bg={'danger'}  >
            // <span className="me-2"><ExclamationCircleOutlined  /></span> Deleted
            // </SoftBadge>         
            :null)}
            {/* <SoftBadge className="me-2" icon={icon} bg={color}>
            {icon} {text}
            </SoftBadge> */}
        </div>
      </Fragment>
    );
  }; 