export const version            = '3.4.4';
export const navbarBreakPoint   = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
// export const settings = {
//   isFluid: true,
//   isRTL: false,
//   isDark: false,
//   isTopNav: false,
//   isVertical: true,
//   get isCombo() {
//     return this.isVertical && this.isTopNav;
//   },
//   showBurgerMenu: false, // controls showing vertical nav on mobile
//   currency: '$',
//   isNavbarVerticalCollapsed: false,
//   navbarStyle: 'transparent'
// };

export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  // navbarPosition: 'combo', // top vertical combo
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent', // transparent vibrant inverted card
  showNavbarSecondary: false,
  NavbarSecondaryContent: []
};
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
