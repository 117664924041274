import React, { useState } from 'react';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import {chooseHeader}    from "./pdfPartials/headers/Headers";
import {chooseRecipient} from "./pdfPartials/recipients/Recipients";
import {chooseItems} from "./pdfPartials/body/LineItems";
import {chooseSpecifications} from "./pdfPartials/body/Specifications";
import {chooseSummary} from "./pdfPartials/body/Summary";
import {chooseTerms} from "./pdfPartials/body/Terms";

import {capitalize} from '../../../helpers/utils'

{/*
| https://github.com/Aymkdn/html-to-pdfmake
*/}
import htmlToPdfmake from "html-to-pdfmake"


class pdfExport {


  constructor() {


      this.docDefinition = {
        template: "temp1",
        backgroundColor: "#0069D9",
        watermark: {
          text: "varexe",
          opacity: 0.1,
          bold: false,
          italics: false,
        },
        pageSize: "A4",
        pageOrientation: "portrait",
        
        // pageOrientation: "landscape",
      };
  }



  static setSettings = (docDefinition) => {  
    this.docDefinition = docDefinition;
  };
  static setDynamicSettings = (type , data) => {

    if( type ==  'docDefinition')
    {
      this.docDefinition = data;
      
    }  

  }

  static downloadInvoice = (obj) => {
    this.setSettings(obj.configuration.designSetting);
    let docDefinition = this.getTemplateWithDef(obj);
    pdfMake.createPdf(docDefinition).download("Invoice No#" + obj.number);
  };

  static openInvoice = (obj) => {
    this.setSettings(obj.configuration.designSetting);
    let docDefinition = this.getTemplateWithDef(obj);
    pdfMake.createPdf(docDefinition).open();
  };

  static printInvoice = (obj) => {
    this.setSettings(obj.configuration.designSetting);
    let docDefinition = this.getTemplateWithDef(obj);
    pdfMake.createPdf(docDefinition).print();
  };





   static openBase64 =  async (obj) => {
    let base64;
    // this.setSettings(obj.configuration.designSetting);
      let docDefinition = this.getTemplateWithDef(obj);
    
      const callBack = new Promise((resolve, reject) => { 
        const condition = true;   
        if(condition) {
          pdfMake.createPdf(docDefinition).getBase64( function(encodedString) {
                resolve(encodedString);
                
            }.bind(this));
        } else {    
            reject('Promise is rejected!');  
        }
    });

    return callBack;

  };

  static getCallBack = (obj) => {

    this.setSettings(obj.configuration.designSetting);
    let docDefinition = this.getTemplateWithDef(obj);
    return pdfMake.createPdf(docDefinition);
  };  

  // static getSubtotal = (items) =>
  //   items
  //     .reduce((sum, item) =>  Number(item.total), 0)
  //     .toFixed(2);

  // static getTotal = (items, discount, shipping) => {

  //   return (
  //     this.getSubtotal(items) -
  //     Number(discount || 0) +
  //     Number(shipping || 0)
  //   ).toFixed(2);

  // }



 

static invoiceInfo = (obj) => ({ 
title:    obj.numberTitle+": " +obj.prefix+ obj.number,
author:   "varexe",
subject:  obj.numberTitle+": " +obj.prefix+ obj.number,
keywords: obj.numberTitle+": " +obj.prefix+ obj.number,
})



  static getTemplateWithDef = (obj) => {
    let docDef = null;
    let __choose = {
      chooseHeader    : 1,
      chooseRecipient : 1,
      chooseItems     : 1,
      _chooseSpecifications : 1,
      chooseTerms     : 1,
      chooseSummary    : 1,
    }
    // this.docDefinition.template = "temp3";
    switch (this.docDefinition.template) {
      case "temp1":

        __choose = {
          _chooseHeader    : 1,
          _chooseRecipient : 1,
          _chooseItems     : 1,
          _chooseSpecifications : 1,
          _chooseTerms     : 1,
          _chooseSummary   : 1,
        }


        docDef = this.generateTemp1(obj, __choose);
        break;

        case "temp2":

          __choose = {
            _chooseHeader    : 4,
            _chooseRecipient : 1,
            _chooseItems     : 1,
            _chooseSpecifications : 1,
            _chooseTerms     : 1,
            _chooseSummary   : 1,
          }
  
  
          docDef = this.generateTemp1(obj, __choose);
          break;

        case "temp3":

          __choose = {
            _chooseHeader         : 5,
            _chooseRecipient      : 1,
            _chooseItems          : 1,
            _chooseSpecifications : 1,
            _chooseTerms          : 1,
            _chooseSummary        : 1,
          }
  
  
          docDef = this.generateTemp1(obj, __choose);
          break;



      default:
        docDef = this.generateTemp1(obj, __choose);
        break;
    }
    return docDef;
  };

  static generateTemp1 = (obj, _choose) => {

    const {
      backgroundColor,
      watermark,
      pageSize,
      pageOrientation,
    } = this.docDefinition;

    var docDefinition = {
      pageSize: pageSize,
      pageOrientation: pageOrientation,
      watermark: {
        text: this.docDefinition.watermark.text,
        color: backgroundColor,
        opacity: Number(watermark.opacity),
        bold: watermark.bold,
        italics: watermark.italics,
      },
      // pageMargins : [40, 80, 40, 60],
      info:   this.invoiceInfo(obj),
      footer: function (currentPage, pageCount, pageSize){ 
        return [        
              {                  
                  canvas: [
                        {
                          type: "rect", x: 0, y: 0, w: pageSize.width, h: 5,
                          color: backgroundColor,
                        },
                  ],
              },
                  {
                    columns: [
                          {
                            // auto-sized columns have their widths based on their content
                            width: 'auto',
                            text: 'Powered by Varexe', 
                            link: 'https://varexe.com/',
                            color: '#9DA9BB' ,
                          },
                          {
                            alignment: "right",
                            width: '*',
                            text: "Page " + currentPage.toString() + " of " + pageCount + "   ",
                          }
                    ],
                        // optional space between columns
                        columnGap: 0,
                        margin: [40, 10, 40, 40],
                      
                  }
                ]
              },
      header: function (currentPage, pageCount, pageSize) {
        return [
          {
            canvas: [ { type: "rect", x: 0, y: 10, w: pageSize.width, h: 5, color: backgroundColor } ],
          },
        ];
      },
      content: [
        chooseHeader(_choose._chooseHeader, obj),
        chooseRecipient(_choose._chooseRecipient,obj),  
        chooseItems(_choose._chooseItems, obj),  
        chooseSpecifications(_choose._chooseSpecifications, obj),        
        {
          style : "tableExample2",
          table: {
            widths: ['50%', '50%'],
            
            headerRows: 1,
            keepWithHeaderRows: 1,
            body : [
              [
                {
                  table:{
                    
                    widths: ['*'],
                    body: [{}],
                  },
                  layout: 'noBorders',
                },                
                {
                  table:{
                    
                    widths: ['*'],
                    body: chooseSummary(_choose._chooseSummary, obj),
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          
          layout: 'noBorders',
        },
        {
          style : "tableExample2",
          table: {
            widths: ['*'],
            
            headerRows: 1,
            keepWithHeaderRows: 1,
            body : [
              [
                {
                  table:{

                    body : chooseTerms(_choose._chooseTerms, obj)
                  },
                  layout: 'noBorders',
                },
              ],
            ],
          },
          
          layout: 'noBorders',
        }
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        CompanyAddressInfo: {
          margin: [0, 5, 0, 15],
        },        
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
      defaultStyle: {
        fontSize: 10,
        columnGap: 15,
      },
    };

    return docDefinition;
  };












}

export default pdfExport;
