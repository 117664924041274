
import api from '../../../services/api/';
const URL = '/invoices/';

class InvoiceService {

    // getInvoice(listPageIndex = null, listPageSize = null, listSort = null, listOrder = null, listSearch = null, isDeleted = null, idStatus = null ){
    getInvoice(data){
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + JSON.stringify(data.listFilters));
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }                         
         if( data.statusTag != undefined && data.statusTag != null )
         {
            params.push( "idStatus=" + data.statusTag );
         }                         
         if( data.generateType != undefined && data.generateType != null )
         {
            params.push( "generateType=" + data.generateType );
         }                         
        return api.get(URL + 'list/' + (params.length ? '?' + params.join('&') : '' ));
    }

    
    getListFiltersInvoice(data){
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listReactTableSearch != undefined && data.listReactTableSearch != null )
         {
            params.push( "listReactTableSearch=" + encodeURIComponent(JSON.stringify(data.listReactTableSearch)));
         }                 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + encodeURIComponent(JSON.stringify(data.listFilters)));
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }                         
         if( data.statusTag != undefined && data.statusTag != null )
         {
            params.push( "idStatus=" + data.statusTag );
         }    
         if( data.idClient != undefined && data.idClient != null )
         {
            params.push( "idClient=" + data.idClient );
         }                               
         if( data.generateType != undefined && data.generateType != null )
         {
            params.push( "generateType=" + data.generateType );
         }                         
        return api.get(URL + 'list_filters/' + (params.length ? '?' + params.join('&') : '' ));
    }

    createInvoice(invoice){
        return api.post(URL, invoice);
    }

    getInvoiceById(idInvoice, generateType = 'invoice'){
        return api.get(URL + 'detail/' + idInvoice + '/?generateType=' + generateType);
            
    }

    updateInvoice( idInvoice, invoice){
        return api.put(URL + idInvoice, invoice);
    }

    updateInvoiceStatus( idInvoice, data){
        return api.put(URL + 'status/' + idInvoice, data);
    }
    updateInvoiceApproved( idInvoice){
        return api.put(URL + 'invoiceapproved/?idInvoice=' + idInvoice);
    }

  
    
    


    updateIncNotifications(idInvoice, data){
        return api.put(URL + 'incnotifications/' + idInvoice, data);
    }
    

    deleteInvoice(data){
        return api.post(URL + "/archiveInvoice" , data);
    }

    // updateIncTag(idInvoice, data){
    //     return api.put(URL + '/updateIncTag/'+ idInvoice, data);
    // }
    // deleteIncTag(idTag, data){
    //     return api.put(URL + 'deleteIncTag/' + idTag);
    // }
    
    getSetting(){
        return api.get(URL + 'setting' );
    }

    updateSetting(params){
        return api.put(URL + 'setting', params);
        
    }

    getDesign(){
        return api.get(URL + 'design' );
    }    
    updateDesign(params){
        return api.put(URL + 'design', params);
        
    }



    

    settinglogo(formData){
        return api.post(URL + "/settinglogo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
    }  
    
    getINCCustomerBySearch(str){
        return api.get(URL + 'clientSearch/' + str);
    }  
    
    getCustomerById(str){
        return api.get(URL + 'clientById/' + str);
    }  
    
    getCustomerInvoiceSummeryById(idClient) {
        return api.get(URL + 'getCustomerInvoiceSummeryById/' + idClient);
    }


    getInvoiceListOverview(){
        return api.get(URL + 'invoiceListOverview' );
    }
    getChartDayStatus(){
        return api.get(URL + 'getChartDayStatus' );
    }

    getAnalyticsGreeting(){
        return api.get(URL + 'getAnalyticsGreeting' );
    }
    getTodayAnalytics(){
        return api.get(URL + 'getTodayAnalytics' );
    }
    
    getInvoiceAnalyticsByMonths(month){
        return api.get(URL + 'get_invoice_analytics_by_months');
    }
    getAnalyticsByNumberOfdays(month){
        return api.get(URL + 'getAnalyticsByNumberOfdays?month='+ month );
    }


    getChartInvoicesStatus(){
        return api.get(URL + 'getChartInvoicesStatus');
    }

    getAnalyticsInvoicesProcessing(data){
        const  params = [];
        if( data.calRange != undefined && data.calRange != null )
         {
            params.push( "calRange=" + data.calRange );
         }        
        if( data.idClient != undefined && data.idClient != null )
         {
            params.push( "idClient=" + data.idClient );
         }
        return api.get(URL + 'getAnalyticsInvoicesProcessing/' + (params.length ? '?' + params.join('&') : '' ));
    }  
    getAnalyticsInvoicesGrossAndDue(data){

        const  params = [];       
        if( data.idClient != undefined && data.idClient != null )
         {
            params.push( "idClient=" + data.idClient );
         }

        return api.get(URL + 'getAnalyticsInvoicesGrossAndDue/' + (params.length ? '?' + params.join('&') : '' ));
    }  
    getNumberOfInvoicesByFilter(data){

        const  params = [];       
        if( data.idClient != undefined && data.idClient != null )
         {
            params.push( "idClient=" + data.idClient );
         }
        if( data.statusTag != undefined && data.statusTag != null )
         {
            params.push( "statusTag=" + data.statusTag );
         }

        return api.get(URL + 'getNumberOfInvoicesByFilter/' + (params.length ? '?' + params.join('&') : '' ));
    }  

    getChartInvoicesGrossAndTax(){
        // ?idClient=' + idClient
        return api.get(URL + 'getChartInvoicesGrossAndTax');
    }  
    
    setScheduleSender(data){
        return api.post(URL + "/scheduleSender", data)
    }
    getScheduleSender(idInvoice){
        return api.get(URL + "/scheduleSender/" + idInvoice)
    }

    setSendQuotation(data){
        return api.post(URL + "/sendQuotation/" , data)
    }   


    getSendQuotation(idInvoice){
        return api.get(URL + "/sendQuotation/" + idInvoice)
    }
    
    setSendEstimate(data){
        return api.post(URL + "/sendEstimate/" , data)
    }   


    getSendEstimate(idInvoice){
        return api.get(URL + "/sendEstimate/" + idInvoice)
    }     
    
    setSendInvoice(data){
        return api.post(URL + "/sendInvoice/" , data)
    }   
    getSendInvoice(idInvoice){
        return api.get(URL + "/sendInvoice/" + idInvoice)
    }     
    setSendInvoicePayment(data){
        return api.post(URL + "/sendInvoicePayment/" , data)
    }   
    getSendInvoicePayment(idInvoice){
        return api.get(URL + "/sendInvoicePayment/" + idInvoice)
    }     

    getInvoiceTemplates(){
        return api.get(URL + "/invoiceTemplates/")
    }



    /*
    | Manual; payment intent for Stripe
    */
    
   setPaymentIntent(data){
       return api.post(URL + "/stripe_manual_payment_intent/" , data)
    } 
    getPartialOnlinePaymentPending(idInvoice){
        return api.get(URL + "/stripe_manual_payment_intent/" + idInvoice)
    } 
    setSendPartialPaymentLink(data){
        return api.post(URL + "/sendPartialPaymentLink/" , data)
    } 
    /*
    | Manual; Payment
    */
    
    setRecordManualPayment(data){
        return api.post(URL + "/recordManualPayment/" , data)
    } 
    getRecordManualPayment(idInvoice){
        return api.get(URL + "/recordManualPayment/" + idInvoice)
    } 
    
    setDeleteManualPayment(data){
        return api.post(URL + "/deleteManualPayment/" , data)
    } 


    /*
    | Estimate
    */
    convertEstimateToInvoice(data){
        return api.post(URL + "/convertEstimateToInvoice/" , data)
    } 
    
    



    



    /*
    | REPORTS
    */

        

    getPaymentReport(data){
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + JSON.stringify(data.listFilters));
         }                                         
         if( data.idStatus != undefined && data.idStatus != null )
         {
            params.push( "idStatus=" + data.idStatus );
         }                         
                         
        return api.get(URL + 'listPaymentReport/' + (params.length ? '?' + params.join('&') : '' ));
    }





    getCustomInvoicePDF(data){
        // return api.post( "https://api.varexe.com/sharepoint/getCustomInvoice" , data)
        // return api.get( "https://api.varexe.com/sharepoint/test")

        const  params = [];
        if( data.publicParams != undefined && data.publicParams != null )
         {
            params.push( "p=" + data.publicParams );
         }
        if( data.publicUuid != undefined && data.publicUuid != null )
         {
            params.push( "uuid=" + data.publicUuid );
         }

        return fetch('https://api.varexe.com/sharepoint/invoice_pdf' + (params.length ? '?' + params.join('&') : '' ))
        .then((response) => response.blob())
        .then((response) => {
            return response;
        });


    } 





}

export default new InvoiceService()