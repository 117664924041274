import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Flex                       from '../common/Flex';
// import logo from '../../assets/img/illustrations/falcon.png';
// import logo from '../../assets/img/varexe/logo-light.svg';
import logo from '../../assets/img/varexe/varexe-logo-2.png';
import VarexeLogo from 'components/common/VarexeLogo';

const Logo = ({ at, width, className, textClass, ...rest }) => {
  return (
    <Fragment>
      <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
      >
        <table className=''>
          <tr>
            <td width={width}>
              {/* <img className="" src={logo} alt="Logo" width={width} height={width} /> */}
              <VarexeLogo type={'svg'} style={{width:'25px'}}  className='fs-1'/>
            </td>
            <td>
              <h6 className="mb-0 d-none d-sm-block"> <span className='fs-0 '>varexe</span> </h6>
              <h6 className="fs--2 mb-0 d-xs-none d-none d-sm-block">Accounting Software</h6>              
            </td>
          </tr>
        </table>

      </Link>      
    </Fragment>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
