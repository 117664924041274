import React , { Fragment } from 'react';
import { connect } from 'react-redux'
/*
| UI
*/
import { Divider, Space,  Radio, Button, Typography, Tooltip, Tag    } from 'antd';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IoAddOutline } from 'react-icons/io5';
import { BsPlusSquareDotted } from "react-icons/bs";
import { PiTrashDuotone } from "react-icons/pi";
import ButtonIcon       from '../../../common/ButtonIcon';
import {

    Card,
    Form,
    FormControl,
    InputGroup,
    Table
  } from 'react-bootstrap';
  
  import { RiDeleteBinLine } from 'react-icons/ri';
  import SimpleBarReact from 'simplebar-react';
  import store from 'store';
  import InputMask from "react-input-mask";

/*
| PAGES
*/

import CustomerService from '../../services/index'


  const mapStateToProps = ({ settings }) => ({
    settings : settings
  })

class CustomerContactNumber extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // client: this.props,
            // loading: true,
            target: this.props.target,
            targerType: this.props.targerType,
            items: this.props.clientContactNumbers,
            defaultContactNames : [],
            idOthers : null 

        }

        if(this.state.items.length == 0)
        {
          this.handleAddItem();
        }

    }

    componentDidMount = () =>{
      this.getUniqueContactNames();
    }


    getUniqueContactNames = () => {
      CustomerService.getUniqueContactNames().then((res) =>{
            
        let result = res.data;

        this.setState({
          defaultContactNames : result.data
        })

      });
    }


    
    isAnyDefault = (items) => {
        let _return = false
        items.map((item, index) => {       
            if( item.isDefault )
            {
                _return  = true;

            } 
        }); 
        return _return;
    }
    
    
    itemDefault = () => {
        const itemDefault = {
          createdBy: store.get('idUser'),
          contactName: '',
          contactNumber: '',
          isDefault: false,
      }   
      return itemDefault;
    }
    
    handleAddItem  = () => {

        let item = this.itemDefault();

        if( !this.isAnyDefault(this.state.items) )
        {
            item.isDefault = true;
        }

          var state = { ...this.state };
          state.items.push(item);
          this.setState({ state });   
    }

    handleDefaultChange= (id, isDefault) => {
        var state = { ...this.state };
        this.state.items = this.state.items.map((item, index) => {
            item.isDefault = false;
  
            if (index !== id) return item;
          
            item.isDefault = true;
            return item;
        })
        this.setState(state);
        this.props.subHandleCustomerContactNumberChange(this.state.target,this.state.items); 
    }

    handleDefaultContactNameChange = (id, target, value) => {

      if( value == 'Others' )
      {
          this.setState({
            idOthers : id
          })
      } else {
        var state = { ...this.state };
        this.state.items = this.state.items.map((item, index) => {
  
            if (index !== id) return item;
          
            if( target == 'contactName' )
            {   
                item.contactName = value;

            } else {
                item.contactNumber = value;
            }
            
            return item;
        })
        this.setState(state); 
        this.props.subHandleCustomerContactNumberChange(this.state.target,this.state.items);         
      }

    }

    changeItem  = (id, target, value) => {

        var state = { ...this.state };
        this.state.items = this.state.items.map((item, index) => {
  
            if (index !== id) return item;
          
            if( target == 'contactName' )
            {   
                item.contactName = value;

            } else {
                item.contactNumber = value;
            }
            
            return item;
        })
        this.setState(state); 
        this.props.subHandleCustomerContactNumberChange(this.state.target,this.state.items);       
    }
    removeItem = (index) => {

        var state = { ...this.state };

        let array = [];
        array = state.items.filter((_, i) => i !== index)


        if( !this.isAnyDefault(array) && array.length > 0 )
        {
            array[0].isDefault = true;
        }
        state.items = array;

        if(state.items.length == 0)
        {
          let item = this.itemDefault();
          item.isDefault = true;
          state.items.push(item);
        }
        this.setState(state);
        this.props.subHandleCustomerContactNumberChange(this.state.target, array);   
        


    }

    ItemRow = ({
        contactName,
        contactNumber,
        phoneFormat,
        isDefault = false,
        id,
        handleDefaultChange,
        handleChange,
        handleRemove,
        handleDefaultContactNameChange
      }) => {
        const {Text} = Typography;
        return (
          <Fragment>
            <Space direction={(this.props.settings.isMobileView ? 'vertical' : 'horizontal' )} size={'small'} key={id} className='rounded-3 p-3 bg-100' style={{ display: 'flex', marginBottom: 8, border: `dashed 1px ${this.props.settings.primaryColor}` }} align="top" block>  
            <>

            <div>
              {(this.state.idOthers == id ? 
                <Form.Control
                  placeholder="(Home, Work, etc)" 
                  type="text"
                  value={contactName}
                  onChange={({ target }) => handleChange(id, 'contactName', target.value)}       
                  style={{widows: '100%'}}      
                />
              :
              <Form.Select value={contactName}  className='mb-0'
              onChange={({ target }) => handleDefaultContactNameChange(id, 'contactName', target.value)} 
              >
                {(this.state.defaultContactNames.map((tag, index)=>
                  <option value={tag} >{tag}</option>
                ))}
              </Form.Select>
              )}
            </div> 
            <div>
            <Tooltip title="Contact Number" placement='top' >
              <InputMask 
                placeholder="Contact Number"
                mask={phoneFormat} 
                value={contactNumber} 
                className="form-control"
                onChange={({ target }) => handleChange(id, 'contactNumber', target.value)} 
              /> 
            </Tooltip>
            </div>
            <div>
            
            {(isDefault ? 
            <Tag color='blue' className='m-0'>Default</Tag>
            :
              
              <Tooltip title="Set as default" placement='top' >
                <Button type='dashed' size='small' onClick={({ target }) => handleDefaultChange(id, target.isDefault)} >Set default</Button>         
              </Tooltip>
              )}
            </div>

              <Button size='small' onClick={() => handleRemove(id)} >
                  <PiTrashDuotone className='fs-1'  /> {(this.props.settings.isMobileView ? 'Remove': null )} 
              </Button>
            </>
            </Space>
          </Fragment>
        );
      };    

    render(){
      
        return (
            <Fragment>
                {/* <Form.Item wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 20, offset: 4 } }}> */}
                        {this.state.items.length > 0 ?  
                        <>
                            {this.state.items.map((item, index) => (
                                  <this.ItemRow 
                                  {...item}
                                  id={index}
                                  key={index}
                                  handleDefaultChange={this.handleDefaultChange}
                                  handleChange={this.changeItem}
                                  handleRemove={this.removeItem}
                                  handleDefaultContactNameChange={this.handleDefaultContactNameChange}
                                    />
                                  
                              ))}
                        </>
                          : 
                          <tr className="is-empty">
                            <td colspan="4">
                                <div className="text-center">
                                  <p></p>
                                  <p className='fs--1 text-400 nunito'>No Contact Number has been added</p>
                                </div>
                            </td>
                          </tr>
                          } 
                                      
                  <Button  type="link" className='mt-3 text-primary'  onClick={this.handleAddItem}  icon={<BsPlusSquareDotted className='fs-1 me-2 pb-1 text-primary' />}>
                  Add Contact Number
                  </Button>

  
            </Fragment>
        )
    }


}
export default connect(mapStateToProps)(CustomerContactNumber);