import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Main from './Main';
import Layout from './layouts/'

// import Dashboard from './components/dashboard/Dashboard';

const routes = [

  {
    path: '/chatbot',
    Component: lazy(() => import('./components/chatbot/components/Chatbot')),
    exact: true,
    },

  {
    path: '/design',
    Component: lazy(() => import('./components/design/components/Design')),
    exact: true,
    },

    {
    path: '/',
    Component: lazy(() => import('./components/dashboard/Dashboard')),
    exact: true,
    },
    {
    path: '/intro-business-information',
    Component: lazy(() => import('./components/intro/components/IntroBusinessInformation')),
    exact: true,
    },
    {
      path: '/dashboard',
      Component: lazy(() => import('./components/dashboard/Dashboard')),
      exact: true,
      },
      {
        path: '/profile',
        Component: lazy(() => import('./components/myProfile/components/MyProfileDetails')),
        exact: true,
      }, 
      {
        path: '/notes',
        Component: lazy(() => import('./components/notes/components/Notes')),
        exact: true,
      },        
      {
        path: '/my-profile-edit',
        Component: lazy(() => import('./components/myProfile/components/MyProfileForm')),
        exact: true,
      },  
    
    
    {
        path: '/customers/list',
        Component: lazy(() => import('./components/customers/components/Customers')),
        exact: true,
    },
    {
        path: '/customers/customer-details/:id',
        Component: lazy(() => import('./components/customers/components/CustomerDetails')),
        exact: false,
    }, 
    {
        path: '/customers/customer-edit/:id',
        Component: lazy(() => import('./components/customers/components/CustomerForm')),
        exact: false,
    },  
    {
        path: '/customers/new',
        Component: lazy(() => import('./components/customers/components/CustomerDetails')),
        exact: false,
    },  
    {
        path: '/customers-statements',
        Component: lazy(() => import('./components/customers-statements/components/CustomersStatementsDetails')),
        exact: false,
    },  


    {
        path: '/invoices/list',
        Component: lazy(() => import('./components/invoices/components/Invoices')),
        exact: true,
    },

    {
      path: '/reports/payments',
      Component: lazy(() => import('./components/invoices/reports/PaymentReport')),
      // Component: lazy(() => import('./components/invoices/components/Invoices')),
      exact: true,
  },    
    
    {
      path: '/invoices/invoice-details/:id',
      Component: lazy(() => import('./components/invoices/components/InvoiceDetails')),
      exact: false,
    }, 
    
    {
      path: '/invoices/invoice-edit/:id',
      Component: lazy(() => import('./components/invoices/components/InvoiceForm')),
      exact: false,
    },  
    {
      path: '/invoices/new/:generateType',
      Component: lazy(() => import('./components/invoices/components/InvoiceForm')),
      exact: true,
    },       
    {
        path: '/estimates/list',
        Component: lazy(() => import('./components/invoices/components/Estimates')),
        exact: true,
    },
    {
      path: '/estimates/estimate-details/:id',
      Component: lazy(() => import('./components/invoices/components/InvoiceDetails')),
      exact: false,
    },
    {
      path: '/estimates/estimate-edit/:id',
      Component: lazy(() => import('./components/invoices/components/InvoiceForm')),
      exact: false,
    },     
    {
      path: '/estimates/new/:generateType',
      Component: lazy(() => import('./components/invoices/components/InvoiceForm')),
      exact: true,
    }, 

    // {
    //     path: '/admin/invoice-customization',
    //     Component: lazy(() => import('./components/invoices/admin-components/InvoiceCustomizationPage')),
    //     exact: true,
    // }, 
    {
      path: '/accounting/sales-tax',
      Component: lazy(() => import('./components/sales-tax/components/SalesTaxes')),
      exact: true,
    },   

    {
      path: '/accounting/sales-tax/sales-tax-details/:id',
      Component: lazy(() => import('./components/sales-tax/components/SalesTaxDetails')),
      exact: false,
    }, 
    {
      path: '/accounting/sales-tax/new',
      Component: lazy(() => import('./components/sales-tax/components/SalesTaxDetails')),
      exact: true,
    },  
  //   {
  //     path: '/admin/invoice-visibilities',
  //     Component: lazy(() => import('./components/invoices/admin-components/InvoiceVisibilities')),
  //     exact: true,
  // },  
//   {
//     path: '/admin/invoice-email-content',
//     Component: lazy(() => import('./components/invoices/admin-components/InvoiceEmailContent')),
//     exact: true,
// },   
//   {
//     path: '/admin/invoice-size-design',
//     Component: lazy(() => import('./components/invoices/admin-components/InvoiceSizeDesign')),
//     exact: true,
// },  
    {
      path: '/settings',
      Component: lazy(() => import('./components/Settings/components/Settings')),
      exact: true,
    }, 
    {
      path: '/admin-settings/configurations',
      Component: lazy(() => import('./components/admin-settings/configurations/components/IncConfigurations')),
      exact: true,
    }, 

    {
      path: '/admin-settings/business-details',
      Component: lazy(() => import('./components/admin-settings/company/components/CompanyDetails')),
      exact: true,
  }, 
    {
      path: '/admin-settings/smtp/list',
      Component: lazy(() => import('./components/admin-settings/SMTP/components/SMTPList')),
      exact: true,
  }, 
    {
      path: '/admin-settings/smtp-details/:id',
      Component: lazy(() => import('./components/admin-settings/SMTP/components/SMTPDashboard')),
      exact: true,
  }, 
    {
      path: '/admin-settings/licenses',
      Component: lazy(() => import('./components/admin-settings/licenses/components/Licenses')),
      exact: true,
  }, 
    {
      path: '/admin-settings/license-details/:id',
      Component: lazy(() => import('./components/admin-settings/licenses/components/LicenseDetails')),
      exact: true,
  },
  
  {
    path: '/admin-settings/bank-accounts',
    Component: lazy(() => import('./components/admin-settings/bank-accounts/components/PaymentStripe')),
    exact: true,
  }, 

    {
        path: '/services/list',
        Component: lazy(() => import('./components/services/components/Services')),
        exact: true,
    },  
    {
        path: '/services/service-details/:id',
        Component: lazy(() => import('./components/services/components/ServiceDetails')),
        exact: false,
    },  
    {
        path: '/services/new',
        Component: lazy(() => import('./components/services/components/ServiceDetails')),
        exact: true,
    },     
   
   
    {
        path: '/services/services-category/list',
        Component: lazy(() => import('./components/services-category/components/ServicesCategory')),
        exact: true,
    },  
    {
        path: '/services/services-category/services-category-details/:id',
        Component: lazy(() => import('./components/services-category/components/ServicesCategoryDetails')),
        exact: false,
    },  
    {
        path: '/services/services-category/new',
        Component: lazy(() => import('./components/services-category/components/ServicesCategoryDetails')),
        exact: true,
    },     

/*
|
|
| Purchases
|
|
*/

{
  path: '/purchases/vendors/list',
  Component: lazy(() => import('./components/vendors/components/Vendors')),
  exact: true,
},  
{
  path: '/purchases/vendors/vendor-details/:id',
  Component: lazy(() => import('./components/vendors/components/VendorDetails')),
  exact: false,
},  
{
  path: '/purchases/vendors/new',
  Component: lazy(() => import('./components/vendors/components/VendorDetails')),
  exact: true,
}, 

/*
|
|
| Bills
|
|
*/




{
  path: '/purchases/bills/list',
  Component: lazy(() => import('./components/bills/components/Bills')),
  exact: true,
},   

{
path: '/purchases/bills/bill-details/:id',
Component: lazy(() => import('./components/bills/components/BillDetails')),
exact: false,
}, 

{
path: '/purchases/bills/bill-edit/:id',
Component: lazy(() => import('./components/bills/components/BillForm')),
exact: false,
},  
{
path: '/purchases/bills/new',
Component: lazy(() => import('./components/bills/components/BillForm')),
exact: true,
},







    /*
    | Create Parts
    */
    {
      path: '/parts/new',
      Component: lazy(() => import('./components/parts/components/PartDetails')),
      exact: true,
  }, 
  {
    path: '/parts/part-details/:id',
    Component: lazy(() => import('./components/parts/components/PartDetails')),
    exact: false,
  },
    
  {
    path: '/parts/list',
    Component: lazy(() => import('./components/parts/components/Parts')),
    exact: true,
  }, 





  {
    path: '/users/list',
    Component: lazy(() => import('./components/users/components/Users')),
    exact: true,
},  
  {
    path: '/users/new',
    Component: lazy(() => import('./components/users/components/UserDetails')),
    exact: true,
},    
    
  {
    path: '/users/user-edit/:id',
    Component: lazy(() => import('./components/users/components/UserDetails')),
    exact: false,
},   
    





/*
|
|
| Appointment
|
|
*/

{
  path: '/calendar',
  Component: lazy(() => import('./components/calendars/components/Calendars')),
  exact: true,
},  



  // END VAREXE

  // Dashboards
//   {
//     path: '/dashboard/alpha',
//     Component: lazy(() => import('pages/dashboard/alpha')),
//     exact: true,
//   }, 

/*
| Auth Card version
*/

// {
//   path: '/auth/logout',
//   Component: lazy(() => import('./components/auth/basic/Logout')),
//   exact: true,
// },
// {
//     path: '/auth/login',
//     Component: lazy(() => import('./components/auth/card/Login')),
//     exact: true,
//   },
//   {
//     path: '/auth/forget-password',
//     Component: lazy(() => import('./components/auth/card/ForgetPassword')),
//     exact: true,
//   },  
//   {
//     path: '/auth/register',
//     Component: lazy(() => import('./components/auth/card/Registration')),
//     exact: true,
//   },  
/*
| End Auth Card version
*/
/*
| Auth Split version
*/
{
  path: '/auth/logout',
  Component: lazy(() => import('./components/auth/split/Logout')),
  exact: true,
},
{
    path: '/auth/login',
    Component: lazy(() => import('./components/auth/split/Login')),
    exact: true,
  },
  {
    path: '/auth/forget-password',
    Component: lazy(() => import('./components/auth/split/PasswordForget')),
    exact: true,
  },  
  {
    path: '/auth/reset-password',
    Component: lazy(() => import('./components/auth/split/PasswordReset')),
    exact: true,
  },    
  {
    path: '/auth/register',
    Component: lazy(() => import('./components/auth/split/Registration')),
    exact: true,
  },
  {
    path: '/auth/register/park/pending-creating-database',
    Component: lazy(() => import('./components/auth/split/WaitingForDatabase')),
    exact: true,
  },

  {
    path: '/errors/404',
    Component: lazy(() => import('./components/errors/Error404')),
    exact: true,
  },

/*
| END Split version
*/


{
  path: '/support',
  Component: lazy(() => import('./components/support/Support')),
  exact: true,
},
{
  path: '/supporttest',
  Component: lazy(() => import('./components/support/SupportTest')),
  exact: true,
},

//   {
//     path: '/auth/500',
//     Component: lazy(() => import('pages/auth/500')),
//     exact: true,
//   },






/*
|
|
| PUBLIC
|
|
*/

{
  path: '/park/pending-creating-database',
  Component: lazy(() => import('./components/public/park/CreatingDatabase')),
  exact: true,
},

/*
|
|
| SharePoint
|
|
*/

{
  path: '/public/sharePoint',
  Component: lazy(() => import('./components/public/sharePoint/SharePoint')),
  exact: true,
},  

/*
| privacy
*/

{
  path: '/policy/privacy',
  Component: lazy(() => import('./components/public/privacy/PrivacyPolicy')),
  exact: true,
},
{
  path: '/policy/terms',
  Component: lazy(() => import('./components/public/terms/Terms')),
  exact: true,
},



/*
| Appatch
*/

{
  path: '/appatch',
  Component: lazy(() => import('./01_site_appatch/components/landing/components/Landing')),
  exact: true,
},
{
  path: '/appatch/services/json-formatter',
  Component: lazy(() => import('./01_site_appatch/components/jsonFormatter/components/JsonFormatter')),
  exact: true,
},







]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {    
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
                <Switch location={location}>
                  {/* <Route path="/" exact component={Dashboard} /> */}
                {/* <Route exact path="/" render={() => <Redirect to="/dashboard" />} /> */}
                {routes.map(({ path, Component, exact }) => 
                {
                return(
                  <Route
                    path={path}
                    key={path}
                    exact={exact}
                    render={({ 
                        location, 
                        match 
                    }) => {
                      return (
                        <div  className={routerAnimation}>
                            <Suspense fallback={null}>
                                <Component match={match} />
                            </Suspense>
                        </div>
                      )
                    }}
                  />
                )
                }
                )}
                <Redirect to="/errors/404" />
              </Switch>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
