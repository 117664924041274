import apiClient from '../api'
import store from 'store'
import { history } from '../../index'
import { useSearchParams } from "react-router-dom";


export async function loginSocial(primaryEmail, accessToken, userID, provider) {
  const params = {
    'primaryEmail' : primaryEmail,
    'accessToken' : accessToken,
    'userID' : userID,
    'provider': provider
  }
    return apiClient
    .post('/auth/loginSocial', params)
    .then(response => {
      if (response) {
        const  accessToken    = response.data.token;
        const  idUser         = response.data.id;
        const  redirect       = response.data.redirect;
        const  userRole       = response.data.userRole;
        if (accessToken) {
          store.set('AccessToken', accessToken);
        }
        if (idUser) {
          store.set('idUser', idUser);
          store.set('userSession', userSession);
          store.set('userRole', userRole);
        }

        if( redirect != null )
        {
          if( history.location.pathname !== redirect )
          {
            history.push(redirect)
          }
        }
        return response.data;
      }
      return false
    })
    .catch(err => console.log(err))  
}

export async function login(primaryEmail, password) {

    const data = {
      primaryEmail:  primaryEmail,
      password:  password,
      notification:  true,
      redirect:      true,
    }
    return apiClient
      .post('/auth', data)
      .then(response => {
        if (response) {
          const  accessToken    = response.data.token;
          const  idUser         = response.data.id;
          const  redirect       = response.data.redirect;
          const  userRole       = response.data.userRole;
          const  userSession    = response.data.userSession;
          if (accessToken) {
            store.set('AccessToken', accessToken);
          }
          if (idUser) {
            store.set('idUser', idUser);
            store.set('userRole', userRole);
            store.set('userSession', userSession);
          }

          if( redirect != null )
          {
            if( history.location.pathname !== redirect )
            {
              history.push(redirect)
            }
          }
          return response.data;
        }
        return false
      })
      .catch(err => console.log(err))
  }
  
  export async function currentAccount() {


    const queryParams = new URLSearchParams(window.location.search);

    if( queryParams.get('AccessToken') && queryParams.get('idUser') )
    {
      store.set('AccessToken', queryParams.get('AccessToken') );
      store.set('idUser',      queryParams.get('idUser') );
    }


    return apiClient
      .get('/auth')
      .then(response => {
        if (response) {
          const  accessToken  = response.data.token
          const  redirect       = response.data.redirect;
          if (accessToken) {
            store.set('AccessToken', accessToken)
          }
          if( redirect != null )
          {
            if( history.location.pathname !== redirect )
            {

              history.push(redirect)
            }
            
          }
          return response.data
        }
        store.remove('AccessToken');
        return false
      })
      .catch(err => console.log(err))
  
  }
  
  export async function forgetpassword(primaryEmail) {
    const params = new URLSearchParams();
    params.append('primaryEmail', primaryEmail);    
    return apiClient
      .post('/auth/forgetpassword', 
        params
      )
      .then(response => {
        if (response) {
          const { returnData } = response.data
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  }
  export async function register(firstname, lastname, primaryEmail, mobile, password, idCountry, timezone,  makeNewUser = false ) {
    const params = new URLSearchParams();
    params.append('password', password);
    params.append('firstname', firstname);
    params.append('lastname', lastname);
    params.append('primaryEmail', primaryEmail);
    params.append('mobile', mobile);
    params.append('idCountry', idCountry);
    params.append('timezone', timezone);
    params.append('makeNewUser', makeNewUser);
    
    return apiClient
      .post('/auth/register', params)
      .then(response => {
        if (response) {
          // const { accessToken } = response.data
          // if (accessToken) {
          //   store.set('AccessToken', accessToken)
          // }
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  }
  
  
  export async function logout() {
    return apiClient
      .get('/auth/logout')
      .then(response => {
        if (response) {
          store.remove('AccessToken')
          store.remove('AccessToken');
          store.remove('idUser'); 
          store.remove('userRole');     
          store.remove('userSession');     
          return response.data       
        }
    
        return false
      })
      .catch(err => console.log(err))
  }
  