import React , { Component, Fragment } from 'react';

/*
| UI
*/
import { Divider, Space, Form, Input, Radio, Button, Typography, Segmented    } from 'antd';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

import { Modal } from 'react-bootstrap';


/*
| Country
*/


import AdvancedCountrySelect         from './AdvancedCountrySelect';


import CountriesService from '../services';

export default class CountryChangeOnModal extends Component {


    constructor(props) {

        super(props)
        this.state = {
            idCountry    : this.props.idCountry,
            idState      : this.props.idState,
            idCity       : this.props.idCity,

            country : "",
            state   : "",
            city    : "",

            isModalOpen : false,

            isLoading:true,

        }

       
    }

    componentDidMount(){ 
        let data = {
            idCountry    : this.props.idCountry,
            idState      : this.props.idState,
            idCity       : this.props.idCity,
        }
        CountriesService.getLabels(data).then((res) => {
            try {
      
              if( res.data !== undefined ){
               let labels = res.data;
                this.setState({
                    country : labels.country.name,
                    state   : labels.state.name,
                    city    : labels.city.name,
                  isLoading:false
                });
      
                // this.setState({controllerProps:_state});
              }            
            
            } catch(error){
                console.log(error);
            }
          });          
     }

     showModal = () => {
        this.setState({isModalOpen: true});
      };
    
       handleOk = () => {
        this.setState({isModalOpen: false});
      };
    
       handleCancel = () => {

        this.setState({isModalOpen: false});
      };    

    render (){
        return (
            <Fragment>
                {(!this.state.isLoading ? 
                    <p className='mb-0 mt-2'>{this.state.city}, {this.state.state}, {this.state.country}</p>
                : null)}
                    
                <Button type='link' onClick={this.showModal}><EditOutlined /> Click to change the country location</Button>                 

                <Modal
                // open={this.state.isModalOpen} 
                // style={{ zIndex: 999999 }}
                // title="Vendor's country location"
                // onOk={this.handleOk} 
                // onCancel={this.handleOk}
                // footer={[
                //     <Button key="submit" type='primary' onClick={this.handleOk}>Close</Button>
                // ]}

                show={this.state.isModalOpen}
                onHide={this.handleOk}
                                
                >
                        <Modal.Header closeButton>
                        <h6>Country location</h6>
                        </Modal.Header>                    

                        <Fragment>
                                <Form
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 18 }}
                                layout="horizontal"
                                disabled={false}
                                style={{ maxWidth: 600, margin:'auto' }}
                                // requiredMark={'optional'}
                                size='large'
                                className='mt-4 position-relative'
                            >

                                <p>You are changing (Country, State, City) location</p>

                                        <Form.Item
                                        label="Country"              
                                    >
                                        <AdvancedCountrySelect 
                                            serviceType="countries"
                                            defaultValue={this.state.idCountry}
                                            callBackResult={(result)=>{
                                                let state = {...this.state};
                                                state.idCountry = result.value;
                                                state.country = result.label;
                                                state.idState = null;
                                                state.idCity  = null;
                    
                    
                                                this.props.handleCountryChange('idCountry', result.value);
                                                this.setState(state);                         
                                            }}
                                        />         
                                    </Form.Item> 
                                    <Form.Item
                                        label="State"              
                                    >
                                        <AdvancedCountrySelect 
                                            serviceType="states"
                                            idCountry={this.state.idCountry}
                                            defaultValue={this.state.idState}
                                            callBackResult={(result)=>{
                                                let state = {...this.state};
                                                state.idState = result.value;
                                                state.state = result.label;
                                                state.idCity = null;
                    
                                                this.props.handleCountryChange('idState', result.value);
                                                this.setState(state); 
                                            }}
                                        />                    
                                    </Form.Item> 
                                    <Form.Item
                                        label="City"              
                                    >
                                        <AdvancedCountrySelect 
                                            serviceType="cities"
                                            idState={this.state.idState}
                                            defaultValue={this.state.idCity}
                                            callBackResult={(result)=>{
                    
                                                let state = {...this.state};
                                                state.idCity = result.value;
                                                state.city = result.label;
                    
                                                this.props.handleCountryChange('idCity', result.value);
                                                this.setState(state); 
                                            }}
                                        />                    
                                    </Form.Item>     
                            </Form>
                        </Fragment>


                        <Modal.Footer>
                            <Button type='primary' onClick={this.handleOk}>Close</Button>
                        </Modal.Footer>


                </Modal>

            </Fragment>
        )
    }
}