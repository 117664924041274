import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Typography} from 'antd'
import {  Card,  Row, Col, Placeholder, Form } from 'react-bootstrap';

import Flex         from 'components/common/Flex';
import Notification from 'components/invoices/analytics/partials/Notification';
import Background   from 'components/common/Background';

/*
| Images
*/
// import ecomBg           from '../../../assets/img/illustrations/ecommerce-bg.png';
// import safebox          from '../../../assets/img/illustrations/3d/mobile-payment.png';
// import greetingsBg      from '../../../assets/img/illustrations/ticket-greetings-bg.png';
// import Payid            from '../../../assets/img/illustrations/badges/star-success.png';
// import Hold             from '../../../assets/img/illustrations/badges/hold-warning.png';


import InvoiceService        from 'components/invoices/services';

import {capitalize} from 'helpers/utils'


/*
| UTILS
*/
import {formatCurrency} from 'helpers/utils';
import PaymentsLineChart from './PaymentsLineChart';

const dropDownOptions = [
  {
      value    : 1,
      name     : 'Today',
      selected : true,
  },
  {
      value    : 30,
      name     : '30 days period',
      selected : false,
  }    
]

class  GreetingCard extends Component {

  constructor(props) {
    super(props);  

      this.state = {
        isLoading: true,
        greetingMessage: '',
        totalPaid: 0.00,
        totalCreatedBill: 0.00,
        notifications: [],
        currencySymbol : '$',
        currencyCode : 'CAD',
        currencyName : '',
        dateTimeZone : '',
        currentDateTime : '',
        calRange : 1,
        calRangeName : 'Today Only',
      }

      dropDownOptions.map((val, index)=>{
        if( val.selected )
        {
           this.state.calRange     =  val.value;
           this.state.calRangeName = val.name;
        }
  });

  }

  componentDidMount(){ 
    this.getData();
  } 


 getData = () => {
    InvoiceService.getAnalyticsGreeting().then( (response) => {
      try {
        if( response.data !==  undefined && response.data.data !==  undefined )
        {        
          // totalCreatedBill
          let result = response.data.data;
          var state = this.state ;
          state.greetingMessage = result.greetingMessage;
          state.totalPaid = result.todayAnalytics.totalPaid;
          state.totalCreatedBill = result.todayAnalytics.totalCreatedBill;
          state.notifications = result.notifications;
          state.currencySymbol = result.currencySymbol;
          state.currencyCode = result.currencyCode;
          state.currencyName = result.currencyName;
          state.dateTimeZone = result.dateTimeZone;
          state.currentDateTime = result.currentDateTime;
          state.isLoading = false;
          this.setState(state);
        }      
        // console.log((new Date().toLocaleString(undefined, {timeZone: state.dateTimeZone})));


        var now = new Date(new Date().toLocaleString(undefined, {timeZone: state.dateTimeZone})  );
        var seconds = now.getSeconds();
        var minutes = now.getMinutes();
        var hours = now.getHours() % 12;

    } catch(error){
      console.log(error);
    }
   });
  }

render() {

  return (
    <Card className="  overflow-hidden mb-1 shadow-none p-0"> 
    {(this.state.isLoading ? 
      <Fragment>
        <Card.Body className="">
          <Placeholder as={Card.Title} animation="glow" className='mb-2'>
          <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          </Placeholder> 
        </Card.Body>
      </Fragment>
      : 
      <Fragment>

        <Card.Body className="p-0">
        <Row className="flex-between-center gx-0">
            <Col  className="align-items-center">
              <div className="">
                <h4 className="mb-0  fs--1 text-600">
                  Varexe
                  <span className=" fw-medium"> Dashboard</span>
                </h4>
                <Typography.Title level={4} className='mb-0'>{this.state.greetingMessage}</Typography.Title>
                <hr />
                <p className='mb-0'><Typography.Text>Currency is <b className='text-primary'>{this.state.currencyName} ({this.state.currencyCode})</b></Typography.Text></p>
                <p className='mb-0'><Typography.Text>Date will be calculate by <b className='text-primary'>{this.state.dateTimeZone}</b></Typography.Text></p>
                
                {/* <p className='text-nunito'>Here's what happening with your Business <span className='fw-bold text-primary'>{capitalize(this.state.calRangeName)}</span></p> */}
              </div>
            </Col>
          </Row>

          {(this.state.notifications.length > 0 ? 
            <Fragment>
                <p className='text-nunito mt-3'>Please read the notification below, generated <span className='fw-bold text-primary'>{capitalize(this.state.calRangeName)}</span></p>
            </Fragment>
            : null)}

          <ul className="mb-0 list-unstyled">
            {this.state.notifications.map((notification, index) => (
              <Notification
                key={index}
                notification={notification}
                isLast={this.state.notifications.length - 1 === index}
              />
            ))}
          </ul>
        </Card.Body>
      </Fragment>
      )}
    </Card>
    )
};
}


export default GreetingCard;
