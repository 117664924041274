
import api from '../../../services/api/';
const URL = '/calendars/';

class CalendarService {

    // getBill(listPageIndex = null, listPageSize = null, listSort = null, listOrder = null, listSearch = null, isDeleted = null, idStatus = null ){
    getList(data){
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + JSON.stringify(data.listFilters));
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }                         
         if( data.startDate != undefined && data.startDate != null )
         {
            params.push( "startDate=" + encodeURIComponent(data.startDate) );
         }   
         if( data.endDate != undefined && data.endDate!= null )
         {
            params.push( "endDate=" + encodeURIComponent(data.endDate) );
         }                                                                    
         if( data.type != undefined && data.type != null )
         {
            params.push( "type=" + data.type );
         } 
         if( data.idClient != undefined && data.idClient != null )
         {
            params.push( "idClient=" + data.idClient );
         }                                                                             
        return api.get(URL + 'list/' + (params.length ? '?' + params.join('&') : '' ));
    }
    getListScrollEvent(data){
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + JSON.stringify(data.listFilters));
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }                         
         if( data.startDate != undefined && data.startDate != null )
         {
            params.push( "startDate=" + encodeURIComponent(data.startDate) );
         }   
         if( data.endDate != undefined && data.endDate!= null )
         {
            params.push( "endDate=" + encodeURIComponent(data.endDate) );
         }
         if( data.idClient != undefined && data.idClient != null )
         {
            params.push( "idClient=" + data.idClient );
         }                                                                             
         if( data.type != undefined && data.type != null )
         {
            params.push( "type=" + data.type );
         }                                                                    
        return api.get(URL + 'listScrollEvent/' + (params.length ? '?' + params.join('&') : '' ));
    }

   getNumberOfEventsInMonth(date){
      return api.get(URL + 'numberOfEventsInMonth?date=' + date);
   }



   getInitData(idCalendar){
      return api.get(URL + 'init/' + idCalendar);
   }
    
   updateCalendar(idCalendar, data){
      return api.put(URL + 'calendar_update/' + idCalendar, data);
   }

   getCalendarById(idCalendar){
      return api.get(URL + 'detail/' + idCalendar);
   }
   getUpcomingEvent(){
      return api.get(URL + 'listUpcomingEvent/');
   }
   setScheduleSender(data){
      return api.post(URL + "/scheduleSender", data)
   }

   setCalendarLabel(data){
      return api.post(URL + "/calendarLabel", data)
   }
   deleteCalendarLabel(data){
      return api.post(URL + "/deleteCalendarLabel", data)
   }
   getListCalendarLabel(){
      return api.get(URL + 'listCalendarLabel/');
   } 

   updateCalendarLabel(idCalendarLabel, data){
      return api.put(URL + 'calendarlabel_update/' + idCalendarLabel , data);
   }   

   

}

export default new CalendarService()