import React , { Fragment, Component }  from 'react';
import 'draft-js/dist/Draft.css';
import {  Button, Nav  } from 'react-bootstrap';
class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }

    render() {
      let className = '';
      if (this.props.active) {
        className += ' text-primary';
      }

        // <span className={className} onMouseDown={this.onToggle}>
        //   {this.props.label}
        // </span>
        //nav-link-card-details
      return (
        <span className={`p-1 m-1 fs--1 ${className}`} style={{cursor:'pointer'}} onMouseDown={this.onToggle}>
        {/* <FontAwesomeIcon icon={menu.icon} className="me-2" /> */}
        {this.props.label}
      </span>          
      );
    }
  }  
  export default StyleButton;