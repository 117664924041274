
import React, { useState,createRef, Fragment, useContext } from 'react';
import SimpleBarReact           from 'simplebar-react';
import { connect }              from 'react-redux'
import classNames               from 'classnames';
import { Link  }                from 'react-router-dom';
import toast, { Toaster }       from 'react-hot-toast';

import moment from 'moment';
import dayjs from 'dayjs';
/*
| Images
*/

/*
| Icons
*/
import {PiArrowRightDuotone, PiArrowLeftDuotone, PiCalendarPlusDuotone, PiCaretLeftBold, PiCaretRightBold} from 'react-icons/pi';
import { IoSaveOutline, IoCloseSharp } from 'react-icons/io5';
/*
| UI
*/
import { Badge, Skeleton, Space, Spin, Button, Divider, Switch, Checkbox, Radio,  DatePicker, Typography, Select, Drawer   } from 'antd';
import { Card,  Row, Col, Container, Modal, CloseButton  } from 'react-bootstrap';


import Flex                   from '../../common/Flex';
import Avatar                 from '../../common/Avatar';


/*
| pages
*/

import ViewDetailsCalendar        from './partials/ViewDetailsCalendar';
import EventComponent        from './partials/EventComponent';
/*
| UTILS
*/
import { isIterableArray, formatCurrency, capitalize, isEmpty }     from 'helpers/utils';

/*
| Invoices
*/


import CalendarService        from '../services/';

/*
| For Search
*/
const WAIT_INTERVAL = 200;
const ENTER_KEY = 13;

const mapStateToProps = ({ settings }) => ({
  settings : settings
})


class CalendarsUpcoming extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      listTitle:"Calendars Upcoming",
      isLoading : false,
      data:[],

      calendarApi: {},
      calendarTitle: null,
      
      idEditingCalendarModal: -1,
      calendarModalShow: false,
      scheduleStartDate     :new Date(),
      scheduleEndDate       :new Date(), 
      
      viewDetailsContent : {}


    }
    this.refreshContent = this.refreshContent.bind(this);

  }


  componentDidMount(){
    this.getData();
  }  
  
  getData = () => {
    CalendarService.getUpcomingEvent().then((res) =>{
      try{


        let result = res.data;

        this.setState({
          isLoading: false,
          data: result.data,
        })


      } catch(error){
        console.log(error);
      }
    });

  }
  
  
  loadMore = () => {

  }


  /*
  | Table Filters
  */

  onSearchGlobal = (value) => {

  }

  onStatusChange = (e) => {
  }


  handleViewSortDate = (value) => {

  }


  handleCalendarModalShow = () => {
      this.setState({calendarModalShow:!this.state.calendarModalShow});
  }  

  refreshContent = () => {
    this.handleViewSortDate(this.state.viewSortDateDefault)
  }  

  
  // calendarRef = createRef();

render(){
  const { Text, Title, Paragraph } = Typography;
    return (
    <Fragment>


          {(this.state.isLoading ? 
            <Container fluid >
              <Row>
                  <Card className='p-3 mb-3'><Skeleton active /></Card>
              </Row>
                
            </Container>
            :   
            <Fragment>

                      <SimpleBarReact className="ask-analytics ">
                        
                        {this.state.data.length == 0 ? 
                        <div className='no-event p-3 rounded-2 border-dashed border-300 border-1x'>
                          <Text className='fw-semi-bold text-400'>No Upcoming Event</Text>
                        </div>
                        : null}
                        {this.state.data.map((data, index)=>{
                          return (
                            <Fragment key={index}>
                              <EventComponent {...data} />
                            </Fragment>
                          )
                        })}
                      </SimpleBarReact>


            </Fragment> 
          )}



    </Fragment>
    )
  }
}
export default connect(mapStateToProps)(CalendarsUpcoming);