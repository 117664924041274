
import  React , {  Fragment, Component } from "react";
import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
/*
| UI
*/
import { Skeleton, Layout, Card, Form, Divider, Typography, Input, Select, Switch   } from 'antd';
import { Row, Container, Button} from 'react-bootstrap';


/*
| Icons
*/
import { IoSaveOutline } from 'react-icons/io5';
/*
| Pages
*/

/*
| Services
*/
import InvoiceService    from '../services/';

export default class OutgoingEmailsSetting extends Component {

    constructor(props) {
  
        super(props)
        this.state = {
            loading:  true, 
            emailAttachmentPDF : false,        
            emailBCC : "",        
            emailCC : "",        
        }

    }


componentDidMount(){
    
    this.getSetting();
    
} 

getSetting = () => {
 
    InvoiceService.getSetting().then((res) => {
        try{
            if( res.data !== undefined ){                
            if( res.data ){

                /* Load saved settings */
                let state = {...this.state};
                let InvoiceSetting = res.data;
                state.invoiceSetting = InvoiceSetting;

                state.emailAttachmentPDF    = InvoiceSetting.emailAttachmentPDF;        
                state.emailBCC              = InvoiceSetting.emailBCC;       
                state.emailCC               =  InvoiceSetting.emailCC;

                state.loading = false;
            this.setState(state);
 
            }

        }
        } catch(error){
            console.log(error);
        }            

      }); 
 
}

handleChange = (target) => {
    const name      = target.name;
    const value     = target.value;

    let state = this.state;

    state[name] = value;
    this.setState(state);
}

handleSettingUpdate = event => {
    var state = { ...this.state };

    toast.promise(    
    InvoiceService.updateSetting(state)
    .then((res) =>{
        let result = res.data;
                  
    }),
    {
      loading: 'Saving',
      success: <span>Outgoing emails setting updated</span>,
      error: <span>Could not save the data.</span>,
    }
  );

                                         
    

  } 



    render(){
        const { Text, Title } = Typography;
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };
        return (
            
            <Fragment>
            { this.state.loading ? 
            <Container fluid className='mb-3'>
                <Skeleton  className="mt-3" />
            </Container>
            :
            <>
                <Title level={4}>Outgoing Emails</Title>
                <Text>Setup how your organization should send emails out. This setting applies to estimates, drafts, invoices and payment recipients.</Text>

                    <Form
                        {...layout}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        disabled={false}
                        style={{ maxWidth: 600, margin:'auto' }}
                        size='large'
                        className="mt-5"
                    >

                        <Form.Item
                            label="Attachment PDF"
                            name="emailAttachmentPDF"
                            // onChange={({ target }) => this.handleChange(target)}               
                        >
                            <Switch className="mt-2" defaultChecked={this.state.emailAttachmentPDF} onChange={(value)=>{
                                let target = {
                                    name : 'emailAttachmentPDF',
                                    value : value,
                                }
                                this.handleChange(target);
                            }} />
                            <p>Attach a copy of the order PDF with the outgoing email.</p>             
                        </Form.Item>  

                        <Form.Item
                            label="Carbon copy(Cc)"
                            name="emailCC"
                            onChange={({ target }) => this.handleChange(target)}               
                        >
                            <Input name="emailCC" defaultValue={this.state.emailCC}   />
                            <p>CC one email to your organization outgoing email.</p>             
                            <p>If you need multiple recipients use a shared mailbox or a distribution list as your email.</p>             
                        </Form.Item>  

                        <Form.Item
                            label="Blind Carbon Copy(Bcc)"
                            name="emailBCC"
                            onChange={({ target }) => this.handleChange(target)}               
                        >
                            <Input name="emailBCC" defaultValue={this.state.emailBCC}   />
                            <p>Bcc one email to your organization outgoing email.</p>             
                            <p>If you need multiple recipients use a shared mailbox or a distribution list as your email.</p>             
                        </Form.Item>    

                        <Divider orientation="left">Save</Divider> 

                        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                            <Button
                                size="sm"
                                className="mt-2 fs--1 fw-normal"
                                type="submit" 
                                onClick={this.handleSettingUpdate}
                            >
                                <IoSaveOutline className=" fs-2 me-2"/> Save Outgoing Emails
                            </Button> 
                        </Form.Item>                                                                       

                    </Form>


            </>
            }
            </Fragment>
        )
    }

}