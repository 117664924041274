
import api from '../../../../services/api/';
const URL = '/tags/';

class TagService {
    getTags(data){
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }                                                 
        return api.get(URL + 'list/' + (params.length ? '?' + params.join('&') : '' ));
    }

    getAssignedTags(data){
      const  params = [];

      if( data.tableName != undefined && data.tableName != null )
      {
         params.push( "tableName=" + data.tableName );
      }       
      if( data.idTable != undefined && data.idTable != null )
      {
         params.push( "idTable=" + data.idTable );
      }          
      return api.get(URL + 'getAssignedTags/'  + (params.length ? '?' + params.join('&') : '' ));
          
  }     

   addAssignedTag(data){
      return api.put(URL + '/addAssignedTag/', data);
   }

   deleteAssignedTags(data){
      return api.put(URL + '/deleteAssignedTags/', data);
   }

    getTagById(idInvoice){
        return api.get(URL + 'detail/' + idInvoice);
            
    }
    updateTag( idTag, tag){
        return api.put(URL + idTag, tag);
    }  
    deleteTag( idTag){
        return api.put(URL + 'deleteTag/' + idTag);
    }  
      

}
export default new TagService()