
import api from '../../../services/api/';
const URL = '/clients/';

class CustomerService {

    getList(data){    
        const  params = [];
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + JSON.stringify(data.listFilters));
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }                           
        return api.get(URL + 'list/' + (params.length ? '?' + params.join('&') : '' ));
    }

    createCustomer(customer){
        return api.post(URL, customer);
    }

    getCustomerById(idCustomer){
        return api.get(URL + 'detail/' + idCustomer);
    }


    updateCustomer( idCustomer, customer){
        return api.put(URL + 'update/' + idCustomer, customer);
        
    }

    deleteCustomer(idCustomer){
        return api.delete(URL + idCustomer);
    }

    getCustomerBySearch(str){
        return api.get(URL + 'search/' + str);
    }


   getUniqueContactNames(str){
       return api.get(URL + 'unique_contact_names/');
   }
   getUniqueEmailNames(str){
       return api.get(URL + 'unique_email_names/');
   }

   //  addBilling(idCustomer,data){
   //    return api.put(URL + 'addBilling/' + idCustomer, data);
   // }    
   //  addShipping(idCustomer,data){
   //    return api.put(URL + 'addShipping/'+ idCustomer, data);
   // } 
   
   getCustomerBillingBySearch(str){
      return api.get(URL + 'clientBillingSearch/' + str);
  } 

   getCustomerBillingByIdClient(idCustomer){
         return api.get(URL + 'all_billing_accounts/' + idCustomer);
   }

   addExistingBilling(data){
      return api.post(URL + 'add_existing_billing', data);
   }

   updateBilling(idCustomer,data){
      return api.put(URL + 'updateBilling/' + idCustomer, data);
   }   
   updateShipping(idCustomer,data){
      return api.put(URL + 'updateShipping/' + idCustomer, data);
   }   

    removeBilling(idCustomer){
        return api.post(URL + 'removeBilling', idCustomer);
    }
    removeShipping(idCustomer){
        return api.post(URL + 'removeShipping', idCustomer);
    }



    getInvoices(data){    
        const  params = [];
      //   if( data.idClient != undefined && data.idClient != null )
      //   {
      //      params.push( "idClient=" + data.idClient );
      //   }        
        if( data.listPageIndex != undefined && data.listPageIndex != null )
         {
            params.push( "listPageIndex=" + data.listPageIndex );
         }
         if( data.listPageSize != undefined && data.listPageSize != null )
         {
            params.push( "listPageSize=" + data.listPageSize );
         }         
         if( data.listSort != undefined && data.listSort != null )
         {
            params.push( "listSort=" + data.listSort );
         }         
         if( data.listOrder != undefined && data.listOrder != null )
         {
            params.push( "listOrder=" + data.listOrder );
         }         
         if( data.listSearch != undefined && data.listSearch != null )
         {
            params.push( "listSearch=" + data.listSearch );
         } 
         if( data.listFilters != undefined && data.listFilters != null )
         {
            params.push( "listFilters=" + JSON.stringify(data.listFilters));
         }                 
         if( data.isDeleted != undefined && data.isDeleted != null )
         {
            params.push( "isDeleted=" + data.isDeleted );
         }  
         if( data.generateType != undefined && data.generateType != null )
         {
            params.push( "generateType=" + data.generateType );
         }                                   
        return api.get(URL + 'getInvoices/' + (params.length ? '?' + params.join('&') : '' ));
    }
    
}

export default new CustomerService()