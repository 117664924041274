import React, {Component, Fragment}      from 'react';

import { Form, Button, Card, Col, Row } from 'react-bootstrap';

import Flex                     from '../../../common/Flex';
import FormGroupInput           from '../../../common/FormGroupInput';
import FalconCardHeader         from '../../../common/FalconCardHeader';
import ButtonIcon               from '../../../common/ButtonIcon';
import ToastVarexe              from '../../../common/ToastVarexe';

import Background   from '../../../common/Background';
import corner1      from '../../../../assets/img/illustrations/corner-1.png';

import { IoSaveOutline, IoCloseSharp, IoAddOutline } from 'react-icons/io5';

/*
| Pages
*/
import InvoiceService       from '../../services/';

class NumberSetting extends Component
{
    constructor(props) {

        super(props)
        this.state = {
            idInvoiceConfiguration: -1,
            displayLogo :   1 ,
            prefix:         "INC-",
            number:         "1000000",
            minNumber:      "1000000",
            
            loading: true,
            isEditingVisible: true,
            redirect:      null             
        };
    } 
    componentDidMount(){

        InvoiceService.getSetting().then((res) =>{
            try{
                if( res.data !== undefined ){
                if( res.data ){
                let InvoiceSetting = res.data;
        
                this.setState({
                    loading:false,
                    idInvoiceConfiguration:  InvoiceSetting.idInvoiceConfiguration,
                    
                    prefix:                  InvoiceSetting.prefix ,   
                    number:                  InvoiceSetting.number ,    
                    minNumber:               InvoiceSetting.minNumber,                       
                });          
                }
                }

            } catch(error){
                console.log(error);
            }            
    
          }); 

          
    } 
    handleChange =  (target) =>  {

        const name      = target.name;
        const value     = target.value;
        const newState  = {};
    
        Object.keys(this.state).forEach(key => {
            if (key.endsWith(name)) {
              newState[key] = value;
            } else {
    
            }
        });
        this.setState(newState);
     }       
    handleSettingUpdate = event => {
        var state = { ...this.state };
        InvoiceService.updateSetting(state)
        .then((res) =>{
            let result = res.data;
          
            if( result.status == 400 )
            { 
                ToastVarexe({
                    type :  'error',
                    message: result.message
                });                                       
            }
            if( result.status == 200 )
            {
                ToastVarexe({
                    type :  'success',
                    message: result.message
                });                   
            }            
        });    
      }       
    render(){
        const { 
            prefix,
            number,
            minNumber
                  
    } = this.state;         
        return (
            <Fragment>
<Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="title">
    <Col md={12} lg={6} >
        <h1 className="fs-3">Invoice Sequence Number</h1>            
    </Col>
</Form.Group>

<Card className={`overflow-hidden mb-5 border border-1 border-warning shadow-none`}>
    <Background image={corner1} className="p-card bg-card" />
    <Card.Body className="position-relative">
      <h5 className="text-warning">Attention</h5>
      <p className="fs-0 mb-0 mt-3">
        <span className="text-warning fw-bold">You always have to choose a greater sequence number!</span><br /> Sequence number always increment by one.
      </p>
      <p className="mt-2">After the update, the incremental number will start with your desired number. This will affect all the <span className="text-warning fw-bold">new invoices</span>. previous invoices will remain the same.</p>
    </Card.Body>
  </Card>

<Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="prefix">
    <Col md={12} lg={4}>
        <Flex direction='lg-row-reverse'>  
            <p>
                Prefix invoice number             
            </p>
        </Flex>        
    </Col>
    <Col md={12} lg={6}>
        <Col  lg={12} xl={6}>
            <Form.Control  
                // id="prefix"
                label="Prefix invoice number"
                name="prefix"
                value={prefix}
                className="form-control"
                onChange={({ target }) => this.handleChange(target)}
            />
            <p className='fs--1 text-600'>This will be displayed at front of the sequence numbers. Updating Prefix will not affect the previous invoices.</p>
        </Col>
    </Col>
</Form.Group>   

<Form.Group as={Row} className="mb-3 justify-content-md-center" controlId="number">
    <Col md={12} lg={4}>
        <Flex direction='lg-row-reverse'>  
            <p>
            Invoice sequence number            
            </p>
        </Flex>        
    </Col>
    <Col md={12} lg={6}>
        <Col  lg={12} xl={6}>
            <Form.Control  
                // id="number"
                label="Number"
                name="number"
                value={number}
                type="number"
                className="form-control"
                onChange={({ target }) => this.handleChange(target)}
            />
            <p className='fs--1 text-600'>Think twice before changing it. choosing a lesser number is impossible.</p>
        </Col>
    </Col>
</Form.Group>  

<Form.Group as={Row} className="mb-3 justify-content-md-center  pb-5" controlId="Save">
    <Col md={12} lg={4}>
        <Flex direction='lg-row-reverse'>  
        <p></p>
        </Flex>        
    </Col>
    <Col md={12} lg={6}>
        <Col  lg={12} xl={12}>
        {this.state.loading ?
        ""
        : 
            <Button

            size="sm"
            className="mt-2 fs--1 fw-normal"
            type="submit" 
            onClick={this.handleSettingUpdate}
            >
            <IoSaveOutline className=" fs-2 me-2"/> Save and push the new number
            </Button> 
        } 
        </Col>
    </Col>
</Form.Group>

                {/* <Card className="mb-3">
                    <FalconCardHeader title="Invoice Number" light={false} />
                    <CardBody className="bg-light">
                        <Col md={12}>
                        <FormGroupInput
                                    id="prefix"
                                    label="Prefix invoice number"
                                    name="prefix"
                                    value={prefix}
                                    className="form-control"
                                    onChange={({ target }) => this.handleChange(target)}
                        />
                        <FormGroupInput
                            id="number"
                            label="Number"
                            name="number"
                            value={number}
                            type="number"
                            className="form-control"
                            onChange={({ target }) => this.handleChange(target)}
                        />                                 
                        </Col>                       
                    </CardBody>
                    <CardFooter>
                        {this.state.loading ?
                        ""
                        : 
                            <ButtonIcon

                            color="falcon-default"
                            size="sm"
                            icon="check"
                            className="ml-2"
                            iconClassName="fs--2"
                            type="submit" 
                            onClick={this.handleSettingUpdate}
                            >
                            Save Invoice Number
                            </ButtonIcon> 
                        } 
                    </CardFooter>                   
                </Card>                  */}
            </Fragment>
        );
    }
}
export default NumberSetting;