
import React, {Fragment, Component} from 'react';
import { Link  }               from 'react-router-dom';
import { Drawer, Space , Steps, message, Popconfirm, Button} from 'antd';
// import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types'

/*
|
*/

import { MdOutlineOpenInNew } from 'react-icons/md';
import { PiEyeDuotone, PiLayoutDuotone, PiArrowSquareRightDuotone  } from 'react-icons/pi';

import { RightOutlined } from '@ant-design/icons';


/*
| Invoice
*/
import InvoiceSummary from "../../invoices/components/partials/InvoiceSummary";
import InvoicePreview   from '../../invoices/components/InvoicePreview';
import InvoiceService   from '../../invoices/services/';

class DrawerInvoice extends Component {

    static defaultProps = {
        idInvoice : -1,
        open : false,
        showButton : true,
        content : <div>No Content</div>,
    }

    constructor(props) {

        super(props);
        this.state = {
            idInvoice : this.props.idInvoice,
            invoice       : {},
            isLoading: true,
            open : this.props.open
        }
        this.getInvoice(this.props.idInvoice);
      }

      componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open > this.props.open
        });
      }

      onClose = () => {
        this.setState({
            open: false,
        });

        if( this.props.hasOwnProperty("handleShowQuickView") )
        {
            this.props.handleShowQuickView(false);
        }

      };

      onOpen = () => {
        this.setState({
            open: true,
        });
      };


      getInvoice = (_idInvoice) => {
        InvoiceService.getInvoiceById(_idInvoice).then((res) => {
          try {
    
            if( res.data !== undefined ){
             let invoice = res.data;
              this.setState({
                invoice : invoice,
                isLoading:false
              });
            }            
          
          } catch(error){
              console.log(error);
          }
        });   
      }  



    render(){
        return (
            <Fragment>
                {(this.props.showButton ? 
                    <Button size={'small'} onClick={() => { this.onOpen() }} className=" bg-100 border-none"    ><PiLayoutDuotone className='fs-1  ' /></Button> 
                : null)}
                <Drawer
                    md={6}
                    destroyOnClose="true"
                    title={<p className='mb-0'>Invoice quick view</p>}
                    width={(window.innerWidth > 1000 ? '50%' : window.innerWidth)}
                    onClose={this.onClose}
                    open={this.state.open}
                    // bodyStyle={{ padding: '10px' }}

                    extra={
                        <Space>
                            <Link className='btn btn-sm btn-falcon-default' to={`/invoices/invoice-details/${this.state.idInvoice}`}>
                                <PiArrowSquareRightDuotone className='fs-1' /> open {this.state.invoice.generateType}
                            </Link>
                        </Space>
                      }

              >
                <div className=''>
                <InvoiceSummary {...this.state.invoice} />
                </div>   

                <InvoicePreview  idInvoice={this.state.idInvoice}  />
                </Drawer>               
            </Fragment>
        )
    }

}

export default DrawerInvoice;