import api from '../api/';
const URL = '/uploader/';

{/*
| Requirements
|
*/}
class UploaderService {



    uploadFile(formData , onUploadProgress) {

      return api.post(URL + "uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });

    }

    upload(data , onUploadProgress) {
        let formData = new FormData();
        formData.append("file",    data.file );
        formData.append("idTable", data.idTable );
    
        return api.post(URL + "/uploadBase64", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        });
      }
    uploadBase64(base64, onUploadProgress) {
      return api.post(URL + "/uploadBase64", base64, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });      
    }
    invoiceUploadBase64(base64, onUploadProgress) {
      return api.post(URL + "/invoiceUploadBase64", base64, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });      
    }
}

export default new UploaderService();