import React, { Component, Fragment } from 'react';
import PropTypes            from 'prop-types';



/*
| UI
*/
import { Badge, Collapse, Space, Tooltip, Divider, Typography  } from 'antd';

// import {   Table } from 'reactstrap';
import {  Card, Col, Row, Table, Placeholder }  from 'react-bootstrap';

import Flex from 'components/common/Flex';
/*
| ICONS
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { PiEyeSlashDuotone, PiTextAlignLeftDuotone } from 'react-icons/pi';

// import Loader               from '../../common/Loader';
// import ButtonIcon           from '../../common/ButtonIcon';
// import createMarkup         from '../../../helpers/createMarkup';

/*
| Pages
*/


import ClientContactSection    from './partials/client/ClientContactSection';
import PreviewSkeleton from './Skeleton/PreviewSkeleton';


/*
| Utils
*/
import { isIterableArray, formatCurrency, decimalZeroRemove, capitalize, isEmpty }     from '../../../helpers/utils';
import { _getLineItemTotal, _getLineExtraFeeTotal, _getSubtotalInvoice, _getTotalInvoice, GetExtraFeeMessage } from '../services/utils';


import InvoiceService  from '../services/';

class InvoicePreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idInvoice     : this.props.idInvoice,
            invoice       : {},
            isLoading: true,
            redirect: null
        }
        

    }

    componentDidMount(){
      try{
        
         this.getInvoice(this.state.idInvoice);
    
        } catch(error){
    
        } 
    }

    getInvoice = (_idInvoice) => {
      InvoiceService.getInvoiceById(_idInvoice).then((res) => {
        try {
  
          if( res.data !== undefined ){
           let invoice = res.data;
            this.setState({
              invoice : invoice,
              isLoading:false
            });
          }            
        
        } catch(error){
            console.log(error);
        }
      });   
    }    

    render()
        {
          const { Text, Title, Paragraph } = Typography;
            const  {
                invoice
            } = this.state;
            
            const ItemsRowPreView = ({ serviceName, serviceDescription, itemSpecifications, quantity, price, extraFee,  tax, discount, discountAmount, item },  key) => {
              
              let prepareData = {
                item:item,
                discountFormat: invoice.discountFormat,
                taxformat:      invoice.taxformat 
              }

                return (
                    <tr key={key}>
                        <td className="align-middle">
                        <h6 className="mb-0 text-nowrap">{serviceName}
                        {( invoice.configuration.displayItemSpecs ? 
                          ( itemSpecifications.length > 0 ?
                          <span className="text-primary ms-2 fs--2">Has specifications</span>  
                          : '')
                          : '')  }                     
                        </h6>
                        <p className="mb-0">{serviceDescription}</p> 
                        {/* {( invoice.configuration.displayItemSpecs ?
                          ( itemSpecifications.length > 0 ? 
                              itemSpecifications.map((spec, index) => 
                                {
                                    return (
                                      <Fragment key={index}><small>{spec.name}:</small> {spec.value} {(itemSpecifications.length-1 > index ? ',' : '' )}</Fragment>
                                    )
                                }
                                )                         

                            : '')
                          : '')} */}
                        </td>
                        <td className="align-middle text-center">
                          <p className='mb-0 text-900 font-sans-serif'>{(invoice.unitsFormat == 'hours'?quantity:decimalZeroRemove(quantity))}</p>  
                        </td>
                        <td className="align-middle text-right">
                          <p className='mb-0 text-900 font-sans-serif'>{formatCurrency(price, invoice.currencySymbol)} {(extraFee != 0 ? <span className='text-primary'>({formatCurrency(extraFee, invoice.currencySymbol)})</span> : null)}</p>
                        </td>
                        {( invoice.configuration.displayItemDiscount ? <td className="align-middle text-right"><p className='mb-0 text-900 font-sans-serif'>-{formatCurrency(discountAmount, invoice.currencySymbol)}</p></td>: '')}
                        {( invoice.configuration.displayItemTax ? <td className="align-middle text-right"><p className='mb-0 text-900 font-sans-serif'>{invoice.taxformat}{tax}</p></td>: '')}
                        
                        <td className="align-middle text-right "><p className='mb-0 text-900 font-sans-serif'>
                          {
                          formatCurrency(_getLineItemTotal(prepareData), invoice.currencySymbol)
                          }</p></td>
                    </tr>
                    );
            }


            const ItemsSpecifications = ({ serviceName, serviceDescription, itemSpecifications, quantity, price, tax, currencySymbol }, key) => {
              return (
                <Fragment>
                  
                  <h6 className="mb-2 mt-3 p-0">{capitalize(serviceName)}</h6>
                  <div className='border rounded'>
                  {(itemSpecifications.map((specification, index) => {
                  return (
                  <Fragment>
                    
                    <Table className='m-0 bg-100' size='sm' >
                      <tbody className=''>
                        <tr>
                        <td className='' style={{width:'50%'}}>                    
                        {(!specification.invoiceDisplay? <Tooltip placement='top' title={'Your customer can not see this option'}><PiEyeSlashDuotone className='me-1 text-danger' /></Tooltip> : null)}{capitalize(specification.name)} 
                        </td>                           
                        <td className='p-0'> 
                        {(
                            specification.type == 'text'
                          || specification.type == 'number'
                          || specification.type == 'email'
                          || specification.type == 'textarea'
                          || specification.type == 'url'
                          ? 
                          !isEmpty(specification.value)?<h6 className="mb-0 mt-0 p-0">{capitalize(specification.value)}</h6>: <h6 className="mb-0 mt-0 p-0 text-400">EMPTY</h6>
                          : null

                        )}

                        {(isIterableArray(specification.options) && specification.options.length > 0 ? 
                        <Table className='m-0 bg-100' size='sm' borderless>
                        <tbody className='p-0'>
                          <tr>
                            <td className='w-75'>
                              <strong className="">{(!isEmpty(specification.value)?
                              (isIterableArray(specification.options) && specification.options.map((val, i)=>{
                                return(
                                  <Fragment>{( val.isChecked ? 
                                    <Fragment>
                                      <p className='m-0'>{val.name}</p>
                                      {(!isEmpty(val.description)? 
                                          <p className='m-0 fw-normal'>{val.description}</p>
                                      : null)}                                      
                                      
                                    </Fragment>
                                    : null)}
                                  </Fragment>
                                );
                              }))
                            : null)}</strong>
                            </td>
                            <td width={50}>
                            <strong className="">{(!isEmpty(specification.value)?
                              (isIterableArray(specification.options) && specification.options.map((val, i)=>{
                                return(
                                  <Fragment>
                                    {( val.isChecked ? <p className='m-0'>x{val.defaultQTY}</p>: null)}
                                  </Fragment>
                                );
                              }))
                            : null)}</strong>          
                            </td>                        
                            <td className="text-start">
                            <strong className="">{(!isEmpty(specification.value)?
                              (isIterableArray(specification.options) && specification.options.map((val, i)=>{
                                return(
                                  <Fragment>
                                    {( val.isChecked ? <p className='m-0'>
                                      {(val.hasAmount ? formatCurrency((val.amount*val.defaultQTY),currencySymbol ) : 'NA')}
                                    </p>: null)}
                                  </Fragment>
                                );
                              }))
                            : null)}</strong>          
                            </td>
                          </tr>
                        </tbody>
                        </Table>
                        : null)}
                          
                        </td> 
                      </tr>
                    </tbody>
                    </Table> 
                  </Fragment>
                  )}))}
                  </div>
                </Fragment>
              );
            }
  // const CustomerSection = (customer) => {
  //   return (<Fragment>
  //     <h5>{customer.firstName} test</h5>
  //     {/* <p className="fs--1" dangerouslySetInnerHTML={createMarkup(customer.address)} /> */}                
  //       {(!this.isEmpty(customer.mobile)
  //       ?
  //       <a className="text-black font-weight-semi-bold fs--1 d-block mt-1" href={`tel:${customer.mobile}`}>
  //         <FontAwesomeIcon icon="phone" className={`fs--1 mr-2 text-400`} />{customer.mobile}
  //       </a>             
  //       : <p className="text-danger fs--1 mb-0"><FontAwesomeIcon icon="phone" className={`fs--1 mr-2 text-400`} />Mobile number is missing</p>
  //       )}      

  //       {(!this.isEmpty(customer.phone)
  //       ?
  //       <a className="text-black font-weight-semi-bold fs--1 d-block mt-1" href={`mailto:${customer.phone}`}>
  //         <FontAwesomeIcon icon="phone" className={`fs--1 mr-2 text-400`} />{customer.phone}
  //       </a>       
  //       : ""
  //       )}
  //       {(!this.isEmpty(customer.email)
  //       ?
  //       <a className="text-black font-weight-semi-bold fs--1 d-block mt-1" href={`mailto:${customer.email}`}>
  //         <FontAwesomeIcon icon="envelope" className={`fs--1 mr-2 text-400`} />{customer.email}
  //       </a>       
  //       : <p className="text-danger fs--1"><FontAwesomeIcon icon="envelope" className={`fs--1 mr-2 text-400`} />Email number is missing</p>
  //       )}
  //     {(!this.isEmpty(customer.note)
  //     ?
  //     <Row>
  //       <Col md="auto">
  //               <Badge.Ribbon  text="Note (Only you can see)" style={{borderRadius:'3px', fontSize:'12px', marginTop:'-15px'}} color="volcano">
  //                 <Card className="mt-4 pt-3 mb-1 shadow-none bg-200 rounded-soft p-2 border-none border-200">
  //                   <h6>{customer.note}</h6>
  //                 </Card>
  //               </Badge.Ribbon>           
  //       </Col>     
  //     </Row>       
  //       : null
  //       )}               
  //     </Fragment>
  //   )};









       
  return(
    <Fragment>
        {(this.state.isLoading ? 
      <Fragment>
        <PreviewSkeleton />
      </Fragment>
      : 
      <Fragment>
<Fragment>




<Row className="justify-content-between align-items-center">

    <Col>
      {/* <h6>{invoice.incTitle} to {invoice.clients[0].firstName} {invoice.clients[0].lastName}</h6> */}
      {/* <CustomerSection {...invoice.clients[0]} />  */}
      <Row>
        <Col md={6}>
          <ClientContactSection customer={invoice.clients[0]} fullInfo={false} />
        </Col>
        <Col md={6}>

        </Col>
      </Row>      
    <Row>
      <Col md={6}>
      {( invoice.configuration.displayCustomerBilling  ?
        <ClientContactSection customer={invoice.clients[1]} fullInfo={true} />
      : '')}          
      </Col>
      <Col md={6}>
      {( invoice.configuration.displayCustomerShipping  ?
        <ClientContactSection customer={invoice.clients[2]} fullInfo={true} />
      : '')}         
        
      </Col>
    </Row>
    </Col>
  </Row>



{/* <Row className="justify-content-between align-items-center">
  <Divider orientation="left" orientationMargin="0" className='fs--1 mb-1'>Custom organization fields</Divider> 

  <Col md={12}  className="pt-3">
    <h6>Field {invoice.customField_1}</h6>
    <h6>{invoice.customFieldValue_1}</h6>
  </Col>

  <Col md={12}  className="pt-3">
    <h6>Field {invoice.customField_2}</h6>
    <h6>{invoice.customFieldValue_2}</h6>
  </Col>

  <Col md={12}  className="pt-3">
    <h6>Field {invoice.customField_3}</h6>
    <h6>{invoice.customFieldValue_3}</h6>
  </Col>    

</Row> */}


{( invoice.displaySetting.displayInternalCustomField_1 ||  invoice.displaySetting.displayInternalCustomField_2 || invoice.displaySetting.displayInternalCustomField_3 ? 
  <Fragment>
    <Divider orientation="left" orientationMargin="0" className='fs--1 mb-1'>Custom organization fields</Divider> 
<Row className='gx-0 bg-100 border border-dashed rounded p-2 p-md-3'>

{( invoice.displaySetting.displayInternalCustomField_1 ?
  <Col lg={6} className='mb-2'>
    <Flex>
      <div><PiTextAlignLeftDuotone className='fs-1 me-1 text-primary' /></div>
      <div>
        <p className='mb-0 text-600'>{invoice.customFieldTitle_1}:</p>
        <Text>{invoice.customFieldValue_1}</Text>    
      </div>
    </Flex>
  </Col>
: null)}

  
{( invoice.displaySetting.displayInternalCustomField_2 ?
  <Col lg={6} className='mb-2'>
    <Flex>
      <div><PiTextAlignLeftDuotone className='fs-1 me-1 text-primary' /></div>
      <div>
        <p className='mb-0 text-600'>{invoice.customFieldTitle_2}:</p>
        <Text>{invoice.customFieldValue_2}</Text>    
      </div>
    </Flex>
  </Col>
: null)}
{( invoice.displaySetting.displayInternalCustomField_3 ?
  <Col lg={6} className='mb-2'>
    <Flex>
      <div><PiTextAlignLeftDuotone className='fs-1 me-1 text-primary' /></div>
      <div>
        <p className='mb-0 text-600'>{invoice.customFieldTitle_3}:</p>
        <Text>{invoice.customFieldValue_3}</Text>    
      </div>
    </Flex>
  </Col>
: null)}
{( invoice.displaySetting.displayInternalCustomField_4 ?
  <Col lg={6} className='mb-2'>
    <Flex>
      <div><PiTextAlignLeftDuotone className='fs-1 me-1 text-primary' /></div>
      <div>
        <p className='mb-0 text-600'>{invoice.customFieldTitle_4}:</p>
        <Text>{invoice.customFieldValue_4}</Text>    
      </div>
    </Flex>
  </Col>
: null)}



  

  </Row>
  </Fragment>
: null)}









<Row className="justify-content-between align-items-center">
  <Divider orientation="left" orientationMargin="0" className='fs--1 mb-1'>{invoice.itemsFormat} and descriptions</Divider> 
</Row>
  
  <div className="table-responsive mt-0 fs--1 border rounded">
    <Table striped >
      <thead>
          <tr className="bg-light text-dark">
            <th className="border-0  text-capitalize">{invoice.itemsFormat}</th>
            <th className="border-0  text-center text-capitalize">{invoice.unitsFormat}</th>
            <th className="border-0  text-right text-capitalize">{invoice.priceFormat}</th>{( invoice.configuration.displayItemDiscount ?<th className="border-0 text-right">Discount</th>: '')}{( invoice.configuration.displayItemTax ?<th className="border-0 text-right">Tax</th>: '')}
            <th className="border-0  text-right text-capitalize">{invoice.amountFormat}</th>
         </tr>
      </thead>
      <tbody>
        {isIterableArray(invoice.items) &&
          invoice.items.map((item, index) => <ItemsRowPreView {...item} item={item} key={index} />)}

      </tbody>
    </Table>
  </div> 



  <Row>
  <Col xs={12} >
  

<Row className="justify-content-between align-items-center">
  <Divider orientation="left" orientationMargin="0" className='fs--1 mb-1'>{invoice.itemsFormat} and descriptions</Divider> 
</Row>
 
  {( !invoice.configuration.displayItemSpecs ?
  <span className='fs--1 text-nunito '><BsFillExclamationTriangleFill className='text-warning fs-1' /> <span className='text-warning'>(Specifications is set to not show)</span> Your customer won't be able to see the details showing below</span>
  : ''
  )}

  <div className="table-responsive mt-0 fs--1">
  <Table size='sm' className='' responsive borderless>
  <tbody>
  {isIterableArray(invoice.items) &&
          invoice.items.map((item, index) => {
            if( item.hasSpecifications )
            {
              return (
                <tr key={index}>
                <td className="align-middle p-0">
                  <ItemsSpecifications {...item} key={index} currencySymbol={invoice.currencySymbol} />
                </td>
                </tr>
              )
            }
            
          }
          
          )}
  </tbody>
</Table>
</div> 

</Col>
</Row>


<Row>
  <Col lg={6}>

  </Col>
  <Col lg={6}>

  <h6 className="mt-2 font-weight-bold">
    Billing Summary
  </h6>     

    <Col className=''>
      <div className='border rounded'>
      <Table size="sm"  className="fs--1 text-right w-100">
        <tbody>
          <tr>
            <th className="text-900">Subtotal:</th>
            <td className="font-weight-semi-bold"><p className='mb-0 text-900 font-sans-serif'>{formatCurrency(invoice.subTotal, invoice.currencySymbol)}</p></td>
          </tr>
          {( invoice.configuration.displaySummaryDiscount ?
          <tr>
            <th className="text-900">Discount {formatCurrency(invoice.discount, invoice.discountFormat)}:</th>
            <td className="font-weight-semi-bold"><p className='mb-0 text-900 font-sans-serif'>-{formatCurrency(invoice.totalDiscountsAmount, invoice.currencySymbol)}</p></td>
          </tr>
          : '')}

          {invoice.taxes.map((defaultTaxe, index) => (
          <tr key={index}>
            <th className="text-900 text-nowrap ">
              {defaultTaxe.abbreviation} {formatCurrency(defaultTaxe.value, invoice.taxformat)}
              {(defaultTaxe.isNumberShown == "1" ? 
                  <span className='fs--1 p-1'>- {defaultTaxe.taxNumber}</span>
                : '')}              
              :</th>
            <td className="font-weight-semi-bold"><p className='ms-1 mb-0 text-900 font-sans-serif'>{formatCurrency(defaultTaxe.amount, invoice.currencySymbol)}</p></td>
          </tr>          
          ))}

        {( invoice.configuration.displaySummaryShipping ?
          <tr>
            <th className="text-900">Shipping:</th>
            <td className="font-weight-semi-bold"><p className='mb-0 text-900 font-sans-serif'>{formatCurrency(invoice.shipping, invoice.currencySymbol)}</p></td>
          </tr>
          : '')}
          <tr className="border-top border-2x font-weight-bold text-900">
            <th>{invoice.totalAmountTitle} ({invoice.currencyCode}):</th>
            <td><p className='mb-0 text-900 font-sans-serif fs-0'>{formatCurrency(invoice.totalBill, invoice.currencySymbol)}</p></td>
          </tr>
        </tbody>
      </Table>
      </div>
    </Col>
    
  </Col>
  </Row>

</Fragment>
      </Fragment>
      // {InvoicePreview}
      )}
 




    </Fragment>
  )
}
}

export default InvoicePreview;