import React, { Fragment }  from 'react';
import PropTypes            from 'prop-types';
// import { Col, Card, Row }              from 'reactstrap';
import {  Card, Col, Row, Table, OverlayTrigger  }  from 'react-bootstrap';
import Flex from '../../../../common/Flex';
import { Badge, Popover  } from 'antd';
import { Media } from 'reactstrap';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import {capitalize, isEmpty, phoneNumFormatter} from '../../../../../helpers/utils';
// import emailImage      from '../../../../../assets/img/icons/email.svg';
// import expressDelivery from '../../../../../assets/img/icons/express-delivery.svg';
import billingImage from '../../../../../assets/img/icons/docs.png';

/*
| ICONS
*/
import { PiNotePencilDuotone, PiPhoneDuotone, PiPhoneSlashDuotone, PiTruckDuotone, PiUserFocusDuotone, PiEnvelopeSimpleDuotone, PiReceiptDuotone } from "react-icons/pi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FaShippingFast, FaFileInvoiceDollar} from 'react-icons/fa';

import {HiOutlineUserCircle} from 'react-icons/hi';
import {BsCreditCard2Front, BsQuestion} from 'react-icons/bs';
import {FcBusinessman, FcCurrencyExchange, FcShipped, FcInTransit, FcAddressBook, FcInfo} from 'react-icons/fc';
/*
| Varexe Helper
*/
/*
| Customers
*/
import CustomerDisplayCard from '../../../../customers/components/customerOverview/CustomerDisplayCard';

const CustomerDetailRow = ({ title, isLastItem, children }) => (
  <Row>
    <Col className="pr-0" xs={12} sm={12}>
      <p
        className={classNames('font-weight-semi-bold fs--2 p-0', {
          'mb-0': isLastItem,
          'mb-1': !isLastItem
        })}
      >
        {title}:
      </p>
    </Col>
    <Col className="fs--1 font-weight-bold">{children}</Col>
  </Row>
);
CustomerDetailRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLastItem: PropTypes.bool
};

CustomerDetailRow.defaultProps = { last: false };

// const HighlightMedia = ({ src, icon, to, title, missing, children }) => {
//   return (

//     <table className="m-0">
//     <tr>
//     <td width="20">
//         <FontAwesomeIcon icon={icon} className="fs--1 mr-2 text-300" />               
//     </td>      
//     <td>
//       <h6 className="mb-0 ">
//         {title}:
//       </h6>                
//     </td>
//     <td>
//     <span className="mb-0"> 
//     {( !isEmpty(children) ?
//       <Media tag={Link} className="align-items-center mb-0" to={to}>
//         <a className="text-black font-weight-semi-bold fs--1" href={`tel:${children}`}>{children}</a>
//       </Media>
//     : <span className="text-muted fs--1 ml-1">{missing}</span> )}                
//     </span>                
//     </td>
//     </tr>
//   </table>


//   );
// };



const clientContactSection = ({customer, fullInfo}) => {
  return (
    <Fragment>
      <div className="border border-1 border-300 rounded-2 p-3 pb-0 ask-analytics-item position-relative mb-3">
        <Flex className="mb-2">
        {/*icon-item icon-item-sm border rounded-3 shadow-none me-2 */}
          <div className="me-2 icon-box p-2 rounded-2 bg-soft-primary">
          
            {/* <FontAwesomeIcon icon="video" className={`text-success`} /> */}
            
            {/* <img src={(customer.type == 'billing' ? billingImage : expressDelivery )} width="30" height="30" /> */}
            {(customer.type == 'main' ?     <PiUserFocusDuotone      className='fs-2 text-primary' /> : null )}
            {(customer.type == 'billing' ?  <PiReceiptDuotone        className='fs-2 text-primary' /> : null )}
            {(customer.type == 'shipping' ? <PiTruckDuotone          className='fs-2 text-primary' /> : null )}
            
          </div>
          <div className="flex-1">
            <p className="mb-0 fs--2 text-500 text-underline">{capitalize(customer.type)} Account info</p>

            <Popover placement="rightTop" title={''} content={<CustomerDisplayCard idClient={customer.idClient} editable={false} cardBorder="success" boxShadow={true}  style={{boxShadow : 'none'}}  />} arrow={true}>
              <h6 className=" mb-0  text-capitalize fs-0 text-underline" style={{ display:'inline', cursor:'help'}}>
              {( !isEmpty(customer.fullName) ? customer.fullName : ('Enter customer name') )}
              </h6>
            </Popover>

            {/* <PopoverStickOnHover
                component={<CustomerDisplayCard idClient={customer.idClient} editable={false} cardBorder="success" boxShadow={true} style={{  zIndex:'9999', minWidth:'400px'}}  />}
                placement="top"
                onMouseEnter={() => { }}
                delay={200}
            >
            <h6 className=" mb-0  text-capitalize fs-0 text-underline" style={{ display:'inline', cursor:'help'}}>
            {( !isEmpty(customer.fullName) ? customer.fullName : ('Enter customer name') )}
            </h6>
            </PopoverStickOnHover> */}


          </div>
        </Flex>
<Row>
<Table size='sm' className='p-0 m-0'>
  <tbody>
  <tr>
    <td style={{width:50}}>
      <Flex direction={'row'}>
        <h6 className="text-700 mb-0  fs--2">Cell:</h6>      
      </Flex>
    </td>
    <td>
      <h6 className="text-700 mb-0">{(isEmpty(customer.mobile)? <span className='text-danger'><PiPhoneSlashDuotone className="fs-1 me-2" /> Empty Mobile number</span>: 
        <Fragment>
          <PiPhoneDuotone className="fs-1 me-2" /> <a href={`tel:${customer.mobile}`} className="fs--1 text-dark"> 
          {customer.mobile}
          </a> 
        </Fragment>
      
      )}</h6>
    </td>
  </tr>
  <tr style={{borderColor:'transparent'}}>
    <td style={{width:50}}>
      <Flex direction={'row'}>
        <h6 className="text-700 mb-0  fs--2">Email:</h6>      
      </Flex>
    </td>
    <td>
      <h6 className="text-700 mb-0">{(isEmpty(customer.email)? <span className='text-danger'><PiEnvelopeSimpleDuotone className="fs-1 me-2" /> Empty Email Address</span>: 
        <Fragment>
          <PiEnvelopeSimpleDuotone className="fs-1 me-2" />
            <a href={`mailto:${customer.email}`} className="fs--1 text-dark"> 
            {customer.email}
            </a>        
        </Fragment>
      )}</h6>
    </td>
  </tr>  
  </tbody>
</Table>
{(fullInfo ?
<div className="bg-light ps-0 pe-1 pt-1 pb-0  ">
    
  <Table striped  size='sm' className='p-0 mt-0'>
    <tbody>
    <tr>
      <td className='' style={{width:50, padding:'0 0 0 20px'}}><h6 className="text-700 mb-0 me-2 fs--1 text-nowrap">Address Line 1:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.addressLine1}</h6></td>
    </tr>
    <tr>
      <td className='' style={{width:50, padding:'0 0 0 20px'}}><h6 className="text-700 mb-0 me-2 fs--1 text-nowrap">Address Line 2:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.addressLine2}</h6></td>
    </tr>           
    {/* <tr>
      <td className='' style={{width:50, padding:'0 0 0 20px'}} ><h6 className="text-700 mb-0 me-2 fs--2 text-nowrap">Unit:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.unit}</h6></td>
    </tr>    
    <tr>
      <td className='' style={{width:50, padding:'0 0 0 20px'}}><h6 className="text-700 mb-0 me-2 fs--2 text-nowrap">Street Number:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.apartment}</h6></td>
    </tr>  
    <tr>
      <td className='' style={{width:50, padding:'0 0 0 20px'}}><h6 className="text-700 mb-0 me-2 fs--2 text-nowrap">Street Name:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.street}</h6></td>
    </tr>    
    <tr>
      <td className='' style={{width:50, padding:'0 0 0 20px'}}><h6 className="text-700 mb-0 me-2 fs--2 text-nowrap">Address/Other:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.address}</h6></td>
    </tr>    
    */}
    <tr style={{borderColor:'transparent'}}>
      <td className='' style={{width:50, padding:'0 0 0 20px'}}><h6 className="text-700 mb-0 me-2 fs--1 text-nowrap">Zipcode:</h6></td>
      <td className='p-0' ><h6 className="text-700 mb-0">{customer.postalCode}</h6></td>
    </tr>      
    </tbody>
  </Table>
      </div>
: null )}

                </Row>

                </div>

    </Fragment>
  );
};
export default clientContactSection;