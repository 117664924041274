
  import React , { Fragment } from 'react';
//   import {  Select } from 'reactstrap';
import PropTypes from 'prop-types'
//   import { Card, InputGroup, Button,  Col, Row, Form, Tab, Nav } from 'react-bootstrap';
//// NEW

    import { Divider, Space, Form, Checkbox,  Input, Radio, Button, Typography, Segmented, Modal , Select, Col , Card, Row, Skeleton, notification, Alert, Popconfirm    } from 'antd';
    import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined, SaveOutlined, QuestionCircleOutlined, CheckCircleTwoTone  } from '@ant-design/icons';
//   import { } from 'react-bootstrap';
//// NEW


  import classnames         from 'classnames';
  import { Link, Redirect } from 'react-router-dom';
  import FormGroupInput   from '../../common/FormGroupInput';
  import ButtonIcon       from '../../common/ButtonIcon';
  import FalconCardHeader from '../../common/FalconCardHeader';
  import Loader           from '../../common/Loader';
  import ToastVarexe      from '../../common/ToastVarexe';

  import moment from 'moment';
/*
| Header
*/
import Flex              from '../../common/Flex';
import ButtonIconTooltip from '../../common/ButtonIconTooltip';
import AppContext        from '../../../context/Context';

/*
| Multiple contacts
*/
import CustomerContactNumber        from './partials/CustomerContactNumber';
import CustomerEmailAddress         from './partials/CustomerEmailAddress';
import CustomerWebPage         from './partials/CustomerWebPage';

import AdvancedCountrySelect        from '../../countries/components/AdvancedCountrySelect';
import CountryChangeOnModal         from '../../countries/components/CountryChangeOnModal';
  



import DatePicker from 'react-datepicker';
import SimpleBarReact from 'simplebar-react';


/*
| Page
*/
import CustomerDisplayCardCustomerDetails from './customerOverview/CustomerDisplayCardCustomerDetails';
import CustomerForm  from './CustomerForm';


import CustomerBillingForm  from './CustomerBillingForm';
/*
| Utils
*/

import {isIterableArray} from '../../../helpers/utils'

/*
| Service
*/
import CustomerService  from '../services/';

class CustomerEdit extends React.Component {

    static defaultProps = {
        idClient : -1,
        segment  : 'general',
    }


    constructor(props) {
        super(props)
        this.state = {
            client: {},
            idClient : this.props.idClient,
            loading: true,
            segment :  this.props.segment,
            displayBillingAccount  : false,
            displayShippingAccount : false,
        }
    }


    componentDidMount(){  

        this.getPageData()
    }

    getPageData = (CallBack = false) => {
        this.setState({
            loading : true
        });     
        CustomerService.getCustomerById(this.state.idClient).then((res) =>{
        try{
            if( res.data !== undefined ){
            if( res.data ){
                let customer = res.data;
                let _state   = this.state;

                _state.client = customer;
                _state.loading   = false;
                
                this.setState(_state); 
                if( CallBack )
                {
                    this.props.customerFormCallBack(_state.client);
                }
            }
            }
            } catch(error){
            console.log(error);
            } 
    
            });         
    }


/*
| Change handles
*/



  
  
  handleCompanyUpdating = (_targerType, name, value) => {
    let i = 0;
    let _state   = this.state.client;
    if( _targerType == 'main' || _targerType == 'mainAccount'  )
    {
        _state.clientCompanies = _state.clientCompanies.map((item, index) => { 
       if (i !== index) return item; 
       return { ...item, [name]: value };
     });
    } 
    if( _targerType == 'billingAccount'  )
    {
        _state.billingAccount.clientCompanies = _state.billingAccount.clientCompanies.map((item, index) => { 
       if (i !== index) return item; 
       return { ...item, [name]: value };
     });
    } 
   
    if( _targerType == 'shippingAccount'  )
    {
        _state.shippingAccount.clientCompanies = _state.shippingAccount.clientCompanies.map((item, index) => { 
       if (i !== index) return item; 
       return { ...item, [name]: value };
     });
    } 
     this.setState(_state);
  
  }
  
  handleCompanyChangeDropDown = ( _targerType, data ) => {
    this.handleCompanyUpdating(_targerType, data.name, data.value)
  } 
  
  handleCompanyChange = ( _targerType ) => (event) => {
    
    const name  = event.target.name;
    const value = event.target.value;
    
    this.handleCompanyUpdating(_targerType, name, value)
  }
  
  
  
  
  handleCustomerWebPageChange = ( _targerType , value) => {
    
    let _state   = this.state.client;
    if( _targerType == 'main' || _targerType == 'mainAccount'  )
    {
        _state.clientWebPages = value;
    } 
    if( _targerType == 'billingAccount'  )
    {
        _state.billingAccount.clientWebPages = value;
    }  
    if( _targerType == 'shippingAccount'  )
    {
        _state.shippingAccount.clientWebPages = value;
    }    
    this.setState(_state);

  }



  handleRemoveShipping = () =>{

    CustomerService.removeShipping(this.state.client.idClient).then((res) =>{
          
      let result = res.data;
      if( result.status == 200 )
      {
        this.getPageData();
      }     
  });
  
  }


  customerFormCallBack = (data) => {   

    this.setState({
        idClient : data.idClient,
        client : data
    }) 

    this.getPageData(true);
    
  }
  customerFormCallBackBilling = () => {  
    this.getPageData();
  }   
  customerFormCallBackShipping= () => {
    this.getPageData();
  }    




    render(){
        const { isRTL } = this.context;        
        const {
            firstName,
            lastName,
            email, 
            mobile,   
            note,
            type,                 
            clientContactNumbers,
            clientEmailAddresses,
            clientWebPages,
            coordinates,
            clientCompanies,
            gender,
            dateOfBirth
          } = this.state.client;
        return (
            <Fragment>





    {(this.state.loading ?

        <Col className='p-3' style={{ maxWidth: 600, margin:'auto' }}>
        <Skeleton active paragraph={{ rows: 7 }} />
        <Divider orientation="left"></Divider>
        <Skeleton active paragraph={{ rows: 7 }} />
        </Col> 
:                 
            <Fragment >

                {(this.state.client.type == 'main' ? 
                <Col className='mt-0 p-0' style={{ maxWidth: 600, margin:'auto' }}>
                    <Row>
                        <Space style={{  margin:'auto' }} className=''>

                            <Segmented 
                                    
                                    size='' 
                                    className='mt-4 mb-0 bg-200' 
                                    options={[
                                        {
                                        label: 'General',
                                        value: 'general',
                                        },
                                        // {
                                        // label: 'Contacts',
                                        // value: 'contacts',
                                        // },     
                                        {
                                        label: 'Billing',
                                        value: 'billing',
                                        icon: (this.state.client.isBilling ? <CheckCircleTwoTone twoToneColor="#52c41a" />: null),
                                        disabled: (this.state.idClient < 1 ? true : false)
                                        },       
                                        {
                                        label: 'Shipping',
                                        value: 'shipping',
                                        icon: (this.state.client.isShipping ? <CheckCircleTwoTone twoToneColor="#52c41a" />: null),
                                        disabled: (this.state.idClient < 1 ? true : false)
                                        },                                                                                                 
                                    ]}  
                                    onChange={(value)=>{
                                        this.setState({segment: value})
                                    }}                  
                                />
                        </Space>
                    </Row>
                    </Col>
                : null)}




                <div className="p-3">


             {(this.state.segment == 'general' ? 
                <Fragment>
                    <div className=''>
                        <CustomerForm 
                        {...this.state.client} 
                        idMain={this.state.client.idClient}
                        target = "main" 
                        customerFormCallBack={this.customerFormCallBack}
                        />  
                    </div>

                </Fragment>
                : null)}
                



                    {/*  BILLING */}

                                {(this.state.segment == 'billing' ?
                                    <Fragment>
                                            <div style={{ maxWidth: 600, margin:'auto' }}>
                                                <CustomerBillingForm idClient={this.state.client.idClient} />
                                            </div>
                                    </Fragment>
                                :null
                                )}

                    {/* END BILLING  */}




{/*  SHIPPING */}



{(this.state.segment == 'shipping' ?
                 <Fragment>

                    {(!this.state.client.isShipping ? 
                    <Fragment>
                        <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 18 }}
                            layout="horizontal"
                            disabled={false}
                            style={{ maxWidth: 600, margin:'auto' }}

                            size='large'
                            >
                            {(!this.state.displayShippingAccount ? 
                            <Alert message="Shipping account is the same as primary account. Uncheck if you would like to add a shipping account." type="success" />    
                            : 
                            <Alert message="If your customer's shipping information is different from its primary account, complete the form below and make sure to save it." type="info" />
                            )}    
                                <Checkbox
                                className='mt-3 mb-3'
                                checked={(this.state.displayShippingAccount ? false : true )}
                                onChange={(e) => {
                                    let value = !e.target.checked;
                                    this.setState({displayShippingAccount: value});
                                    }}
                                >
                                Shipping Acount is the same as Primary Acount
                                </Checkbox>
                        </Form>  

                        {(this.state.displayShippingAccount ? 
                                <CustomerForm 
                                {...this.state.client.shippingAccount} 
                                idMain={this.state.client.idClient}
                                target = "shippingAccount" 
                                customerFormCallBack={this.customerFormCallBackShipping}
                                />            
                        : null)} 



                    </Fragment>
                        : 
                        (this.state.client.shippingAccount.idClient > 0 ?
                        <div className={``} >


                            <Col style={{ maxWidth: 600, margin:'auto' }} >
                                <Row justify="end">
                                    <Popconfirm
                                            title="Delete the shipping account"
                                            description="Are you sure to delete this shipping account?"
                                            onConfirm={this.handleRemoveShipping}
                                            okText="Yes"
                                            cancelText="No"
                                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                            >
                                        <Button danger>Delete shipping account</Button>
                                    </Popconfirm>    
                                </Row>
                            </Col>       
                            
                            <CustomerForm 
                            {...this.state.client.shippingAccount} 
                            idMain={this.state.client.idClient}
                            target = "shippingAccount" 
                            customerFormCallBack={this.customerFormCallBackShipping}
                            />  
                        </div>
                        : 
                        <Fragment></Fragment>              
                        )
                    )}


                 </Fragment>
            :null
             )}

{/* END SHIPPING  */}



</div>
            </Fragment>
)}


            </Fragment>
        )
    }

}

export default CustomerEdit;