import React, {Fragment, Component} from 'react';

import { Divider } from 'antd';
import { Offcanvas, Button, Form }  from 'react-bootstrap';
// import { SwatchesPicker    }          from 'react-color';
import { CirclePicker     }          from 'react-color';


import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

import Flex                   from '../../Flex';
import ToastVarexe            from '../../ToastVarexe';

import {isEmpty} from '../../../../helpers/utils'



import TagService        from '../services/';

class TagsVarexe extends Component
{

    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isCanvasShowing:this.props.isCanvasShowing,
            isCanvasEditingShowing:false,
            placement:'end',
            

            selectedTag : {
                id:-1,
                name:'',
                bgColor:'0079FF',
            },

            tags:[]
            
        }
    }

    componentDidMount(){
        this.getFullTags();
      }  


      getFullTags = () => {
          let state = this.state;
         TagService.getTags(state).then( (response) => {
            try {
              if( response.data !==  undefined && response.data.data !==  undefined )
              {
                let result = response.data;
                state.tags = result.data;
                // state.listPageIndex = result.listPageIndex;
                // state.listPageSize  = result.listPageSize;
                // state.listTotalSize = result.listTotalSize;
      
                this.setState(state);        
              }        
            } catch(error){
              console.log(error);
            }
        });
      }

      handleOpenNew = () => {
        let state = this.state;
        state.selectedTag = {
            id:-1,
            name:'',
            bgColor:'0079FF',
        }  
        state.isCanvasEditingShowing    = true;
        this.setState(state);        
      }
      handleEditing = (label) => {
    
        this.setState({
            isCanvasEditingShowing:true,
            selectedTag:label
        })
      }

      handleChangeName = (e) => {
        let state = this.state;
        state.selectedTag.name = e.target.value;
        this.setState(state);   
      }

      handleBackgroundColorChange = (e) => {
        let state = this.state;
        state.selectedTag.bgColor = e.hex;
        this.setState(state);    
      }

      handleSave = () => {
        let state = this.state;

        if( isEmpty(state.selectedTag.name) )
        {
            state.selectedTag.name = '';
            this.setState(state); 

            ToastVarexe({
                type :  'error',
                title: 'Tag\'s name can not be empty!',
                message: 'Tag\'s name can not be empty!',
                duration:5
            }); 
            return false;

        } else {

            TagService.updateTag(state.selectedTag.id, state.selectedTag).then( (response) => {
                try {
                  if( response.data !==  undefined && response.data.data !==  undefined )
                  {
                    let result = response.data;
                    let tagResult = result.data;
    
    
                    if( state.selectedTag.id != -1 )
                    {
                        state.tags = state.tags.map((tag, index) => {
                            if(tag.id != tagResult.id){
                                return tag;
                            } else {
                                tag.bgColor = tagResult.bgColor;
                                tag.rgb     = tagResult.rgb;
                                tag.color   = tagResult.color;
                                tag.name    = tagResult.name;
                                return tag;
                            }
                        });
                    } else {
                        state.tags.push(tagResult);
                    }
                    state.isCanvasEditingShowing    = false;
                    this.setState(state); 
    
    
                  }        
                } catch(error){
                  console.log(error);
                }
            });
        }

      }

      handleDeleteTag = (_id) => {
        let state = this.state;
            TagService.deleteTag(_id).then( (response) => {
                try {
                  if( response.data !==  undefined && response.data.data !==  undefined )
                  {
                    let result = response.data;
                    let tagResult = result.data;
                        state.tags.map((tag, index) => {
                            if(tag.id == _id){

                                state.tags.splice(index , 1);
                            }
                        });
                    this.setState(state); 
    
    
                  }        
                } catch(error){
                  console.log(error);
                }
            });        
      }

      handleCanvasClose = () => {
        this.setState({isCanvasShowing:false})
      }
      handleEditingCanvasClose = () => {
        this.setState({isCanvasEditingShowing:false})
      }

    render(){

        const colors = [
            '#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', 
            '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', 
            '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', 
            
            '#827717', '#FFC107', '#FF9800', '#FF5722', '#7BDCB5', '#00D084', ]

        return(
            <Fragment>

            <Offcanvas show={this.state.isCanvasShowing} onHide={this.handleCanvasClose} placement={this.state.placement} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Tags</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <p className='text-nunito'>Categorizing your list can help you organize and track your orders, task, or customers with the help of Tags.
                 You can create new Tags and assign them names and colors.
                </p>
                    <div className="px-3">
                        <Button
                        variant="primary"
                        size="sm"
                        className="d-block w-100  text-nunito"
                        onClick={()=>{this.handleOpenNew()}}
                        >
                        Create label Tag
                        </Button>
                    </div>
                    <Divider orientation="left">Your Tags</Divider>
                    <div className="p-3 border rounded">
                        {this.state.tags.map(item => (
                            <div className="d-grid gap-2 text-end">
                                <Flex direction="row">
                                    <Button
                                        as="button"
                                        size="sm"
                                        className={`rounded-1 mb-2  text-start w-75 text-nunito`}
                                        // style={{background:`rgba(${label.rgb.r}, ${label.rgb.g}, ${label.rgb.b}, .8)` , color: `#${label.color}`}}
                                        style={{ background:`rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, .08)`, color: `rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, 1)`, borderColor:  `rgba(${item.rgb.r}, ${item.rgb.g}, ${item.rgb.b}, 1)` }}
                                        key={item.id}
                                    >
                                        {item.name}
                                    </Button>
                                    <Button size="sm" variant="outline-default border-0" className="mb-1 ms-2 fs-1 p-0"
                                    onClick={
                                        ()=>{
                                            // this.setState({isCanvasEditingShowing:true})
                                            this.handleEditing(item)
                                        }
                                    }
                                    ><FiEdit /></Button>
                                    <Button size="sm" variant="outline-default border-0" className="mb-1 ms-2 fs-1 p-0"
                                    onClick={()=>{
                                        this.handleDeleteTag(item.id);
                                    }}
                                    ><AiOutlineDelete className='' /></Button>
                                </Flex>
                            </div>
                        ))}
                    </div>
            </Offcanvas.Body>
            </Offcanvas>





            <Offcanvas show={this.state.isCanvasEditingShowing} onHide={this.handleEditingCanvasClose} backdrop={false} scroll={false} placement={this.state.placement} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Editing Tag</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {/* <p className='text-nunito'> 2
                </p> */}
                <Divider orientation="left">Give a name</Divider>
                <div className="px-3 mb-5">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" value={this.state.selectedTag.name } onChange={this.handleChangeName} placeholder="Enter your Tag name" />
                    </Form.Group>
                </div>

                <Divider orientation="left">Select a color</Divider>
                <div className="px-3 mb-5">
                <CirclePicker  
                    color={ this.state.selectedTag.bgColor }
                    colors={colors}
                    onChangeComplete={ this.handleBackgroundColorChange }
                    height={600}
                />
                </div>


                <div className="px-3 mt-3">
                        <Button
                        variant="primary"
                        size="sm"
                        className="d-block w-100  text-nunito"
                        onClick={()=>{this.handleSave()}}
                        >
                        Save label
                        </Button>
                </div>


            </Offcanvas.Body>
            </Offcanvas>






            </Fragment>
        )
    }

}
export default TagsVarexe;