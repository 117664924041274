import React from 'react'
import { connect } from 'react-redux'
// import { Layout } from 'antd'
import { Col, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import Main from '../../Main';
// import AuthCardLayout from '../../layouts/AuthCardLayout';
// import AuthBasicLayout from '../../layouts/AuthBasicLayout';
// import LoginForm from '../../components/auth/LoginForm';
// import Sidebar from 'components/cleanui/layout/Sidebar'
// import SupportChat from 'components/cleanui/layout/SupportChat'
// import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = props => {
  return (
      <Main {...props}>
        
        {/* <AuthCardLayout  /> */}
        {/* <AuthCardLayout> */}
          {/* <Row className="text-left justify-content-between">
          <Col xs="auto">
            <h5>Log in</h5>
          </Col>
          <Col xs="auto">
            <p className="fs--1 text-600">
              or <Link to="/auth/register">create an account</Link>
            </p>
          </Col>
        </Row> */}
        {/* <LoginForm /> */}
        {/* </AuthCardLayout> */}
      </Main>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
