import {capitalize, isEmpty, phoneNumFormatter, formatCurrency} from '../../../../../helpers/utils';


export const SummaryData= (obj, columnColor) => {

    let summary = [];
    
    summary.push( [ {text: "Subtotal", fillColor: columnColor} , formatCurrency( obj.subTotal ,obj.currencySymbol)  ] );
    
    if(obj.displaySetting.displaySummaryDiscount)
    {
      
      summary.push( [{text: "Discounts", fillColor: columnColor}, (obj.discount > 0 ? "-" + formatCurrency( obj.discount ,obj.configuration.discountFormat)  : formatCurrency( obj.discount ,obj.configuration.discountFormat) )]);
    
    }
    
    for (let index = 0; index < obj.taxes.length; index++) {
      const element = obj.taxes[index];
    
      let taxNumber = '';
      if(element.isNumberShown=="1")
      {
        taxNumber = '-' +element.taxNumber;
      }
      summary.push( [{text: element.abbreviation + taxNumber, fillColor: columnColor}, obj.taxformat + element.value ]);
    }
    
    if(obj.displaySetting.displaySummaryShipping)
    {
      
      summary.push( [{text: "Shipping", fillColor: columnColor}, formatCurrency( obj.shipping ,obj.currencySymbol)]);
    }
    
    
    summary.push( [{text: obj.totalAmountTitle+ " ("+obj.currencyCode+")", fillColor: columnColor}, formatCurrency( obj.totalBill ,obj.currencySymbol)]);
    
    
    return summary;      
}

export const PaymentData= (obj, columnColor) => {
  let payment = [];

  for(let index = 0; index < obj.payments.length; index++){

    const p = obj.payments[index];
    
    payment.push( [{text: 'Payment on ' + p.paymentDate + ' using ' +  p.paymentMethod.name , fillColor: columnColor}, formatCurrency( p.payment ,obj.currencySymbol) ]);
  }
  
  payment.push( [{text: obj.amountDue+ " ("+obj.currencyCode+")", fillColor: columnColor}, formatCurrency( obj.totalBillDue ,obj.currencySymbol)]);
  return payment;
}


export const chooseSummary = ($type, obj) => {
  let layout;
  let columnColor = '#FFF';

        //    layout = 'noBorders';
        //    layout = 'lightHorizontalLines';
          //  layout = {
          //     fillColor: function (rowIndex, node, columnIndex) {
          //       return rowIndex === 0 ? "#f2f2f2" : null;
          //     }};

    switch($type){  

      case 1 :
        

          columnColor = '#f2f2f2';
        return Summary1(obj, layout, columnColor);
      break;
      case 2 :
           layout = 'lightHorizontalLines';
        return Summary1(obj, layout, columnColor);
      break;      
     
      default:
        return Summary1(obj);
      break;
    }
  }

  export const Summary1 = (obj, layout, columnColor) => {
    let data =  [
       [
        {
          table:{
            // alignment: "right",
            widths: ['60%','40%'],
            body:
              SummaryData(obj, columnColor)
            
          },
          layout: layout,                        
        },
        
        
      ],
      [
        {
          table:{
            // alignment: "right",
            widths: ['60%','40%'],
            body:
              PaymentData(obj, columnColor)
            
          },
          layout: layout,                        
        },
        
        
      ],      
    ]
    return data;    
  }