import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import { Col, ProgressBar, Row, OverlayTrigger, Tooltip, Placeholder, Card, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvoiceService  from '../../../invoices/services/';
import TrendComponent from '../../../invoices/analytics/TrendComponent';
import Flex from '../../../common/Flex';


import { getColor, rgbaColor, formatCurrency, capitalize } from 'helpers/utils';

/*
| IF PIE CHART
*/
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);
/*
| end IF PIE CHART
*/


export default  class Statistics extends Component {
  static defaultProps = {
    flexDirection: 'row'
  }; 

    constructor(props){
        super(props)
        this.state = {
            idClient :this.props.idClient,
            flexDirection : this.props.flexDirection,
            loading  :true,
            data: [],
            total: 0,
            totalDraft: 0,
            totalInvoice: 0,
            invoiceGross: [],
        }       

    }


    componentDidMount(){

        InvoiceService.getCustomerInvoiceSummeryById(this.state.idClient).then((res) =>{
            try{
               
              if( res.data !== undefined ){
                if( res.data ){
                    let _data = res.data.data;
                    let _state= {...this.state}

                    _data.invoiceStatus.map((item, index)=>{

                        



                        let variant;
                        let color;
                        switch (item.name) {
                            case 'Canceled':
                                // variant = 'danger';
                                color = rgbaColor(getColor('danger'), 0.5);
                            break;
                            case 'Draft':
                                // variant = 'warning';
                                // _state.totalDraft += parseInt(item.value);
                                color = rgbaColor(getColor('warning'), 0.5);
                            break;
                            case 'Invoiced':
                                variant = 'primary';
                                // _state.totalInvoice += parseInt(item.value);
                                color = rgbaColor(getColor('primary'), 0.5);
                            break;
                            default:
                                //  variant = 'success';
                                color = rgbaColor(getColor('success'), 0.5);
                            break;
                        }

                        _state.total += parseInt(item.value);
                        // if( item.value == 0) return;
                        // _state.data.push(
                        //     {
                        //         id:      index,
                        //         amount:  item.value,
                        //         variant: variant,
                        //         name:    item.name                                
                        //     }
                        // )
                        if( item.value == 0) return;
                        _state.data.push(
                            {
                                value:  item.value,
                                name:    item.name ,
                                itemStyle: {
                                    color: color
                                }                                                              
                            }
                        )

                    });
                    
                _state.invoiceGross = _data.invoiceGross;
                _state.loading      = false;
                  this.setState(_state);  
                }
              }
              } catch(error){
                console.log(error);
              } 
        });
    }



    render(){
/*
| IF PIE CHART
*/

        const updateDimensions = () => {
            if (window.innerWidth < 530) {
              chartRef.current.getEchartsInstance().setOption({
                series: [{ radius: '45%' }]
              });
            }
            else
              chartRef.current.getEchartsInstance().setOption({
                series: [{ radius: '60%' }]
              });
          }

          const getOption = () => ({
            title: [
              {
                // text: 'Pie Label Align Chart',
                left: 'center',
                textStyle: {
                  color: getColor('600')
                }
              },
              {
                // subtext: 'alignTo: "labelLine"',
                left: '50%',
                top: '85%',
                textAlign: 'center',
                subtextStyle: {
                  color: getColor('700')
                }
              }
            ],
        
            tooltip: {
              trigger: 'item',
              padding: [7, 10],
              backgroundColor: getColor('100'),
              borderColor: getColor('300'),
              textStyle: { color: getColor('dark') },
              borderWidth: 1,
              transitionDuration: 0,
              axisPointer: {
                type: 'none'
              }
            },
        
            series: [
              {
                type: 'pie',
                radius: window.innerWidth < 530 ? '45%' : '60%',
                center: ['50%', '50%'],
                data: this.state.data,
                label: {
                  position: 'outer',
                  alignTo: 'labelLine',
                  bleedMargin: 5,
                  color: getColor('700')
                },
                left: '5%',
                right: '5%',
                top: 0,
                bottom: 0
              }
            ]
            });

/*
| end IF PIE CHART
*/


          const ContainerContent = () => {
            
            return (
              <Fragment>
                {this.state.invoiceGross.map((item, index)=>
                   {
                       if( item.initials == "TX" )
                       {
                           return;
                       }
                       if( item.initials == "DUE" )
                       {
                        if( item.count <= 0 )
                        {
                          return;

                        }
                       }                       
                   return (
                   

                    <Fragment>
                      <div className='flex-fill p-2'>

                      <Table 
                          className={classNames('m-0 p-0', {
                            'text-danger ': item.initials === "DUE",
                          })}                      
                      >
                        <tr className='p-0 m-0'>
                          <td valign='top' className='p-0 m-0'>

                              <h6 className={classNames('mb-0 p-0 text-nowrap', {
                                  'text-danger ': item.initials === "DUE",
                                })}
                              >
                              {item.title}
                              <TrendComponent trend={item.trend} percent={item.percent} initials={item.initials} style={'pills'}  />
                            </h6>
                            <p className='fs--1 p-0 mb-0 text-nowrap'><b>{item.count}</b> {item.description}</p>                            
                            <p className='fs--2 p-0 text-600'>{capitalize(item.trend)} in last 14 days</p>                            
                          </td>
                          <td valign='top' className='p-0 m-0'>
                               <h6 
                                  className={classNames('m-0 p-0 text-end', {
                                    'text-danger ': item.initials === "DUE",
                                  })}                               
                               >{formatCurrency(item.amount, item.currency)}</h6>
                          </td>
                        </tr>

                      </Table>


                      </div>
                    </Fragment>

                  //  <Col className={(index < this.state.invoiceGross.length-1 ? 'border-end border-200' : "")}>
                  //   <h6 className="fs--1 text-600 mb-0">{item.title}</h6>
                  //   <p className="fs--1 text-600 mb-0">{item.description}</p>
                  //   <h4 className="mb-0">{formatCurrency(item.amount, item.currency)}</h4>
                  //   <TrendComponent trend={item.trend} percent={item.percent} initials={item.initials} style={'pills'}  />
                  //   </Col>
                    )
                   }
                )}
              </Fragment>
            )

          }



        return (
          <Fragment>
              {(this.state.loading?
              <Fragment>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} /> 
                        <Placeholder xs={12} /> 
                    </Placeholder>                    
              </Fragment>
              : 
              <Fragment>
            <Row className="mb-2">
            <Flex direction={this.state.flexDirection} >
                <ContainerContent />
            </Flex>

              {/* <Col xs={4} className="border-end text-center border-200">
                <h4 className="fs-0 mb-0">{this.state.totalInvoice}</h4>
                <p className="fs--1 text-600 mb-0">Invoices</p>
              </Col>
              <Col className="text-center">
                <h4 className="fs-0 mb-0 text-600">{this.state.totalDraft}</h4>
                <p className="fs--1 text-600 mb-0">Draft</p>
              </Col> 
 {(this.state.total > 0? 
<Col>
     <ReactEChartsCore
        echarts={echarts}
        option={getOption()}
        style={{ height: '100px' }}
    />    
</Col>
: null)} */}



            </Row>
            {/* {(this.state.total > 0? 
            <Fragment>
            <ProgressBar
              className="overflow-visible mt-4 rounded-3"
              style={{ height: '6px' }}
            >
              {this.state.data.map((item, index) => (
                <ProgressBar
                  variant={item.variant}
                  now={parseInt((parseInt(item.amount) * 100) / this.state.total)}
                  key={item.id}
                  className={classNames('overflow-visible position-relative', {
                    'rounded-end rounded-pill ': index === 0,
                    'rounded-start rounded-pill': index === this.state.data.length - 1,
                    ' border-end border-white border-2': index !== this.state.data.length - 1
                  })}
                  label={
                      <OverlayTrigger
                      placement="top"
                      overlay={
                          <Tooltip id={'tooltip-'+ item.name}>
                          {item.name}
                          </Tooltip>
                      }
                      >
                      <span className="mt-n4 text-800"> {parseInt((item.amount * 100) / this.state.total)}%</span>
                      </OverlayTrigger>
                  }
                />
              ))}
            </ProgressBar>

                
 

                </Fragment>

            : null)} */}
            </Fragment>
            )}
          </Fragment>
        );
    }
};

