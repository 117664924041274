
import React, {Component, Fragment} from 'react'

/*
| UI
*/
import { Typography   } from 'antd';
import { Button, Row, Col, Form, Card, Tooltip, Table } from 'react-bootstrap';
import Flex    from '../../../common/Flex';
import Avatar  from '../../../common/Avatar';


/*
| Utils
*/

import {  formatCurrency }     from '../../../../helpers/utils';

export default class InvoiceSummary extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount(){

    }




    render(){
        const { Text, Title, Paragraph } = Typography;
        return(
            <Fragment>
                <Card className='mb-3 rounded-1'>

                    {/* <Card.Header className={`mb-0 p-0 badge-varexe-secondary`}>
                        <div className='ms-2 mt-2'>

                            <Title level={4} className={`text-capitalize text-secondary`}>{this.props.generateType} {this.props.stateTag}</Title>                            
                        </div>
                    </Card.Header> */}

                    <Card.Header className={`mb-0 p-0 badge-varexe-${this.props.invoiceStatus.class}`}>
                        <div className='ms-2 mt-2'>
                            {/* <h6 className=' mb-0'>{this.props.incTitle} status</h6> */}
                            <Title level={4} className={`text-capitalize text-${this.props.invoiceStatus.class}`}>{this.props.generateType} {this.props.invoiceStatus.name}</Title>                            
                        </div>
                    </Card.Header>


                    <Card.Body className=''>
                    <h6 className='mt-0 mb-1'>{this.props.incTitle} Summary</h6>

                    <Flex wrap='wrap' className="">
                        <div className="me-3 flex-grow-1"><Text>{this.props.numberTitle}:</Text></div>
                        <div className="text-end"><h1 className="text-primary fw-semi-bold fs-2 p-0 m-0 ">{this.props.prefix}{this.props.number}</h1></div>
                    </Flex>




{/* 
{( this.props.displaySetting.displayInternalCustomField_1 ||  this.props.displaySetting.displayInternalCustomField_2 || this.props.displaySetting.displayInternalCustomField_3 ? 
<Row>
  <Col xs={12}>
      <h6 className="mt-3 font-weight-bold">
      Custom organization fields
      </h6>
      <Table borderless size="sm"  className=" ">
        <tbody>
          {( this.props.displaySetting.displayInternalCustomField_1 ?
          <tr className={`${( this.props.displaySetting.displayCustomField_2 || this.props.displaySetting.displayCustomField_3 ? 'border-bottom' : null )}`}>
            <th className="text-900">{this.props.customFieldTitle_1}:</th>
            <td className="font-weight-semi-bold"><p className='mb-0 text-900 font-sans-serif'>{this.props.customFieldValue_1}</p></td>
          </tr>
          : '')}
        {( this.props.displaySetting.displayInternalCustomField_2 ?
          <tr className={`${( this.props.displaySetting.displayCustomField_3 ? 'border-bottom' : null )}`}>
            <th className="text-900">{this.props.customFieldTitle_2}:</th>
            <td className="font-weight-semi-bold"><p className='mb-0 text-900 font-sans-serif'>{this.props.customFieldValue_2}</p></td>
          </tr>
          : '')}
        {( this.props.displaySetting.displayInternalCustomField_3 ?
          <tr className=''>
            <th className="text-900">{this.props.customFieldTitle_3}:</th>
            <td className="font-weight-semi-bold" ><p className='mb-0 text-900 font-sans-serif'>{this.props.customFieldValue_3}</p></td>
          </tr>
          : '')}
        {( this.props.displaySetting.displayInternalCustomField_4 ?
          <tr className=''>
            <th className="text-900">{this.props.customFieldTitle_4}:</th>
            <td className="font-weight-semi-bold" ><p className='mb-0 text-900 font-sans-serif'>{this.props.customFieldValue_4}</p></td>
          </tr>
          : '')}

        </tbody>
      </Table>   
  </Col>
  </Row>
: null)} */}
















                    <Flex wrap='wrap' className="mt-3 border-bottom">
                        <div className="flex-grow-1"><Text>{this.props.billingDateTitle}:</Text></div>
                        <div className=""><b><Text>{this.props.billingDateFormated}</Text></b></div>
                    </Flex>
                    {(this.props.configuration.displayDueDate?
                    <Flex wrap='wrap' className="">
                        <div className="flex-grow-1"><Text>{this.props.dueDateTitle}:</Text></div>
                        <div className=""><b><Text>{this.props.dueDateFormated}</Text></b></div>
                    </Flex>
                    : null)}
                    <Flex wrap='wrap' className="bg-100 mt-3 rounded">
                        <div className="p-2 flex-grow-1"><Text>{this.props.totalAmountTitle} <span className='fs--2'>({this.props.currencyCode})</span>:</Text></div>
                        <div className="p-2"><b><Text>{formatCurrency(this.props.totalBill,this.props.currencySymbol)}</Text></b></div>
                    </Flex>                    

                    {(this.props.payments.length > 0 ?
                    <Fragment>
                        <h6 className='mt-3 mb-1'>Payment history</h6>
                        {
                            this.props.payments.map((payment, index)=>   
                            (payment.status? 
                                <Flex wrap='wrap' className={` ${ ( this.props.payments.length-1 != index ? "border-bottom" : null) }`}>
                                    <div className="flex-grow-1"><Text>Payment on {payment.paymentDate} using {payment.paymentMethod.name}</Text></div>
                                    <div className=""><b><Text>{formatCurrency(payment.payment,this.props.currencySymbol)}</Text></b></div>
                                </Flex>                    
                            
                                : null)                             
                            )                            
                        }
                    </Fragment>


                    : null )}   

                {(this.props.totalBill != this.props.totalBillDue? 

                    <>
                    {/* <h6 className='mt-3 mb-1'>{this.props.incTitle} Summary</h6> */}
                    <Flex wrap='wrap' className="bg-100 rounded mt-3">
                        <div className="p-2 flex-grow-1 "><Text>{this.props.amountDue} ({this.props.currencyCode}):</Text></div>
                        <div className="p-2 "><b><Text>{formatCurrency(this.props.totalBillDue,this.props.currencySymbol)}</Text></b></div>
                    </Flex>                            
                    </>

                : null)}

                    <Flex direction='row-reverse'>
                        <div className=" mt-3">
                            <Flex>
                                <div><Avatar className='me-2' size="xl" rounded="circle" src={this.props.createdByObj.imageDisplay} /></div>
                                <div>
                                    
                                <h6 className="mb-0">{this.props.createdByObj.firstName} {this.props.createdByObj.lastName}</h6>
                                <p className='m-0 fs--1 text-nunito'><b>{this.props.createdDateFormated}</b></p>                                    
                                </div>
                            </Flex>
                        </div>
                    </Flex>                  


                    </Card.Body>
                </Card>
            </Fragment>
        )
    }

}