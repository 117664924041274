
import api from '../../../../services/api/';
const URL = '/businesses/';

class CompanyService {

    getSetting(){
        return api.get('invoices/setting' );
    }
    
    updateSetting(params){
      return api.put('invoices/setting', params);
      
    }    
    getCompanyInformation(){
      return api.get(URL + 'company_information' );
    }
    updateCompanyInformation(params){
      return api.put(URL + 'company_information', params);
      
    }    
    getBusinessBilling(){
        return api.get(URL + 'company_billing' );
    }
    updateBusinessBilling(params){
      return api.put(URL + 'company_billing', params);
      
    }    
    updateOtherFormatSetting(params){
        return api.put(URL + 'updateOtherFormatSetting', params);
        
    }    
    
    settinglogo(formData){
        return api.post(URL + "/settinglogo", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
    }  
    settingCompanyCoverImage(formData){
        return api.post( URL + "settingCompanyCoverImage", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
    }  






  stripe_paymentSetup(data){
      return api.post(URL + 'prepare_new_payment_setup', data);
  }
  stripe_handlePaymentSetup(data){
      return api.post(URL + 'handle_payment_setup', data);
  }
  stripe_setPaymentMethodDefault(data){
      return api.post(URL + 'set_payment_method_default', data);
  }
  stripe_removePaymentMethod(data){
      return api.post(URL + 'remove_payment_method', data);
  }





}

export default new CompanyService()