import React from 'react';
import PropTypes from 'prop-types';


import dashboard from 'assets/img/icons/3d/dashboard.png';
import calendar from 'assets/img/icons/3d/calendar-star.png';
// import calendar from 'assets/img/icons/calendar.png';
import credit from 'assets/img/icons/3d/credit-card.png';
import product from 'assets/img/icons/3d/product.png';


const imageSwitch = (call) => {

    switch(call){

        case 'dashboard':
            return dashboard;
        break;
        case 'calendar':
            return calendar;
        break;
        case 'credit':
            return credit;
        break;
        case 'product':
            return product;
        break;

    }

}

const IconImage = ({ icon, ...props }) => (
    <img src={imageSwitch(icon)} {...props} width={25} />
);

IconImage.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default IconImage;
